import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Typography } from '@mui/material';
import { HiExclamationCircle } from "react-icons/hi2";
export default function LogoutWarning({handleClickOpen,handleClose,open,text}) {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

  return (
    <React.Fragment>
     
     

      <Dialog open={open}
        onClose={handleClose}>
      <Stack
        justifyContent={"space-around"}
        alignItems={"center"}
        sx={{ height: "200px" }}>
        <DialogTitle
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            color: "rgba(193, 32, 37, 1)",
          }}>
          <HiExclamationCircle size={25} />
        Log Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", color: "black" }}>
            <Typography variant="body2">
            "Are you sure you want to Logout?"
              
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
          }}>
          <Button
            sx={{ width: "50%", height: "45px" }}
            variant="outlined"
            onClick={handleClose}
            color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            color="error"
            sx={{ width: "50%", height: "45px" }}>
            Log Out
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
    </React.Fragment>
  );
}