import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import profile from "../images/doctor.png";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  Stack,
  styled,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { contact } from "../redux/data/action";

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const ChatBox2 = ({ isOpen, toggleChat, setIsOpen }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [hospital, setHospital] = useState("");
  const [errors, setErrors] = useState("");
  const maxLength = 100;
  const [openSubmission, setOpenSubmission] = useState(false);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue?.length <= maxLength) {
      setComment(inputValue);
    }
  };
  const handleCloseSubmission = () => {
    setOpenSubmission(false);

    setOpen(false);
  };

  const handleInputChange = (fieldName, value) => {
    const error = validateField(fieldName, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error || "",
    }));

    switch (fieldName) {
      case "userName":
        setUserName(value);
        break;
      case "hospital":
        setHospital(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "comment":
        setComment(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "userName":
        if (!value.trim().match(/^[a-zA-Z.\s]+$/)) {
          error = "Invalid username";
        }
        break;
      case "hospital":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid hospital name ";
        }
        break;
      case "email":
        if (!value.trim().match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[\w-]{2,4}$/)) {
          error = "Invalid email address.";
        }
        break;
      case "phone":
        if (!value.trim().match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "comment":
        if (!value.trim().match(/^[#.0-9a-zA-Z\s,-]+$/)) {
          error = "Invalid address format.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    ["userName", "hospital", "phone", "comment", "email"].forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospital"
          ? hospital
          : key === "phone"
          ? phone
          : key === "comment"
          ? comment
          : key === "email"
          ? email
          : "";
      const error = validateField(key, value);
      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!hospital.trim()) {
      formErrors["hospital"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phone.trim()) {
      formErrors["phone"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!comment.trim()) {
      formErrors["comment"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      dispatch(contact(userName, email, phone, comment, hospital));
      setOpen(false);
      setOpenSubmission(true);
      setTimeout(() => {
        handleCloseSubmission();
      }, 2000);
      setUserName("");
      setHospital("");
      setPhone("");
      setEmail("");
      setComment("");
      setErrors("");
      setIsOpen(!isOpen);
      //
    } else {
      // /////console.log("Form has errors. Please correct them.");
    }
  };

  const handleIconClick = () => {
    setUserName("");
    setHospital("");
    setPhone("");
    setEmail("");
    setComment("");
    setErrors("");
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className="overlay"
        style={{ display: isOpen ? "block" : "none" }}
        onClick={toggleChat}
      ></div>

      <div
        className="chat-box"
        style={{
          display: isOpen ? "flex" : "none",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {/* <Box
            component="img"
            src={profile}
            sx={{ width: "100%", height: "100px" }}
          /> */}
          <Box sx={{ position: "relative", width: "100%", height: "100px" }}>
            <Box
              component="img"
              src={profile}
              alt="Profile"
              sx={{ width: "100%", height: "100%" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
                cursor: "pointer",
                color: "white",
                backgroundColor: "rgba(193, 32, 37, 1)",
                width: "20px",
                height: "20px",
                padding: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={toggleChat}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
            How can we help you?
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  placeholder="Mr. Samuel Arora"
                  variant="filled"
                  size="small"
                  sx={{ width: "100%" }}
                  InputProps={{
                    style: { height: "50px", fontSize: "15px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  value={userName}
                  onChange={(e) =>
                    handleInputChange("userName", e.target.value)
                  }
                />
                {errors.userName && <HelperText>{errors.userName}</HelperText>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  placeholder="Apollo Hospitals, Delhi"
                  variant="filled"
                  size="small"
                  sx={{ width: "100%" }}
                  InputProps={{
                    style: { height: "50px", fontSize: "15px" },

                    startAdornment: (
                      <InputAdornment position="start">
                        <ApartmentIcon />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  value={hospital}
                  onChange={(e) =>
                    handleInputChange("hospital", e.target.value)
                  }
                />
                {errors.hospital && <HelperText>{errors.hospital}</HelperText>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  placeholder="samuel008@gmail.com"
                  variant="filled"
                  size="small"
                  sx={{ width: "100%" }}
                  InputProps={{
                    style: { height: "50px", fontSize: "15px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  value={email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
                {errors.email && <HelperText>{errors.email}</HelperText>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  placeholder="011 7179 1090"
                  variant="filled"
                  size="small"
                  sx={{ width: "100%" }}
                  InputProps={{
                    style: { height: "50px", fontSize: "15px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  value={phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
                {errors.phone && <HelperText>{errors.phone}</HelperText>}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  name="text"
                  placeholder="Add comments"
                  multiline
                  maxRows={10}
                  InputProps={{
                    style: { height: "100px", fontSize: "15px" },
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: maxLength,
                  }}
                  helperText={`${comment?.length}/${maxLength} characters`}
                  value={comment}
                  onChange={(e) => {
                    handleInputChange("comment", e.target.value);
                    handleChange(e);
                  }}
                />
                {errors.comment && <HelperText>{errors.comment}</HelperText>}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handleIconClick}
                  fullWidth
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    border: "1px solid rgba(193, 32, 37, 1)",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
      <Dialog open={openSubmission} onClose={handleCloseSubmission}>
        <Stack
          justifyContent={"space-evenly"}
          alignItems={"center"}
          sx={{ width: "420px", height: "200px" }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              color: "rgba(193, 32, 37, 1)",
            }}
          >
            Request submitted!
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogContentText sx={{ textAlign: "center", color: "black" }}>
              <Typography variant="body2">
                Your request has been successfully submitted.
                <br />
                 Our team will get in touch with you soon.
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Stack>
      </Dialog>
    </>
  );
};

export default ChatBox2;
