import {
  AccordionDetails,
  Box,
  Button,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import MuiAccordion from "@mui/material/Accordion";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  padding: "40px",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const InnerContainer = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  padding: "20px",
  borderRadius: "10px",
  display: "flex",
  width: "70%",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  border: "2px solid rgba(255, 255, 255, 1)",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  paddingBottom: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  marginBottom: 15,
  bgColor: "white",
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(248, 248, 248, 1)",
  height: "30px",
}));
const Faq = () => {
  const navigate = useNavigate();
  return (
    <Wrapper sx={{}}>
      <InnerContainer>
        <Typography variant="h4" sx={{ margin: "30px" ,fontWeight:600}}>
          Frequently Asked Questions (FAQs)
        </Typography>
        <Box
          sx={{
            width: " 90%",
            marginBottom: "30px",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",

            justifyContent: "center",
            borderRadius: "0px",
          }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <h4>How to place an order?</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign={"start"}>
                If you are a new customer create a free account by using this
                link https://vajra.software/registration. If you are an existing
                customer use the sign-in option provided in this link
                https://vajra.software/my-account/. If you have a pre-approved
                business account you get exclusive pricing, GST invoices, bulk
                purchasing options, credit facility by using the mobile
                application. You can also download the ASTHRA app from Google
                Play Store for a user-friendly shopping experience. Browse
                through our extensive product list, choose the product you need,
                add to cart, proceed to the cart, and input your personal
                details, including delivery address, contact number, and email
                id. Further, proceed to checkout to make the required payment.
                Once the payment is placed, your order has been confirmed and
                ready to be shipped to your delivery address.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <h4> Do I need an account to place order?</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign={"start"}>
                Yes, you do. For security purposes and authenticity of order
                delivery, we provide our services to only the registered users.
                To order with us, register today at Vajra and become an esteemed
                user. We would be very glad to be of your service. For any
                queries, call us at +91-8971580265 or write to us at
                contact@vajra.software
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <h4> Do I need an account to view the price of the product?</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign={"start"}>
                Yes, it is possible only post-registration with Vajra. To view
                the price of any product on Vajra, or request for Quotations,
                one has to Register on https://vajra.software or ASTHRA App and
                become a User.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <h4> How to contact Vajra</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign={"start"}>
                If you are having any trouble with registering or placing an
                order; or if you have any other queries related to our products
                and services, please feel free to contact us at
                contact@vajra.software or call us at +91-8971580265. We are here
                to be of your service during our regular business hours.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <h4> Is there any mobile app available</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign={"start"}>
                Yes there is. We have an Android App called Vajra where
                healthcare facilities such as hospitals, laboratories, and
                pharmacies can place their orders, and at the same time, Medical
                device Vendors can Sell their products to hospitals.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
       
      </InnerContainer>
    </Wrapper>
  );
};

export default Faq;
