import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { HiExclamationCircle } from "react-icons/hi2";
import { Box, Stack, Typography } from "@mui/material";

function DeleteButton({ openDelete, handleCloseDelete, handleDelete }) {
  return (
    <Dialog open={openDelete} onClose={handleCloseDelete}>
      <Stack
        justifyContent={"space-around"}
        alignItems={"center"}
        sx={{ height: "200px" }}>
        <DialogTitle
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            color: "rgba(193, 32, 37, 1)",
          }}>
          <HiExclamationCircle size={25} />
          Order delete request
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", color: "black" }}>
            <Typography variant="body2">
              Deleting this order will permanently remove it.
              <br />
              Please confirm your decision.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
          }}>
          <Button
            sx={{ width: "50%", height: "45px" }}
            variant="outlined"
            onClick={handleCloseDelete}
            color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            color="error"
            sx={{ width: "50%", height: "45px" }}>
            Delete
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default DeleteButton;
