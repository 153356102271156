import React from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Register from "../components/Register";
import SignIn from "../components/SignIn";
import ForgotPassword from "../components/ForgotPassword";
import TermsCondition from "../components/TermsCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclaimer from "../components/Disclaimer";
import Faq from "../components/Faq";
import Contact from "../components/Contact";
function PublicRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<SignIn />} />
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/terms-and-conditions" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default PublicRoute;
