import {
  POST_SIGNIN_FAILURE,
  POST_SIGNIN_REQUEST,
  POST_SIGNIN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from "./action";

const initState = {
  isLoading: false,
  isError: false,
  error: {},
  signIn: {},
  refreshToken: [],
  register: [],
  resetPassword: [],
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case POST_SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        signIn: action.payload,
      };

    case POST_SIGNIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        refreshToken: action.payload,
      };

    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        register: action.payload,
      };

    case REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        resetPassword: action.payload,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return {
        ...state,
      };
  }
};
