import axios from "axios";
import FileSaver from "file-saver";
const token = localStorage.getItem("AstraToken");

let branch = JSON.parse(localStorage.getItem("branchData"));
let userData = JSON.parse(localStorage.getItem("AstraUserData"));

export const ALL_ORDER_REQUEST = "ALL_ORDER_REQUEST";
export const ALL_ORDER_SUCCESS = "ALL_ORDER_SUCCESS";
export const ALL_ORDER_FAILURE = "ALL_ORDER_FAILURE";

export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT_FAILURE";

export const REQUEST_PRODUCT_REQUEST = "REQUEST_PRODUCT_REQUEST";
export const REQUEST_PRODUCT_SUCCESS = "REQUEST_PRODUCT_SUCCESS";
export const REQUEST_PRODUCT_FAILURE = "REQUEST_PRODUCT_FAILURE";

export const ORDER_DETAIL_BY_ID_REQUEST = "ORDER_DETAIL_BY_ID_REQUEST";
export const ORDER_DETAIL_BY_ID_SUCCESS = "ORDER_DETAIL_BY_ID_SUCCESS";
export const ORDER_DETAIL_BY_ID_FAILURE = "ORDER_DETAIL_BY_ID_FAILURE";

export const EDIT_ORDER_REQUEST = "EDIT_ORDER_REQUEST";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAILURE = "EDIT_ORDER_FAILURE";

export const NEW_ORDER_REQUEST = "NEW_ORDER_REQUEST";
export const NEW_ORDER_SUCCESS = "NEW_ORDER_SUCCESS";
export const NEW_ORDER_FAILURE = "NEW_ORDER_FAILURE";

export const DELETE_EDITED_PRODUCT_REQUEST = "DELETE_EDITED_PRODUCT_REQUEST";
export const DELETE_EDITED_PRODUCT_SUCCESS = "DELETE_EDITED_PRODUCR_SUCCESS";
export const DELETE_EDITED_PRODUCT_FAILURE = "DELETE_EDITED_PRODUCT_DELETE";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const TRANSACTION_REQUEST = "TRANSACTION_REQUEST";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILURE = "TRANSACTION_FAILURE";

export const ORDER_TRACKING_REQUEST = "ORDER_TRACKING_REQUEST";
export const ORDER_TRACKING_SUCCESS = "ORDER_TRACKING_SUCCESS";
export const ORDER_TRACKING_FAILURE = "ORDER_TRACKING_FAILURE";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

export const ADDPRODUCT_REQUEST = "ADDPRODUCT_REQUEST";
export const ADDPRODUCT_SUCCESS = "ADDPRODUCT_SUCCESS";
export const ADDPRODUCT_FAILURE = "ADDPRODUCT_FAILURE";



export const DASHBOARD_ANALYTICS_REQUEST = "DASHBOARD_ANALYTICS_REQUEST";
export const DASHBOARD_ANALYTICS_SUCCESS = "DASHBOARD_ANALYTICS_SUCCESS";
export const DASHBOARD_ANALYTICS_FAILURE = "DASHBOARD_ANALYTICS_FAILURE";

export const GET_INVALID_TOKEN_REQUEST = "GET_INVALID_TOKEN_REQUEST";
export const GET_INVALID_TOKEN_SUCCESS = "GET_INVALID_TOKEN_SUCCESS";
export const GET_INVALID_TOKEN_FAILURE = "GET_INVALID_TOKEN_FAILURE";

export const GET_AUTHENTICATION_ERROR_SUCCESS = "GET_AUTHENTICATION_ERROR_SUCCESS";

export const DOWNLOAD_PDF_REQUEST = "DOWNLOAD_PDF_REQUEST";
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS";
export const DOWNLOAD_PDF_FAILURE = "DOWNLOAD_PDF_FAILURE";

const allOrderRequest = () => {
  return { type: ALL_ORDER_REQUEST };
};
const allOrderSuccess = (data) => {
  return {
    type: ALL_ORDER_SUCCESS,
    payload: data,
  };
};
const allOrderFailure = (error) => {
  return {
    type: ALL_ORDER_FAILURE,
    payload: error,
  };
};
const contactRequest = () => {
  return { type: CONTACT_REQUEST };
};
const contactSuccess = (data) => {
  return {
    type: CONTACT_SUCCESS,
    payload: data,
  };
};
const contactFailure = (error) => {
  return {
    type: CONTACT_FAILURE,
    payload: error,
  };
};


const requestProductRequest = () => {
  return { type: REQUEST_PRODUCT_REQUEST };
};
const requestProductSuccess = (data) => {
  return {
    type: REQUEST_PRODUCT_SUCCESS,
    payload: data,
  };
};
const requestProductFailure = (error) => {
  return {
    type: REQUEST_PRODUCT_FAILURE,
    payload: error,
  };
};
const orderDetailByIdRequest = () => {
  return { type: ORDER_DETAIL_BY_ID_REQUEST };
};
const orderDetailByIdSuccess = (data) => {
  return {
    type: ORDER_DETAIL_BY_ID_SUCCESS,
    payload: data,
  };
};
const orderDetailByIdFailure = (error) => {
  return {
    type: ORDER_DETAIL_BY_ID_FAILURE,
    payload: error,
  };
};

const editOrderRequest = () => {
  return { type: EDIT_ORDER_REQUEST };
};
const editOrderSuccess = (data) => {
  return {
    type: EDIT_ORDER_SUCCESS,
    payload: data,
  };
};
const editOrderFailure = (error) => {
  return {
    type: EDIT_ORDER_FAILURE,
    payload: error,
  };
};

const newOrderRequest = () => {
  return { type: NEW_ORDER_REQUEST };
};
const newOrderSuccess = (data) => {
  return {
    type: NEW_ORDER_SUCCESS,
    payload: data,
  };
};
const newOrderFailure = (error) => {
  return {
    type: NEW_ORDER_FAILURE,
    payload: error,
  };
};

const placeOrderRequest = () => {
  return { type: PLACE_ORDER_REQUEST };
};
const placeOrderSuccess = (data) => {
  return {
    type: PLACE_ORDER_SUCCESS,
    payload: data,
  };
};
const placeOrderFailure = (error) => {
  return {
    type: PLACE_ORDER_FAILURE,
    payload: error,
  };
};

const deleteOrderRequest = () => {
  return {
    type: DELETE_ORDER_REQUEST,
  };
};

const deleteOrderSuccess = (data) => {
  return {
    type: DELETE_ORDER_SUCCESS,
    payload: data,
  };
};

const deleteOrderFailure = () => {
  return {
    type: DELETE_ORDER_FAILURE,
  };
};

const profileRequest = () => {
  return { type: PROFILE_REQUEST };
};

const profileSuccess = (data) => {
  ///////console.log("data", data);
  return { type: PROFILE_SUCCESS, payload: data };
};

const profileFailure = () => {
  return { type: PROFILE_FAILURE };
};
const cancelOrderRequest = () => {
  return { type: CANCEL_ORDER_REQUEST };
};

const cancelOrderSuccess = (data) => {
  ///////console.log("data", data);
  return { type: CANCEL_ORDER_SUCCESS, payload: data };
};

const cancelOrderFailure = () => {
  return { type: CANCEL_ORDER_FAILURE };
};

const transactionRequest = () => {
  return {
    type: TRANSACTION_REQUEST,
  };
};

const transactionSuccess = (data) => {
  return {
    type: TRANSACTION_SUCCESS,
    payload: data,
  };
};

const transactionFailure = () => {
  return {
    type: TRANSACTION_FAILURE,
  };
};

const orderTrackingRequest = () => {
  return {
    type: ORDER_TRACKING_REQUEST,
  };
};

const orderTrackingSuccess = (data) => {
  return {
    type: ORDER_TRACKING_SUCCESS,
    payload: data,
  };
};

const orderTrackingFailure = () => {
  return {
    type: ORDER_TRACKING_FAILURE,
  };
};

const dashboardRequest = () => {
  return {
    type: DASHBOARD_REQUEST,
  };
};

const dashboardSuccess = (data) => {
  return {
    type: DASHBOARD_SUCCESS,
    payload: data,
  };
};

const dashboardFailure = () => {
  return {
    type: DASHBOARD_FAILURE,
  };
};

const AddProductRequest = () => {
  return {
    type: ADDPRODUCT_REQUEST,
  };
};

const AddProductSuccess = (data) => {
  return {
    type: ADDPRODUCT_SUCCESS,
    payload: data,
  };
};

const AddProductFailure = () => {
  return {
    type: ADDPRODUCT_FAILURE,
  };
};





const dashboardAnalyticsRequest = () => {
  return {
    type: DASHBOARD_ANALYTICS_REQUEST,
  };
};

const dashboardAnalyticsSuccess = (data) => {
  return {
    type: DASHBOARD_ANALYTICS_SUCCESS,
    payload: data,
  };
};

const dashboardAnalyticsFailure = () => {
  return {
    type: DASHBOARD_ANALYTICS_FAILURE,
  };
};

const getInvalidTokenSuccess = (data) => {
  return { type: GET_INVALID_TOKEN_SUCCESS, payload: data };
};

const getAuthenticationSuccess = (data) => {
  /////console.log("daraaaa", data);
  return { type: GET_AUTHENTICATION_ERROR_SUCCESS, payload: data };
};

export const getInvalidToken = (data) => (dispatch) => {
  // dispatch(getInvalidTokenRequest());

  dispatch(getInvalidTokenSuccess(data));

  // dispatch(getInvalidTokenFailure());
};
export const getAuthenticationError = (data) => (dispatch) => {
  /////console.log("data", data);
  dispatch(getAuthenticationSuccess(data));
};

const DownloadPDFRequest = () => {
  return { type: DOWNLOAD_PDF_REQUEST };
};

const DownloadPDFSuccess = (data) => {
  return { type: DOWNLOAD_PDF_SUCCESS, payload: data };
};

const DownloadPDFFailure = (error) => {
  return { type: DOWNLOAD_PDF_FAILURE, payload: error };
};
export const allOrder = () => (dispatch) => {
  dispatch(allOrderRequest());

  const requestData = {
    userid: userData.userid,
    branchid: branch.branchId,
    hospid: userData.hospid,
  };

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_ALL_ORDER_LIST,  
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(allOrderSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(allOrderFailure(error));
    });
};



export const contact =
  (userName, email, phone, comment, hospital) => (dispatch) => {
    dispatch(contactRequest());

    const requestData = {
      username: userName,
      hospname: hospital,
      email: email,
      mobile: phone,
      message: comment,
    };
    ///////console.log("requestData", requestData);
    return axios({
      method: "POST",
      url: process.env.REACT_APP_GET_CONTACT,
      data: requestData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data === "INVALID_TOKEN") {
          dispatch(getInvalidToken(res.data));
        }
        if (res.data === "AUTHENTICATION_ERROR") {
          dispatch(getAuthenticationError(res.data));
        }
        if (
          res.data !== "AUTHENTICATION_ERROR" ||
          res.data !== "INVALID_TOKEN"
        ) {
          dispatch(contactSuccess(res.data));
        }
      })
      .catch((error) => {
        dispatch(contactFailure(error));
      });
  };



export const requestProduct =
  (product, model, brand, quantity) => (dispatch) => {
    dispatch(requestProductRequest());

    const requestData = {
      userid: userData.userid,
      username: userData.name,
      userrole: userData.role,
      hospid: userData.hospid,
      hospname: userData.hospname,
      branch: branch.branchName,
      branchid: branch.branchId,
      product: product,
      brand: brand,
      model: model,
      quantity: quantity,
    };
    ///////console.log("requestData", requestData);
    return axios({
      method: "POST",
      url: process.env.REACT_APP_GET_REQUEST,
      data: requestData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data === "INVALID_TOKEN") {
          dispatch(getInvalidToken(res.data));
        }
        if (res.data === "AUTHENTICATION_ERROR") {
          dispatch(getAuthenticationError(res.data));
        }
        if (
          res.data !== "AUTHENTICATION_ERROR" ||
          res.data !== "INVALID_TOKEN"
        ) {
          dispatch(requestProductSuccess(res.data));
        }
      })
      .catch((error) => {
        dispatch(requestProductFailure(error));
      });
  };


export const orderDetailById = (requestData) => (dispatch) => {
  dispatch(orderDetailByIdRequest());

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_ORDER_ID_DETAILS,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(orderDetailByIdSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(orderDetailByIdFailure(error));
    });
};

export const editOrder = (requestData) => (dispatch) => {
  dispatch(editOrderRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_EDIT_ORDER);
  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_EDIT_ORDER,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(editOrderSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(editOrderFailure(error));
    });
};
export const newOrder = () => (dispatch) => {
  dispatch(newOrderRequest());
  const requestData = {
    userid: userData.userid,
    branchid: branch.branchId,
    hospid: userData.hospid,
  };
  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_NEW_ORDER,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(newOrderSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(newOrderFailure(error));
    });
};

export const placeOrder = (requestData) => (dispatch) => {
  dispatch(placeOrderRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_PLACE_ORDER);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_PLACE_ORDER,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(placeOrderSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(placeOrderFailure(error));
    });
};

export const deleteOrder = (requestData) => (dispatch) => {
  dispatch(deleteOrderRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_DELETE_PRODUCT);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_DELETE_PRODUCT,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(deleteOrderSuccess(res.data));
      }
      // dispatch(deleteOrderSuccess(res.data));
    })
    .catch((error) => {
      dispatch(deleteOrderFailure(error));
    });
};

export const profileData = () => (dispatch) => {
  dispatch(profileRequest());
  let requestData = {
    userid: userData.userid,

    hospid: userData.hospid,
  };

  // console.log("requestData API", process.env.REACT_APP_GET_PROFILE);
  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_PROFILE,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(profileSuccess(res.data));
      }
      // dispatch(profileSuccess(res.data));
    })
    .catch((error) => {
      dispatch(profileFailure(error));
    });
};
export const cancelOrder = (requestData) => (dispatch) => {
  dispatch(cancelOrderRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_CANCEL_ORDER);
  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_CANCEL_ORDER,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(cancelOrderSuccess(res.data));
      }
      //dispatch(cancelOrderSuccess(res.data));
    })
    .catch((error) => {
      dispatch(cancelOrderFailure(error));
    });
};

export const transaction = () => (dispatch) => {
  dispatch(transactionRequest());
  const requestData = {
    userid: userData.userid,
    branchid: branch.branchId,
    hospid: userData.hospid,
  };

  
  // console.log("requestData API", process.env.REACT_APP_GET_TRANSACTION_ORDER);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_TRANSACTION_ORDER,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(transactionSuccess(res.data));
      }
      // dispatch(transactionSuccess(res.data));
    })
    .catch((error) => {
      dispatch(transactionFailure(error));
    });
};
export const orderTracking = (requestData) => (dispatch) => {
  dispatch(orderTrackingRequest());
  const requestData = {
    userid: userData.userid,
    branchid: branch.branchId,
    hospid: userData.hospid,
  };
  // console.log("requestData API", process.env.REACT_APP_GET_TRACK_ORDER);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_TRACK_ORDER,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(orderTrackingSuccess(res.data));
      }
      //  dispatch(orderTrackingSuccess(res.data));
    })
    .catch((error) => {
      dispatch(orderTrackingFailure(error));
    });
};

export const dashboard = (requestData) => (dispatch) => {
  dispatch(dashboardRequest());
  // const requestData = {
  //   userid: userData.userid,
  //   branchid: branch.branchId,
  //   hospid: userData.hospid,
  // };

  // console.log("requestData API", process.env.REACT_APP_GET_DASHBOARD);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_DASHBOARD,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(dashboardSuccess(res.data));
      }
      //dispatch(dashboardSuccess(res.data));
    })
    .catch((error) => {
      dispatch(dashboardFailure(error));
    });
};
export const dashboardAnalytics = (requestData) => (dispatch) => {
  dispatch(dashboardAnalyticsRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_ANALYTICS);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_ANALYTICS,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      }
      if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      }
      if (res.data !== "AUTHENTICATION_ERROR" || res.data !== "INVALID_TOKEN") {
        dispatch(dashboardAnalyticsSuccess(res.data));
      }
      //dispatch(dashboardAnalyticsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(dashboardAnalyticsFailure(error));
    });
};
export const DownloadPDF = (pdf, title) => (dispatch) => {
  /////console.log("Attempting to download PDF from URL:", pdf);
  dispatch(DownloadPDFRequest());
  axios({
    url: `${pdf}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      /////console.log("res**************", response.data);
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, `${title}`);
      dispatch(DownloadPDFSuccess(response.data));
    })
    .catch((error) => {
      console.error("PDF download failed:", error);
      dispatch(DownloadPDFFailure(error));
    });
};


export const AddProduct = (requestData) => (dispatch) => {
  dispatch(AddProductRequest());


  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_ADD_PRODUCT,
    data: requestData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      if (res.data === "INVALID_TOKEN") {
        dispatch(getInvalidToken(res.data));
      } else if (res.data === "AUTHENTICATION_ERROR") {
        dispatch(getAuthenticationError(res.data));
      } else {
        dispatch(AddProductSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(AddProductFailure(error));
    });
};

