import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import { AiFillCloseSquare } from "react-icons/ai";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestProduct } from "../../redux/data/action";

const Fields = styled(TextField)(({ theme }) => ({
  borderRadius: "5px",
  "& fieldset": { border: "none" },
  background: "rgba(244, 244, 244, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const RequestProduct = () => {
  const dispatch = useDispatch();
  const requestProducts = useSelector((store) => store.data.requestProducts);
  let branch = JSON.parse(localStorage.getItem("branchData"));
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [quantity, setQuantity] = useState(0);

  const [product, setProduct] = useState("");
  const [model, setModel] = useState("");
  const [brand, setBrand] = useState("");
  const [error, setError] = useState("");
  const validateField = (fieldName, value, regex) => {
    return !value.trim().match(regex) ? `Invalid ${fieldName}` : "";
  };

  const handleInputChange = (fieldName, value) => {
    const regex = {
      product: /^[a-zA-Z\s]+$/,
      model: /^[#.0-9a-zA-Z\s,-]+$/,
      brand: /^[a-zA-Z\s]+$/,
    };

    const error = validateField(fieldName, value, regex[fieldName]);
    setError((prevErrors) => ({ ...prevErrors, [fieldName]: error }));

    switch (fieldName) {
      case "product":
        setProduct(value);
        break;
      case "model":
        setModel(value);
        break;
      case "brand":
        setBrand(value);
        break;

      case "quantity":
        if (parseInt(value) > 0) {
          setError((prevErrors) => ({ ...prevErrors, quantity: "" }));
        }
        break;
      default:
        break;
    }
  };
  const handleSubmit = (e) => {
    const requiredFields = ["product", "model", "brand"];
    const formErrors = {};

    requiredFields.forEach((key) => {
      const value = {
        product: product,
        model: model,
        brand: brand,
      }[key];

      if (!value.trim()) {
        formErrors[key] = "This field is required.";
      }
    });

    if (quantity <= 0) {
      formErrors["quantity"] = "Please add quantity";
    }

    setError(formErrors);

    if (Object.keys(formErrors)?.length === 0) {
      dispatch(requestProduct(product, model, brand, quantity));
      setProduct("");
      setModel("");
      setBrand("");
      setQuantity(0);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);
    } else {
      ///////console.log("Form has errors. Please correct them.");
    }
  };

  ///////console.log("qua", quantity);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    if (quantity <= 0) {
      setError((prevErrors) => ({ ...prevErrors, quantity: "" }));
    }
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const handleClickOpenRequestOrder = () => {
    setOpenRequestOrder(true);
  };

  const handleCloseRequestOrder = () => {
    setOpenRequestOrder(false);
  };
  const handleClear = () => {
    setProduct("");
    setModel("");
    setBrand("");
  };
  return (
    <>
      {!isMobileScreen ? (
        <>
          <React.Fragment>
            <Button variant="contained" onClick={handleClickOpenRequestOrder}>
              REQUEST PRODUCTS
            </Button>
            <Dialog
              open={openRequestOrder}
              onClose={handleCloseRequestOrder}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                //   gap="5px"
                sx={{ width: "550px", height: "380px" }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-around"
                  sx={{ width: "100%" }}
                >
                  <DialogContent
                    id="alert-dialog-title"
                    sx={{ textAlign: "start" }}
                  >
                    <Typography variant="h6">
                      Request for new products
                    </Typography>
                  </DialogContent>
                  <DialogContent
                    id="alert-dialog-title"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      width: "30%",
                      gap: "20px",
                    }}
                  >
                    <AiFillCloseSquare
                      onClick={handleCloseRequestOrder}
                      size={25}
                      style={{ color: "rgba(193, 32, 37, 1)" }}
                    />
                  </DialogContent>
                </Stack>
                {showSuccessMessage ? (
                  <>
                    <DialogContent>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: "100%", height: "100%" }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "rgba(193, 32, 37, 1)",
                            fontWeight: 700,
                          }}
                        >
                          Your request was successfully placed!{" "}
                        </Typography>
                      </Stack>
                    </DialogContent>
                  </>
                ) : (
                  <>
                    {" "}
                    <DialogContent>
                      <Grid
                        container
                        spacing={0}
                        rowGap={2}
                        style={{
                          width: "100%",

                          "& .MuiGrid-item": {
                            paddingLeft: "16px",
                          },
                        }}
                      >
                        <Grid item xs={8} sx={{ width: "200px" }}>
                          <Fields
                            sx={{ width: "300px" }}
                            placeholder="Product Name"
                            value={product}
                            onChange={(e) =>
                              handleInputChange("product", e.target.value)
                            }
                          />
                          {error.product && (
                            <HelperText>{error.product}</HelperText>
                          )}
                        </Grid>
                        <Grid item xs={4} sx={{}}>
                          <Fields
                            sx={{ width: "150px" }}
                            placeholder="Brand"
                            value={brand}
                            onChange={(e) =>
                              handleInputChange("brand", e.target.value)
                            }
                          />
                          {error.brand && (
                            <HelperText>{error.brand}</HelperText>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <Fields
                            sx={{ width: "300px" }}
                            placeholder="Model"
                            value={model}
                            onChange={(e) =>
                              handleInputChange("model", e.target.value)
                            }
                          />
                          {error.model && (
                            <HelperText>{error.model}</HelperText>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Fields
                            value={quantity}
                            InputProps={{
                              startAdornment: (
                                <IconButton onClick={handleDecrement}>
                                  <RemoveIcon />
                                </IconButton>
                              ),
                              endAdornment: (
                                <IconButton onClick={handleIncrement}>
                                  <AddIcon />
                                </IconButton>
                              ),
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                textAlign: "center",
                              },
                              width: "150px",
                            }}
                          />
                          {error.quantity && (
                            <HelperText>{error.quantity}</HelperText>
                          )}
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogContent sx={{ width: "100%" }}>
                      <Grid
                        container
                        spacing={0}
                        rowGap={2}
                        style={{
                          width: "100%",

                          "& .MuiGrid-item": {
                            paddingLeft: "16px",
                          },
                        }}
                      >
                        <Grid item xs={8} sx={{ width: "200px" }}>
                          <Button
                            variant="outlined"
                            onClick={handleCloseRequestOrder}
                            sx={{ width: "150px", height: "45px" }}
                          >
                            CANCEL
                          </Button>
                        </Grid>
                        <Grid item xs={4} sx={{}}>
                          <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ width: "150px", height: "45px" }}
                          >
                            Request
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </>
                )}
              </Stack>
            </Dialog>
          </React.Fragment>
        </>
      ) : (
        <>
          <React.Fragment>
            <Button
              onClick={handleClickOpenRequestOrder}
              color="info"
              size="small"
              sx={{
                width: "148px",
                height: "45px",
                border: "1px solid black",
              }}
              variant="outline"
            >
              <Label variant="caption">Request products</Label>
            </Button>
            <Dialog
              open={openRequestOrder}
              onClose={handleCloseRequestOrder}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                //   gap="5px"
                sx={{ height: "380px" }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-around"
                  sx={{ width: "100%" }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{ textAlign: "start" }}
                  >
                    <Label variant="subtitle1">Request new products!</Label>
                  </DialogTitle>
                  {/* <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      // width: "30%",
                      gap: "20px",
                    }}>
                    <AiFillCloseSquare
                      onClick={handleCloseRequestOrder}
                      size={25}
                      style={{ color: "rgba(193, 32, 37, 1)" }}
                    />
                  </DialogTitle> */}
                </Stack>
                {showSuccessMessage ? (
                  <>
                    <DialogContent>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: "100%", height: "100%" }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "rgba(193, 32, 37, 1)",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          Your request was successfully placed!{" "}
                        </Typography>
                      </Stack>
                    </DialogContent>
                  </>
                ) : (
                  <>
                    {" "}
                    {/* <DialogContent>
                      <Grid
                        container
                        spacing={0}
                        rowGap={2}
                        style={{
                          // width: "100%",

                          "& .MuiGrid-item": {
                            paddingLeft: "16px",
                          },
                        }}>
                        <Grid item xs={16} sx={{  }}>
                          <Fields
                            sx={{  }}
                            placeholder="Product Name"
                            value={product}
                            onChange={(e) =>
                              handleInputChange("product", e.target.value)
                            }
                          />
                          {error.product && (
                            <HelperText>{error.product}</HelperText>
                          )}
                        </Grid>

                        <Grid item xs={16}>
                          <Fields
                            sx={{  }}
                            placeholder="Model"
                            value={model}
                            onChange={(e) =>
                              handleInputChange("model", e.target.value)
                            }
                          />
                          {error.model && (
                            <HelperText>{error.model}</HelperText>
                          )}
                        </Grid>

                        <Grid item xs={4} sx={{}}>
                          <Fields
                            sx={{ width: "150px" }}
                            placeholder="Brand"
                            value={brand}
                            onChange={(e) =>
                              handleInputChange("brand", e.target.value)
                            }
                          />
                          {error.brand && (
                            <HelperText>{error.brand}</HelperText>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Fields
                            value={quantity}
                            InputProps={{
                              startAdornment: (
                                <IconButton onClick={handleDecrement}>
                                  <RemoveIcon />
                                </IconButton>
                              ),
                              endAdornment: (
                                <IconButton onClick={handleIncrement}>
                                  <AddIcon />
                                </IconButton>
                              ),
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                textAlign: "center",
                              },
                              width: "150px",
                            }}
                          />
                          {error.quantity && (
                            <HelperText>{error.quantity}</HelperText>
                          )}
                        </Grid>
                      </Grid>
                    </DialogContent> */}
                    {/* <DialogContent sx={{ width: "100%" }}>
                      <Grid
                        container
                        spacing={0}
                        rowGap={2}
                        style={{
                          width: "100%",

                          "& .MuiGrid-item": {
                            paddingLeft: "16px",
                          },
                        }}>
                        <Grid item xs={8} sx={{ width: "200px" }}>
                          <Button
                            variant="outlined"
                            onClick={handleCloseRequestOrder}
                            sx={{ width: "150px", height: "45px" }}>
                            CANCEL
                          </Button>
                        </Grid>
                        <Grid item xs={4} sx={{}}>
                          <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ width: "150px", height: "45px" }}>
                            Request
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogContent> */}
                    <DialogContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Fields
                            fullWidth
                            sx={{}}
                            placeholder="Product Name"
                            value={product}
                            onChange={(e) =>
                              handleInputChange("product", e.target.value)
                            }
                          />
                          {error.product && (
                            <HelperText>{error.product}</HelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Fields
                            fullWidth
                            sx={{}}
                            placeholder="Model"
                            value={model}
                            onChange={(e) =>
                              handleInputChange("model", e.target.value)
                            }
                          />
                          {error.model && (
                            <HelperText>{error.model}</HelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Fields
                                fullWidth
                                placeholder="Brand"
                                value={brand}
                                onChange={(e) =>
                                  handleInputChange("brand", e.target.value)
                                }
                              />
                              {error.brand && (
                                <HelperText>{error.brand}</HelperText>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <Fields
                                fullWidth
                                value={quantity}
                                InputProps={{
                                  startAdornment: (
                                    <IconButton onClick={handleDecrement}>
                                      <RemoveIcon />
                                    </IconButton>
                                  ),
                                  endAdornment: (
                                    <IconButton onClick={handleIncrement}>
                                      <AddIcon />
                                    </IconButton>
                                  ),
                                }}
                                sx={{
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                              />
                              {error.quantity && (
                                <HelperText>{error.quantity}</HelperText>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                onClick={handleCloseRequestOrder}
                                sx={{
                                  width: "100%",
                                  height: "45px",
                                  color: "black",
                                  border: "1px solid black",
                                }}
                              >
                                CANCEL
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ width: "100%", height: "45px" }}
                              >
                                Request
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </>
                )}
              </Stack>
            </Dialog>
          </React.Fragment>
        </>
      )}
    </>
  );
};

export default RequestProduct;
