import {
  AppBar,
  Box,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import { FaPlus } from "react-icons/fa6";
import Loading_page from "../../../src/components/Loading_Page"; 

import logos from "../../images/asthra logo-white.png";
import PercentageBar from "./PercentageBar";
import CurrentOrder from "./CurrentOrder";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dashboard } from "../../redux/data/action";
import { useState } from "react";
import OrderTabs from "./OrderTabs";

import RightMenu from "./RightMenu";
import PropTypes from "prop-types";

import Navbar from "../../MobileView/Navbar";
import { BsGraphUpArrow } from "react-icons/bs";

import { LuStar } from "react-icons/lu";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { BsTruck } from "react-icons/bs";
import Footer from "../../MobileView/Footer";

import DashboardTracking from "../OrderTracking/DashboardTracking";
import BasicTabs from "./Tab";
import { useParams } from "react-router-dom";
import NumberFormat from "../../components/NumberFormat";
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: "10px",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
  display: "flex",

  alignItems: "center",
}));

const CurrentOrderBox = styled(Stack)(({ theme }) => ({
  width: "400px",
}));

function CustomTabPanel(props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OnBoarding = () => {
  const dashboardData = useSelector((store) => store.data.dashboard);
  /////console.log("dashboardData", dashboardData);
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [value, setValue] = React.useState(0);

  const { tabIndex } = useParams();

  /////console.log("tabIndex", tabIndex);

  const [value, setValue] = React.useState(parseInt(tabIndex, 10) || 0); // Setting default to 0 if no tabIndex is provided

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [scrollableHeight, setScrollableHeight] = useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  let branch = JSON.parse(localStorage.getItem("branchData"));
  const dispatch = useDispatch();

  const [analyticsGraphSection, setAnalyticsGraphSection] = useState([]);
  const [analyticsDataSection, setAnalyticsDataSection] = useState([]);
  const [trackerSection, setTrackerSection] = useState([]);
  const [topProdsSection, setTopProdsSection] = useState([]);
  const [topBrandsSection, setTopBrandsSection] = useState([]);
  const [creditSection, setCreditSection] = useState({});

  useEffect(() => {
    if (dashboardData) {
      setAnalyticsGraphSection(dashboardData.analyticsgraph_section || []);
      setAnalyticsDataSection(dashboardData.analyticsdata_section || []);
      setTrackerSection(dashboardData.tracker_section || []);
      setTopProdsSection(dashboardData.top_prods_section || []);
      setTopBrandsSection(dashboardData.top_brands_section || []);
      setCreditSection(dashboardData.credit_section || {});
    }
  }, [dashboardData]);

  useEffect(() => {
    const requestData = {
      userid: userData.userid,
      branchid: branch.branchId,
      hospid: userData.hospid,
    };
    dispatch(dashboard(requestData));
  }, []);

  useEffect(() => {
    const calculateScrollableHeight = () => {
      const navbar = document.getElementById("navbar");
      const searchBar = document.getElementById("search-bar");
      const heading = document.getElementById("heading");
      const footer = document.getElementById("footer");

      if (navbar && searchBar && heading && footer) {
        const navbarHeight = navbar.offsetHeight;
        const searchBarHeight = searchBar.offsetHeight;
        const headingHeight = heading.offsetHeight;
        const footerHeight = footer.offsetHeight;

        const windowHeight = window.innerHeight;
        const remainingHeight =
          windowHeight -
          navbarHeight -
          searchBarHeight -
          headingHeight -
          footerHeight -
          50;

        setScrollableHeight(remainingHeight);
      }
    };

    calculateScrollableHeight();

    const handleResize = () => {
      calculateScrollableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dashboardData]);

  return (
    <>
      {!isMobileScreen ? (
        <StyledBox
          sx={{
            padding: "20px",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",

            width: "100%",
          }}>
          <StyledBox
            sx={{
              flexDirection: "column",
              height: "100%",
              alignItems: "start",
              justifyContent: "start",
              gap: "20px",

              width: "60%",
            }}>
            <OrderTabs
              orders={analyticsDataSection}
              topBrandsSection={topBrandsSection}
              topProdsSection={topProdsSection}
              analyticsGraphSection={analyticsGraphSection}
            />
          </StyledBox>

          <StyledBox
            sx={{
              flexDirection: "column",
              height: "100%",
              justifyContent: "start",
              gap: "20px",
              // width:"30%",
            }}>
            {creditSection?.length === 0 ? (
              <CurrentOrderBox
                sx={{
                  height: "50%",

                  padding: "20px",
                  gap: "20px",
                  background: "white",
                  borderRadius: "10px",
                }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <Label>CREDITS</Label>

                  {/* <Label2
                    sx={{
                      display: "flex",
                      gap: "10px",
                      color: "black",
                    }}>
                    Add credits <FaPlus size={15} />
                  </Label2> */}
                </Stack>
                <Stack
                  justifyContent={"space-between"}
                  style={{
                    height: "184px",
                    padding: "20px",
                    borderRadius: "5px",
                    background: "rgba(193, 32, 37, 1)",
                  }}>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    style={{ color: "white" }}>
                    {/* <Label
                      sx={{
                        display: "flex",
                        gap: "10px",
                        color: "white",
                      }}>
                      Add credits <FaPlus size={15} />
                    </Label> */}
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    style={{ color: "white" }}>
                    <Stack>
                      <Label variant="caption">
                        {" "}
                        Payment exceeds balance.
                        <br /> Top up/Buy credits.
                      </Label>
                    </Stack>
                    <Stack>
                      <Label variant="caption">Insufficient balance </Label>
                      <Label variant="subtitle2">₹<NumberFormat number={30000}/></Label>
                    </Stack>
                  </Stack>
                </Stack>

                <PercentageBar creditSection={creditSection} />
              </CurrentOrderBox>
            ) : (
              <CurrentOrderBox
                sx={{
                  height: "50%",

                  padding: "20px",
                  gap: "20px",
                  background: "white",
                  borderRadius: "10px",
                }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <Label variant="h5">CREDITS</Label>

                  {/* <Label2
                    sx={{
                      display: "flex",
                      gap: "10px",
                      color: "black",
                    }}>
                    Add credits <FaPlus size={15} />
                  </Label2> */}
                </Stack>
                <Stack
                  justifyContent={"space-between"}
                  style={{
                    height: "184px",
                    padding: "20px",
                    borderRadius: "5px",
                    background:
                      "linear-gradient(81.8deg, #00D86D 0.56%, #00809A 154.57%)",
                  }}>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    style={{ color: "white" }}>
                       
                    <Label variant="h6">₹ <NumberFormat number={creditSection.totalcredits}/>  </Label>

                    <Label2>
                      <Box component="img" src={logos} />
                    </Label2>
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    style={{ color: "white" }}>
                    <Stack>
                      <Label variant="caption">Used</Label>
                      <Label variant="subtitle2">
                        ₹ <NumberFormat number={creditSection.used_credit}/>     
                      </Label>
                    </Stack>
                    <Stack>
                      <Label variant="caption">Balance credits</Label>
                      <Label variant="subtitle2">
                        ₹ <NumberFormat number={creditSection.balance_credits}/>      
                      </Label>
                    </Stack>
                  </Stack>
                </Stack>

                <PercentageBar creditSection={creditSection} />
              </CurrentOrderBox>
            )}

            <CurrentOrderBox
              sx={{
                height: "50%",
                border: "2px solid rgba(255, 246, 246, 1)",

                justifyContent: "space-around",
                bgcolor: "rgba(251, 251, 251, 1)",

                borderRadius: "10px",
              }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{ padding: "20px" }}>
                {trackerSection?.length > 0 ? (
                  <Label variant="h5">ACTIVE ORDERS</Label>
                ) : (
                  <Label variant="h5">NO ORDERS ACTIVE </Label>
                )}

                {trackerSection?.length > 0 ? (
                  <Label>{trackerSection?.length} orders active</Label>
                ) : (
                  <Label></Label>
                )}
              </Stack>

              <Stack sx={{ height: "70%" }}>
                <CurrentOrder trackerSection={trackerSection} />
              </Stack>
            </CurrentOrderBox>
          </StyledBox>
          <StyledBox sx={{ height: "100%", width: "40px" }}>
            <Stack gap={3}>
              <RightMenu />
            </Stack>
          </StyledBox>
        </StyledBox>
      ) : (
        <>
          <div>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"Dashboard"} />
            </AppBar>
            <Toolbar />
            <Box sx={{ height: "100%", backgroundColor: "#f0f0f0" }}>
              <Stack
                sx={{
                  backgroundColor: "#f0f0f0",

                  position: "fixed",

                  zIndex: 1,

                  bgcolor: "rgba(241, 241, 241, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                }}
                id="search-bar">
                <Stack
                  sx={{
                    marginTop: "10px",
                    gap: "10px",

                    width: "100%",

                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Tabs
                    sx={{}}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{ style: { display: "none" } }}>
                    <Tab
                      icon={
                        <Stack
                          style={{
                            border:
                              value === 0
                                ? "2px solid rgba(255, 255, 255, 1)"
                                : "none",
                            backgroundColor:
                              value === 0 ? "white" : "transparent",
                            padding: "8px",
                            borderRadius: "10px",
                            color: value === 0 ? "black" : "grey",
                            width: "36px",
                            height: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              value === 0
                                ? "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset"
                                : "none ",
                          }}>
                          <BsGraphUpArrow size={20} />
                        </Stack>
                      }
                      {...a11yProps(0)}
                      selected={value === 0}
                    />
                    <Tab
                      icon={
                        <Stack
                          style={{
                            border:
                              value === 1
                                ? "2px solid rgba(255, 255, 255, 1)"
                                : "none",
                            backgroundColor:
                              value === 1 ? "white" : "transparent",
                            padding: "8px",
                            borderRadius: "10px",
                            color: value === 1 ? "black" : "grey",
                            width: "36px",
                            height: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              value === 1
                                ? "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset"
                                : "none ",
                          }}>
                          <FaIndianRupeeSign size={20} />
                        </Stack>
                      }
                      {...a11yProps(1)}
                      selected={value === 1}
                    />
                    <Tab
                      icon={
                        <Stack
                          style={{
                            border:
                              value === 2
                                ? "2px solid rgba(255, 255, 255, 1)"
                                : "none",
                            backgroundColor:
                              value === 2 ? "white" : "transparent",
                            padding: "8px",
                            borderRadius: "10px",
                            color: value === 2 ? "black" : "grey",
                            width: "36px",
                            height: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              value === 2
                                ? "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset"
                                : "none ",
                          }}>
                          <LuStar size={20} />
                        </Stack>
                      }
                      {...a11yProps(2)}
                      selected={value === 2}
                    />
                    <Tab
                      icon={
                        <Stack
                          style={{
                            border:
                              value === 3
                                ? "2px solid rgba(255, 255, 255, 1)"
                                : "none",
                            backgroundColor:
                              value === 3 ? "white" : "transparent",
                            padding: "8px",
                            borderRadius: "10px",
                            color: value === 3 ? "black" : "grey",
                            width: "36px",
                            height: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              value === 3
                                ? "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset"
                                : "none ",
                          }}>
                          <BsTruck size={20} />
                        </Stack>
                      }
                      {...a11yProps(3)}
                      selected={value === 3}
                    />
                  </Tabs>
                </Stack>
              </Stack>

              <Box sx={{ paddingTop: "90px" }}>
                <Stack
                  id="heading"
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",

                    // marginTop: "32px",
                  }}
                  flexDirection={"row"}></Stack>

                <Stack
                  sx={{
                    height: scrollableHeight,
                    overflowY: "auto",
                    boxShadow: "none",
                  }}>
                  <Stack sx={{ padding: "20px", overflowY: "auto" }}>
                    <CustomTabPanel value={value} index={0} style={{}}>
                      <Stack sx={{ height: "90%" }}>
                        <OrderTabs
                          orders={analyticsDataSection}
                          topBrandsSection={topBrandsSection}
                          topProdsSection={topProdsSection}
                          analyticsGraphSection={analyticsGraphSection}
                        />
                      </Stack>
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={value}
                      index={1}
                      style={{ width: "100%" }}>
                      <Stack
                        sx={{
                          flexDirection: "column",
                          height: "100%",
                          justifyContent: "start",
                          gap: "20px",

                          // width:"30%",
                        }}>
                        {creditSection?.length === 0 ? (
                          <Stack
                            sx={{
                              height: "50%",

                              gap: "20px",
                              background: "white",
                              borderRadius: "10px",
                            }}>
                            <Stack
                              flexDirection={"row"}
                              justifyContent={"space-between"}>
                              <Label >CREDITS</Label>

                              <Label2
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  color: "black",
                                }}>
                                Add credits <FaPlus size={15} />
                              </Label2>
                            </Stack>
                            <Stack
                              justifyContent={"space-between"}
                              style={{
                                height: "174px",
                                padding: "20px",
                                borderRadius: "5px",
                                background: "rgba(193, 32, 37, 1)",
                              }}>
                              <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                style={{ color: "white" }}>
                                <Label
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    color: "white",
                                  }}>
                                  Add credits <FaPlus size={15} />
                                </Label>
                              </Stack>
                              <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                style={{ color: "white" }}>
                                <Stack>
                                  <Label variant="caption">
                                    {" "}
                                    Payment exceeds balance.
                                    <br /> Top up/Buy credits.
                                  </Label>
                                </Stack>
                                <Stack>
                                  <Label variant="caption">
                                    Insufficient balance{" "}
                                  </Label>
                                  <Label variant="subtitle2">₹<NumberFormat number={30000}/></Label>
                                </Stack>
                              </Stack>
                            </Stack>

                            <PercentageBar creditSection={creditSection} />
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              height: "50%",

                              borderRadius: "10px",
                            }}>
                            <Stack
                              justifyContent={"space-between"}
                              style={{
                                height: "174px",
                                padding: "20px",
                                borderRadius: "5px",
                                background:
                                  "linear-gradient(81.8deg, #00D86D 0.56%, #00809A 154.57%)",
                              }}>
                              <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                style={{ color: "white" }}>
                                <Label variant="h6">
                                 
                                  ₹<NumberFormat number={creditSection.totalcredits }/> 
                                </Label>

                                <Label2>
                                  <Box component="img" src={logos} />
                                </Label2>
                              </Stack>
                              <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                style={{ color: "white" }}>
                                <Stack>
                                  <Label variant="subtitle2">Used</Label>
                                  <Label variant="subtitle2">
                                    ₹<NumberFormat number={creditSection.used_credit}/>
                                  </Label>
                                </Stack>
                                <Stack>
                                  <Label variant="subtitle2">
                                    Balance credits
                                  </Label>
                                  <Label variant="subtitle2">
                                    ₹<NumberFormat number={creditSection.balance_credits}/>
                                  </Label>
                                </Stack>
                              </Stack>
                            </Stack>

                            <PercentageBar creditSection={creditSection} />
                          </Stack>
                        )}
                      </Stack>
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={value}
                      index={2}
                      style={{ width: "100%" }}>
                      <BasicTabs
                        topBrandsSection={topBrandsSection}
                        topProdsSection={topProdsSection}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={value}
                      index={3}
                      style={{ width: "100%", height: "100%" }}>
                      <DashboardTracking />
                    </CustomTabPanel>
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
              id="footer">
              <Footer />
            </AppBar>
          </div>
        </>
      )}
      <Loading_page/>
    </>
  );
};

export default OnBoarding;
