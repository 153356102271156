import {
  Divider,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import NumberFormat from "../../components/NumberFormat";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: "10px",
}));

function Graph({ data }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  function getCurrentMonth() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentMonth = months[currentMonthIndex];
    return currentMonth;
  }

  const currentMonth = getCurrentMonth();
  /////console.log("data", data);

  return (
    <>
      {!isMobileScreen ? (
        <>
          <div style={{ width: "100%", height: "100%" }}>
            <Label sx={{ paddingLeft: "25px" }} variant="caption">
              | Savings
            </Label>
            <ResponsiveContainer width="100%" height={250} style={{}}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, bottom: 5, left: -33 }}>
                <XAxis
                  dataKey="orderdate"
                  style={{
                    fontSize: "small",
                  }}
                />
                <YAxis domain={[0, 50]} tick={false} tickLine={false} />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload?.length) {
                      const orders = payload.map((entry) => entry.payload);
                      return (
                        <Stack
                          className="custom-tooltip"
                          style={{
                            background: "white",
                            width: "142px",
                            padding: "10px",
                            gap: "10px",
                            border: "2px solid rgba(255, 255, 255, 1)",
                            boxShadow:
                              "0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                          }}>
                          {orders.map((order) => (
                            <Stack key={order.orderid} sx={{}}>
                              <Stack
                                sx={{}}
                                flexDirection={"column"}
                                gap={"10px"}
                                justifyContent={"space-between"}>
                                <Label variant="caption">
                                  {order.orderdate} {currentMonth}{" "}
                                </Label>
                                <Divider />
                                <Stack
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}>
                                  <Label
                                    variant="caption"
                                    style={{ color: "rgba(255, 153, 0, 1)" }}>
                                    {Math.round(order.totsavings)}%
                                  </Label>
                                  <Label variant="caption">
                                    ₹
                                    {/* <NumberFormat number={order.total_value}/> */}
                                    {parseFloat(
                                        order.total_value
                                      ).toFixed(2)}
                                  </Label>
                                </Stack>
                              </Stack>
                            </Stack>
                          ))}
                        </Stack>
                      );
                    }
                    return null;
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="totsavings"
                  stroke="rgba(255, 153, 0, 1)"
                  dot={{ stroke: "black", fill: "white" }}
                  activeDot={{
                    stroke: "rgba(193, 32, 37, 1)",
                    fill: "rgba(193, 32, 37, 1)",
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width="100%" height={210} style={{}}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, bottom: 5, left: -44 }}>
                <XAxis
                  dataKey="orderdate"
                  style={{
                    fontSize: "small",
                  }}
                />
                <YAxis domain={[0, 50]} tick={false} tickLine={false} />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload?.length) {
                      const orders = payload.map((entry) => entry.payload);
                      return (
                        <Stack
                          className="custom-tooltip"
                          style={{
                            background: "white",
                            width: "142px",
                            padding: "10px",
                            gap: "10px",
                            border: "2px solid rgba(255, 255, 255, 1)",
                            boxShadow:
                              "0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                          }}>
                          {orders.map((order) => (
                            <Stack key={order.orderid} sx={{}}>
                              <Stack
                                sx={{}}
                                flexDirection={"column"}
                                gap={"10px"}
                                justifyContent={"space-between"}>
                                <Label variant="caption">
                                  {order.orderdate} {currentMonth}{" "}
                                </Label>
                                <Divider />
                                <Stack
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}>
                                  <Label
                                    variant="caption"
                                    style={{ color: "rgba(255, 153, 0, 1)" }}>
                                    {Math.round(order.totsavings)}%
                                  </Label>
                                  <Label variant="caption">
                                    ₹<NumberFormat number={order.total_value}/>
                                  </Label>
                                </Stack>
                              </Stack>
                            </Stack>
                          ))}
                        </Stack>
                      );
                    }
                    return null;
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="totsavings"
                  stroke="rgba(255, 153, 0, 1)"
                  dot={{ stroke: "black", fill: "white" }}
                  activeDot={{
                    stroke: "rgba(193, 32, 37, 1)",
                    fill: "rgba(193, 32, 37, 1)",
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </>
  );
}

export default Graph;
