import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import logo from "../images/asthra logo red-03 2.png";

import { useLocation, useNavigate } from "react-router-dom";

import { styled, useMediaQuery, useTheme } from "@mui/material";

const ImageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "50px",
    height: "54px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function Navbar() {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleSignUp = () => {
    navigate("/signup");
    handleCloseDrawer();
  };

  const handleSignIn = () => {
    navigate("/signin");
    handleCloseDrawer();
  };
  const handleHome = () => {
    navigate("/");
    handleCloseDrawer();
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
        bgcolor: "white",
        "& .MuiContainer-root": {
          boxShadow: " rgba(230, 210, 209, 1) 0px 10px 10px -10px",
        },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ImageBox
              component="img"
              src={logo}
              sx={{ ml: 4, height: "100px", width: "100px" }}
              onClick={handleHome}
            />

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "black",
                textDecoration: "none",
              }}
            ></Typography>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: "20px",
            }}
          >
            <Button
              onClick={handleSignUp}
              variant="outlined"
              sx={{
                border: "1px solid rgba(193, 32, 37, 1)",
                color: "rgba(193, 32, 37, 1)",
                fontWeight: 500,
                borderRadius: "3px ",
              }}
            >
              Register
            </Button>
            <Button
              onClick={handleSignIn}
              variant="outlined"
              sx={{
                border: "1px solid rgba(193, 32, 37, 1)",
                bgcolor: "rgba(193, 32, 37, 1)",
                color: "white",
                fontWeight: 500,
                borderRadius: "3px ",
                "&:hover": {
                  bgcolor: "rgba(193, 32, 37, 1)",
                  color: "white",
                },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
