import * as React from "react";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { Button, styled } from "@mui/material";

const Box1 = styled(Box)(({ theme }) => ({
  width: "50%",

  display: "flex",

  alignItems: "center",

  flexDirection: "row",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function Footer() {
  const navigate = useNavigate();

  const handleterms = () => {
    navigate("/terms-and-conditions");
  };

  const handlefaq = () => {
    navigate("/faq");
  };

  return (
    <React.Fragment>
      <Box
        color="primary"
        sx={{
          width: "100%",
          bgcolor: "rgba(193, 32, 37, 1)",
          height: "50px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box1>
          <a href="https://medpick.in/" target="_blank">
            <Button sx={{ color: "white", textTransform: "none" }}>
              Medpick - All Rights Reserved
            </Button>
          </a>
        </Box1>
        <Box1 sx={{}}>
          <Button
            sx={{ color: "white", textTransform: "none" }}
            onClick={handleterms}
          >
            Terms & Conditions
          </Button>
          {/* <Typography sx={{ color: "white" }}>|</Typography> */}
          {/* <Button sx={{ color: "white", textTransform: "none" }}onClick={handlefaq}>FAQ</Button> */}
        </Box1>
      </Box>
    </React.Fragment>
  );
}
