// import React, { useState } from "react";
// import ChatBox1 from "./ChatBox1";
// import ChatBox2 from "./ChatBox2";
// import { MdOutlineComment } from "react-icons/md";
// import { RiWhatsappFill } from "react-icons/ri";
// const ChatComponent = () => {
//   const [isOpen1, setIsOpen1] = useState(false);
//   const [isOpen2, setIsOpen2] = useState(false);

//   const toggleChat1 = () => {
//     setIsOpen1(!isOpen1);
//     setIsOpen2(false);
//   };

//   const toggleChat2 = () => {
//     setIsOpen2(!isOpen2);
//     setIsOpen1(false);
//   };

//   return (
//     <div style={{ height: "100%", position: "relative" }}>
//       <style>
//         {`
//           .chat-icon-container {
//             display: flex;
//             justify-content: space-between;
//             position: fixed;
//             bottom: 20px;
//             right: 20px;
//             width: calc(100% - 40px);
//           }

//           .chat-icon1 {
//             width: 50%;
//             text-align: center;
//             background-color: white;
//             color: #fff;
//             width: 50px;
//             height: 50px;
//             border-radius: 50%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             cursor: pointer;
//             z-index: 999;
//           }
//           .chat-icon2 {
//             width: 50%;
//             text-align: center;
//             background-color: white;
//             color: #fff;
//             width: 50px;
//             height: 50px;
//             border-radius: 50%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             cursor: pointer;
//             z-index: 999;
//           }

//           .chat-box1 {
//             position: fixed;
//             bottom: 20px;
//             right: 80px;
//             width: 400px;
//             background-color: #fff;
//             border-radius: 5px;
//             display: ${isOpen1 ? "block" : "none"};
//             z-index: 998;
//           }

//           .chat-box {
//             position: fixed;
//             bottom: 20px;
//             right: 80px;
//             width: 500px;
//             background-color: #fff;
//             border-radius: 5px;
//             display: ${isOpen2 ? "block" : "none"};
//             z-index: 998;
//             padding: 20px;
//           }

//           .overlay {
//             position: fixed;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
//             z-index: 998; /* Ensure it's above everything except the chat boxes */
//             display: ${isOpen1 || isOpen2 ? "block" : "none"};
//           }

//           .chat-box-header {
//             background-color: #007bff;
//             color: #fff;
//             padding: 10px;
//             border-radius: 5px 5px 0 0;
//           }

//           .chat-box-body {
//             padding: 10px;
//             overflow-y: auto;
//           }

//           .chat-input {
//             width: calc(100% - 20px);
//             margin: 0 10px;
//             padding: 8px;
//             border: 1px solid #ccc;
//             border-radius: 5px;
//             outline: none;
//           }

//           .chat-submit {
//             background-color: #007bff;
//             color: #fff;
//             border: none;
//             padding: 8px 15px;
//             border-radius: 5px;
//             cursor: pointer;
//             outline: none;
//           }

//           @media (max-width: 600px) {
//             .chat-icon-container {
//               width: 120px;

//               height:"60px
//             }

//             .chat-icon1 {
//               width: 60px;

//             }

//             .chat-box1 {
//               width: 290px;
//               height: 500px;
//             }

//             .chat-box {
//               position: fixed;
//               bottom: 20px;
//               right: 80px;
//               width: 246px;
//               height: 700px;
//               background-color: #fff;
//               border-radius: 5px;
//               display: ${isOpen1 ? "block" : "none"};
//               z-index: 998;
//             }
//           }
//         `}
//       </style>
//       <div className="chat-icon-container">
//         <div
//           className="chat-icon1"
//           style={{
//             color: "rgba(37, 211, 102, 1)",
//           }}
//           onClick={toggleChat1}>

//           <RiWhatsappFill size={150} />
//         </div>
//         <div
//           className="chat-icon2"
//           style={{
//             color: "white",
//             backgroundColor: "#007bff",
//           }}
//           onClick={toggleChat2}>

//           <MdOutlineComment size={30} />
//         </div>
//       </div>
//       <ChatBox1
//         isOpen={isOpen1}
//         toggleChat={toggleChat1}
//         setIsOpen1={setIsOpen1}
//       />
//       <ChatBox2
//         isOpen={isOpen2}
//         toggleChat={toggleChat2}
//         setIsOpen={setIsOpen2}
//       />
//     </div>
//   );
// };

// export default ChatComponent;
import React, { useState } from "react";
import ChatBox1 from "./ChatBox1";
import ChatBox2 from "./ChatBox2";
import { MdOutlineComment } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
const ChatBot = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleChat1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };

  const toggleChat2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen1(false);
  };

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <style>
        {`
          .chat-icon {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background-color: white;
            color: #fff;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 999;
          

           
           
          }
          .chat-box1 {
            position: fixed;
            bottom: 20px;
            right: 80px;
            width: 400px;
            height: 450px;
            background-color: #fff;
           
            border-radius: 5px;
            display: ${isOpen1 ? "block" : "none"};
            z-index: 998;
           
          }
          .chat-box {
            position: fixed;
            bottom: 20px;
            right: 80px;
            width: 500px;
            height: 580px;
            background-color: #fff;
           
            border-radius: 5px;
            display: ${isOpen2 ? "block" : "none"};
            z-index: 998;
             padding:20px
          }
          
          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
            z-index: 998; /* Ensure it's above everything except the chat boxes */
            display: ${isOpen1 || isOpen2 ? "block" : "none"};
          }
          .chat-box-header {
            background-color: #007bff;
            color: #fff;
            padding: 10px;
            border-radius: 5px 5px 0 0;
          }

          .chat-box-body {
            padding: 10px;
            overflow-y: auto;
          }

          .chat-input {
            width: calc(100% - 20px);
            margin: 0 10px;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
          }

          .chat-submit {
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 8px 15px;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
          }

          @media (max-width: 600px) {
            .chat-box1 {
              width: 300px;
              height: 412px;
            }
          }
        `}
      </style>
      <div
        className="chat-icon"
        style={{
          bottom: "20px",
          right: "3px",
          color: "rgba(37, 211, 102, 1)",
        }}
        onClick={toggleChat1}>
        <RiWhatsappFill size={120} />
      </div>
      <ChatBox1
        isOpen={isOpen1}
        toggleChat={toggleChat1}
        setIsOpen1={setIsOpen1}
      />

      <div
        className="chat-icon"
        style={{
          bottom: "70px",
          right: "3px",
          color: "white",
          backgroundColor: "#007bff",
        }}
        onClick={toggleChat2}>
        <MdOutlineComment size={25} />
      </div>
      <ChatBox2
        isOpen={isOpen2}
        toggleChat={toggleChat2}
        setIsOpen={setIsOpen2}
      />
    </div>
  );
};

export default ChatBot;
