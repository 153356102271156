import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TablePagination,
  Toolbar,
  Tooltip,
  useMediaQuery,
  TextField,
  InputAdornment,
  FormHelperText,
  DialogContent,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BsFileEarmarkPdfFill } from "react-icons/bs";

import InputBase from "@mui/material/InputBase";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { DownloadPDF, transaction } from "../../redux/data/action";
import Footer from "../../MobileView/Footer";
import DrawerBox from "../CommonComponent/Drawer";
import Navbar from "../../MobileView/Navbar";
import NumberFormat from "../../components/NumberFormat";
import LoadingPage from "../../components/LoadingPage";
import Loading_page from "../../components/Loading_Page";

//////////////////////////////////////////////////////////////////////////////////////////////
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";

import Paper from "@mui/material/Paper";

import { FaFilePdf } from "react-icons/fa6";

import Box from "@mui/material/Box";
import profile from "../../images/doctor.png";
import { contact } from "../../redux/data/action";

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

//////////////////////////////////////////////////////////////////////////////////////////////

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",

  overflow: "auto",
}));

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  cursor: "pointer",
  textDecoration: "none",
  color: "black",
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",
  textDecoration: "none",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "repeat(1,1fr)",
  flexDirection: "column",
  height: "calc(100vh - 180px)",
  gap: "10px",
  // border:'1px solid red',
  // height: "621px",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "200px",
  height: "100%",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search3 = styled(Stack)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const Transaction = () => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const [userName, setUserName] = useState("");
  const [hospital, setHospital] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({
    userName: "",
    hospital: "",
    email: "",
    phone: "",
    comment: "",
  });
  const maxLength = 300;
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);
  const [open, setOpen] = React.useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const TransactionData = useSelector((store) => store.data.transaction);
  /////console.log("TransactionData", TransactionData);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkedRows, setCheckedRows] = useState([]);
  const [creditDetail, setCreditDetail] = useState({});
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });

  /////console.log(currentMonth);
  const [filteredData, setFilteredData] = useState([]);
  /////console.log("filteredData************", filteredData);
  const [scrollableHeight, setScrollableHeight] = useState(0);
  /////console.log("scrollableHeight", scrollableHeight);
  useEffect(() => {
    const calculateScrollableHeight = () => {
      const navbar = document.getElementById("navbar");
      const searchBar = document.getElementById("search-bar");
      const heading = document.getElementById("heading");
      const footer = document.getElementById("footer");

      if (navbar && searchBar && heading && footer) {
        const navbarHeight = navbar.offsetHeight;
        const searchBarHeight = searchBar.offsetHeight;
        const headingHeight = heading.offsetHeight;
        const footerHeight = footer.offsetHeight;

        const windowHeight = window.innerHeight;
        const remainingHeight =
          windowHeight -
          navbarHeight -
          searchBarHeight -
          headingHeight -
          footerHeight -
          50;

        setScrollableHeight(remainingHeight);
      }
    };

    calculateScrollableHeight();

    const handleResize = () => {
      calculateScrollableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (TransactionData && TransactionData?.length > 0) {
      setData(TransactionData.transactions);
    }
  }, [TransactionData]);
  /////console.log("data", data);
  useEffect(() => {
    if (Object.keys(TransactionData)?.length > 0 && TransactionData !== 0) {
      setData(TransactionData.transactions);
      setCreditDetail(TransactionData.credits_detail);
    }
  }, [TransactionData]);

  useEffect(() => {
    dispatch(transaction());
  }, []);

  useEffect(() => {
    /////console.log("isOpen state changed:", isOpen);
  }, [isOpen]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
  };

  const toggleChat = () => {
    /////console.log("toggleChat called");
    setIsOpen(!isOpen);
  };
  const handleInputChange = (field, value) => {
    // Update the respective input value state
    switch (field) {
      case "userName":
        setUserName(value);
        break;
      case "hospital":
        setHospital(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
        break;
    }

    // Clear the error for the field if the user is typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Reset the error for the changed field
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    ["userName", "hospital", "phone", "comment", "email"].forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospital"
          ? hospital
          : key === "phone"
          ? phone
          : key === "comment"
          ? comment
          : key === "email"
          ? email
          : "";
      const error = validateField(key, value);
      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!hospital.trim()) {
      formErrors["hospital"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phone.trim()) {
      formErrors["phone"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!comment.trim()) {
      formErrors["comment"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      dispatch(contact(userName, email, phone, comment, hospital));
      setOpen(false);
      setOpenSubmission(true);
      setTimeout(() => {
        handleCloseSubmission();
      }, 2000);
      setUserName("");
      setHospital("");
      setPhone("");
      setEmail("");
      setComment("");
      setErrors("");
      setIsOpen(!isOpen);
      //
    } else {
      // /////console.log("Form has errors. Please correct them.");
    }
  };

  const handleIconClick = () => {
    setUserName("");
    setHospital("");
    setPhone("");
    setEmail("");
    setComment("");
    setErrors("");
    setIsOpen(!isOpen);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "userName":
        if (!value.trim().match(/^[a-zA-Z.\s]+$/)) {
          error = "Invalid username";
        }
        break;
      case "hospital":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid hospital name";
        }
        break;
      case "email":
        // Enhanced email validation to ensure proper format
        if (
          !value
            .trim()
            .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
          error = "Please enter a valid email address.";
        }
        break;
      case "phone":
        if (!value.trim().match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "comment":
        if (!value.trim().match(/^[#.0-9a-zA-Z\s,-]+$/)) {
          error = "Invalid address format.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue?.length <= maxLength) {
      setComment(inputValue);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {!isMobileScreen ? (
        <>
          <DrawerBox
            data={data}
            setData={setData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            heading={"Transactions"}
            Filter1="orderstatus"
            Filter2=""
            FilterName1="Status"
            sortPrice="totprice"
            sortDate="date"
            name="orderid"
          />
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              alignItems: "start",
            }}
          >
            <LeftWrapper>
              <LeftBox1 sx={{}}>
                <Stack
                  sx={{ height: "50%", width: "60%" }}
                  justifyContent={"space-between"}
                  gap={"20px"}
                >
                  <Label variant="button">CREDITS</Label>
                  <Stack>
                    <Label
                      variant="button"
                      sx={{ color: "rgba(183, 183, 183, 1)" }}
                    >
                      BALANCE
                    </Label>
                    <Label
                      variant="subtitle1"
                      sx={{ color: "rgba(193, 32, 37, 1)" }}
                    >
                      ₹<NumberFormat number={creditDetail.balance_credits} />
                    </Label>
                  </Stack>
                  <Stack>
                    <Label
                      variant="button"
                      sx={{ color: "rgba(183, 183, 183, 1)" }}
                    >
                      USED
                    </Label>
                    <Label
                      variant="subtitle1"
                      sx={{ color: "rgba(193, 32, 37, 1)" }}
                    >
                      ₹<NumberFormat number={creditDetail.used_credit} />
                    </Label>
                  </Stack>
                </Stack>
                <Button
                  sx={{ width: "133px" }}
                  variant="outlined"
                  onClick={() => navigate("/")}
                >
                  See credits
                </Button>
              </LeftBox1>
              <LeftBox1>
                <Stack
                  sx={{ height: "50%", width: "60%" }}
                  justifyContent={"space-between"}
                  gap={"20px"}
                >
                  <Label variant="button">Payment due</Label>
                  <Stack>
                    <Label variant="button">Total</Label>
                    <Label
                      variant="subtitle1"
                      sx={{ color: "rgba(193, 32, 37, 1)" }}
                    >
                      ₹<NumberFormat number={creditDetail.payment_due} />
                    </Label>
                  </Stack>
                </Stack>
                <Button
                  sx={{ width: "133px" }}
                  variant="contained"
                  onClick={toggleChat}
                >
                  Pay Now
                </Button>
              </LeftBox1>
            </LeftWrapper>
            <Grid container>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    height: "calc(100vh - 180px)",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    boxShadow:
                      "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  }}
                >
                  <React.Fragment>
                    {data?.length > 0 && filteredData?.length > 0 ? (
                      <>
                        {" "}
                        <Table size="small" stickyHeader style={{}}>
                          <TableHead style={{}}>
                            <TableRow style={{}}>
                              <TableHeading>
                                <Label variant="caption"> ORDER ID</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> DATE & TIME</Label>
                              </TableHeading>

                              <TableHeading>
                                <Label variant="caption"> AMOUNT</Label>
                              </TableHeading>
                              <TableHeading
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                {" "}
                                <Label variant="caption"> SAVINGS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> ORDER STATUS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption">
                                  {" "}
                                  INVOICE DOWNLOAD
                                </Label>
                              </TableHeading>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData
                              // ?.slice(
                              //   page * rowsPerPage,
                              //   page * rowsPerPage + rowsPerPage
                              // )
                              ?.map((row, index) => (
                                <TableRow
                                  key={row.orderid}
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "rgba(255, 237, 237, 1)",
                                    },
                                  }}
                                  style={{
                                    boxShadow:
                                      "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                  }}
                                >
                                  <TableRows>
                                    {row.orderid?.length > 11 ? (
                                      <Tooltip title={row.orderid}>
                                        <TableText
                                          component={Link}
                                          to={`/order-summary/${row.orderid}`}
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {/* {row.orderid.slice(0, 9) + "..."} */}
                                          {row.orderid}
                                        </TableText>
                                      </Tooltip>
                                    ) : (
                                      <TableText
                                        component={Link}
                                        to={`/order-summary/${row.orderid}`}
                                        variant="caption"
                                        style={{
                                          maxWidth: "11ch",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {row.orderid}
                                      </TableText>
                                    )}
                                  </TableRows>

                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText variant="caption">
                                      {row.date} & {row.time}
                                    </TableText>
                                  </TableRows>
                                  {/* <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}>
                                    <TableText variant="caption">
                                      {row.time}
                                    </TableText>
                                  </TableRows> */}
                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText variant="caption">
                                      ₹<NumberFormat number={row.totprice} />
                                    </TableText>
                                  </TableRows>
                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText
                                      variant="caption"
                                      sx={{
                                        color: "rgba(255, 153, 0, 1)",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {parseFloat(
                                        row.totsavings_percent
                                      ).toFixed(2)}
                                      %
                                    </TableText>
                                  </TableRows>

                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText
                                      variant="caption"
                                      sx={{
                                        color:
                                          row.orderstatus === "Delivered"
                                            ? "rgba(54, 201, 2, 1)"
                                            : "black",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {" "}
                                      {row.orderstatus}
                                    </TableText>
                                  </TableRows>

                                  {row.orderstatus === "Delivered" ? (
                                    <TableRows style={{}}>
                                      <Box
                                        onClick={() =>
                                          dispatch(
                                            DownloadPDF(
                                              row?.invoice,
                                              row?.orderid
                                            )
                                          )
                                        }
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "15px",
                                          background: "rgba(255, 237, 237, 1)",
                                          width: "100%",
                                          height: "100%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <BsFileEarmarkPdfFill
                                          size={10}
                                          style={{
                                            color: "rgba(193, 32, 37, 1)",

                                            width: "21px",
                                            height: "21px",
                                            borderRadius: "3px",
                                          }}
                                        />
                                        <TableText
                                          variant="caption"
                                          sx={{ fontWeight: 600 }}
                                        >
                                          Download PDF
                                        </TableText>
                                      </Box>
                                    </TableRows>
                                  ) : (
                                    <>
                                      <TableRows
                                        style={{
                                          fontSize: "medium",

                                          textAlign: "center",
                                        }}
                                        component={Link}
                                        to={`/order-summary/${row.orderid}`}
                                      ></TableRows>
                                    </>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <div
                          className="chat-box"
                          style={{
                            display: isOpen ? "flex" : "none",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Adding box-shadow for border effect
                            borderRadius: "8px", // Optional: to round the corners
                          }}
                        >
                          <div
                            className="chat-box"
                            style={{
                              display: isOpen ? "flex" : "none",
                              justifyContent: "space-evenly",
                              flexDirection: "column",
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              {/* <Box
            component="img"
            src={profile}
            sx={{ width: "100%", height: "100px" }}
          /> */}
                              <Box
                                sx={{
                                  position: "relative",
                                  width: "100%",
                                  height: "100px",
                                }}
                              >
                                <Box
                                  component="img"
                                  src={profile}
                                  alt="Profile"
                                  sx={{ width: "100%", height: "100%" }}
                                />
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "8px",
                                    right: "8px",
                                    cursor: "pointer",
                                    color: "white",
                                    backgroundColor: "rgba(193, 32, 37, 1)",
                                    width: "20px",
                                    height: "20px",
                                    padding: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  onClick={toggleChat}
                                >
                                  <CloseIcon />
                                </Box>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                sx={{ marginTop: 2 }}
                              >
                                How can we help you?
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box component="form" noValidate>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      hiddenLabel
                                      id="filled-hidden-label-small"
                                      placeholder="Mr. Samuel Arora"
                                      variant="filled"
                                      size="small"
                                      sx={{ width: "100%" }}
                                      InputProps={{
                                        style: {
                                          height: "50px",
                                          fontSize: "15px",
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PersonIcon />
                                          </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                      }}
                                      value={userName}
                                      onChange={(e) =>
                                        handleInputChange(
                                          "userName",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.userName && (
                                      <HelperText>{errors.userName}</HelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      hiddenLabel
                                      id="filled-hidden-label-small"
                                      placeholder="Apollo Hospitals, Delhi"
                                      variant="filled"
                                      size="small"
                                      sx={{ width: "100%" }}
                                      InputProps={{
                                        style: {
                                          height: "50px",
                                          fontSize: "15px",
                                        },

                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <ApartmentIcon />
                                          </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                      }}
                                      value={hospital}
                                      onChange={(e) =>
                                        handleInputChange(
                                          "hospital",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.hospital && (
                                      <HelperText>{errors.hospital}</HelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      hiddenLabel
                                      id="filled-hidden-label-small"
                                      placeholder="samuel008@gmail.com"
                                      variant="filled"
                                      size="small"
                                      sx={{ width: "100%" }}
                                      InputProps={{
                                        style: {
                                          height: "50px",
                                          fontSize: "15px",
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <EmailIcon />
                                          </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                      }}
                                      value={email}
                                      onChange={(e) =>
                                        handleInputChange(
                                          "email",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.email && (
                                      <HelperText>{errors.email}</HelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      hiddenLabel
                                      id="filled-hidden-label-small"
                                      placeholder="011 7179 1090"
                                      variant="filled"
                                      size="small"
                                      sx={{ width: "100%" }}
                                      InputProps={{
                                        style: {
                                          height: "50px",
                                          fontSize: "15px",
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <LocalPhoneIcon />
                                          </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                      }}
                                      value={phone}
                                      onChange={(e) =>
                                        handleInputChange(
                                          "phone",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors.phone && (
                                      <HelperText>{errors.phone}</HelperText>
                                    )}
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                      required
                                      fullWidth
                                      variant="filled"
                                      name="text"
                                      placeholder="Add comments"
                                      multiline
                                      maxRows={10}
                                      InputProps={{
                                        style: {
                                          height: "100px",
                                          fontSize: "15px",
                                        },
                                        disableUnderline: true,
                                      }}
                                      inputProps={{
                                        maxLength: maxLength,
                                      }}
                                      helperText={`${comment?.length}/${maxLength} characters`}
                                      value={comment}
                                      onChange={(e) => {
                                        handleInputChange(
                                          "comment",
                                          e.target.value
                                        );
                                        handleChange(e);
                                      }}
                                    />
                                    {errors.comment && (
                                      <HelperText>{errors.comment}</HelperText>
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <Button
                                      onClick={handleIconClick}
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        mt: 3,
                                        mb: 2,
                                        border:
                                          "1px solid rgba(193, 32, 37, 1)",
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Button
                                      onClick={handleSubmit}
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      sx={{ mt: 3, mb: 2 }}
                                    >
                                      Submit
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </div>
                          <Dialog
                            open={openSubmission}
                            onClose={handleCloseSubmission}
                          >
                            <Stack
                              justifyContent={"space-evenly"}
                              alignItems={"center"}
                              sx={{ width: "420px", height: "200px" }}
                            >
                              <DialogTitle
                                sx={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",
                                  color: "rgba(193, 32, 37, 1)",
                                }}
                              >
                                Request submitted!
                              </DialogTitle>
                              <DialogContent
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <DialogContentText
                                  sx={{ textAlign: "center", color: "black" }}
                                >
                                  <Typography variant="body2">
                                    Your request has been successfully
                                    submitted.
                                    <br />
                                     Our team will get in touch with you soon.
                                  </Typography>
                                </DialogContentText>
                              </DialogContent>
                            </Stack>
                          </Dialog>
                        </div>
                        <Dialog
                          open={openSubmission}
                          onClose={handleCloseSubmission}
                        >
                          <Stack
                            justifyContent={"space-evenly"}
                            alignItems={"center"}
                            sx={{ width: "420px", height: "200px" }}
                          >
                            <DialogTitle
                              sx={{
                                textAlign: "center",
                                color: "rgba(193, 32, 37, 1)",
                              }}
                            >
                              Request submitted!
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <DialogContentText
                                sx={{ textAlign: "center", color: "black" }}
                              >
                                <Typography variant="body2">
                                  Your request has been successfully submitted.
                                  <br />
                                  Our team will get in touch with you soon.
                                </Typography>
                              </DialogContentText>
                            </DialogContent>
                          </Stack>
                        </Dialog>
                      </>
                    ) : (
                      <>
                        <Box1
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <Typography>
                            No orders have been placed yet.
                          </Typography>
                          <Label2>Add to your cart now</Label2>
                          <Button variant="contained">PLACE NEW ORDER</Button>
                        </Box1>
                      </>
                    )}
                  </React.Fragment>
                </Paper>
              </Grid>
            </Grid>

            <Dialog open={openSubmission} onClose={handleCloseSubmission}>
              <Stack
                justifyContent={"space-around"}
                alignItems={"center"}
                sx={{ width: "420px", height: "200px" }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    color: "rgba(193, 32, 37, 1)",
                  }}
                >
                  <Label variant="h6">Confirm payment</Label>
                </DialogTitle>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <DialogContentText
                    sx={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    <Label2 variant="body2">
                      You have selected {checkedRows?.length} bills for payment.
                    </Label2>
                  </DialogContentText>
                </Box>
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: "100px" }}
                    onClick={handleCloseSubmission}
                    color="primary"
                  >
                    EDIT
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ width: "100px" }}
                  >
                    PAY
                  </Button>
                </DialogActions>
              </Stack>
            </Dialog>
          </StyledBox>
        </>
      ) : (
        <>
          <div>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"Transactions"} />
            </AppBar>
            <Toolbar />
            <Box sx={{ height: "100%" }}>
              <Stack
                sx={{
                  backgroundColor: "#f0f0f0",

                  position: "fixed",

                  zIndex: 1,

                  paddingTop: "20px",
                  paddingBottom: "20px",
                  bgcolor: "rgba(241, 241, 241, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                }}
                id="search-bar"
              >
                <Stack
                  sx={{
                    marginTop: "20px",
                    gap: "10px",

                    width: "80%",
                  }}
                >
                  <Stack
                    flexDirection={"row"}
                    gap={"10px"}
                    sx={{
                      height: "40px",

                      justifyContent: "space-between",
                      bgcolor: "white",
                      borderRadius: "10px",
                      border: "2px solid rgba(255, 255, 255, 1)",

                      boxShadow:
                        "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                    }}
                  >
                    <Search3
                      sx={{
                        justifyContent: "center",
                        alignItems: "start",
                        paddingLeft: "20px",
                      }}
                    >
                      <Label>{currentMonth}</Label>
                    </Search3>

                    <DrawerBox
                      data={data}
                      setData={setData}
                      filteredData={filteredData}
                      setFilteredData={setFilteredData}
                      heading={"Transactions"}
                      Filter1="orderstatus"
                      Filter2=""
                      sortPrice="totprice"
                      sortDate="date"
                      name="orderid"
                    />
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    gap={"10px"}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Stack
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      sx={{
                        width: "85px",
                        height: "82px",

                        border: "2px solid rgba(255, 255, 255, 1)",
                        bgcolor: "white",
                        boxShadow:
                          "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        borderRadius: "10px",
                      }}
                    >
                      <Label sx={{ color: "rgba(255, 153, 0, 1)" }}>
                        {" "}
                        {TransactionData.savings}
                      </Label>
                      <Label variant="body2"> Savings</Label>
                    </Stack>
                    <Stack
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      sx={{
                        width: "85px",
                        height: "82px",
                        border: "2px solid rgba(255, 255, 255, 1)",
                        bgcolor: "white",
                        boxShadow:
                          "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        borderRadius: "10px",
                      }}
                    >
                      <Label> {creditDetail.payment_due}</Label>
                      <Label variant="body2"> Due</Label>
                    </Stack>
                    <Stack
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      sx={{
                        width: "86px",
                        height: "86px",
                        // border: "1px solid rgba(193, 32, 37, 1)",
                        bgcolor: "rgba(193, 32, 37, 1)",
                        color: "white",
                        boxShadow:
                          "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        borderRadius: "10px",
                      }}
                    >
                      <Label variant="body2"> ₹</Label>
                      <Label variant="body2"> Pay Now</Label>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Box sx={{ paddingTop: "140px" }}>
                <Stack
                  id="heading"
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",

                    marginTop: "32px",
                  }}
                  flexDirection={"row"}
                ></Stack>

                <Paper
                  sx={{
                    marginTop: "16px",
                    height: scrollableHeight,
                    overflowY: "auto",
                    boxShadow: "none",
                  }}
                >
                  <Stack sx={{ padding: "20px", overflowY: "auto" }}>
                    {/* console.log("fil***********", data) */}

                    {filteredData.map((item, index) => (
                      <>
                        <Stack
                          sx={{
                            flexDirection: "row",

                            marginBottom: "10px",

                            bgcolor: "white",
                            minHeight: "80px",
                            marginTop: "4%",
                            gap: "10px",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "100%",
                              padding: "10px",
                              gap: "10px",
                              boxShadow:
                                " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                              //
                              borderRadius: "10px",
                            }}
                          >
                            <Stack
                              onClick={() =>
                                navigate(`/order-summary/${item.orderid}`)
                              }
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                            >
                              <Label
                                variant="subtitle2"
                                style={{
                                  maxWidth: "11ch",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  textDecoration: "none",
                                }}
                              >
                                {item.orderid}
                              </Label>
                              <Label
                                variant="subtitle2"
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                {parseFloat(item.totsavings_percent).toFixed(2)}
                                %
                              </Label>
                            </Stack>

                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label2 variant="body2" sx={{}}>
                                ₹<NumberFormat number={item.totprice} />
                              </Label2>
                            </Stack>
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label2 variant="body2"> {item.date}</Label2>
                              <Label2 variant="body2"> {item.time}</Label2>
                              {item.orderstatus === "Delivered" ? (
                                <Label
                                  variant="body2"
                                  sx={{ color: "rgba(54, 201, 2, 1)" }}
                                >
                                  {" "}
                                  {item.orderstatus}
                                </Label>
                              ) : (
                                <Label
                                  variant="body2"
                                  sx={{ color: "rgba(146, 146, 146, 1)" }}
                                >
                                  {" "}
                                  {item.orderstatus}
                                </Label>
                              )}
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item.orderstatus === "Delivered" ? (
                              <Button
                                variant="contained"
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  minWidth: "0px",
                                  padding: 0,
                                }}
                              >
                                <FaFilePdf
                                  size={15}
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(item?.invoice, item?.orderid)
                                    )
                                  }
                                />
                              </Button>
                            ) : (
                              <IconButton></IconButton>
                            )}
                          </Stack>
                        </Stack>
                      </>
                    ))}
                  </Stack>
                </Paper>
              </Box>
            </Box>
            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
              id="footer"
            >
              <Footer />
            </AppBar>
          </div>
        </>
      )}
      <Loading_page />
    </>
  );
};

export default Transaction;
