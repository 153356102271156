import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MdSavings } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import Loading_page from "../components/Loading_Page";
import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allOrder, cancelOrder, contact } from "../redux/data/action";
import { Link, useNavigate } from "react-router-dom";
import { RiEdit2Fill } from "react-icons/ri";
import DeleteButton from "./CommonComponent/DeleteButton";
import EmptyPage from "./CommonComponent/EmptyPage";

import DrawerBox from "./CommonComponent/Drawer";
import Navbar from "../MobileView/Navbar";
import Footer from "../MobileView/Footer";
import NumberFormat from "../components/NumberFormat";

//////////////////////////////////////////////////////////////////////////////////////////////
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  InputAdornment,
  FormHelperText,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import profile from "../images/doctor.png";

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

//////////////////////////////////////////////////////////////////////////////////////////////

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));
const Label3 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: "rgba(146, 146, 146, 1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  textDecoration: "none",
  color: "black",
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",
  textDecoration: "none",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "repeat(1,1fr)",
  flexDirection: "column",
  height: "calc(100vh - 180px)",
  gap: "10px",
  // border:'1px solid red',
  // height: "621px",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "200px",
  height: "100%",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",

  border: "1px solid rgba(235, 235, 235, 1)",
  width: "134px",
  height: "76px",
  textAlign: "center",
  borderRadius: "2px ",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search3 = styled(Stack)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const MyOrder = () => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const [userName, setUserName] = useState("");
  const [hospital, setHospital] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({
    userName: "",
    hospital: "",
    email: "",
    phone: "",
    comment: "",
  });
  const maxLength = 300;
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);
  const [open, setOpen] = React.useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const myOrderData = useSelector((store) => store.data.allOrder);
  /////console.log("myOrderData data are:", myOrderData);
  const cancelOrderResponse = useSelector((store) => store.data.cancelOrder);
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  let branchData = JSON.parse(localStorage.getItem("branchData"));
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [allOrderList, setAllOrderList] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});

  const [getId, setGetId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const [scrollableHeight, setScrollableHeight] = useState(0);
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });

  useEffect(() => {
    const calculateScrollableHeight = () => {
      const navbar = document.getElementById("navbar");
      const searchBar = document.getElementById("search-bar");
      const heading = document.getElementById("heading");
      const footer = document.getElementById("footer");

      if (navbar && searchBar && heading && footer) {
        const navbarHeight = navbar.offsetHeight;
        const searchBarHeight = searchBar.offsetHeight;
        const headingHeight = heading.offsetHeight;
        const footerHeight = footer.offsetHeight;

        const windowHeight = window.innerHeight;
        const remainingHeight =
          windowHeight -
          navbarHeight -
          searchBarHeight -
          headingHeight -
          footerHeight -
          50;

        setScrollableHeight(remainingHeight);
      }
    };

    calculateScrollableHeight();

    const handleResize = () => {
      calculateScrollableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData]);
  const handleExpandClick = (index, row) => {
    /////console.log("row", row);
    setGetId(row);
    setExpandedIndex(index === expandedIndex ? null : index);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (allOrderList && allOrderList?.length > 0) {
      setData(allOrderList);
    }
  }, [allOrderList]);

  useEffect(() => {
    dispatch(allOrder());

    setLoading(false);
  }, [dispatch, cancelOrderResponse]);
  const handleDelete = () => {
    const requestData = {
      orderid: getId.orderid,
      userid: userData.userid,
      branchid: branchData.branchId,
    };
    dispatch(cancelOrder(requestData));

    setOpenDelete(false);
  };

  const handleOpenDelete = (row) => {
    setGetId(row);
    setOpenDelete(true);
  };

  useEffect(() => {
    if (myOrderData) {
      setOrderDetail(myOrderData.month_order_details || {});
      setAllOrderList(myOrderData.orders_list || []);
    }
  }, [myOrderData]);

  if (loading) {
    return <CircularProgress />;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleCloseSubmission = () => {
    setOpenSubmission(false);
  };

  const toggleChat = () => {
    /////console.log("toggleChat called");
    setIsOpen(!isOpen);
  };
  const handleInputChange = (field, value) => {
    // Update the respective input value state
    switch (field) {
      case "userName":
        setUserName(value);
        break;
      case "hospital":
        setHospital(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
        break;
    }

    // Clear the error for the field if the user is typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Reset the error for the changed field
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    ["userName", "hospital", "phone", "comment", "email"].forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospital"
          ? hospital
          : key === "phone"
          ? phone
          : key === "comment"
          ? comment
          : key === "email"
          ? email
          : "";
      const error = validateField(key, value);
      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!hospital.trim()) {
      formErrors["hospital"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phone.trim()) {
      formErrors["phone"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!comment.trim()) {
      formErrors["comment"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      dispatch(contact(userName, email, phone, comment, hospital));
      setOpen(false);
      setOpenSubmission(true);
      setTimeout(() => {
        handleCloseSubmission();
      }, 2000);
      setUserName("");
      setHospital("");
      setPhone("");
      setEmail("");
      setComment("");
      setErrors("");
      setIsOpen(!isOpen);
      //
    } else {
      // /////console.log("Form has errors. Please correct them.");
    }
  };

  const handleIconClick = () => {
    setUserName("");
    setHospital("");
    setPhone("");
    setEmail("");
    setComment("");
    setErrors("");
    setIsOpen(!isOpen);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "userName":
        if (!value.trim().match(/^[a-zA-Z.\s]+$/)) {
          error = "Invalid username";
        }
        break;
      case "hospital":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid hospital name";
        }
        break;
      case "email":
        // Enhanced email validation to ensure proper format
        if (
          !value
            .trim()
            .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
          error = "Please enter a valid email address.";
        }
        break;
      case "phone":
        if (!value.trim().match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "comment":
        if (!value.trim().match(/^[#.0-9a-zA-Z\s,-]+$/)) {
          error = "Invalid address format.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue?.length <= maxLength) {
      setComment(inputValue);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {!isMobileScreen ? (
        <>
          <DrawerBox
            data={data}
            setData={setData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            heading={"My Orders"}
            Filter1="orderstatus"
            Filter2=""
            sortPrice="totprice"
            sortDate="date"
            name="orderid"
          />
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              alignItems: "start",
              // border:'3px solid red',
            }}
          >
            <LeftWrapper>
              <LeftBox1>
                <LeftBox>
                  {Object.keys(orderDetail).length !== 0 ? (
                    <Label style={{ color: "rgba(255, 153, 0, 1)" }}>
                      {orderDetail.totsavings}%
                    </Label>
                  ) : (
                    <Label style={{ color: "rgba(255, 153, 0, 1)" }}>00%</Label>
                  )}

                  <Label2 style={{ fontSize: "small" }}>
                    Savings this month
                  </Label2>
                </LeftBox>
                <LeftBox>
                  {Object.keys(orderDetail).length !== 0 ? (
                    <Label>{orderDetail.order_count}</Label>
                  ) : (
                    <Label>00</Label>
                  )}

                  <Label2 style={{ fontSize: "small" }}>
                    Orders this month
                  </Label2>
                </LeftBox>

                <Button
                  sx={{ width: "133px" }}
                  variant="contained"
                  onClick={() => navigate("/new-order")}
                >
                  New Order
                </Button>
              </LeftBox1>
              <LeftBox1>
                <Stack
                  sx={{ height: "50%", alignItems: "center" }}
                  justifyContent={"space-between"}
                  gap={"20px"}
                >
                  <Label variant="button">Payment due</Label>

                  {allOrderList?.length > 0 ? (
                    <Stack>
                      <Label variant="button">Total</Label>
                      <Label
                        variant="subtitle1"
                        sx={{ color: "rgba(193, 32, 37, 1)" }}
                      >
                        ₹
                        <NumberFormat
                          number={myOrderData?.payment_due?.pay_due}
                        />
                      </Label>
                    </Stack>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        color: "rgba(183, 183, 183, 1)",
                        wordBreak: "break-word",
                        width: "90%",
                        textAlign: "center",
                      }}
                    >
                      Your account is clear! All payments have been successfully
                      processed.
                    </Typography>
                  )}
                </Stack>
                <Button
                  sx={{ width: "133px" }}
                  disabled={allOrderList?.length === 0}
                  variant="contained"
                  onClick={toggleChat}
                >
                  Pay Now
                </Button>
              </LeftBox1>
            </LeftWrapper>
            <div
              className="chat-box"
              style={{
                display: isOpen ? "flex" : "none",
                justifyContent: "space-evenly",
                flexDirection: "column",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Adding box-shadow for border effect
                borderRadius: "8px", // Optional: to round the corners
              }}
            >
              <div
                className="chat-box"
                style={{
                  display: isOpen ? "flex" : "none",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <Box
            component="img"
            src={profile}
            sx={{ width: "100%", height: "100px" }}
          /> */}
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100px",
                    }}
                  >
                    <Box
                      component="img"
                      src={profile}
                      alt="Profile"
                      sx={{ width: "100%", height: "100%" }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "rgba(193, 32, 37, 1)",
                        width: "20px",
                        height: "20px",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={toggleChat}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
                    How can we help you?
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="form" noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="Mr. Samuel Arora"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={userName}
                          onChange={(e) =>
                            handleInputChange("userName", e.target.value)
                          }
                        />
                        {errors.userName && (
                          <HelperText>{errors.userName}</HelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="Apollo Hospitals, Delhi"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },

                            startAdornment: (
                              <InputAdornment position="start">
                                <ApartmentIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={hospital}
                          onChange={(e) =>
                            handleInputChange("hospital", e.target.value)
                          }
                        />
                        {errors.hospital && (
                          <HelperText>{errors.hospital}</HelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="samuel008@gmail.com"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={email}
                          onChange={(e) =>
                            handleInputChange("email", e.target.value)
                          }
                        />
                        {errors.email && (
                          <HelperText>{errors.email}</HelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="011 7179 1090"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalPhoneIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={phone}
                          onChange={(e) =>
                            handleInputChange("phone", e.target.value)
                          }
                        />
                        {errors.phone && (
                          <HelperText>{errors.phone}</HelperText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="filled"
                          name="text"
                          placeholder="Add comments"
                          multiline
                          maxRows={10}
                          InputProps={{
                            style: { height: "100px", fontSize: "15px" },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            maxLength: maxLength,
                          }}
                          helperText={`${comment?.length}/${maxLength} characters`}
                          value={comment}
                          onChange={(e) => {
                            handleInputChange("comment", e.target.value);
                            handleChange(e);
                          }}
                        />
                        {errors.comment && (
                          <HelperText>{errors.comment}</HelperText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={handleIconClick}
                          fullWidth
                          variant="outlined"
                          sx={{
                            mt: 3,
                            mb: 2,
                            border: "1px solid rgba(193, 32, 37, 1)",
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={handleSubmit}
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
              <Dialog open={openSubmission} onClose={handleCloseSubmission}>
                <Stack
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  sx={{ width: "420px", height: "200px" }}
                >
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      color: "rgba(193, 32, 37, 1)",
                    }}
                  >
                    Request submitted!
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DialogContentText
                      sx={{ textAlign: "center", color: "black" }}
                    >
                      <Typography variant="body2">
                        Your request has been successfully submitted.
                        <br />
                         Our team will get in touch with you soon.
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                </Stack>
              </Dialog>
            </div>
            <Grid container>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    // height:
                    //   allOrderList?.length > 0 && filteredData?.length > 0
                    //     ? "621px"
                    //     : "621px",
                    // minHeight: "621px",
                    height: "calc(100vh - 180px)",
                    // border:"2px solid red",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    // boxShadow:
                    //   "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  }}
                >
                  <React.Fragment>
                    {allOrderList?.length > 0 && filteredData?.length > 0 ? (
                      <>
                        {" "}
                        <Table stickyHeader size="small" style={{}}>
                          <TableHead>
                            <TableRow>
                              <TableHeading>
                                <Label variant="caption"> ORDER ID</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> DATE & TIME</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> TOTAL PROD</Label>
                              </TableHeading>
                              {/* <TableHeading>
                                <Label variant="caption"> QTY</Label>
                              </TableHeading> */}
                              <TableHeading>
                                <Label variant="caption"> PRICE</Label>
                              </TableHeading>
                              <TableHeading
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                {" "}
                                <Label variant="caption"> SAVINGS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> STATUS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> </Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> </Label>
                              </TableHeading>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData
                              // ?.slice(
                              //   page * rowsPerPage,
                              //   page * rowsPerPage + rowsPerPage
                              // )
                              ?.map((row, index) => (
                                <TableRow
                                  key={row.orderid}
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "rgba(255, 237, 237, 1)",
                                    },
                                  }}
                                  style={{
                                    boxShadow:
                                      "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                  }}
                                >
                                  <TableRows>
                                    {row.orderid?.length > 11 ? (
                                      <Tooltip title={row.orderid}>
                                        <TableText
                                          component={Link}
                                          to={`/order-summary/${row.orderid}`}
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {/* {row.orderid.slice(0, 9) + "..."} */}
                                          {row.orderid}
                                        </TableText>
                                      </Tooltip>
                                    ) : (
                                      <TableText
                                        component={Link}
                                        to={`/order-summary/${row.orderid}`}
                                        variant="caption"
                                        style={{
                                          maxWidth: "11ch",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {row.orderid}
                                      </TableText>
                                    )}
                                  </TableRows>

                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText variant="caption">
                                      {row.date} & {row.time}
                                    </TableText>
                                  </TableRows>

                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText variant="caption">
                                      {" "}
                                      {row.totproduct} nos
                                    </TableText>
                                  </TableRows>
                                  {/* <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}>
                                    <TableText variant="caption">
                                      {row.totquantity}
                                    </TableText>
                                  </TableRows> */}
                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText variant="caption">
                                      ₹ <NumberFormat number={row.totprice} />
                                    </TableText>
                                  </TableRows>
                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText
                                      variant="caption"
                                      sx={{
                                        color: "rgba(255, 153, 0, 1)",
                                        fontWeight: 700,
                                      }}
                                    >
                                      ₹ <NumberFormat number={row.totsavings} />
                                    </TableText>
                                  </TableRows>
                                  <TableRows
                                    component={Link}
                                    to={`/order-summary/${row.orderid}`}
                                  >
                                    <TableText
                                      variant="caption"
                                      sx={{
                                        color:
                                          row.orderstatus === "Delivered"
                                            ? "rgba(54, 201, 2, 1)"
                                            : "rgba(255, 0, 8, 1)",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {" "}
                                      {row.orderstatus}
                                    </TableText>
                                  </TableRows>

                                  {row.orderstatus === "Processing" && (
                                    <>
                                      <TableRows style={{ fontSize: "medium" }}>
                                        <>
                                          <MdDeleteForever
                                            onClick={() =>
                                              handleOpenDelete(row)
                                            }
                                            size={10}
                                            style={{
                                              color: "rgba(193, 32, 37, 1)",
                                              border:
                                                "1px solid rgba(193, 32, 37, 1)",
                                              width: "21px",
                                              height: "21px",
                                              borderRadius: "3px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </>
                                      </TableRows>
                                      <TableRows
                                        style={{ fontSize: "medium" }}
                                        component={Link}
                                        to={`/edit-order/${row.orderid}`}
                                        data={row}
                                      >
                                        <>
                                          <RiEdit2Fill
                                            size={10}
                                            style={{
                                              color: "rgba(193, 32, 37, 1)",
                                              border:
                                                "1px solid rgba(193, 32, 37, 1)",
                                              width: "21px",
                                              height: "21px",
                                              borderRadius: "3px",
                                            }}
                                          />
                                        </>
                                      </TableRows>
                                    </>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {/* {filteredData?.length > rowsPerPage && (
                          <TablePagination
                            sx={{ width: "95%" }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )} */}
                      </>
                    ) : (
                      <>
                        <EmptyPage />
                      </>
                    )}
                  </React.Fragment>
                </Paper>
              </Grid>
            </Grid>
          </StyledBox>
        </>
      ) : (
        <>
          <div>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"My order"} />
            </AppBar>
            <Toolbar />
            <Box sx={{ height: "100%" }}>
              <Stack
                sx={{
                  backgroundColor: "#f0f0f0",

                  position: "fixed",

                  zIndex: 1,

                  paddingTop: "20px",
                  paddingBottom: "20px",
                  bgcolor: "rgba(241, 241, 241, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                }}
                id="search-bar"
              >
                <Stack
                  sx={{
                    marginTop: "20px",
                    gap: "10px",

                    width: "80%",
                  }}
                >
                  <Stack
                    flexDirection={"row"}
                    gap={"10px"}
                    sx={{
                      height: "40px",

                      justifyContent: "space-between",
                      bgcolor: "white",
                      borderRadius: "10px",
                      border: "2px solid rgba(255, 255, 255, 1)",

                      boxShadow:
                        "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                    }}
                  >
                    <Search3
                      sx={{
                        justifyContent: "center",
                        alignItems: "start",
                        paddingLeft: "20px",
                      }}
                    >
                      <Label>{currentMonth}</Label>
                    </Search3>

                    <DrawerBox
                      data={data}
                      setData={setData}
                      filteredData={filteredData}
                      setFilteredData={setFilteredData}
                      heading={"My Orders"}
                      Filter1="orderstatus"
                      Filter2=""
                      sortPrice="totprice"
                      sortDate="date"
                      name="orderid"
                    />
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    gap={"10px"}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Stack
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      sx={{
                        width: "85px",
                        height: "82px",

                        border: "2px solid rgba(255, 255, 255, 1)",
                        bgcolor: "white",
                        boxShadow:
                          "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        borderRadius: "10px",
                      }}
                    >
                      <Label sx={{ color: "rgba(255, 153, 0, 1)" }}>
                        {" "}
                        {orderDetail.totsavings}%
                      </Label>
                      <Label variant="caption"> SAVINGS</Label>
                    </Stack>
                    <Stack
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      sx={{
                        width: "85px",
                        height: "82px",
                        border: "2px solid rgba(255, 255, 255, 1)",
                        bgcolor: "white",
                        boxShadow:
                          "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        borderRadius: "10px",
                      }}
                    >
                      <Label> {myOrderData.orders_list.length}</Label>
                      <Label variant="caption"> ORDERS</Label>
                    </Stack>
                    <Stack
                      onClick={() => navigate("/new-order")}
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      sx={{
                        width: "86px",
                        height: "86px",
                        // border: "1px solid rgba(193, 32, 37, 1)",
                        bgcolor: "rgba(193, 32, 37, 1)",
                        color: "white",
                        boxShadow:
                          "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        borderRadius: "10px",
                      }}
                    >
                      <Label> +</Label>
                      <Label variant="caption"> NEW</Label>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Box sx={{ paddingTop: "140px" }}>
                <Stack
                  id="heading"
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",

                    marginTop: "32px",
                  }}
                  flexDirection={"row"}
                ></Stack>

                <Paper
                  sx={{
                    marginTop: "16px",
                    height: scrollableHeight,
                    overflowY: "auto",
                    boxShadow: "none",
                  }}
                >
                  <Stack sx={{ padding: "20px", overflowY: "auto" }}>
                    {filteredData.map((item, index) => (
                      <>
                        <Stack
                          sx={{
                            flexDirection: "row",

                            marginBottom: "10px",

                            bgcolor: "white",
                            minHeight: "80px",
                            marginTop: "4%",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "93%",

                              padding: "10px",
                              gap: "10px",
                              boxShadow:
                                " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                              //
                              borderRadius: "10px",
                            }}
                          >
                            <Stack
                              onClick={() =>
                                navigate(`/order-summary/${item.orderid}`)
                              }
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                            >
                              <Label
                                variant="subtitle2"
                                style={{
                                  maxWidth: "11ch",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  textDecoration: "none",
                                }}
                              >
                                {item.orderid}
                              </Label>
                              <Label
                                variant="subtitle2"
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                ₹<NumberFormat number={item.totsavings} />{" "}
                                <MdSavings />
                              </Label>
                            </Stack>

                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label3 variant="caption" sx={{}}>
                                ₹<NumberFormat number={item.totprice} />
                              </Label3>

                              <Label3 variant="caption"> {item.date}</Label3>
                              <Label3
                                variant="caption"
                                style={{
                                  color:
                                    item.orderstatus === "Delivered"
                                      ? "rgba(54, 201, 2, 1)"
                                      : item.orderstatus === "Processing"
                                      ? "rgba(193, 32, 37, 1)"
                                      : "black",
                                }}
                              >
                                {item.orderstatus}
                              </Label3>
                            </Stack>
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label3 variant="caption">
                                {" "}
                                {item.totquantity} nos
                              </Label3>
                              <Label3 variant="caption"> {item.time}</Label3>
                            </Stack>

                            <Collapse in={expandedIndex === index}>
                              <Stack gap={"15px"}>
                                <Stack
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}
                                >
                                  {item.orderstatus === "Delivered" && (
                                    <>
                                      <Link
                                        to={`/order-summary/${item.orderid}`}
                                      >
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            color: "black",
                                            width: "117px",

                                            gap: "5px",
                                            border: "1px solid black",
                                          }}
                                        >
                                          Summary
                                        </Button>
                                      </Link>

                                      <Button
                                        onClick={() => navigate("/new-order")}
                                        variant="contained"
                                        sx={{
                                          width: "117px",

                                          gap: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        Order Again
                                      </Button>
                                    </>
                                  )}

                                  {item.orderstatus === "Processing" && (
                                    <>
                                      <Link
                                        to={`/order-summary/${item.orderid}`}
                                      >
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            color: "black",
                                            width: "117px",

                                            gap: "5px",
                                            border: "1px solid black",
                                          }}
                                        >
                                          <RiEdit2Fill
                                            size={15}
                                            style={{
                                              color: " rgba(27, 27, 27, 1)",
                                            }}
                                          />
                                          Edit
                                        </Button>
                                      </Link>

                                      <Button
                                        onClick={() => handleOpenDelete(item)}
                                        variant="contained"
                                        sx={{
                                          width: "117px",

                                          gap: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <MdDeleteForever size={15} style={{}} />
                                        Delete
                                      </Button>
                                    </>
                                  )}

                                  {(item.orderstatus === "In Progress" ||
                                    item.orderstatus === "Shipped") && (
                                    <>
                                      <Button
                                        onClick={() =>
                                          navigate("/order-tracking", {
                                            state: getId,
                                          })
                                        }
                                        variant="outlined"
                                        sx={{
                                          color: "black",
                                          width: "117px",

                                          gap: "5px",
                                          border: "1px solid black",
                                        }}
                                      >
                                        <FaTruck
                                          size={15}
                                          style={{
                                            color: " rgba(27, 27, 27, 1)",
                                          }}
                                        />
                                        Track
                                      </Button>

                                      <Button
                                        onClick={() => navigate("/transaction")}
                                        variant="contained"
                                        sx={{
                                          width: "117px",

                                          gap: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        ₹ Pay Now
                                      </Button>
                                    </>
                                  )}
                                </Stack>
                              </Stack>
                            </Collapse>
                          </Stack>
                          <Stack
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "8%",
                            }}
                          >
                            <IconButton
                              onClick={() => handleExpandClick(index, item)}
                            >
                              <RiEdit2Fill size={15} />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </>
                    ))}
                  </Stack>
                </Paper>
              </Box>
            </Box>
            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
              id="footer"
            >
              <Footer />
            </AppBar>
          </div>
        </>
      )}

      <DeleteButton
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDelete={handleDelete}
      />

      <Loading_page />
    </>
  );
};

export default MyOrder;
