import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NumberFormat from "../../components/NumberFormat";
//////////////////////////////////////////////////////////////////////////////////////////////
import { useState } from "react";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  InputAdornment,
  FormHelperText,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import profile from "../../images/doctor.png";
import { useDispatch, useSelector } from "react-redux";
import { contact } from "../../redux/data/action";

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

//////////////////////////////////////////////////////////////////////////////////////////////
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: "10px",
}));
const YourComponent = ({ creditSection }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  /////console.log("creditSection", creditSection);
  const totalCredits = parseInt(creditSection.totalcredits);
  const balanceCredits = parseInt(creditSection.balance_credits);
  const payDue = parseInt(creditSection.pay_due);
  const used_credit = parseInt(creditSection.used_credit);
  const percentage = Math.round((balanceCredits / totalCredits) * 100);

  const isNotEmpty = Object.keys(creditSection).length > 0;

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const [userName, setUserName] = useState("");
  const [hospital, setHospital] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({
    userName: "",
    hospital: "",
    email: "",
    phone: "",
    comment: "",
  });
  const maxLength = 300;
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
  };

  const toggleChat = () => {
    /////console.log("toggleChat called");
    setIsOpen(!isOpen);
  };
  const handleInputChange = (field, value) => {
    // Update the respective input value state
    switch (field) {
      case "userName":
        setUserName(value);
        break;
      case "hospital":
        setHospital(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
        break;
    }

    // Clear the error for the field if the user is typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Reset the error for the changed field
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    ["userName", "hospital", "phone", "comment", "email"].forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospital"
          ? hospital
          : key === "phone"
          ? phone
          : key === "comment"
          ? comment
          : key === "email"
          ? email
          : "";
      const error = validateField(key, value);
      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!hospital.trim()) {
      formErrors["hospital"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phone.trim()) {
      formErrors["phone"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!comment.trim()) {
      formErrors["comment"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      dispatch(contact(userName, email, phone, comment, hospital));
      setOpen(false);
      setOpenSubmission(true);
      setTimeout(() => {
        handleCloseSubmission();
      }, 2000);
      setUserName("");
      setHospital("");
      setPhone("");
      setEmail("");
      setComment("");
      setErrors("");
      setIsOpen(!isOpen);
      //
    } else {
      // /////console.log("Form has errors. Please correct them.");
    }
  };

  const handleIconClick = () => {
    setUserName("");
    setHospital("");
    setPhone("");
    setEmail("");
    setComment("");
    setErrors("");
    setIsOpen(!isOpen);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "userName":
        if (!value.trim().match(/^[a-zA-Z.\s]+$/)) {
          error = "Invalid username";
        }
        break;
      case "hospital":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid hospital name";
        }
        break;
      case "email":
        // Enhanced email validation to ensure proper format
        if (
          !value
            .trim()
            .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
          error = "Please enter a valid email address.";
        }
        break;
      case "phone":
        if (!value.trim().match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "comment":
        if (!value.trim().match(/^[#.0-9a-zA-Z\s,-]+$/)) {
          error = "Invalid address format.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue?.length <= maxLength) {
      setComment(inputValue);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {!isMobileScreen ? (
        <>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ width: "100%", gap: "10px" }}
          >
            {isNotEmpty ? (
              <Typography variant="caption">{percentage}%</Typography>
            ) : (
              <Typography variant="caption">100%</Typography>
            )}
            <LinearProgress
              variant="determinate"
              value={parseFloat(percentage)} // Convert to float to ensure correct value
              sx={{ width: "100%" }}
            />
            {isNotEmpty ? (
              <Typography variant="caption">{100 - percentage}%</Typography>
            ) : (
              <Typography variant="caption">0%</Typography>
            )}
          </Stack>

          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                border: "1px solid rgba(193, 32, 37, 1)",
                width: "144px",
                height: "100%",
              }}
            >
              {isNotEmpty ? (
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 700, color: "rgba(193, 32, 37, 1)" }}
                >
                  ₹ <NumberFormat number={payDue} />
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 700, color: "rgba(193, 32, 37, 1)" }}
                >
                  ₹0
                </Typography>
              )}
            </Stack>
            <Button
              onClick={toggleChat}
              variant="contained"
              sx={{ width: "144px" }}
              disabled={!isNotEmpty}
            >
              PAY DUE
            </Button>
          </Stack>
          <div
            className="chat-box"
            style={{
              display: isOpen ? "flex" : "none",
              justifyContent: "space-evenly",
              flexDirection: "column",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Adding box-shadow for border effect
              borderRadius: "8px", // Optional: to round the corners
            }}
          >
            <div
              className="chat-box"
              style={{
                display: isOpen ? "flex" : "none",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {/* <Box
            component="img"
            src={profile}
            sx={{ width: "100%", height: "100px" }}
          /> */}
                <Box
                  sx={{ position: "relative", width: "100%", height: "100px" }}
                >
                  <Box
                    component="img"
                    src={profile}
                    alt="Profile"
                    sx={{ width: "100%", height: "100%" }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "rgba(193, 32, 37, 1)",
                      width: "20px",
                      height: "20px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={toggleChat}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
                <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
                  How can we help you?
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box component="form" noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="Mr. Samuel Arora"
                        variant="filled"
                        size="small"
                        sx={{ width: "100%" }}
                        InputProps={{
                          style: { height: "50px", fontSize: "15px" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        value={userName}
                        onChange={(e) =>
                          handleInputChange("userName", e.target.value)
                        }
                      />
                      {errors.userName && (
                        <HelperText>{errors.userName}</HelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="Apollo Hospitals, Delhi"
                        variant="filled"
                        size="small"
                        sx={{ width: "100%" }}
                        InputProps={{
                          style: { height: "50px", fontSize: "15px" },

                          startAdornment: (
                            <InputAdornment position="start">
                              <ApartmentIcon />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        value={hospital}
                        onChange={(e) =>
                          handleInputChange("hospital", e.target.value)
                        }
                      />
                      {errors.hospital && (
                        <HelperText>{errors.hospital}</HelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="samuel008@gmail.com"
                        variant="filled"
                        size="small"
                        sx={{ width: "100%" }}
                        InputProps={{
                          style: { height: "50px", fontSize: "15px" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        value={email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                      {errors.email && <HelperText>{errors.email}</HelperText>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="011 7179 1090"
                        variant="filled"
                        size="small"
                        sx={{ width: "100%" }}
                        InputProps={{
                          style: { height: "50px", fontSize: "15px" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocalPhoneIcon />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        value={phone}
                        onChange={(e) =>
                          handleInputChange("phone", e.target.value)
                        }
                      />
                      {errors.phone && <HelperText>{errors.phone}</HelperText>}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        variant="filled"
                        name="text"
                        placeholder="Add comments"
                        multiline
                        maxRows={10}
                        InputProps={{
                          style: { height: "100px", fontSize: "15px" },
                          disableUnderline: true,
                        }}
                        inputProps={{
                          maxLength: maxLength,
                        }}
                        helperText={`${comment?.length}/${maxLength} characters`}
                        value={comment}
                        onChange={(e) => {
                          handleInputChange("comment", e.target.value);
                          handleChange(e);
                        }}
                      />
                      {errors.comment && (
                        <HelperText>{errors.comment}</HelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={handleIconClick}
                        fullWidth
                        variant="outlined"
                        sx={{
                          mt: 3,
                          mb: 2,
                          border: "1px solid rgba(193, 32, 37, 1)",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={handleSubmit}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </div>
            <Dialog open={openSubmission} onClose={handleCloseSubmission}>
              <Stack
                justifyContent={"space-evenly"}
                alignItems={"center"}
                sx={{ width: "420px", height: "200px" }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    color: "rgba(193, 32, 37, 1)",
                  }}
                >
                  Request submitted!
                </DialogTitle>
                <DialogContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DialogContentText
                    sx={{ textAlign: "center", color: "black" }}
                  >
                    <Typography variant="body2">
                      Your request has been successfully submitted.
                      <br />
                       Our team will get in touch with you soon.
                    </Typography>
                  </DialogContentText>
                </DialogContent>
              </Stack>
            </Dialog>
          </div>
        </>
      ) : (
        <>
          <Stack sx={{ gap: "20px", width: "100%" }}>
            <Stack sx={{ width: "100%" }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  // width: "90%",
                  gap: "10px",
                  background: "white",
                  borderRadius: " 0px 0px 10px 10px",
                  height: "60px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                {isNotEmpty ? (
                  <Label variant="subtitle2">{percentage}%</Label>
                ) : (
                  <Label variant="subtitle2">100%</Label>
                )}
                <LinearProgress
                  variant="determinate"
                  value={parseFloat(percentage)} // Convert to float to ensure correct value
                  sx={{ width: "100%" }}
                />
                {isNotEmpty ? (
                  <Label variant="subtitle2">{100 - percentage}%</Label>
                ) : (
                  <Label variant="subtitle2">0%</Label>
                )}
              </Stack>
            </Stack>

            <Stack
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                width: "100%",

                height: "200px",
                bgcolor: "white",
                borderRadius: "10px",
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{ width: "100%", height: "60%" }}
              >
                <Stack
                  // alignItems={"center"}
                  justifyContent={"space-around"}
                  sx={{
                    //  border: "1px solid rgba(193, 32, 37, 1)",
                    width: "40%",
                    // height: "90%",
                    padding: "20px",
                  }}
                >
                  <Label>Credits</Label>
                  <Typography>
                    Use {used_credit}% balance
                    <br />
                    remaining
                  </Typography>
                </Stack>
                <Stack
                  justifyContent={"space-around"}
                  sx={{
                    width: "40%",
                    padding: "20px",
                    alignItems: "flex-end",
                  }}
                >
                  <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                    Total Due
                  </Label>

                  {isNotEmpty ? (
                    <Label variant="h6" sx={{ fontWeight: 700 }}>
                      ₹<NumberFormat number={payDue} />
                    </Label>
                  ) : (
                    <Label variant="h6" sx={{ fontWeight: 700 }}>
                      ₹0
                    </Label>
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  borderTop: "2px dashed rgba(217, 217, 217, 1)",
                  width: "90%",
                }}
              ></Stack>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-around"}
                sx={{ width: "100%", padding: "20px" }}
              >
                <Button
                  onClick={() => navigate("/transaction")}
                  variant="contained"
                  sx={{ width: "90%", height: "45px" }}
                  disabled={!isNotEmpty}
                >
                  Pay Due
                </Button>
              </Stack>
            </Stack>
          </Stack>

          {/*  */}
        </>
      )}
    </>
  );
};

export default YourComponent;
