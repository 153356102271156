import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Loading_page from "../../components/Loading_Page";

import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MdInsertComment } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";

import {
  AppBar,
  Button,
  IconButton,
  List,
  Popover,
  Stack,
  TablePagination,
  TextField,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
  Modal,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  FormHelperText,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useState } from "react";
import { MdOutlinePayment } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import { BiCommentDetail } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteOrder,
  editOrder,
  orderDetailById,
  newOrder,
  AddProduct,
  cancelOrder,
} from "../../redux/data/action";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BiUser } from "react-icons/bi";

import TopBar from "../CommonComponent/TopBar";
import EmptyPage from "../CommonComponent/EmptyPage";
import { IoIosAdd } from "react-icons/io";
import { IoIosRemove } from "react-icons/io";
import { AiFillMinusSquare } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import DeleteButton from "../CommonComponent/DeleteButton";
import Submission from "../CommonComponent/Submission";

import Navbar from "../../MobileView/Navbar";
import Footer from "../../MobileView/Footer";
import DrawerBox from "../CommonComponent/Drawer";
import NumberFormat from "../../components/NumberFormat";
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",

  //   border: "1px  solid black",
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const Box2 = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  display: "flex",
  width: "90%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",

  height: "621px",
  justifyContent: "space-between",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "200px",
  height: "407px",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const LeftBox2 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "200px",
  height: "194px",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "start",

  flexDirection: "column",

  width: "80%",
  height: "90%",
  textAlign: "start",
  borderRadius: "2px ",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Label3 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  color: "rgba(146, 146, 146, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const ScrollableBox = styled(Stack)({
  overflowY: "auto",
  justifyContent: "space-between",
});
const EditOrder = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const branchData = JSON.parse(localStorage.getItem("branchData"));
  const allOrderList = useSelector((store) => store.data.orderDetailById);
  const allProduct = useSelector((store) => store.data.newOrders);

  const savingsPercent = allOrderList?.orderdetails?.totsavings_percent;
  const date_order = allOrderList?.orderdetails?.date;
  const time_order = allOrderList?.orderdetails?.time;
  const price_order = allOrderList?.orderdetails?.totprice;
  const product_order = allOrderList?.orderdetails?.totproduct;

  const deleteOrderResponse = useSelector((store) => store.data.deleteOrder);
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  const [getId, setGetId] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const params = useParams();
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [orderDetail, setOrderDetail] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [scrollableHeight, setScrollableHeight] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    const calculateScrollableHeight = () => {
      const navbar = document.getElementById("navbar");
      const searchBar = document.getElementById("search-bar");
      const heading = document.getElementById("heading");
      const footer = document.getElementById("footer");

      if (navbar && searchBar && heading && footer) {
        const navbarHeight = navbar.offsetHeight;
        const searchBarHeight = searchBar.offsetHeight;
        const headingHeight = heading.offsetHeight;
        const footerHeight = footer.offsetHeight;

        const windowHeight = window.innerHeight;
        const remainingHeight =
          windowHeight -
          navbarHeight -
          searchBarHeight -
          headingHeight -
          footerHeight -
          50;

        setScrollableHeight(remainingHeight);
      }
    };

    calculateScrollableHeight();

    const handleResize = () => {
      calculateScrollableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData]);
  const handlePopoverOpen = (event, productId, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
    setCommentValue(comment);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedProductId(null);
  };

  const handleSaveComment = () => {
    const index = filteredData.findIndex(
      (item) => item.productid === selectedProductId
    );
    if (index !== -1) {
      const updatedComments = [...comments];
      updatedComments[index] = commentValue;
      setComment(updatedComments);
      handlePopoverClose();
    }
  };

  const handleOpenDelete = (row) => {
    setGetId(row);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (Object.keys(allOrderList)?.length > 0 && allOrderList !== 0) {
      setData(allOrderList.products);
      setOrderDetail(allOrderList.orderdetails);
    }
  }, [allOrderList]);

  useEffect(() => {
    const requestData = {
      orderid: params.id,
      userid: userData.userid,
      branchid: branchData.branchId,
    };
    dispatch(orderDetailById(requestData));
  }, [deleteOrderResponse]);

  useEffect(() => {
    const allProduct = {
      orderid: params.id,
      userid: userData.userid,
      branchid: branchData.branchId,
    };
    dispatch(newOrder(allProduct));
  }, [params.id, userData.userid, branchData.branchId]);

  const handleSubmitChanges = () => {
    let totproduct = data?.length;
    let totquantity = quantities.reduce((acc, curr) => acc + curr, 0);
    let totprevprice = data.reduce(
      (acc, curr) => acc + parseInt(curr.prev_price),
      0
    );
    let totprice = data.reduce(
      (acc, curr) => acc + parseInt(curr.curr_price),
      0
    );
    let totsavings = data.reduce(
      (acc, curr) => acc + parseInt(curr.savings),
      0
    );

    let productslist = data.map((item, index) => ({
      productid: item.productid,
      quantity: String(quantities[index]),
      savings: item.savings,
      comment: comments[index],
    }));

    const order = {
      orderid: params.id,
      userid: userData.userid,
      username: userData.name,
      userrole: userData.role,
      branchid: branchData.branchId,
      totproduct: totproduct,
      totquantity: totquantity,
      totprevprice: totprevprice,
      totprice: totprice,
      totsavings: totsavings,
      productslist: productslist,
    };

    dispatch(editOrder(order));
    setOpenSubmission(true);
    setTimeout(() => {
      handleCloseSubmission();
    }, 2000);
  };

  const [comments, setComment] = useState([]);
  useEffect(() => {
    setQuantities(filteredData.map((item) => parseInt(item.quantity)));
    setComment(filteredData.map((item) => item.comment));
  }, [filteredData]);

  const [quantities, setQuantities] = useState(() =>
    Array(allOrderList.length).fill(1)
  );

  const handleIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] += 1;
    setQuantities(updatedQuantities);
  };

  const handleDecrement = (index) => {
    if (quantities[index] > 1) {
      // Ensure it doesn't go below 1
      const updatedQuantities = [...quantities];
      updatedQuantities[index] -= 1;
      setQuantities(updatedQuantities);
    }
  };

  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = parseInt(value) || 0;
    setQuantities(updatedQuantities);
  };

  const [loading, setLoading] = useState(false); // Add a loading state

  const handleDelete = () => {
    let totproduct = data?.length;
    let totquantity = quantities.reduce((acc, curr) => acc + curr, 0);
    let totprevprice = data.reduce(
      (acc, curr) => acc + parseInt(curr.prev_price),
      0
    );
    let totprice = data.reduce(
      (acc, curr) => acc + parseInt(curr.curr_price),
      0
    );
    let totsavings = data.reduce(
      (acc, curr) => acc + parseInt(curr.savings),
      0
    );

    let requestData = {
      orderid: params.id,
      userid: userData.userid,
      username: userData.name,
      userrole: userData.role,
      branchid: branchData.branchId,
      totproduct: String(totproduct),
      totquantity: String(totquantity),
      totprevprice: String(totprevprice),
      totprice: String(totprice),
      totsavings: String(totsavings),
      productid: getId.productid,
    };

    dispatch(deleteOrder(requestData)).then(() => {
      // Hide loading after successful deletion
      setFilteredData((prevData) =>
        prevData.filter((item) => item.productid !== getId.productid)
      );
      setOpenDelete(false);
      setShowSubmitModal(true);
    });
  };

  const [open, setOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addedProducts, setAddedProducts] = useState([]);

  const handleAddProduct = () => {
    const productsToAdd = selectedProducts.map((productId) => {
      const selectedProduct = allProduct.find(
        (product) => product.productid === productId
      );

      const productData = {
        orderid: params.id,
        userid: userData.userid,
        username: userData.name,
        userrole: userData.role,
        branchid: branchData.branchId,
        branch: branchData.branchName,
        hospid: userData.hospid,
        hospname: userData.hospname,
        totproduct: selectedProducts.length.toString(),
        totquantity: 1,
        totprevprice: selectedProduct.prev_price,
        totprice: selectedProduct.curr_price,
        totsavings: (
          selectedProduct.prev_price - selectedProduct.curr_price
        ).toString(),
        totsavings_percent: `${(
          ((selectedProduct.prev_price - selectedProduct.curr_price) /
            selectedProduct.prev_price) *
          100
        ).toFixed(2)}%`,
        //        totsavings_percent: `${(
        //   100 *
        //   ((selectedProduct.prev_price - selectedProduct.curr_price) /
        //     selectedProduct.prev_price)
        // ).toFixed(2)}%`,
        totgst: (
          parseFloat(selectedProduct.cgst) + parseFloat(selectedProduct.sgst)
        ).toString(),
        subtot: (
          parseFloat(selectedProduct.curr_price) * selectedProduct.quantity
        ).toString(),
        productid: selectedProduct.productid,
        product: selectedProduct.product,
        hsn: selectedProduct.hsn,
        brand: selectedProduct.brand,
        category: selectedProduct.category,
        packsize: selectedProduct.packsize,
        quantity: 1,
        prev_price: selectedProduct.prev_price,
        curr_price: selectedProduct.curr_price,
        cgst: selectedProduct.cgst,
        sgst: selectedProduct.sgst,
        savings: (
          selectedProduct.prev_price - selectedProduct.curr_price
        ).toString(),
        comment: selectedProduct.comment || "NA",
      };

      return productData;
    });

    // Dispatch products individually
    productsToAdd.forEach((product) => {
      dispatch(AddProduct(product));
    });

    // Update the data state to include new products
    setFilteredData((prevData) => [
      ...prevData,
      ...productsToAdd, // add the newly added products to the table
    ]);

    setData((prevData) => [
      ...prevData,
      ...productsToAdd, // Sync data for handleSubmitChanges
    ]);

    setAddedProducts((prev) => [...prev, ...selectedProducts]); // Add selected products to added products
    setSelectedProducts([]);

    handleClose();
  };

  const handleSelectChange = (event) => {
    setSelectedProducts(event.target.value);
    setError("");
  };
  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  const handleDeleteAllProducts = () => {
    const requestData = {
      orderid: params.id,
      userid: userData.userid,
      branchid: branchData.branchId,
    };
    dispatch(cancelOrder(requestData));
    setOpenDelete(false);
    navigate("/my-order");

    handleConfirmClose();
  };
  const handleRemoveProduct = (productToRemove) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product !== productToRemove)
    );
  };

  const handleAddProductClick = () => {
    if (selectedProducts.length === 0) {
      setError("Please select at least one product.");
      return;
    }
    // Call the original handleAddProduct function
    handleAddProduct(selectedProducts);

    // Reset the selected products after adding
    setSelectedProducts([]);
  };

  const unavailableProductIds = Array.isArray(allOrderList?.products)
    ? allOrderList.products
        .filter((product) => product.productid)
        .map((product) => product.productid.toString())
    : [];
  const productOptions = allProduct
    .filter((product) => {
      const isUnavailable = unavailableProductIds.includes(
        product.productid.toString()
      );
      const isAlreadyAdded = addedProducts.includes(
        product.productid.toString()
      );
      return !isUnavailable && !isAlreadyAdded;
    })
    .map((product) => ({
      value: product.productid,
      label: product.product,
    }));

  // const unavailableProductIds = Array.isArray(allOrderList?.products)
  //   ? allOrderList.products
  //       .filter((product) => product.productid) // Only products with productid
  //       .map((product) => product.productid.toString()) // Convert to string for consistency
  //   : [];

  // const productOptions = allProduct
  //   .filter((product) => {
  //     const isUnavailable = unavailableProductIds.includes(
  //       product.productid.toString()
  //     ); // Ensure consistent comparison
  //     const isAlreadyAdded = addedProducts.includes(
  //       product.productid.toString()
  //     ); // Check if product is already added
  //     return !isUnavailable && !isAlreadyAdded; // Only show products that are not added or unavailable
  //   })
  //   .map((product) => ({
  //     value: product.productid,
  //     label: product.product,
  //   }));

  return (
    <>
      {!isMobileScreen ? (
        <>
          <DrawerBox
            data={data}
            setData={setData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            heading={"Edit order"}
            Filter1="category"
            Filter2="brand"
            sortPrice="curr_price"
            sortDate=""
            name="product"
            show="show"
          />
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              alignItems: "start",
            }}
          >
            <LeftWrapper>
              <LeftBox1>
                <LeftBox>
                  <Label>ORDER DETAILS</Label>

                  <Stack>
                    <Label variant="caption" style={{}}>
                      ORDER ID
                    </Label>

                    <Typography
                      sx={{ wordBreak: "break-word", fontSize: "small" }}
                    >
                      {params.id}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Label variant="caption" style={{}}>
                      DATE & TIME
                    </Label>
                    <Label2 style={{ fontSize: "small" }}>
                      {date_order} at {time_order}
                    </Label2>
                  </Stack>
                  <Stack>
                    <Label variant="caption" style={{}}>
                      TOTAL VALUE
                    </Label>
                    <Label2 style={{ fontSize: "small" }}>
                      ₹ <NumberFormat />
                      {price_order}
                    </Label2>
                  </Stack>
                  <Stack>
                    <Label variant="caption" style={{}}>
                      TOTAL ITEMS
                    </Label>
                    <Label2 style={{ fontSize: "small" }}>
                      {product_order} nos
                    </Label2>
                  </Stack>

                  <Stack
                    flexDirection="column"
                    justifyContent="flex-end"
                    sx={{
                      width: "100%",

                      alignItems: "flex-end",
                      color: "rgba(255, 168, 0, 1)",
                    }}
                  >
                    <Label variant="h6" style={{}}>
                      {parseFloat(savingsPercent).toFixed(2)}%
                    </Label>
                    <Label variant="body2" style={{}}>
                      Saved
                    </Label>
                  </Stack>
                </LeftBox>
              </LeftBox1>
              <LeftBox2>
                <Label variant="button">Order status</Label>
                <Stack
                  sx={{
                    width: "100%",

                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80%",
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      height: "100%",

                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      textAlign: "start",
                      gap: "20px",
                    }}
                  >
                    <Box2>
                      <Stack flexDirection={"row"} gap={"20px"}>
                        <HiOutlineClipboardCheck
                          size={20}
                          style={{ color: "rgba(193, 32, 37, 1)" }}
                        />

                        <Label2 variant="caption">Order Approved</Label2>
                      </Stack>
                      {orderDetail.orderstatus !== "Processing" ? (
                        <IoIosCheckmarkCircle
                          size={20}
                          style={{ color: "rgba(54, 201, 2, 1)" }}
                        />
                      ) : (
                        <IoIosCloseCircle
                          size={20}
                          style={{ color: "rgba(255, 0, 0, 1)" }}
                        />
                      )}
                    </Box2>
                  </List>
                </Stack>
              </LeftBox2>
            </LeftWrapper>
            <Grid container>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    height:
                      data?.length > 0 && filteredData?.length > 0
                        ? "621px"
                        : "621px",
                    maxHeight: "621px",
                    display: "flex",
                    flexDirection: "column",

                    boxShadow:
                      "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  }}
                >
                  <React.Fragment>
                    {data?.length > 0 && filteredData?.length > 0 ? (
                      <>
                        {" "}
                        <Table size="small" style={{}}>
                          <TableHead>
                            <TableRow>
                              <TableHeading>
                                <Label variant="caption"> PROD.NAME</Label>
                              </TableHeading>

                              <TableHeading>
                                <Label variant="caption"> BRAND</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption">HSN</Label>
                              </TableHeading>
                              {/* <TableHeading>
                                <Label variant="caption"> CATEGORY</Label>
                              </TableHeading> */}
                              <TableHeading>
                                <Label variant="caption"> PKG SIZE</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> PRICE</Label>
                              </TableHeading>

                              <TableHeading
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                {" "}
                                <Label variant="caption"> SAVINGS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label
                                  variant="caption"
                                  style={{ textAlign: "center" }}
                                >
                                  {" "}
                                  QTY
                                </Label>
                              </TableHeading>

                              <TableHeading style={{ textAlign: "center" }}>
                                <MdInsertComment />
                              </TableHeading>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => (
                                <TableRow
                                  key={row.product}
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "rgba(255, 237, 237, 1)",
                                    },
                                  }}
                                  style={{
                                    boxShadow:
                                      "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                  }}
                                >
                                  <TableRows>
                                    {row.product?.length > 11 ? (
                                      <Tooltip title={row.product}>
                                        <TableText
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {/* {row.product.slice(0, 9) + "..."} */}
                                          {row.product}
                                        </TableText>
                                      </Tooltip>
                                    ) : (
                                      <TableText
                                        variant="caption"
                                        style={{
                                          maxWidth: "11ch",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {row.product}
                                      </TableText>
                                    )}
                                  </TableRows>

                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.brand}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.hsn}
                                    </TableText>
                                  </TableRows>
                                  {/* <TableRows>
                                    <TableText variant="caption">
                                      {" "}
                                      {row.category}
                                    </TableText>
                                  </TableRows> */}
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.packsize}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      ₹<NumberFormat number={row.curr_price} />
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText
                                      variant="caption"
                                      sx={{ color: "rgba(255, 153, 0, 1)" }}
                                    >
                                      ₹<NumberFormat number={row.savings} />
                                    </TableText>
                                  </TableRows>

                                  <TableRows
                                    style={{
                                      background: "rgba(255, 246, 246, 1)",
                                      border: "1px solid white",
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    <TableText variant="caption">
                                      <TextField
                                        value={quantities[index]}
                                        onChange={(event) =>
                                          handleQuantityChange(event, index)
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <IconButton
                                              onClick={() =>
                                                handleDecrement(index)
                                              }
                                            >
                                              <IoIosRemove size={16} />
                                            </IconButton>
                                          ),
                                          endAdornment: (
                                            <IconButton
                                              onClick={() =>
                                                handleIncrement(index)
                                              }
                                            >
                                              <IoIosAdd size={16} />
                                            </IconButton>
                                          ),
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input": {
                                            textAlign: "center",
                                            width: "20px",
                                            fontSize: "12px",
                                          },
                                          "& fieldset": { border: "none" },
                                          padding: 0,
                                        }}
                                      />
                                    </TableText>
                                  </TableRows>
                                  <TableRows
                                    style={{
                                      background: "rgba(255, 246, 246, 1)",
                                    }}
                                  >
                                    <TableText variant="caption">
                                      {row.comment !== "" ? (
                                        <>
                                          {" "}
                                          <BiCommentDetail
                                            size={20}
                                            onClick={(event) =>
                                              handlePopoverOpen(
                                                event,
                                                row.productid,
                                                row.comment
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <Typography
                                            variant="caption"
                                            onClick={(event) =>
                                              handlePopoverOpen(
                                                event,
                                                row.productid,
                                                row.comment
                                              )
                                            }
                                          >
                                            comments
                                          </Typography>
                                        </>
                                      )}

                                      <>
                                        <Popover
                                          open={
                                            isPopoverOpen &&
                                            selectedProductId === row.productid
                                          }
                                          anchorEl={anchorEl}
                                          onClose={handlePopoverClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-around",
                                              flexDirection: "column",
                                              width: "200px",
                                              minHeight: "90px",
                                              padding: "10px",
                                            }}
                                          >
                                            <>
                                              <TextField
                                                autoFocus
                                                multiline
                                                value={commentValue}
                                                onChange={(e) =>
                                                  setCommentValue(
                                                    e.target.value
                                                  )
                                                }
                                              />

                                              <Stack
                                                sx={{ marginTop: 2 }}
                                                flexDirection="row"
                                                justifyContent="space-around"
                                              >
                                                <Button
                                                  size="small"
                                                  variant="outlined"
                                                  onClick={handlePopoverClose}
                                                  sx={{ width: "54px" }}
                                                >
                                                  Cancel
                                                </Button>

                                                <Button
                                                  size="small"
                                                  variant="contained"
                                                  onClick={handleSaveComment}
                                                  sx={{ width: "54px" }}
                                                >
                                                  Save
                                                </Button>
                                              </Stack>
                                            </>
                                          </Box>
                                        </Popover>
                                      </>
                                    </TableText>
                                  </TableRows>

                                  {loading && <Loading_page />}
                                  <TableRows
                                    style={{
                                      background: "rgba(255, 246, 246, 1)",
                                    }}
                                  >
                                    <TableText variant="caption">
                                      <IconButton
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleOpenDelete(row)}
                                      >
                                        <AiFillMinusSquare />
                                      </IconButton>
                                    </TableText>
                                  </TableRows>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {filteredData?.length > rowsPerPage && (
                          <TablePagination
                            sx={{ width: "95%" }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <EmptyPage />
                      </>
                    )}
                  </React.Fragment>
                </Paper>
              </Grid>
            </Grid>
          </StyledBox>
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Stack gap="20px" flexDirection={"row"}>
              <Button
                variant="outlined"
                sx={{ width: "200px" }}
                onClick={() => navigate("/my-order")}
              >
                All Orders
              </Button>
            </Stack>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{ width: "200px" }}
                onClick={handleOpen}
              >
                Add Product
              </Button>

              <Button
                variant="contained"
                sx={{ width: "200px" }}
                onClick={handleConfirmOpen}
              >
                Cancel Order
              </Button>

              <Button
                variant="contained"
                sx={{ width: "200px" }}
                onClick={handleSubmitChanges}
              >
                Submit changes
              </Button>
            </Box>

            {showSubmitModal && (
              <Modal
                open={showSubmitModal}
                onClose={() => {}}
                aria-labelledby="submit-changes-modal"
                aria-describedby="please-submit-changes-to-apply"
              >
                <Box sx={modalStyle}>
                  <Stack
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h6" component="h2">
                      Please click on the "Submit Changes" To Update your
                      Orders.
                    </Typography>
                  </Stack>
                  <Stack>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, width: "auto" }}
                      onClick={() => {
                        setShowSubmitModal(false);
                        handleSubmitChanges();
                      }}
                    >
                      Submit Changes
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            )}

            <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  p: 4,
                  pt: 3,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                  Add New Product
                </Typography>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel id="select-product-label">
                    Select Products
                  </InputLabel>
                  <Select
                    labelId="select-product-label"
                    multiple
                    value={selectedProducts}
                    onChange={handleSelectChange}
                    input={<OutlinedInput label="Select Products" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              productOptions.find(
                                (option) => option.value === value
                              )?.label
                            }
                            onDelete={() => handleRemoveProduct(value)}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {productOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={selectedProducts.includes(option.value)}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleAddProduct}
                >
                  Add
                </Button>
              </Box>
            </Modal>

            <Modal open={confirmOpen} onClose={handleConfirmClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <IconButton
                  onClick={handleConfirmClose}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
                  Are you sure you want to Cancel the Order?
                </Typography>
                <Box display="flex" justifyContent="center" gap="10%">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteAllProducts}
                  >
                    Yes
                  </Button>
                  <Button variant="outlined" onClick={handleConfirmClose}>
                    No
                  </Button>
                </Box>
              </Box>
            </Modal>
          </StyledBox>
        </>
      ) : (
        <>
          <div>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"Edit order"} />
            </AppBar>
            <Toolbar />
            <Box sx={{ height: "100%" }}>
              <Stack
                sx={{
                  backgroundColor: "#f0f0f0",

                  position: "fixed",

                  zIndex: 1,

                  paddingTop: "20px",
                  paddingBottom: "20px",
                  bgcolor: "rgba(241, 241, 241, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                }}
                id="search-bar"
              >
                <Stack
                  sx={{
                    bgcolor: "white",
                    border: "2px solid rgba(255, 255, 255, 1)",
                    height: "130px",
                    width: "221px",
                    boxShadow:
                      "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                    padding: "20px",
                    borderRadius: "10px",
                    gap: "5px",
                    justifyContent: "space-around",
                  }}
                >
                  <Label variant="subtitle2"> {orderDetail.orderid}</Label>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    gap={"5px"}
                  >
                    <Stack gap={"5px"}>
                      <Label3 variant="subtitle2">New Total</Label3>
                      <Label3 variant="subtitle2">Products</Label3>
                    </Stack>
                    <Stack gap={"5px"}>
                      <Label3 variant="subtitle2">
                        ₹<NumberFormat number={17000000} />
                      </Label3>
                      <Label3 variant="subtitle2">80 nos</Label3>
                    </Stack>
                  </Stack>
                  <Button variant="contained" onClick={handleSubmitChanges}>
                    Submit
                  </Button>
                </Stack>

                <TopBar
                  data={data}
                  setData={setData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  heading={"Order Summary"}
                />
              </Stack>
              <Box sx={{ paddingTop: "150px" }}>
                <Stack
                  id="heading"
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",

                    marginTop: "32px",
                  }}
                  flexDirection={"row"}
                ></Stack>

                <Paper
                  sx={{
                    marginTop: "16px",
                    height: scrollableHeight,
                    overflowY: "auto",
                    boxShadow: "none",
                  }}
                >
                  <ScrollableBox sx={{ padding: "20px" }}>
                    {filteredData?.map((item, index) => (
                      <Stack
                        sx={{
                          flexDirection: "row",

                          marginBottom: "10px",
                          bgcolor: "white",
                          minHeight: "110px",
                          marginTop: "4%",
                          // width: "98%",
                          gap: "10px",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            padding: "10px",
                            gap: "10px",
                            borderRadius: "10px",
                            boxShadow:
                              " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                            bgcolor: "white",
                            justifyContent: "space-around",
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Label
                              variant="subtitle1"
                              style={{
                                maxWidth: "11ch",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textDecoration: "none",
                              }}
                            >
                              {item.product}
                            </Label>
                            <Label variant="subtitle2" sx={{}}>
                              {item.brand}
                            </Label>
                          </Stack>

                          <>
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label3 variant="caption">
                                ₹<NumberFormat number={item.curr_price} />
                              </Label3>
                              <Label3 variant="caption">
                                {item.packsize}/pkg
                              </Label3>
                              <Label3 variant="caption">{item.hsn}</Label3>
                            </Stack>
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label3 variant="caption">
                                {item.cgst}% cgst
                              </Label3>
                              <Label3 variant="caption">
                                {item.sgst}% sgst
                              </Label3>
                            </Stack>
                            <Stack
                              sx={{
                                // bgcolor: "rgba(255, 246, 246, 1)",
                                justifyContent: "space-between",
                              }}
                              flexDirection={"row"}
                            >
                              <Button
                                size="small"
                                startIcon={<MdInsertComment />}
                                variant="outlined"
                                sx={{
                                  color: "",
                                  height: "100%",
                                  width: "109px",
                                  border: "1px solid rgba(193, 32, 37, 1)",
                                }}
                                onClick={(event) =>
                                  handlePopoverOpen(
                                    event,
                                    item.productid,
                                    item.comment
                                  )
                                }
                              >
                                {item.comment === "" ? (
                                  <Label2 variant="caption">Add</Label2>
                                ) : (
                                  <Label2 variant="caption">Edit</Label2>
                                )}
                              </Button>

                              <Popover
                                open={
                                  isPopoverOpen &&
                                  selectedProductId === item.productid
                                }
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "column",
                                    width: "200px",
                                    minHeight: "90px",
                                    padding: "10px",
                                  }}
                                >
                                  <>
                                    <TextField
                                      autoFocus
                                      multiline
                                      value={commentValue}
                                      onChange={(e) =>
                                        setCommentValue(e.target.value)
                                      }
                                    />

                                    <Stack
                                      sx={{ marginTop: 2 }}
                                      flexDirection="row"
                                      justifyContent="space-around"
                                    >
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={handlePopoverClose}
                                        sx={{ width: "54px" }}
                                      >
                                        Cancel
                                      </Button>

                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={handleSaveComment}
                                        sx={{ width: "54px" }}
                                      >
                                        Save
                                      </Button>
                                    </Stack>
                                  </>
                                </Box>
                              </Popover>

                              <Stack
                                style={{
                                  border: "1px solid rgba(193, 32, 37, 1)",
                                  height: "30px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "109px",
                                  color: "rgba(193, 32, 37, 1)",
                                  bgcolor: "rgba(255, 246, 246, 1)",
                                }}
                              >
                                <TextField
                                  style={{ color: "rgba(193, 32, 37, 1)" }}
                                  value={quantities[index]}
                                  onChange={(event) =>
                                    handleQuantityChange(event, index)
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        onClick={() => handleDecrement(index)}
                                      >
                                        <IoIosRemove
                                          size={16}
                                          style={{
                                            color: "rgba(193, 32, 37, 1)",
                                          }}
                                        />
                                      </IconButton>
                                    ),
                                    endAdornment: (
                                      <IconButton
                                        onClick={() => handleIncrement(index)}
                                      >
                                        <IoIosAdd
                                          size={16}
                                          style={{
                                            color: "rgba(193, 32, 37, 1)",
                                          }}
                                        />
                                      </IconButton>
                                    ),
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      textAlign: "center",
                                      width: "20px",
                                      fontSize: "12px",
                                      fontWeight: 700,
                                      color: "rgba(193, 32, 37, 1)",
                                    },
                                    "& fieldset": { border: "none" },
                                    padding: 0,
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </>
                        </Stack>
                        <Stack
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "10%",
                          }}
                        >
                          <Box>
                            <MdDeleteForever
                              onClick={() => handleOpenDelete(item)}
                              size={25}
                              style={{
                                color: "black",
                              }}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    ))}
                  </ScrollableBox>
                </Paper>
              </Box>
            </Box>
            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
              id="footer"
            >
              <Footer />
            </AppBar>
          </div>
        </>
      )}

      <DeleteButton
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDelete={handleDelete}
      />
      <Submission
        openSubmission={openSubmission}
        handleCloseSubmission={handleCloseSubmission}
        // handleSubmission={handleSubmission}
      />
      <Loading_page />
    </>
  );
};

export default EditOrder;
