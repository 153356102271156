import React, { useState } from "react";
import { MdOutlinePlace } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { IoCloseOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { FaTruck } from "react-icons/fa";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { AiFillCloseSquare } from "react-icons/ai";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { RiCloseFill } from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { TbEyeFilled } from "react-icons/tb";
const RightMenu = () => {
  let userData = JSON.parse(localStorage.getItem("AstraUserData")) || {};
  /////console.log(userData.name)
  let branch = JSON.parse(localStorage.getItem("branchData")) || {};
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expandedNotification, setExpandedNotification] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClickNotification = () => {
    setExpandedNotification(!expandedNotification);
  };
  const open = Boolean(anchorEl);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleBranchSelect = (branchIndex) => {
    const selectedBranchId = userData?.branchid[branchIndex];
    const selectedBranchName = userData?.branchname[branchIndex];

    localStorage.setItem(
      "branchData",
      JSON.stringify({
        branchId: selectedBranchId,
        branchName: selectedBranchName,
      })
    );

    handleClose1();
    window.location.reload();
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const isDashboard = location.pathname === "/dashboard";

  return (
    <>
      {!isMobileScreen ? (
        <>
          <Stack
            sx={{
              justifyContent: "center",
              fontWeight: "bold",
              display: isDashboard ? "none" : "flex",
            }}
          >
            {branch?.branchName}
          </Stack>

          <Avatar
            sx={{
              border: "2px solid black",
              color: "black",
              bgcolor: "white",
              cursor: "pointer",
            }}
            onClick={handleClick1}
          >
            <MdOutlinePlace />
          </Avatar>

          {path !== "/profile" && (
            <Avatar
              onClick={() => navigate("/profile")}
              sx={{
                border: "2px solid black",
                color: "black",
                bgcolor: "white",
                cursor: "pointer",
              }}
              aria-label="recipe"
            >
              {userData?.name?.charAt(0)}
            </Avatar>
          )}

          <Avatar
            sx={{
              border: "2px solid #FF0008",
              color: "black",
              bgcolor: "white",
              cursor: "pointer",
              display: "none",
            }}
            onClick={handleClick2}
          >
            <Badge color="primary" variant="dot" overlap="circular">
              <IoIosNotifications sx={{}} />
            </Badge>
          </Avatar>
          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Card sx={{ maxWidth: 400, minWidth: 300 }}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{
                      border: "2px solid black",
                      color: "black",
                      bgcolor: "#EFEFEF",
                      cursor: "pointer",
                    }}
                    aria-label="recipe"
                  >
                    {userData?.name?.charAt(0)}
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                    <IoCloseOutline onClick={handleClose1} />
                  </IconButton>
                }
                title={userData?.hospname}
                subheader={branch?.branchName}
              />

              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ cursor: "pointer" }}
                  >
                    Choose branch
                  </Typography>
                </Stack>

                <Divider />

                {userData?.branchname?.map((el, index) => (
                  <Typography
                    key={index}
                    onClick={() => handleBranchSelect(index)}
                    sx={{ cursor: "pointer" }}
                  >
                    {el}
                  </Typography>
                ))}
              </CardContent>
              <CardActions disableSpacing></CardActions>
            </Card>
          </Popover>
          <Popover
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Card
              style={{
                maxWidth: 400,
                minWidth: 300,
                overflowX: "auto",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Stack flexDirection={"row"} alignItems={"center"} gap={"20px"}>
                  <IoIosNotifications size={20} />
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    Notifications
                  </Typography>
                </Stack>

                <AiFillCloseSquare
                  onClick={handleClose2}
                  size={20}
                  style={{ color: "rgba(193, 32, 37, 1)" }}
                />
              </CardContent>

              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <CardHeader
                  sx={{
                    boxShadow: " 0px 0px 15px 0px rgba(210, 191, 191, 0.7)",
                  }}
                  avatar={
                    <Avatar
                      sx={{
                        color: "green",
                        bgcolor: "white",
                        cursor: "pointer",
                      }}
                      aria-label="recipe"
                    >
                      <TiTick />
                    </Avatar>
                  }
                  title={
                    <Typography variant="subtitle2">
                      Order successfully completed!
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption">
                      {" "}
                      Your order MNJUI009467 has been successfully completed.
                      Should you require assistance, contact Team Asthra
                    </Typography>
                  }
                />
                <CardHeader
                  sx={{
                    boxShadow: " 0px 0px 15px 0px rgba(210, 191, 191, 0.7)",
                  }}
                  avatar={
                    <Avatar
                      sx={{
                        color: "rgba(193, 32, 37, 1)",
                        bgcolor: "white",
                        cursor: "pointer",
                      }}
                      aria-label="recipe"
                    >
                      <FaTruck />
                    </Avatar>
                  }
                  title={
                    <Typography variant="subtitle2">
                      Your order has been dispatched
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption">
                      Your order MNJUI009467 has been dispatched and is now on
                      its way to you. Contact Team Asthra for more details
                    </Typography>
                  }
                />
                <CardHeader
                  sx={{
                    boxShadow: " 0px 0px 15px 0px rgba(210, 191, 191, 0.7)",
                  }}
                  avatar={
                    <Avatar
                      sx={{
                        color: "rgba(193, 32, 37, 1)",
                        bgcolor: "white",
                        cursor: "pointer",
                      }}
                      aria-label="recipe"
                    >
                      <HiOutlineClipboardCheck />
                    </Avatar>
                  }
                  title={
                    <Typography variant="subtitle2">
                      Your order has been approved!
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption">
                      Your order MNJUI009467 has been successfully approved and
                      is now being processed. Track order and view details here.
                    </Typography>
                  }
                />
                <CardHeader
                  sx={{
                    boxShadow: " 0px 0px 15px 0px rgba(210, 191, 191, 0.7)",
                  }}
                  avatar={
                    <Avatar
                      sx={{
                        color: "green",
                        bgcolor: "white",
                      }}
                      aria-label="recipe"
                    >
                      <TiTick />
                    </Avatar>
                  }
                  title={
                    <Typography variant="subtitle2">
                      Order successfully completed!
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption">
                      Your order MNJUI009467 has been successfully completed.
                      Should you require assistance, contact Team Asthra
                    </Typography>
                  }
                />
              </CardContent>
              <CardActions disableSpacing></CardActions>
            </Card>
          </Popover>
        </>
      ) : (
        <Stack
          sx={{
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <Stack
            style={{
              width: "172px",
              minHeight: "49px",
              background: "rgba(242, 242, 242, 1)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => navigate("/profile")}
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ color: "black", textAlign: "start" }}
              endIcon={
                <KeyboardArrowDownIcon
                  style={{ color: "rgba(242, 242, 242, 1)" }}
                />
              }
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                gap="10px"
                fontWeight="700"
                sx={{
                  width: "130px",
                }}
              >
                <TbEyeFilled
                  size={20}
                  style={{ color: "rgba(71, 71, 71, 1)" }}
                />
                <Typography variant="caption" sx={{ fontWeight: 700 }}>
                  View profile
                </Typography>
              </Stack>
            </Button>
          </Stack>
          <Stack
            style={{
              width: "172px",
              minHeight: "49px",
              background: "rgba(242, 242, 242, 1)",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              // onClick={"handleExpandClick"}
              onClick={() => navigate("/profile")}
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ color: "black", textAlign: "start" }}
              endIcon={
                <KeyboardArrowDownIcon
                  style={{ color: "rgba(242, 242, 242, 1)" }}
                />
              }
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                gap="10px"
                fontWeight="700"
                sx={{
                  width: "130px",
                }}
              >
                <MdLogout size={20} style={{ color: "rgba(71, 71, 71, 1)" }} />
                <Typography variant="caption" sx={{ fontWeight: 700 }}>
                  Log Out
                </Typography>
              </Stack>
            </Button>
          </Stack>
          <Stack
            style={{
              width: "172px",
              minHeight: "49px",
              background: "rgba(242, 242, 242, 1)",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleExpandClick}
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ color: "black", textAlign: "start" }}
              endIcon={!expanded ? <KeyboardArrowDownIcon /> : <RiCloseFill />}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                gap="10px"
                fontWeight="700"
                sx={{
                  width: "130px",
                }}
              >
                <MdOutlinePlace
                  size={20}
                  style={{ color: "rgba(71, 71, 71, 1)" }}
                />
                <Typography variant="caption" sx={{ fontWeight: 700 }}>
                  {" "}
                  {branch.branchName}
                </Typography>
              </Stack>
            </Button>

            <Collapse in={expanded}>
              <Stack sx={{ width: "150px" }}>
                <Divider style={{ background: "black" }} />
              </Stack>

              <Stack
                sx={{
                  width: "150px",
                  marginTop: "10px",
                  gap: "10px",
                  minHeight: "69px",
                }}
              >
                {userData?.branchname?.map((el, index) => (
                  <Typography
                    variant="body2"
                    key={index}
                    onClick={() => handleBranchSelect(index)}
                    sx={{
                      cursor: "pointer",
                      color: "rgba(135, 135, 135, 1)",
                    }}
                  >
                    {el}
                  </Typography>
                ))}
              </Stack>
            </Collapse>
          </Stack>
          <Stack
            style={{
              width: "172px",
              minHeight: "49px",
              background: "rgba(242, 242, 242, 1)",
              justifyContent: "center",
              alignItems: "center",
              display: "none",
            }}
          >
            <Button
              onClick={handleExpandClickNotification}
              id="demo-positioned-button"
              sx={{ color: "black", textAlign: "start" }}
              endIcon={
                !expandedNotification ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <RiCloseFill />
                )
              }
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                gap="10px"
                fontWeight="700"
                sx={{
                  width: "130px",
                }}
              >
                <IoIosNotifications
                  size={20}
                  style={{ color: "rgba(71, 71, 71, 1)" }}
                />
                <Typography variant="caption" sx={{ fontWeight: 700 }}>
                  {" "}
                  Notifications
                </Typography>
              </Stack>
            </Button>

            <Collapse in={expandedNotification}>
              <Stack sx={{ width: "150px" }}>
                <Divider style={{ background: "black" }} />
              </Stack>

              <Stack
                sx={{
                  width: "150px",
                  marginTop: "10px",
                  gap: "10px",
                  minHeight: "69px",
                }}
              >
                <Stack
                  style={{
                    maxWidth: "150px",
                    // minWidth: 300,
                    maxHeight: "250px",
                    overflowX: "auto",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <CardHeader
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      avatar={
                        <Avatar
                          sx={{
                            color: "green",
                            bgcolor: "white",
                            width: "15px",
                            height: "15px",
                            marginTop: "5px",
                          }}
                          aria-label="recipe"
                        >
                          <TiTick />
                        </Avatar>
                      }
                      title={
                        <Typography variant="caption">
                          Order successfully completed!
                        </Typography>
                      }
                      subheader={
                        <Typography variant="caption">
                          {" "}
                          Your order MNJUI009467 has been successfully
                          completed. Should you require assistance, contact Team
                          Asthra
                        </Typography>
                      }
                    />
                    <CardHeader
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      avatar={
                        <Avatar
                          sx={{
                            color: "rgba(193, 32, 37, 1)",
                            bgcolor: "white",
                            width: "15px",
                            height: "15px",
                            marginTop: "5px",
                          }}
                          aria-label="recipe"
                        >
                          <FaTruck />
                        </Avatar>
                      }
                      title={
                        <Typography variant="caption">
                          Your order has been dispatched
                        </Typography>
                      }
                      subheader={
                        <Typography variant="caption">
                          Your order MNJUI009467 has been dispatched and is now
                          on its way to you. Contact Team Asthra for more
                          details
                        </Typography>
                      }
                    />
                    <CardHeader
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      avatar={
                        <Avatar
                          sx={{
                            color: "rgba(193, 32, 37, 1)",
                            bgcolor: "white",
                            width: "15px",
                            height: "15px",
                            marginTop: "5px",
                          }}
                          aria-label="recipe"
                        >
                          <HiOutlineClipboardCheck />
                        </Avatar>
                      }
                      title={
                        <Typography variant="caption">
                          Your order has been approved!
                        </Typography>
                      }
                      subheader={
                        <Typography variant="caption">
                          Your order MNJUI009467 has been successfully approved
                          and is now being processed. Track order and view
                          details here.
                        </Typography>
                      }
                    />
                    <CardHeader
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                      }}
                      avatar={
                        <Avatar
                          sx={{
                            color: "green",
                            bgcolor: "white",
                            width: "15px",
                            height: "15px",
                            marginTop: "5px",
                          }}
                          aria-label="recipe"
                        >
                          <TiTick />
                        </Avatar>
                      }
                      title={
                        <Typography variant="caption">
                          Order successfully completed!
                        </Typography>
                      }
                      subheader={
                        <Typography variant="caption">
                          Your order MNJUI009467 has been successfully
                          completed. Should you require assistance, contact Team
                          Asthra
                        </Typography>
                      }
                    />
                  </Stack>
                  <CardActions disableSpacing></CardActions>
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default RightMenu;
