import * as React from "react";

import Box from "@mui/material/Box";

import {
  Button,
  IconButton,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import logo from "../images/asthra logo red-03 1.png";
import medpick from "../images/medpick.png";
import Google from "../images/Google.png";
import Apple from "../images/Apple.png";

import astra from "../images/astra.png";
import Vajra from "../images/Vajra.png";

import logos from "../images/asthra logo-white.png";
import medpicks from "../images/medpickwhite.png";
import googleandapple from "../images/googleapple.png";
import astras from "../images/Astrawhte.png";
import Vajras from "../images/vajrawhite.png";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
const FooterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  paddingLeft: "20px",

  paddingRight: "20px",
  width: "90%",
  // gap:"20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const FooterBox1 = styled(Box)(({ theme }) => ({
  width: "35%",
  height: "250px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: "40px",
  borderRadius: "10px",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  [theme.breakpoints.down("xl")]: {

    // width: "400px",
    height: "250px",
  },
  [theme.breakpoints.down("lg")]: {
    // width: "393px",
    height: "237px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const FooterBox2 = styled(Box)(({ theme }) => ({
  width: "687px",
  height: "250px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "start",
  padding: "40px",
  borderRadius: "10px",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  [theme.breakpoints.down("xl")]: {
    // width: "600px",
  height: "250px",
  width:"45%"
  },
  [theme.breakpoints.down("lg")]: {
    // width: "597px",
    height: "237px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Box1 = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Boxes = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  flexDirection: "column",
  gap: "15px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const InnerBox = styled(Box)(({ theme }) => ({
  width: "100%",
  //   height: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  // gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Text = styled(Typography)(({ theme }) => ({
  justifyContent: "start",
  alignItems: "center",
  width: "100%",
  display: "flex",
  gap: "2%",
  fontWeight: 500,
  color: " #000000",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
    borderRadius: "3px",
    color: "rgba(255, 255, 255, 1)",
    fontWeight: 400,
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Text2 = styled(Typography)(({ theme }) => ({
  justifyContent: "start",
  alignItems: "center",
  width: "100%",
  display: "flex",
  gap: "2%",
  fontWeight: 500,
  color: " #000000",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
    borderRadius: "3px",
    width: "185px",
    color: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(255, 255, 255, 1)",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const MainText = styled(Typography)(({ theme }) => ({
  height: "70px",
  width: "100%",
  fontWeight: 700,
  color: " #000000",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    height: "100px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    color: "white",
  },
  [theme.breakpoints.down("xs")]: {},
}));

export default function Footer2() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleMapButtonClick = () => {
    const location =
      "2086,9th Cross RPC Layout, Vijayanagar Bangalore - 560040";

    const encodedLocation = encodeURIComponent(location);

    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;

    window.open(mapsUrl, "_blank");
  };
  return (
    <>
      {!isMobileScreen || !isTabScreen ? (
        <>
          <FooterBox
            sx={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}>
            <FooterBox1 sx={{}}>
              <Box1>
                <InnerBox sx={{ height: "60%" }}>
                  <Box as="img" src={logo} sx={{ mr: 1 }} />
                  <Typography
                    sx={{ color: "rgba(193, 32, 37, 1)", fontWeight: 700 }}>
                    ASTHRA
                  </Typography>
                </InnerBox>
                <InnerBox sx={{ height: "40%" }}>
                  <Typography
                    variant="caption"
                    sx={{ color: "rgba(193, 32, 37, 1)", fontWeight: 700 }}>
                    A Service by
                  </Typography>
                  <Box as="img" src={medpick} sx={{ mt: 7 }} />
                </InnerBox>
              </Box1>
              <Box1 sx={{ justifyContent: "end", gap: "20px" }}>
                <Typography
                  sx={{ color: "rgba(193, 32, 37, 1)", fontWeight: 700 }}>
                 Register Now
                </Typography>

                <Box as="img" src={Google} />
                <Box as="img" src={Apple} />
              </Box1>
            </FooterBox1>
            <FooterBox2 sx={{}}>
              <Box1 sx={{ textAlign: "start" }}>
                <MainText variant="button" sx={{}}>
                  ABOUT US
                </MainText>
                <Boxes sx={{ width: "100%" }}>
                  <Text variant="body2" onClick={() => navigate("/faq")}>
                    FAQ
                  </Text>
                  <Text variant="body2" onClick={() => navigate("/contact")}>
                    Contact
                  </Text>
                  <Text
                    variant="body2"
                    sx={{ wordWrap: "break-word" }}
                    onClick={() => navigate("/terms-and-conditions")}>
                    Terms & Conditions
                  </Text>
                  <Text variant="body2" onClick={() => navigate("/disclaimer")}>
                    Disclaimer
                  </Text>
                  <Text
                    variant="body2"
                    onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </Text>
                </Boxes>
              </Box1>
              <Box1 sx={{ textAlign: "start" }}>
                <MainText variant="button" sx={{ marginLeft: 2 }}>
                  CONTACT
                </MainText>
                <Boxes sx={{ width: "100%" }}>
                  <Text variant="body2">
                    <IconButton>
                      <MdEmail size={15} color="black" />
                    </IconButton>
                    &nbsp;contact@asthra.com
                  </Text>
                  <Text variant="body2">
                    <IconButton>
                      <FaPhone size={15} color="black" />
                    </IconButton>
                    &nbsp;(+91) 89715 80265
                  </Text>

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <IconButton>
                      <FaLocationDot size={15} color="black" />
                    </IconButton>
                    &nbsp;
                    <Text variant="body2">
                      2086,9th Cross RPC Layout, Vijayanagar Bangalore - 560040
                    </Text>
                  </Box>
                </Boxes>
              </Box1>
              <Box1 sx={{ textAlign: "start" }}>
                <MainText variant="button" sx={{ textAlign: "center" }}>
                  OUR SERVICES
                </MainText>
                <Boxes
                  sx={{
                    width: "100%",
                    height: "50%",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}>
                  <Box>
                    {" "}
                    <Box
                      as="img"
                      src={astra}
                      sx={{
                        mt: 1,
                        width: "55px",
                        height: "55px",
                      }}
                    />
                  </Box>
                  <Box>
                    {" "}
                    <Box
                      as="img"
                      src={Vajra}
                      sx={{
                        mt: 1,
                        width: "55px",
                        height: "55px",
                      }}
                    />
                  </Box>
                </Boxes>
              </Box1>
            </FooterBox2>
          </FooterBox>
          <Box
            color="primary"
            sx={{
              width: "100%",
              bgcolor: "rgba(193, 32, 37, 1)",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            
            }}>
           
          
              <Button sx={{ color: "white", textTransform: "none" }}>
                Medpick - All Rights Reserved
              </Button>
            
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "rgba(193, 32, 37, 1)",

            justifyContent: "space-around",
            alignItems: "center",
            // height: "100vh",
            width: "100%",
          }}>
          <Box1
            sx={{
              flexDirection: "row",
              width: "90%",
              height: "89px",
              gap: "0px",
              justifyContent: "center",

              marginTop: "20px",
              marginBottom: "20px",
              border: "1px solid white",
            }}>
            {/* <InnerBox sx={{ height: "40%" }}>
                  <Box as="img" src={logo}  />
                  <Typography variant="caption" sx={{ color: "rgba(193, 32, 37, 1)" }}>
                    ASTHRA
                  </Typography>
                </InnerBox> */}
            <InnerBox sx={{ height: "40%" }}>
              <Box
                as="img"
                src={logos}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <Typography variant="caption" sx={{ color: "white" }}>
                ASTRA
              </Typography>
            </InnerBox>
            <InnerBox sx={{ height: "40%" }}>
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                  width: "97px",
                  height: "14px",
                }}>
                A Service by
              </Typography>
              <Box as="img" src={medpicks} sx={{ mt: 2 }} />
            </InnerBox>
          </Box1>
          <Text></Text>
          <Text></Text>
          <Box1
            sx={{
              width: "90%",
              marginBottom: "2%",
              justifyContent: "space-between",
              flexDirection: "row",
            }}>
            <Box as="img" src={googleandapple} sx={{ mt: 1, width: "100%" }} />
          </Box1>
          <Box1
            sx={{
              justifyContent: "start",
              width: "90%",
              // padding: "20px",
            }}>
            <Box1
              sx={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                gap: "20px",
                height: "313px",
                color: "white",
              }}>
              <Box1
                sx={{
                  textAlign: "start",

                  width: "35%",
                }}>
                <MainText variant="body2" sx={{ color: "white" }}>
                  ABOUT US
                </MainText>
                <Boxes
                  sx={{
                    width: "90%",
                    textAlign: "start",
                  }}>
                  <Text variant="body2">FAQ</Text>
                  <Text variant="body2">Contact</Text>
                  <Text variant="body2">Terms & Conditions</Text>
                  <Text variant="body2">Disclaimer</Text>
                  <Text variant="body2">Privacy Policy</Text>
                </Boxes>
              </Box1>
              <Box1
                sx={{
                  textAlign: "start",
                  width: "55%",
                }}>
                <MainText variant="body2">CONTACT</MainText>
                <Boxes sx={{ width: "100%" }}>
                  <Text2 variant="body2" sx={{ height: "45px" }}>
                    <IconButton>
                      <MdEmail size={15} color="white" />
                    </IconButton>
                    &nbsp;contact@asthra.com
                  </Text2>
                  <Text2 variant="body2" sx={{ height: "45px" }}>
                    <IconButton>
                      <FaPhone size={15} color="white" />
                    </IconButton>
                    &nbsp;(+91) 89715 80265
                  </Text2>

                  <Text2
                    variant="body2"
                    sx={{ height: "45px" }}
                    onClick={handleMapButtonClick}>
                    <IconButton>
                      <FaLocationDot size={15} color="white" />
                    </IconButton>
                    &nbsp; Go to this location
                  </Text2>
                </Boxes>
              </Box1>
            </Box1>

            <Box1
              sx={{
                textAlign: "start",
                flexDirection: "row",
                width: "100%",
              }}>
              <MainText variant="body2" sx={{ textAlign: "center" }}>
                OUR SERVICES
              </MainText>
              <Boxes
                sx={{
                  width: "100%",

                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "row",
                }}>
                <Box>
                  {" "}
                  <Box
                    as="img"
                    src={astras}
                    sx={{
                      mt: 1,
                      width: "55px",
                      height: "55px",
                    }}
                  />
                </Box>
                <Box>
                  {" "}
                  <Box
                    as="img"
                    src={Vajras}
                    sx={{
                      mt: 1,
                      width: "55px",
                      height: "55px",
                    }}
                  />
                </Box>
              </Boxes>
            </Box1>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 1)",
              }}>
              {" "}
              © Medpick - All Rights Reserved
            </Typography>
          </Box1>
        </Box>
      )}
    </>
  );
}
