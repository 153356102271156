import {
  ALL_ORDER_FAILURE,
  ALL_ORDER_REQUEST,
  ALL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CONTACT_FAILURE,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  DASHBOARD_ANALYTICS_FAILURE,
  DASHBOARD_ANALYTICS_REQUEST,
  DASHBOARD_ANALYTICS_SUCCESS,
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  ADDPRODUCT_FAILURE,
  ADDPRODUCT_REQUEST,
  ADDPRODUCT_SUCCESS,
  DELETE_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  GET_AUTHENTICATION_ERROR_SUCCESS,
  GET_INVALID_TOKEN_FAILURE,
  GET_INVALID_TOKEN_REQUEST,
  GET_INVALID_TOKEN_SUCCESS,
  NEW_ORDER_FAILURE,
  NEW_ORDER_REQUEST,
  NEW_ORDER_SUCCESS,
  ORDER_DETAIL_BY_ID_FAILURE,
  ORDER_DETAIL_BY_ID_REQUEST,
  ORDER_DETAIL_BY_ID_SUCCESS,
  ORDER_TRACKING_FAILURE,
  ORDER_TRACKING_REQUEST,
  ORDER_TRACKING_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  REQUEST_PRODUCT_FAILURE,
  REQUEST_PRODUCT_REQUEST,
  REQUEST_PRODUCT_SUCCESS,
  TRANSACTION_FAILURE,
  TRANSACTION_REQUEST,
  TRANSACTION_SUCCESS,
  DOWNLOAD_PDF_FAILURE,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
} from "./action";

const initState = {
  isLoading: false,
  isError: false,
  error: {},
  allOrder: [],
  contact: [],
  requestProducts: "",
  orderDetailById: {},
  editOrder: "",
  newOrders: [],
  placeOrder: [],
  deleteOrder: [],
  profile: {},
  transaction: [],
  cancelOrder: "",
  orderTracking: [],
  dashboard: [],
  dashboardAnalytics: [],
  addproductdata: [],
  invalidToken: [],
  authenticationError: [],
  downloadPdf: [],
};

export const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case ALL_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ALL_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allOrder: action.payload,
      };

    case ALL_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        contact: action.payload,
      };

    case CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case REQUEST_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case REQUEST_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        requestProducts: action.payload,
      };

    case REQUEST_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ORDER_DETAIL_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ORDER_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orderDetailById: action.payload,
      };

    case ORDER_DETAIL_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case EDIT_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orderDetailById: action.payload,
      };

    case EDIT_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case NEW_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case NEW_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        newOrders: action.payload,
      };

    case NEW_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case PLACE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        placeOrder: action.payload,
      };

    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    // case DELETE_ORDER_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isError: false,
    //   };

    // case DELETE_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isError: false,
    //     deleteOrder: action.payload,
    //   };

    // case DELETE_ORDER_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isError: true,
    //     error: action.payload,
    //   };

    case DELETE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        deleteOrder: action.payload,
      };

    case DELETE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        profile: action.payload,
      };

    case PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case TRANSACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        transaction: action.payload,
      };

    case TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case CANCEL_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        cancelOrder: action.payload,
      };

    case CANCEL_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case ORDER_TRACKING_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orderTracking: action.payload,
      };

    case ORDER_TRACKING_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    case DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        dashboard: action.payload,
      };

    case DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

      case ADDPRODUCT_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case ADDPRODUCT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          addproductdata: action.payload,
        };
  
      case ADDPRODUCT_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };

    case DASHBOARD_ANALYTICS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DASHBOARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        dashboardAnalytics: action.payload,
      };

    case DASHBOARD_ANALYTICS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case GET_INVALID_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_INVALID_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        invalidToken: action.payload,
      };
    case GET_INVALID_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case GET_AUTHENTICATION_ERROR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        authenticationError: action.payload,
      };
    case DOWNLOAD_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        downloadPdf: action.payload,
      };

    case DOWNLOAD_PDF_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
