

import { legacy_createStore, applyMiddleware, combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { dataReducer } from "./data/reducer";
import { thunk } from "redux-thunk";


const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
});

export const store = legacy_createStore(
  rootReducer,
  applyMiddleware(thunk)
);
