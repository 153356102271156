const NumberFormat = ({ number }) => {
  const formatNumber = (num) => {
    if (isNaN(num)) {
      return number;
    }
    return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return formatNumber(number)
};

export default NumberFormat;