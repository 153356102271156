import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { RxHamburgerMenu } from "react-icons/rx";
// import logo from "../../images/asthra logo red-03 1.png";
import logo from "../images/asthra logo red-03 1.png";
import { useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { MdMessage } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import profile from "../images/doctor.png";
import { RiWhatsappFill } from "react-icons/ri";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  Stack,
  styled,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { contact } from "../redux/data/action";
import {
  Avatar,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { RiEqualizerFill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GrFilter } from "react-icons/gr";
import { LuSettings2 } from "react-icons/lu";
import InputBase from "@mui/material/InputBase";
import { RiSearchLine } from "react-icons/ri";
import RightMenu from "../pages/OnBoarding/RightMenu";
const ImageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "50px",
    height: "54px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Button)(({ theme }) => ({
  width: "172px",
  height: "45px",
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

function Navbar({ heading }) {
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const openSort = Boolean(anchorElSort);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  let branch = JSON.parse(localStorage.getItem("branchData"));
  // /////console.log("branch", branch);
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleHome = () => {
    navigate("/");
    handleCloseDrawer();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const name = userData.name;

  // Function to get initials
  function getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < words.length; i++) {
      initials += words[i][0];
    }
    return initials;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenwhatup, setIsOpenwhatup] = useState(false);

  const [openSubmission, setOpenSubmission] = useState(false);
  const [userName, setUserName] = useState("");
  const [hospital, setHospital] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});
  const maxLength = 300;

  const toggleChat = () => {
    setIsOpen(!isOpen);
    handleDrawerClose();
  };

  const toggleChatwhatup = () => {
    setIsOpenwhatup(!isOpenwhatup);
    handleDrawerClose();
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case "userName":
        setUserName(value);
        break;
      case "hospital":
        setHospital(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
        break;
    }
  };

  // Get initials based on the name
  const initials = getInitials(name);
  // /////console.log("initials", initials);

  const handleAsthraButtonClick = () => {
    setOpenSubmission(true); // This will open the dialog
  };

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
  };

  return (
    <>
      <Box sx={{ height: "60px", position: "static" }}>
        <Box
          sx={{
            bgcolor: "white",
            height: "60px",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <ImageBox component="img" src={logo} onClick={handleHome} />
            </IconButton>
            <Typography
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: "center",
                color: "rgba(193, 32, 37, 1)",
                fontWeight: 700,
              }}
            >
              {heading}
            </Typography>
            <IconButton
              onClick={handleDrawerOpen}
              edge="end"
              aria-label="add"
              sx={{ ml: 2, color: "rgba(0, 0, 0, 1)" }}
            >
              <RxHamburgerMenu size={20} />
            </IconButton>
          </Toolbar>

          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            sx={{ "& .MuiDrawer-paper": {} }}
          >
            <Stack
              sx={{
                width: 222,
                gap: "10px",
                height: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              role="presentation"
            >
              <Stack>
                <Stack
                  sx={{
                    width: "172px",
                    height: "20px",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "700" }}>
                    ABC Health Care
                  </Typography>
                  <IconButton onClick={handleDrawerClose}>
                    <IoCloseSharp size={15} style={{ color: "black" }} />
                  </IconButton>
                </Stack>
                <Divider sx={{ bgcolor: "rgba(238, 232, 232, 1)" }} />
                <List
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar>{initials}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={userData.name}
                      secondary={userData.role}
                    />
                  </ListItemButton>
                </List>
                <Divider sx={{ bgcolor: "rgba(238, 232, 232, 1)" }} />
                <RightMenu />
              </Stack>

              <Stack gap={"10px"} marginBottom={"20px"}>
                <Button
                  variant="outlined"
                  sx={{ gap: "20px" }}
                  onClick={() => navigate("/new-order")}
                >
                  <GoPlus />
                  Place order
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid rgba(5, 165, 216, 1)",
                    color: "rgba(5, 165, 216, 1)",
                    gap: "20px",
                  }}
                  onClick={toggleChat}
                >
                  <MdMessage />
                  Asthra team
                </Button>
                <Button
                  onClick={toggleChatwhatup}
                  variant="outlined"
                  sx={{
                    border: "1px solid rgba(54, 201, 2, 1)",
                    color: "rgba(54, 201, 2, 1)",
                    gap: "20px",
                  }}
                >
                  <IoLogoWhatsapp />
                  Chat with us
                </Button>
              </Stack>
            </Stack>
          </Drawer>
        </Box>
      </Box>

      <div>
        <div
          className="overlay"
          style={{ display: isOpen ? "block" : "none" }}
          onClick={toggleChat}
        ></div>

        <div
          className="chat-box"
          style={{
            display: isOpen ? "flex" : "none",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ position: "relative", width: "100%", height: "100px" }}>
              <Box
                component="img"
                src="profile.jpg" // Add your profile image source here
                alt="Profile"
                sx={{ width: "100%", height: "100%" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "rgba(193, 32, 37, 1)",
                  width: "20px",
                  height: "20px",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={toggleChat}
              >
                <CloseIcon />
              </Box>
            </Box>
            <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
              How can we help you?
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="form" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-small"
                    placeholder="Mr. Samuel Arora"
                    variant="filled"
                    size="small"
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: { height: "50px", fontSize: "15px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    value={userName}
                    onChange={(e) =>
                      handleInputChange("userName", e.target.value)
                    }
                  />
                  {errors.userName && (
                    <HelperText>{errors.userName}</HelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-small"
                    placeholder="Apollo Hospitals, Delhi"
                    variant="filled"
                    size="small"
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: { height: "50px", fontSize: "15px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    value={hospital}
                    onChange={(e) =>
                      handleInputChange("hospital", e.target.value)
                    }
                  />
                  {errors.hospital && (
                    <HelperText>{errors.hospital}</HelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-small"
                    placeholder="samuel008@gmail.com"
                    variant="filled"
                    size="small"
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: { height: "50px", fontSize: "15px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    value={email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  {errors.email && <HelperText>{errors.email}</HelperText>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-small"
                    placeholder="011 7179 1090"
                    variant="filled"
                    size="small"
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: { height: "50px", fontSize: "15px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalPhoneIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    value={phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                  {errors.phone && <HelperText>{errors.phone}</HelperText>}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="text"
                    placeholder="Add comments"
                    multiline
                    maxRows={10}
                    InputProps={{
                      style: { height: "100px", fontSize: "15px" },
                      disableUnderline: true,
                    }}
                    inputProps={{
                      maxLength: maxLength,
                    }}
                    helperText={`${comment?.length}/${maxLength} characters`}
                    value={comment}
                    onChange={(e) =>
                      handleInputChange("comment", e.target.value)
                    }
                  />
                  {errors.comment && <HelperText>{errors.comment}</HelperText>}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={toggleChat}
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 3,
                      mb: 2,
                      border: "1px solid rgba(193, 32, 37, 1)",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>

        <Dialog open={openSubmission} onClose={handleCloseSubmission}>
          <Stack
            justifyContent={"space-evenly"}
            alignItems={"center"}
            sx={{ width: "420px", height: "200px" }}
          >
            <DialogTitle
              sx={{ textAlign: "center", color: "rgba(193, 32, 37, 1)" }}
            >
              Request submitted!
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ textAlign: "center", color: "black" }}>
                <Typography variant="body2">
                  Your request has been successfully submitted.
                  <br />
                  Our team will get in touch with you soon.
                </Typography>
              </DialogContentText>
            </DialogContent>
          </Stack>
        </Dialog>
      </div>

      <div
        className="chat-box1"
        style={{
          display: isOpenwhatup ? "flex" : "none",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            bgcolor: "rgba(193, 32, 37, 1)",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            padding: "4px",
          }}
        >
          <Typography>Chat through WhatsApp</Typography>
          <CloseIcon onClick={toggleChatwhatup} sx={{ cursor: "pointer" }} />
        </Box>

        <Box sx={{ padding: "10px" }}>
          {/* Chat bubble for the message */}
          <div style={{ marginBottom: "10px" }}>
            <Typography color="rgba(193, 32, 37, 1)" variant="subtitle2">
              Asthra Team
            </Typography>
            <Typography variant="subtitle2">
              Hi, start a conversation to know more
            </Typography>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <Typography variant="subtitle2" color="rgba(135, 135, 135, 1)">
              The team typically replies in a few minutes.
            </Typography>
          </div>

          {/* User's response */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                backgroundColor: "#e0e0e0",
                padding: "10px",
                borderRadius: "10px",
                maxWidth: "70%",
                textAlign: "right",
              }}
            >
              <Typography variant="subtitle2">
                I would like to start a conversation
              </Typography>
              <Typography variant="caption" color="rgba(135, 135, 135, 1)">
                ⓘ Takes you to an outside link
              </Typography>
            </div>
          </Box>

          {/* WhatsApp Button and Cancel button */}
          <Stack
            justifyContent="flex-end"
            flexDirection="row"
            sx={{ width: "100%" }}
            gap="20px"
          >
            <IconButton sx={{ color: "rgba(37, 211, 102, 1)" }}>
              <RiWhatsappFill size={30} />
            </IconButton>

            <a
              href={`https://api.whatsapp.com/send?phone=918971580268&text=Hi,%20I%20have%20a%20query%20in%20Astra%20App%20https://astra.software${window.location.pathname}.%20Can%20you%20help%20?`}
              target="_blank"
              style={{ height: "100%" }}
              rel="noreferrer"
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  bgcolor: "rgba(37, 211, 102, 1)",
                  width: "100px",
                  height: "45px",
                  "&:hover": {
                    bgcolor: "rgba(37, 211, 102, 1)",
                    color: "white",
                  },
                }}
              >
                Start
              </Button>
            </a>

            <Button
              onClick={toggleChatwhatup}
              variant="outlined"
              color="primary"
              size="small"
              sx={{ width: "100px", height: "45px" }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </div>
    </>
  );
}

export default Navbar;
