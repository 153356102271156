import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { FaClipboardCheck, FaTruck, FaCheck } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { TbClipboardCheck } from "react-icons/tb";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Button, Select, Stack } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepLabel } from "@mui/material";

import { TiTick } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import { orderTracking } from "../../redux/data/action";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const Buttons = styled(Button)(({ theme }) => ({
  background: "white",
  border: "1px solid black",
  color: "black",
  width: "130px",
  marginTop: "20px",
}));

const InnerBox = styled(Stack)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  border: "2px solid rgba(255, 255, 255, 1)",
  boxShadow:
    "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  borderRadius: "10px",
}));

const VerticalLinearProgress = styled(LinearProgress)(() => ({
  width: "6px",
  height: "100%",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#F5F6FA",
  },
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: "black",
  },
}));

const steps = ["Approved", "Shipped", "Delivered"];

const DashboardTracking = () => {
  const dashboardData = useSelector((store) => store.data.dashboard);

  const orderTrackingData = useSelector((store) => store.data.orderTracking);
  /////console.log("orderTrackingData", orderTrackingData);
  const [trackerSection, setTrackerSection] = useState([]);
  useEffect(() => {
    if (dashboardData) {
      setTrackerSection(dashboardData.tracker_section || []);
    }
  }, [dashboardData]);


  const [data, setData] = useState([]);
  const location = useLocation();
  const getData = location.state;
  /////console.log("getData************", getData);

  const dispatch = useDispatch();

  const [selectedOrder, setSelectedOrder] = useState(null);
  /////console.log("selectedOrder", selectedOrder);
  const [lastObject, setLastObject] = useState("");
  /////console.log("lastObject", lastObject);

  const getStatusIndex = (status) => {
    /////console.log("status", status);
    switch (status) {
      case "Processing":
        return 0;
      case "In Progress":
        return 1;
      case "Shipped":
        return 2;
      case "Delivered":
        return 3;
      default:
        return 0;
    }
  };
  /////console.log("get", getData);
  const [activeStep, setActiveStep] = useState("");
  useEffect(() => {
    setActiveStep(
      getStatusIndex(
        selectedOrder
          ? selectedOrder.orderstatus
          : getData && getData.orderstatus
      )
    );
  }, [selectedOrder, getData]);
  const getStepColor = (stepIndex) => {
    return stepIndex <= activeStep ? "black" : "rgba(231, 231, 231, 1)";
  };

  useEffect(() => {
    if (orderTrackingData && orderTrackingData?.length > 0) {
      setData(orderTrackingData);

      setLastObject(orderTrackingData[orderTrackingData?.length - 1]);
    }
  }, [orderTrackingData]);

  useEffect(() => {
    dispatch(orderTracking());
  }, []);

  useEffect(() => {
    const matchedOrder = data?.find(
      (order) => order.orderid === getData?.orderid
    );
    if (matchedOrder) {
      setSelectedOrder(matchedOrder);
    }
  }, [data, getData]);

  const handleOrderChange = (event) => {
    setSelectedOrder(
      data?.find((order) => order.orderid === event.target.value)
    );
  };

  return (
    <>
      <>
        <Stack
          sx={{
            backgroundColor: "#f0f0f0",

            // position: "fixed",

            zIndex: 1,

            bgcolor: "rgba(241, 241, 241, 1)",
            // justifyContent: "space-around",
            alignItems: "start",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            height: "100%",

            // overflow: "auto",
          }}
          id="search-bar">
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              bgcolor: "rgba(241, 241, 241, 1)",
              gap: "20px",

              width: "100%",
              height: "100%",

              //justifyContent: "space-between",
            }}>
            <Stack
              sx={{
                flexDirection: "row",
                gap: "10px",

                width: "100%",
              }}>
              <InnerBox
                sx={{
                  width: "70%",
                  height: "142px",
                }}>
                <Stack
                  sx={{
                    padding: "10px",
                    justifyContent: "space-between",
                    height: "100%",
                  }}>
                  <Label sx={{ paddingLeft: "10px" }}>Active orders</Label>

                  <Select
                    value={
                      (selectedOrder && selectedOrder.orderid) || // If selectedOrder is not null, use its orderid
                      (getData && getData.orderid) || // If getData is not null, use its orderid
                      (lastObject && lastObject.orderid)
                    }
                    onChange={handleOrderChange}
                    sx={{
                      width: 200,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      "& .MuiOutlinedInput-root": {
                        border: "none", // Remove outline border
                      },
                      "& .MuiSelect-root": {
                        borderBottom: "none", // Remove bottom border
                      },
                      borderRadius: "30px",
                      "& fieldset": { border: "none" },

                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                    }}>
                    {data.map((order, index) => (
                      <MenuItem key={index} value={order.orderid}>
                        <Label variant="body2" noWrap>
                          {order.orderid}
                        </Label>
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </InnerBox>
              <InnerBox
                sx={{
                  width: "30%",
                  height: "142px",
                }}>
                <Stack
                  sx={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    alignItems: "center",

                    justifyContent: "space-between",
                    height: "100%",
                  }}>
                  <Label variant="body2" sx={{ color: "rgba(255, 153, 0, 1)" }}>
                    Active{" "}
                  </Label>
                  <Stack sx={{ alignItems: "center" }}>
                    
                    <Label variant="body2">{trackerSection?.length}</Label>
                    <Label variant="body2">Orders</Label>
                  </Stack>
                </Stack>
              </InnerBox>
            </Stack>
            <InnerBox
              sx={{
                width: "100%",
                height: "36vh",
                justifyContent: "space-around",
              }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",

                  height: "100%",
                }}>
                <Stack
                  style={{
                    display: "flex",
                    justifyContent: "space-around",

                    alignItems: "center",
                    padding: "0px",

                    height: "90%",
                  }}>
                  <StepLabel>
                    <TbClipboardCheck
                      size={20}
                      style={{ color: getStepColor(1) }}
                    />
                  </StepLabel>
                  <StepLabel>
                    <FaTruck size={20} style={{ color: getStepColor(2) }} />
                  </StepLabel>
                  <StepLabel>
                    <TiTick size={20} style={{ color: getStepColor(3) }} />
                  </StepLabel>
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    height: "80%",
                    p: 2,
                  }}>
                  <VerticalLinearProgress
                    variant="determinate"
                    color="secondary"
                    value={(activeStep / 3) * 100}
                    sx={{
                      [`& .${linearProgressClasses.bar}`]: {
                        transform: `translateY(${
                          (activeStep / 3) * 100
                        }%)!important`,
                      },
                    }}
                  />
                </Box>
                <Stack
                  style={{
                    display: "flex",
                    justifyContent: "space-around",

                    alignItems: "center",
                    height: "90%",
                  }}>
                  {steps.map((label, index) => (
                    <div key={label} style={{}}>
                      <StepLabel>
                        <Typography
                          variant="caption"
                          style={{
                            color: getStepColor(index + 1),
                            fontWeight: 600,
                          }}>
                          {label}
                        </Typography>
                      </StepLabel>
                    </div>
                  ))}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "90%",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  padding: 2,
                  gap:"10px"
                }}>
                <Buttons>Summary</Buttons>
                <Buttons>Edit Order</Buttons>
              </Stack>
            </InnerBox>
          </Stack>
        </Stack>
      </>
    </>
  );
};

export default DashboardTracking;
