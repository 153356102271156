import {
  AppBar,
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { HiExclamationCircle } from "react-icons/hi2";

import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import profile from "../images/Doctor 1.png";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../redux/data/action";
import { useNavigate } from "react-router-dom";
import Navbar from "../MobileView/Navbar";

import { AccountCircle, Phone, Mail, LocationOn } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import Footer from "../MobileView/Footer";
import RightMenu from "./OnBoarding/RightMenu";
import LoadingPage from "../components/Loading_Page";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",
}));

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  width: "400px",
  height: "400px",
  alignItems: "start",
  padding: "25px",
  flexDirection: "column",
  background: "white",
  borderRadius: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "350px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "310px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",
}));

const Box2 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  width: "470px",
  height: "400px",
  alignItems: "start",
  padding: "25px",
  flexDirection: "column",
  background: "white",
  borderRadius: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "430px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "350px",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
}));
const Profile = () => {
  const profileDetail = useSelector((store) => store.data.profile);
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [editablePersonal, setEditablePersonal] = useState(false);
  const [editableHospital, setEditableHospital] = useState(false);
  const [formDataPersonal, setFormDataPersonal] = useState({
    "User Name": data.name,
    // Designation: data.designation,
    "Contact Number": data.personal_mobile,
    "Email ID": data.email,
    Location:
      data.personal_location && data.personal_location?.length > 0
        ? data.personal_location[0]
        : "New York",
  });

  const [formDataHospital, setFormDataHospital] = useState({
    "Hospital Name": data.hospname,
    "Contact Number": data.hospcontact,
    "Email ID": data.email,
    Location: data.hospital_location,
  });

  const [personalFieldErrors, setPersonalFieldErrors] = useState({});
  const [hospitalFieldErrors, setHospitalFieldErrors] = useState({});
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFieldChangePersonal = (fieldName, value) => {
    setPersonalFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    setFormDataPersonal({
      ...formDataPersonal,
      [fieldName]: value,
    });
  };

  const handleFieldChangeHospital = (fieldName, value) => {
    setHospitalFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    setFormDataHospital({
      ...formDataHospital,
      [fieldName]: value,
    });
  };

  const handleSubmitPersonal = () => {
    const errors = validateFormData(formDataPersonal);
    if (Object.keys(errors)?.length === 0) {
      localStorage.setItem(
        "formDataPersonal",
        JSON.stringify(formDataPersonal)
      );

      setEditablePersonal(false);
    } else {
      setPersonalFieldErrors(errors);
    }
  };

  const handleSubmitHospital = () => {
    const errors = validateFormData(formDataHospital);
    if (Object.keys(errors)?.length === 0) {
      localStorage.setItem(
        "formDataHospital",
        JSON.stringify(formDataHospital)
      );

      setEditableHospital(false);
    } else {
      setHospitalFieldErrors(errors);
    }
  };

  const validateFormData = (formData) => {
    let errors = {};

    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        errors[key] = `${key} cannot be empty.`;
      }
    });
    return errors;
  };

  const handleEditHospital = () => {
    setEditableHospital(true);
  };
  const handleEditPersonal = () => {
    setEditablePersonal(true);
  };

  useEffect(() => {
    dispatch(profileData());
  }, []);

  useEffect(() => {
    if (Object.keys(profileDetail)?.length > 0) {
      setData(profileDetail);
    }
  }, [profileDetail]);

  useEffect(() => {
    if (data) {
      setFormDataPersonal((prevState) => ({
        ...prevState,
        "User Name": data.name,
        // Designation: data.designation,
        "Contact Number": data.personal_mobile,
        "Email ID": data.email,
        Location:
          data.personal_location && data.personal_location?.length > 0
            ? data.personal_location[0]
            : "New York",
      }));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setFormDataHospital((prevState) => ({
        ...prevState,
        "Hospital Name": data.hospname,
        "Contact Number": data.hospcontact,
        "Email ID": data.email,
        Location: data.hospital_location,
      }));
    }
  }, [data]);
  const fieldIcons = {
    "User Name": <AccountCircle />,
    Designation: <AssignmentIcon />, // You can set null if you don't want an icon for this field
    "Contact Number": <Phone />,
    "Email ID": <Mail />,
    Location: <LocationOn />,
  };
  const fieldIconsHospital = {
    "Hospital Name": <DomainAddIcon />,

    "Contact Number": <Phone />,
    "Email ID": <Mail />,
    Location: <LocationOn />,
  };

  const handleClicksignin = () => {
    // window.location.reload();
    localStorage.clear();

    navigate("/signin");
    window.location.reload();
  };
  
  return (
    <>
        <>
          <StyledBox
            sx={{
              height: "130px",
              borderRadius: "0px 0px 10px 10px",
              alignItems: "start",
              padding: "2%",
              boxShadow: "0px 4px 15px 0px rgba(230, 210, 209, 1)",
              background: "white",
              display: { xs: "none",sm:"flex", md: "flex" }
            }}
          >
            <Stack
              flexDirection={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              sx={{}}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "6%",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "20%",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>

                <Label2
                  variant="caption"
                  color="info"
                  onClick={() => navigate("/")}
                >
                  Dashboard
                </Label2>

                <Typography color="info">|</Typography>

                <Label2
                  variant="caption"
                  color="info"
                  onClick={() => navigate("/profile")}
                >
                  Profile
                </Label2>
              </Box>

              <Stack flexDirection={"row"} gap={"20px"}>
                <RightMenu />
              </Stack>
            </Stack>
            <InnerBox sx={{ width: "100%", marginTop: "2%" }}>
              <Label color="primary" variant="button" size="large">
                PROFILE
              </Label>
              {/*  */}
            </InnerBox>
          </StyledBox>
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              display: { xs: "none",sx:"flex", md: "flex" }
            }}
          >
            <Box1 sx={{}}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Label color="primary">Personal Info</Label>

                {/* {editablePersonal ? (
                  <Button
                    size="small"
                    variant="contained"
                    border="1px solid rgba(193, 32, 37, 1)"
                    paddingLeft="3px"
                    paddingRight="3px"
                    color="primary"
                    width="50px"
                    sx={{ cursor: "pointer" }}
                    onClick={handleSubmitPersonal}>
                    Submit
                  </Button>
                ) : (
                  <Box onClick={handleEditPersonal}>
                    <CiEdit size={20} cursor="pointer" />
                  </Box>
                )} */}
              </Stack>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "25ch" },
                  marginTop: "40px",
                  gap: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",

                  height: "80%",
                }}
                noValidate
                autoComplete="off"
              >
                {Object.entries(formDataPersonal).map(([key, value]) => (
                  <div key={key}>
                    <Label variant="subtitle2">{key}</Label>
                    <TextField
                      required
                      id={key}
                      value={value}
                      variant="standard"
                      disabled={!editablePersonal}
                      InputProps={{ style: { fontSize: "14px" } }}
                      onChange={(e) =>
                        handleFieldChangePersonal(key, e.target.value)
                      }
                    />
                    {personalFieldErrors[key] && (
                      <FormHelperText error>
                        {personalFieldErrors[key]}
                      </FormHelperText>
                    )}
                  </div>
                ))}
              </Box>
            </Box1>
            <Box1 sx={{}}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Label color="primary">Hospital Info</Label>
                {/* {editableHospital ? (
                  <Button
                    size="small"
                    variant="contained"
                    border="1px solid rgba(193, 32, 37, 1)"
                    paddingLeft="3px"
                    paddingRight="3px"
                    color="primary"
                    width="50px"
                    sx={{ cursor: "pointer" }}
                    onClick={handleSubmitHospital}>
                    Submit
                  </Button>
                ) : (
                  <Box onClick={handleEditHospital}>
                    <CiEdit size={20} cursor="pointer" />
                  </Box>
                )} */}
              </Stack>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "25ch" },
                  marginTop: "40px",
                  gap: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",

                  height: "65%",
                }}
                noValidate
                autoComplete="off"
              >
                {Object.entries(formDataHospital).map(([key, value]) => (
                  <div key={key}>
                    <Label variant="subtitle2">{key}</Label>
                    <TextField
                      required
                      id={key}
                      value={value}
                      variant="standard"
                      disabled={!editableHospital}
                      InputProps={{ style: { fontSize: "14px" } }}
                      onChange={(e) =>
                        handleFieldChangeHospital(key, e.target.value)
                      }
                    />
                    {hospitalFieldErrors[key] && (
                      <FormHelperText error>
                        {hospitalFieldErrors[key]}
                      </FormHelperText>
                    )}
                  </div>
                ))}
              </Box>
            </Box1>

            <Box2
              sx={{
                position: "relative",
                padding: "25px",
              }}
            >
              <Box
                component="img"
                src={profile}
                sx={{ width: "100%", height: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                sx={{
                  position: "absolute",
                  top: "85%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "250px",
                  zIndex: 10,
                }}
              >
                Log out
              </Button>
            </Box2>
          </StyledBox>
          <LoadingPage/>

        </>
        <>
          <AppBar position="fixed" id="navbar" sx={{ display: { xs: "flex",sm:"none", md: "none" } }}>
            <Navbar heading={"Profile"} />
          </AppBar>
          <Toolbar />
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"20px"}
            sx={{ paddingBottom: "90px" ,display: { xs: "flex",sm:"flex", md: "none" } }}
          >
            <Stack
              sx={{
                position: "relative",

                width: "100%",
              }}
            >
              <Box
                component="img"
                src={profile}
                sx={{ height: "200px", objectFit: "cover" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                sx={{
                  position: "absolute",
                  top: "75%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "200px",
                }}
              >
                Log out
              </Button>
            </Stack>
            <Box1
              sx={{
                boxShadow:
                  "10px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",

                border: "2px solid rgba(255, 255, 255, 1)",
              }}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Label color="primary">Personal Info</Label>

                {editablePersonal ? (
                  <Typography
                    variant="caption"
                    border="1px solid rgba(193, 32, 37, 1)"
                    paddingLeft="3px"
                    paddingRight="3px"
                    color="primary"
                    width="50px"
                    sx={{ cursor: "pointer" }}
                    onClick={handleSubmitPersonal}
                  >
                    Submit
                  </Typography>
                ) : (
                  <Box onClick={handleEditPersonal}>
                    <CiEdit size={20} cursor="pointer" />
                  </Box>
                )}
              </Stack>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "25ch" },
                  marginTop: "40px",
                  gap: "35px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",

                  height: "80%",
                }}
                noValidate
                autoComplete="off"
              >
                {Object.entries(formDataPersonal).map(([key, value]) => (
                  <div key={key}>
                    <TextField
                      required
                      id={key}
                      value={value}
                      variant="standard"
                      disabled={!editablePersonal}
                      InputProps={{
                        style: { fontSize: "14px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {fieldIcons[key]}
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        handleFieldChangePersonal(key, e.target.value)
                      }
                    />
                    {personalFieldErrors[key] && (
                      <FormHelperText error>
                        {personalFieldErrors[key]}
                      </FormHelperText>
                    )}
                  </div>
                ))}
              </Box>
            </Box1>
            <Box1
              sx={{
                boxShadow:
                  "10px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",

                border: "2px solid rgba(255, 255, 255, 1)",
              }}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Label color="primary">Hospital Info</Label>
                {editableHospital ? (
                  <Typography
                    variant="caption"
                    border="1px solid rgba(193, 32, 37, 1)"
                    paddingLeft="3px"
                    paddingRight="3px"
                    color="primary"
                    width="50px"
                    sx={{ cursor: "pointer" }}
                    onClick={handleSubmitHospital}
                  >
                    Submit
                  </Typography>
                ) : (
                  <Box onClick={handleEditHospital}>
                    <CiEdit size={20} cursor="pointer" />
                  </Box>
                )}
              </Stack>
              <Dialog open={open} onClose={handleClose}>
                <Stack
                  justifyContent="space-around"
                  alignItems="center"
                  sx={{ height: "200px" }}
                >
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      color: "rgba(193, 32, 37, 1)",
                    }}
                  >
                    <HiExclamationCircle size={25} />
                    Log Out
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ textAlign: "center", color: "black" }}
                    >
                      <Typography variant="body2">
                        Are you sure you want to Logout?
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="primary"
                      sx={{ width: "50%", height: "45px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleClicksignin}
                      color="error"
                      sx={{ width: "50%", height: "45px" }}
                    >
                      Log Out
                    </Button>
                  </DialogActions>
                </Stack>
              </Dialog>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "25ch" },
                  marginTop: "40px",
                  gap: "35px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",

                  height: "65%",
                }}
                noValidate
                autoComplete="off"
              >
                {Object.entries(formDataHospital).map(([key, value]) => (
                  <div key={key}>
                    <TextField
                      required
                      id={key}
                      value={value}
                      variant="standard"
                      disabled={!editableHospital}
                      InputProps={{
                        style: { fontSize: "14px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            {fieldIconsHospital[key]}
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        handleFieldChangeHospital(key, e.target.value)
                      }
                    />
                    {hospitalFieldErrors[key] && (
                      <FormHelperText error>
                        {hospitalFieldErrors[key]}
                      </FormHelperText>
                    )}
                  </div>
                ))}
              </Box>
            </Box1>
          </Stack>
          <Stack sx={{ display: { xs: "flex",sm:"none", md: "none" } }}><Footer /></Stack>
          
        </>
    </>
  );
};

export default Profile;
