import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading_page from "../components/Loading_Page"; // Make sure to import your loading component
import ReloginModal from "../components/Relogin";
import Profile from "../pages/Profile";
import Contact from "../pages/Contact";
import MyOrder from "../pages/MyOrder";
import NewOrder from "../pages/NewOrder/NewOrder";
import OrderSummary from "../pages/OrderSummary/OrderSummary";
import EditOrder from "../pages/OrderSummary/EditOrder";
import Transaction from "../pages/Transaction/Transaction";
import OrderTracking from "../pages/OrderTracking/OrderTracking";
import OnBoarding from "../pages/OnBoarding/OnBoarding";
import EditNewOrder from "../pages/NewOrder/EditNewOrder";
import Home from "../pages/CommonComponent/Home";

function PrivateRoute() {
  const [loading, setLoading] = useState(false);
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  const invalidToken = useSelector((store) => store?.data?.invalidToken);

  useEffect(() => {
    const onRouteChange = () => {
      setLoading(true);
    };

    // Event listener for route changes (use this if needed for manual route change handling)
    const handleNavigationStart = () => {
      setLoading(true);
    };
    
    const handleNavigationEnd = () => {
      setLoading(false);
    };

    // You can hook into browser's navigation events
    window.addEventListener("beforeunload", handleNavigationStart);
    window.addEventListener("load", handleNavigationEnd);

    return () => {
      window.removeEventListener("beforeunload", handleNavigationStart);
      window.removeEventListener("load", handleNavigationEnd);
    };
  }, []);

  return (
    <>
      {invalidToken === "INVALID_TOKEN" ? (
        <ReloginModal status={invalidToken} />
      ) : (
        <>
          {userData && (
            <>
              {/* Show loading page when loading state is true */}
              <Loading_page loading={loading} />

              <Routes>
                <Route path="*" element={<OnBoarding />} />
                <Route path="/" element={<OnBoarding />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/my-order" element={<MyOrder />} />
                <Route path="/new-order" element={<NewOrder />} />
                <Route path="/order-summary/:id" element={<OrderSummary />} />
                <Route path="/edit-order/:id" element={<EditOrder />} />
                <Route path="/transaction" element={<Transaction />} />
                <Route path="/order-tracking/" element={<OrderTracking />} />
                <Route path="/edit-order" element={<EditNewOrder />} />
                <Route path="/home" element={<Home />} />
              </Routes>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PrivateRoute;
