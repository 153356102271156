import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Loading_page from "../../components/Loading_Page";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MdDeleteForever } from "react-icons/md";
import { BsClipboard2Check } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  AppBar,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  Modal,
  Popover,
  Stack,
  TablePagination,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cancelOrder, orderDetailById } from "../../redux/data/action";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

import TopBar from "../CommonComponent/TopBar";
import EmptyPage from "../CommonComponent/EmptyPage";
import { BiCommentDetail } from "react-icons/bi";
import { RiEdit2Fill } from "react-icons/ri";
import Footer from "../../MobileView/Footer";
import Navbar from "../../MobileView/Navbar";
import { FaPlus } from "react-icons/fa6";
import DrawerBox from "../CommonComponent/Drawer";
import DeleteButton from "../CommonComponent/DeleteButton";
import NumberFormat from "../../components/NumberFormat";
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",
}));

const Box2 = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  display: "flex",
  width: "80%",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",

  height: "621px",
  justifyContent: "space-between",
  gap: "20px",
}));

const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "200px",
  height: "380px",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));
const LeftBox2 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "200px",
  height: "210px",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));
const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "start",

  flexDirection: "column",

  width: "80%",
  height: "90%",
  textAlign: "start",
  borderRadius: "2px ",
  justifyContent: "space-between",
}));
const ButtonBox = styled(Button)(({ theme }) => ({
  width: "40px",
  height: "40px",
  minWidth: 0,
  padding: 0,
  borderRadius: "10px",
  boxShadow: "0px 10px 20px 0px rgba(230, 210, 209, 0.6)",
}));
const Label3 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  color: "rgba(146, 146, 146, 1)",
}));

const OrderSummary = () => {
  const allOrderList = useSelector((store) => store.data.orderDetailById);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [filteredData, setFilteredData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const response = useSelector((store) => store.data.orderCancel);
  const branchData = JSON.parse(localStorage.getItem("branchData"));
  /////console.log("response gui", response);
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));

  const params = useParams();
  const [data, setData] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [commentValue, setCommentValue] = useState("");

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [scrollableHeight, setScrollableHeight] = useState(0);

  useEffect(() => {
    const calculateScrollableHeight = () => {
      const navbar = document.getElementById("navbar");
      const searchBar = document.getElementById("search-bar");
      const heading = document.getElementById("heading");
      const footer = document.getElementById("footer");

      if (navbar && searchBar && heading && footer) {
        const navbarHeight = navbar.offsetHeight;
        const searchBarHeight = searchBar.offsetHeight;
        const headingHeight = heading.offsetHeight;
        const footerHeight = footer.offsetHeight;

        const windowHeight = window.innerHeight;
        const remainingHeight =
          windowHeight -
          navbarHeight -
          searchBarHeight -
          headingHeight -
          footerHeight -
          50;

        setScrollableHeight(remainingHeight);
      }
    };

    calculateScrollableHeight();

    const handleResize = () => {
      calculateScrollableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData]);

  const handlePopoverOpen = (event, productId, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
    setCommentValue(comment);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedProductId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (Object.keys(allOrderList)?.length > 0 && allOrderList !== 0) {
      setData(allOrderList.products);
      setOrderDetail(allOrderList.orderdetails);
    }
  }, [allOrderList]);

  useEffect(() => {
    const requestData = {
      orderid: params.id,
      userid: userData.userid,
      branchid: branchData.branchId,
    };

    dispatch(orderDetailById(requestData));
  }, [dispatch]);

  const handleDelete = () => {
    const requestData = {
      orderid: params.id,
      userid: userData.userid,
      branchid: branchData.branchId,
    };
    dispatch(cancelOrder(requestData));
    setOpenDelete(false);
    navigate("/my-order");
  };
  ///////console.log("data", data);
  ///////console.log("filter", filteredData);
  const handleOpenDelete = (row) => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  return (
    <>
      {!isMobileScreen ? (
        <>
          <DrawerBox
            data={data}
            setData={setData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            heading={"Order Summary"}
            Filter1="category"
            Filter2="brand"
            sortPrice="curr_price"
            sortDate=""
            name="product"
          />

          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              alignItems: "start",
            }}
          >
            <LeftWrapper>
              <LeftBox1>
                <LeftBox>
                  <Label>ORDER DETAILS</Label>

                  <Stack>
                    <Label variant="caption" style={{}}>
                      ORDER ID
                    </Label>

                    <Typography
                      sx={{ wordBreak: "break-word", fontSize: "small" }}
                    >
                      {params.id}
                    </Typography>
                    <Label2 style={{ fontSize: "small" }}></Label2>
                  </Stack>
                  <Stack>
                    <Label variant="caption" style={{}}>
                      DATE & TIME
                    </Label>
                    <Label2 style={{ fontSize: "small" }}>
                      {orderDetail.date} at {orderDetail.time}
                    </Label2>
                  </Stack>
                  <Stack>
                    <Label variant="caption" style={{}}>
                      TOTAL VALUE
                    </Label>
                    <Label2 style={{ fontSize: "small" }}>
                      ₹<NumberFormat number={orderDetail.totprice} />
                    </Label2>
                  </Stack>
                  <Stack>
                    <Label variant="caption" style={{}}>
                      TOTAL ITEMS
                    </Label>
                    <Label2 style={{ fontSize: "small" }}>
                      {orderDetail.totproduct} nos
                    </Label2>
                  </Stack>

                  <Stack
                    flexDirection="column"
                    justifyContent="flex-end"
                    sx={{
                      width: "100%",

                      alignItems: "flex-end",
                      color: "rgba(255, 168, 0, 1)",
                    }}
                  >
                    <Label variant="h6" style={{}}>
                      {parseFloat(orderDetail.totsavings_percent).toFixed(2)}%
                    </Label>
                    <Label variant="body2" style={{}}>
                      Saved
                    </Label>
                  </Stack>
                </LeftBox>
              </LeftBox1>
              <LeftBox2>
                <Label variant="button" sx={{ marginTop: "10px" }}>
                  Order status
                </Label>
                <Stack
                  sx={{
                    width: "100%",

                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80%",
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      textAlign: "start",
                      gap: "20px",
                    }}
                  >
                    {orderDetail.orderstatus === "Cancelled" ? (
                      <Box2>
                        <Stack
                          flexDirection={"row"}
                          gap={"20px"}
                          sx={{ width: "140px" }}
                        >
                          <HiOutlineClipboardCheck
                            size={20}
                            style={{ color: "rgba(193, 32, 37, 1)" }}
                          />
                          <Label2 variant="caption">Order Cancelled</Label2>
                        </Stack>
                        <IoIosCloseCircle
                          size={20}
                          style={{ color: "rgba(255, 0, 0, 1)" }}
                        />
                      </Box2>
                    ) : (
                      <>
                        <Box2>
                          <Stack
                            flexDirection={"row"}
                            gap={"20px"}
                            sx={{ width: "140px" }}
                          >
                            <HiOutlineClipboardCheck
                              size={20}
                              style={{ color: "rgba(193, 32, 37, 1)" }}
                            />
                            <Label2 variant="caption">Order Approved</Label2>
                          </Stack>
                          {orderDetail.orderstatus !== "Processing" ? (
                            <IoIosCheckmarkCircle
                              size={20}
                              style={{ color: "rgba(54, 201, 2, 1)" }}
                            />
                          ) : (
                            <IoIosCloseCircle
                              size={20}
                              style={{ color: "rgba(255, 0, 0, 1)" }}
                            />
                          )}
                        </Box2>
                        <Box2>
                          <Stack
                            flexDirection={"row"}
                            gap={"20px"}
                            sx={{ width: "140px" }}
                          >
                            <FaTruck
                              size={20}
                              style={{ color: "rgba(193, 32, 37, 1)" }}
                            />
                            <Label2 variant="caption">Shipped</Label2>
                          </Stack>
                          {orderDetail.orderstatus === "Shipped" ||
                          orderDetail.orderstatus === "Delivered" ? (
                            <IoIosCheckmarkCircle
                              size={20}
                              style={{ color: "rgba(54, 201, 2, 1)" }}
                            />
                          ) : (
                            <IoIosCloseCircle
                              size={20}
                              style={{ color: "rgba(255, 0, 0, 1)" }}
                            />
                          )}
                        </Box2>
                        <Box2>
                          <Stack
                            flexDirection={"row"}
                            gap={"20px"}
                            sx={{ width: "140px" }}
                          >
                            <TiTick
                              size={20}
                              style={{ color: "rgba(193, 32, 37, 1)" }}
                            />
                            <Label2 variant="caption">Delivered</Label2>
                          </Stack>
                          {orderDetail.orderstatus === "Delivered" ? (
                            <IoIosCheckmarkCircle
                              size={20}
                              style={{ color: "rgba(54, 201, 2, 1)" }}
                            />
                          ) : (
                            <IoIosCloseCircle
                              size={20}
                              style={{ color: "rgba(255, 0, 0, 1)" }}
                            />
                          )}
                        </Box2>
                      </>
                    )}

                    <Stack sx={{}}>
                      <Button
                        variant="outlined"
                        sx={{ width: "133px" }}
                        onClick={() =>
                          navigate("/order-tracking", { state: orderDetail })
                        }
                      >
                        Track Order
                      </Button>
                    </Stack>
                  </List>
                </Stack>
              </LeftBox2>
            </LeftWrapper>
            <Grid container >
              <Grid item xs={12} >
                <Paper
                  sx={{
                    height:
                      data?.length > 0 && filteredData?.length > 0
                        ? "621px"
                        : "621px",
                    maxHeight: "621px",
                    display: "flex",
                    flexDirection: "column",

                    boxShadow:
                      "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  }}
                >
                  {/* <React.Fragment>
                    {data?.length > 0 && filteredData?.length > 0 && (
                      <>
                        {" "}
                        <Table size="small" style={{}}>
                          <TableHead>
                            <TableRow>
                              <TableHeading>
                                <Label variant="caption"> PROD.NAME</Label>
                              </TableHeading>

                              <TableHeading>
                                <Label variant="caption"> BRAND</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption">HSN</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> CATEGORY</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> PKG SIZE</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> PRICE</Label>
                              </TableHeading>

                              <TableHeading
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                {" "}
                                <Label variant="caption"> SAVINGS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> QTY</Label>
                              </TableHeading>

                              <TableHeading></TableHeading>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => (
                                <TableRow
                                  key={row.productid}
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "rgba(255, 237, 237, 1)",
                                    },
                                  }}
                                  style={{
                                    boxShadow:
                                      "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                  }}
                                >
                                  <TableRows>
                                    {row.product?.length > 11 ? (
                                      <Tooltip title={row.product}>
                                        <TableText
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {row.product}
                                        </TableText>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title={row.product}>
                                        <TableText
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {row.product}
                                        </TableText>
                                      </Tooltip>
                                    )}
                                  </TableRows>

                                  <TableRows>
                                    <Tooltip title={row.brand}>
                                      <TableText variant="caption">
                                        {row.brand}
                                      </TableText>
                                    </Tooltip>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.hsn}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <Tooltip title={row.category}>
                                      <TableText variant="caption">
                                        {" "}
                                        {row.category}
                                      </TableText>
                                    </Tooltip>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.packsize}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      ₹<NumberFormat number={row.curr_price} />
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      ₹<NumberFormat number={row.savings} />
                                    </TableText>
                                  </TableRows>

                                  <TableRows
                                    style={{
                                      border: "1px solid white",
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    <TableText variant="caption">
                                      <TableText variant="caption">
                                        {row.quantity}
                                      </TableText>
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.comment === "" ? (
                                        <Typography></Typography>
                                      ) : (
                                        <BiCommentDetail
                                          size={20}
                                          onClick={(event) =>
                                            handlePopoverOpen(
                                              event,
                                              row.productid,
                                              row.comment
                                            )
                                          }
                                        />
                                      )}

                                      <Popover
                                        open={
                                          isPopoverOpen &&
                                          selectedProductId === row.productid
                                        }
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            flexDirection: "column",
                                            width: "200px",
                                            minHeight: "50px",
                                            padding: "10px",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            sx={{ p: 2 }}
                                          >
                                            {row.comment}
                                          </Typography>
                                        </Box>
                                      </Popover>
                                    </TableText>
                                  </TableRows>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {filteredData?.length > rowsPerPage && (
                          <TablePagination
                            sx={{ width: "95%" }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )}
                      </>
                    )}

                    {data &&
                      data.length === 0 &&
                      filteredData?.length === 0 &&
                      allOrderList && <EmptyPage />}
                  </React.Fragment> */}

                  <React.Fragment >
                    {data?.length > 0 && filteredData?.length > 0 ? (
                      <>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableHeading>
                                <Label variant="caption"> PROD.NAME</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> BRAND</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption">HSN</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> CATEGORY</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> PKG SIZE</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> PRICE</Label>
                              </TableHeading>
                              <TableHeading
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                <Label variant="caption"> SAVINGS</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> QTY</Label>
                              </TableHeading>
                              <TableHeading></TableHeading>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => (
                                <TableRow
                                  key={row.productid}
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "rgba(255, 237, 237, 1)",
                                    },
                                  }}
                                  style={{
                                    boxShadow:
                                      "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                  }}
                                >
                                  <TableRows>
                                    {row.product?.length > 11 ? (
                                      <Tooltip title={row.product}>
                                        <TableText
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {row.product}
                                        </TableText>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title={row.product}>
                                        <TableText
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {row.product}
                                        </TableText>
                                      </Tooltip>
                                    )}
                                  </TableRows>

                                  <TableRows>
                                    <Tooltip title={row.brand}>
                                      <TableText variant="caption">
                                        {row.brand}
                                      </TableText>
                                    </Tooltip>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.hsn}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <Tooltip title={row.category}>
                                      <TableText variant="caption">
                                        {" "}
                                        {row.category}
                                      </TableText>
                                    </Tooltip>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.packsize}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      ₹<NumberFormat number={row.curr_price} />
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      ₹<NumberFormat number={row.savings} />
                                    </TableText>
                                  </TableRows>

                                  <TableRows
                                    style={{
                                      border: "1px solid white",
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    <TableText variant="caption">
                                      <TableText variant="caption">
                                        {row.quantity}
                                      </TableText>
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.comment === "" ? (
                                        <Typography></Typography>
                                      ) : (
                                        <BiCommentDetail
                                          size={20}
                                          onClick={(event) =>
                                            handlePopoverOpen(
                                              event,
                                              row.productid,
                                              row.comment
                                            )
                                          }
                                        />
                                      )}

                                      <Popover
                                        open={
                                          isPopoverOpen &&
                                          selectedProductId === row.productid
                                        }
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            flexDirection: "column",
                                            width: "200px",
                                            minHeight: "50px",
                                            padding: "10px",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            sx={{ p: 2 }}
                                          >
                                            {row.comment}
                                          </Typography>
                                        </Box>
                                      </Popover>
                                    </TableText>
                                  </TableRows>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>

                        {filteredData.length > rowsPerPage && (
                          <TablePagination
                            sx={{ width: "95%" }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )}
                      </>
                    ) : (
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ position: "relative", top: "50%" }}
                      >
                        No Product Found
                      </Typography>
                    )}

                    {/* {data && data.length === 0 && filteredData?.length === 0 && allOrderList && <EmptyPage />} */}
                  </React.Fragment>
                </Paper>
              </Grid>
            </Grid>
          </StyledBox>
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",

              alignItems: "center",
            }}
          >
            <Stack
              gap="20px"
              flexDirection={"row"}
              sx={{ lignItems: "center" }}
            >
              <Button
                variant="outlined"
                sx={{ width: "200px" }}
                onClick={() => navigate("/my-order")}
              >
                All Orders
              </Button>
              {orderDetail.orderstatus === "Processing" && (
                <>
                  <Link to={`/edit-order/${params.id}`}>
                    <Button variant="outlined" sx={{ width: "200px" }}>
                      Edit Order
                    </Button>
                  </Link>
                  <Stack
                    onClick={handleOpenDelete}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      border: "1px solid rgba(193, 32, 37, 1)",
                      width: "35px",
                      height: "37px",
                      borderRadius: "3px ",
                    }}
                  >
                    <MdDeleteForever
                      size={25}
                      style={{
                        color: "rgba(193, 32, 37, 1)",
                        cursor: "pointer",
                      }}
                    />
                  </Stack>
                </>
              )}
            </Stack>

            <Button
              variant="contained"
              sx={{ width: "200px" }}
              onClick={() => navigate("/new-order")}
            >
              New Order
            </Button>
          </StyledBox>
        </>
      ) : (
        <>
          <div>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"Order Summary"} />
            </AppBar>
            <Toolbar />
            <Box sx={{}}>
              <Stack
                sx={{
                  backgroundColor: "#f0f0f0",

                  position: "fixed",

                  zIndex: 1,

                  paddingTop: "20px",
                  paddingBottom: "20px",
                  bgcolor: "rgba(241, 241, 241, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
                id="search-bar"
              >
                <Stack
                  sx={{
                    bgcolor: "white",
                    border: "2px solid rgba(255, 255, 255, 1)",
                    height: "110px",
                    width: "283px",
                    boxShadow:
                      "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",

                    borderRadius: "10px",
                    gap: "20px",
                  }}
                >
                  <Stack sx={{ padding: "15px", gap: "10px" }}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      sx={{}}
                    >
                      {orderDetail.orderid &&
                      orderDetail.orderid?.length > 11 ? (
                        <Tooltip title={orderDetail.orderid}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              maxWidth: "162px",

                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontWeight: 700,
                            }}
                          >
                            {orderDetail.orderid.slice(0, 24) + "..."}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          style={{
                            // maxWidth: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                          }}
                        >
                          {orderDetail.orderid}
                        </Typography>
                      )}

                      <TopBar
                        data={data}
                        setData={setData}
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                        heading={"Order Summary"}
                      />
                    </Stack>

                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Stack sx={{}}>
                        <Label3 variant="subtitle2">{orderDetail.date}</Label3>
                        <Label3 variant="subtitle2">{orderDetail.time}</Label3>
                      </Stack>

                      <Stack>
                        <Label3 variant="subtitle2">
                          ₹<NumberFormat number={orderDetail.totprice} />
                        </Label3>
                        <Label3 variant="subtitle2">
                          {orderDetail.totproduct} nos
                        </Label3>
                      </Stack>
                      <Stack>
                        <Label3
                          variant="subtitle1"
                          style={{ color: "rgba(255, 153, 0, 1)" }}
                        >
                          {orderDetail.totsavings_percent} %
                        </Label3>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  sx={{ width: "283px" }}
                >
                  <Stack
                    flexDirection={"row"}
                    sx={{
                      bgcolor: "white",
                      border: "2px solid rgba(255, 255, 255, 1)",
                      height: "40px",
                      width: "183px",
                      boxShadow:
                        "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",

                      borderRadius: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Label variant="subtitle2">
                      <BsClipboard2Check /> Order {orderDetail.orderstatus}
                    </Label>
                  </Stack>

                  <ButtonBox variant="contained">
                    <FaPlus size={17} />
                  </ButtonBox>
                </Stack>
              </Stack>
              <Box sx={{ paddingTop: "180px" }}>
                <Stack
                  id="heading"
                  sx={{
                    padding: "0 20px",
                    marginTop: "37px",
                  }}
                  flexDirection={"column"}
                >
                  <Stack
                    sx={{
                      justifyContent: "space-between",
                      flexDirection: "row",

                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Label sx={{ color: "#B7B7B7" }}>Products</Label>

                    <Link to={`/edit-order/${params.id}`}>
                      {orderDetail.orderstatus === "Processing" && (
                        <IconButton>
                          <RiEdit2Fill style={{ color: "#878787" }} />
                        </IconButton>
                      )}
                    </Link>
                  </Stack>
                  <Divider />
                </Stack>

                <Paper
                  sx={{
                    height: scrollableHeight,
                    overflowY: "auto",
                    boxShadow: "none",
                  }}
                >
                  <Stack sx={{ overflowY: "auto" }}>
                    <Stack
                      sx={{
                        padding: "20px",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      {filteredData?.map((item) => (
                        <Stack
                          sx={{
                            borderRadius: "10px",
                            height: "100px",
                            gap: "20px",
                            boxShadow:
                              " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                            padding: "15px",
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Label>{item.product}</Label>
                            <Label>{item.brand}</Label>
                          </Stack>

                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Stack>
                              <Label3 variant="subtitle2">
                                ₹<NumberFormat number={item.curr_price} />
                              </Label3>
                              <Label3 variant="subtitle2">
                                {item.category}
                              </Label3>
                            </Stack>
                            <Stack>
                              <Label3 variant="subtitle2">
                                {item.quantity} nos
                              </Label3>
                              <Label3 variant="subtitle2">
                                {item.packsize}/pkg
                              </Label3>
                            </Stack>
                            <Stack>
                              <Label
                                variant="subtitle2"
                                sx={{ color: "rgba(255, 153, 0, 1)" }}
                              >
                                {item.savings}%
                              </Label>
                              <Label3 variant="subtitle2">{item.hsn}</Label3>
                            </Stack>
                            <Stack>
                              <Label3 variant="subtitle2">
                                {item.comment === "" ? (
                                  <Typography></Typography>
                                ) : (
                                  <BiCommentDetail
                                    size={20}
                                    onClick={(event) =>
                                      handlePopoverOpen(
                                        event,
                                        item.productid,
                                        item.comment
                                      )
                                    }
                                  />
                                )}

                                <Popover
                                  open={
                                    isPopoverOpen &&
                                    selectedProductId === item.productid
                                  }
                                  anchorEl={anchorEl}
                                  onClose={handlePopoverClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      flexDirection: "column",
                                      width: "200px",
                                      minHeight: "50px",
                                      padding: "10px",
                                    }}
                                  >
                                    <Typography variant="body2" sx={{ p: 2 }}>
                                      {item.comment}
                                    </Typography>
                                  </Box>
                                </Popover>
                              </Label3>
                            </Stack>
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              </Box>
            </Box>

            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
              id="footer"
            >
              <Footer />
            </AppBar>
          </div>
        </>
      )}

      <div>
        {/* <Button variant="contained" onClick={handleDelete}>
          Cancel Order
        </Button> */}

        {/* <Popover
         
          open={response === true}
         
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
        </Popover> */}
        {/* <Modal open={apiError !== null}>
          <div>{apiError}</div>
        </Modal> */}
      </div>

      <DeleteButton
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDelete={handleDelete}
      />
      <Loading_page />
    </>
  );
};

export default OrderSummary;
