import React from "react";


import MainRoutes from "./routes/MainRoutes";
import ChatBot from "./components/ChatBot";

function App() {
  return (
    <>
      <ChatBot />
      <MainRoutes />

      {/* <CalenderCheck/> */}
    </>
  );
}

export default App;
