import React from "react";
import HomePage from "../components/HomePage";
import Dashboard from "../components/Dashboard";

const MainRoutes = () => {
  const token = localStorage.getItem("AstraToken");
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));

  return (
    <div>
      {token && userData ? (
        <>
          <Dashboard />
        </>
      ) : (
        <>
          <HomePage />
        </>
      )}
    </div>
  );
};

export default MainRoutes;
