import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import { FiPlusSquare } from "react-icons/fi";
import { MdInsertComment } from "react-icons/md";
import { CiSquareCheck } from "react-icons/ci";
import {
  AppBar,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItemText,
  Paper,
  Popover,
  Stack,
  TablePagination,
  TextField,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { LuSettings2 } from "react-icons/lu";
import InputBase from "@mui/material/InputBase";
import { RiSearchLine } from "react-icons/ri";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newOrder, placeOrder } from "../../redux/data/action";
import { useNavigate } from "react-router-dom";
import { BiCommentDetail } from "react-icons/bi";
import { RiAddBoxFill } from "react-icons/ri";

import { IoIosAdd } from "react-icons/io";
import { IoIosRemove } from "react-icons/io";
import RequestProduct from "./RequestProduct";
import SeeOrder from "./SeeOrder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { useMemo } from "react";
import { useTheme } from "@emotion/react";
import Navbar from "../../MobileView/Navbar";
import DrawerBox from "../CommonComponent/Drawer";
import { MdDelete } from "react-icons/md";
import NumberFormat from "../../components/NumberFormat";
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",
}));

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Label3 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,

  cursor: "pointer",
  fontSize: "small",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",

  minHeight: "621px",
  justifyContent: "space-between",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  textAlign: "start",
  width: "200px",
  minHeight: "543px",
  borderRadius: "10px",
  paddingTop: "10px",
  paddingBottom: "10px",
  justifyContent: "space-between",
  gap: "20px",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "start",

  flexDirection: "column",

  width: "70%",
  gap: "13px",

  textAlign: "start",
  borderRadius: "2px ",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const Search2 = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "80%",
  // height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const Search3 = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper2 = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase2 = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const appStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  background: "white",
};

const headerStyle = {
  zIndex: 1100, // or any value to place it above other elements
  position: "sticky",
  top: 0,
};

const searchContainerStyle = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "2px 4px",
  borderRadius: 4,
  margin: "20px auto",
  maxWidth: 600,
  zIndex: 1100,
  position: "sticky",
  top: 64, // Adjust based on your app bar height
};
const searchInputStyle = {
  marginLeft: 1,
  flex: 1,
};

const contentStyle = {
  flexGrow: 1,
  overflowY: "auto",
  paddingTop: 16, // Adjust as needed
  paddingBottom: 16, // Adjust as needed
};

const footerStyle = {
  backgroundColor: "#fff", // Adjust as needed
  boxShadow:
    "0px 10px 20px 0px rgb(145 136 136 / 60%), 5px 5px 15px 2px rgba(230, 210, 209, 0.3)",
  position: "sticky",
  bottom: 0,

  height: "156px",
};

const EditNewOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let branch = JSON.parse(localStorage.getItem("branchData"));
  let branchData = JSON.parse(localStorage.getItem("branchData"));
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  const [open, setOpen] = React.useState(false);
  const datas = JSON.parse(localStorage.getItem("savedData")) || [];

  ///////console.log("datas", datas);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const retrievedDatas = JSON.parse(localStorage.getItem("savedData"));
  const [openSubmission, setOpenSubmission] = useState(false);
  const [data, setData] = useState([]);
  ///////console.log("retrievedDatas", retrievedDatas);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [commentValue, setCommentValue] = useState("");
  /////console.log("commentValue", commentValue);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [quantities, setQuantities] = useState([]);

  const [comments, setComment] = useState([]);
  /////console.log("quantities", quantities);

  const handlePopoverOpen = (event, productId, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
    setCommentValue(comment);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedProductId(null);
  };

  const handleSaveComment = () => {
    const index = data.findIndex(
      (item) => item.productid === selectedProductId
    );
    if (index !== -1) {
      const updatedComments = [...comments];
      updatedComments[index] = commentValue;
      setComment(updatedComments);
      handlePopoverClose();
    }
  };

  useEffect(() => {
    setQuantities(data.map((item) => parseInt(item.quantity)));
    setComment(data.map((item) => item.comment));
  }, [data]);

  /////console.log("comments", comments);

  const handleIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] += 1;
    setQuantities(updatedQuantities);
  };

  const handleDecrement = (index) => {
    if (quantities[index] > 0) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] -= 1;
      setQuantities(updatedQuantities);
    }
  };
  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = parseInt(value) || 0;
    setQuantities(updatedQuantities);
  };

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
    // navigate(-1);
    localStorage.removeItem("savedData");
    setOpen(false);
    dispatch(newOrder());
    navigate("/new-order");
  };
  let totalProducts = 0;
  let subtotal = 0;
  let totalCGST = 0;
  let totalSGST = 0;

  retrievedDatas &&
    retrievedDatas?.forEach((product) => {
      totalProducts = retrievedDatas?.length;
      subtotal += parseFloat(product.curr_price) * product.quantity;
      totalCGST +=
        (parseFloat(product.cgst) / 100) *
        (parseFloat(product.curr_price) * product.quantity);
      totalSGST +=
        (parseFloat(product.sgst) / 100) *
        (parseFloat(product.curr_price) * product.quantity);
    });

  const grandTotal = subtotal + totalCGST + totalSGST;

  useEffect(() => {
    const datas = JSON.parse(localStorage.getItem("savedData")) || [];

    if (Array.isArray(datas) && datas?.length !== 0) {
      const initialQuantities = datas.map((product) => product.quantity);
      setQuantities(initialQuantities);

      setData(datas);
    }
  }, [open]);
  const calculateSubtotal = () => {
    let subtotal = 0;
    datas &&
      datas.forEach((product, index) => {
        subtotal += parseFloat(product.curr_price) * quantities[index];
      });
    return subtotal;
  };

  const calculateTotalCGST = () => {
    let totalCGST = 0;
    datas &&
      datas.forEach((product, index) => {
        totalCGST +=
          (parseFloat(product.cgst) / 100) *
          (parseFloat(product.curr_price) * quantities[index]);
      });
    return totalCGST;
  };

  const calculateTotalSGST = () => {
    let totalSGST = 0;
    datas &&
      datas.forEach((product, index) => {
        totalSGST +=
          (parseFloat(product.sgst) / 100) *
          (parseFloat(product.curr_price) * quantities[index]);
      });
    return totalSGST;
  };

  const calculateGrandTotal = () => {
    return calculateSubtotal() + calculateTotalCGST() + calculateTotalSGST();
  };

  const totalQuantities = quantities.reduce((acc, val) => acc + val, 0);

  const totalPrevPrice = data.reduce(
    (total, product, index) => total + product.prev_price * quantities[index],
    0
  );
  const totalPrice = calculateGrandTotal();
  const totalSavingPrice = totalPrevPrice - totalPrice;
  const totalSavingPercentage = (
    (totalSavingPrice / totalPrevPrice) *
    100
  ).toFixed(2);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalCurrentPrice, setTotalCurrentPrice] = useState(0);

  useEffect(() => {
    let quantitySum = 0;
    let priceSum = 0;

    datas?.forEach((item) => {
      quantitySum += item?.quantity;
      priceSum += parseInt(item?.curr_price) * item?.quantity;
    });

    setTotalQuantity(quantitySum);
    setTotalCurrentPrice(priceSum);
  }, []);

  const handleDeleteRow = (productId) => {
    const index = datas.findIndex((item) => item.productid === productId);
    if (index !== -1) {
      const updatedDatas = [...datas];

      updatedDatas.splice(index, 1);

      setData(updatedDatas);

      localStorage.setItem("savedData", JSON.stringify(updatedDatas));
      dispatch(newOrder());
    }
  };

  const handleSubmit = () => {
    let productslist = datas.map((item, index) => ({
      productid: item.productid,
      quantity: String(quantities[index]),
      savings: item.savings,
      comment: comments[index],

      product: item.product,
      hsn: item.hsn,
      brand: item.brand,
      category: item.category,
      packsize: item.packsize,

      prev_price: item.prev_price,
      curr_price: item.curr_price,
      cgst: item.cgst,
      sgst: item.sgst,
    }));
    const requestData = {
      userid: userData.userid,
      username: userData.name,
      userrole: userData.role,
      hospid: userData.hospid,
      hospname: userData.hospname,
      branch: branchData.branchName,
      branchid: branchData.branchId,
      totproduct: totalProducts,
      totquantity: totalQuantities,
      totprevprice: totalPrevPrice,

      totgst: totalCGST,
      subtot: subtotal,
      totprice: totalPrice,
      totsavings: totalSavingPrice,
      totsavings_percent: totalSavingPercentage,
      productslist: productslist,
    };

    dispatch(placeOrder(requestData));
    setOpenSubmission(true);
    setTimeout(() => {
      handleCloseSubmission();
    }, 2000);
  };
  return (
    <>
      <div style={appStyle}>
        {/* <Navbar heading={"Edit Order"} style={headerStyle} /> */}
        <AppBar position="fixed" id="navbar">
          <Navbar heading={"Edit Order"} />
        </AppBar>
        <Toolbar />

        <Container component="main" style={contentStyle}>
          <Box mt={4}>
            {data.map((item, index) => (
              <>
                <Stack
                  sx={{
                    flexDirection: "row",
                    // padding: "10px",
                    marginBottom: "10px",
                    bgcolor: "white",
                    minHeight: "110px",
                    marginTop: "4%",
                    width: "98%",
                    gap: "10px",
                  }}>
                  <Stack
                    sx={{
                      width: "90%",
                      padding: "10px",
                      gap: "10px",
                      borderRadius: "10px",
                      boxShadow: " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                      bgcolor: "white",
                      justifyContent: "space-around",
                    }}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}>
                      <Label
                        variant="subtitle2"
                        style={{
                          maxWidth: "11ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textDecoration: "none",
                        }}>
                        {item.product}
                      </Label>
                      <Label
                        variant="subtitle2"
                        sx={{ color: "rgba(255, 153, 0, 1)" }}>
                        {item.brand}
                      </Label>
                    </Stack>

                    <>
                      <Stack
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        sx={{}}>
                        <Label3 variant="caption">₹{item.curr_price}</Label3>
                        <Label3 variant="caption">{item.packsize}/pkg</Label3>
                        <Label3 variant="caption">{item.hsn}</Label3>
                      </Stack>
                      <Stack
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        sx={{}}>
                        <Label3 variant="caption">{item.cgst}% cgst</Label3>
                        <Label3 variant="caption">{item.sgst}% sgst</Label3>
                      </Stack>
                      <Stack
                        sx={{
                          // bgcolor: "rgba(255, 246, 246, 1)",
                          justifyContent: "space-between",
                        }}
                        flexDirection={"row"}>
                        <Button
                          variant="outlined"
                          sx={{
                            color: "rgba(255, 246, 246, 1)",
                            height: "100%",
                            width: "109px",
                            border: "1px solid rgba(193, 32, 37, 1)",
                          }}
                          onClick={(event) =>
                            handlePopoverOpen(
                              event,
                              item.productid,
                              item.comment
                            )
                          }>
                          <MdInsertComment
                            style={{ color: "rgba(193, 32, 37, 1)" }}
                          />
                        </Button>

                        <Popover
                          open={
                            isPopoverOpen &&
                            selectedProductId === item.productid
                          }
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexDirection: "column",
                              width: "200px",
                              minHeight: "100px",
                              padding: "10px",
                              bgcolor: "rgba(255, 246, 246, 1)",
                            }}>
                            {item.comment === "" ? (
                              <>
                                <TextField
                                  autoFocus
                                  multiline
                                  value={commentValue}
                                  onChange={(e) =>
                                    setCommentValue(e.target.value)
                                  }
                                />

                                <Stack
                                  sx={{ marginTop: 2 }}
                                  flexDirection="row"
                                  justifyContent="space-around">
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={handlePopoverClose}
                                    sx={{ width: "54px" }}>
                                    Cancel
                                  </Button>

                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleSaveComment}
                                    sx={{ width: "54px" }}>
                                    Save
                                  </Button>
                                </Stack>
                              </>
                            ) : (
                              <Typography
                                sx={{
                                  width: "90%",
                                  height: "90%",
                                  display: "flex",
                                  wordBreak: "break-word",
                                }}
                                alignItems={"center"}
                                justifyContent={"start"}>
                                {item.comment}
                              </Typography>
                            )}
                          </Box>
                        </Popover>

                        <Stack
                          style={{
                            border: "1px solid rgba(193, 32, 37, 1)",
                            height: "30px",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "109px",
                            color: "rgba(193, 32, 37, 1)",
                            bgcolor: "rgba(255, 246, 246, 1)",
                          }}>
                          <TextField
                            style={{ color: "rgba(193, 32, 37, 1)" }}
                            value={quantities[index]}
                            onChange={(event) =>
                              handleQuantityChange(event, index)
                            }
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  onClick={() => handleDecrement(index)}>
                                  <IoIosRemove
                                    size={16}
                                    style={{
                                      color: "rgba(193, 32, 37, 1)",
                                    }}
                                  />
                                </IconButton>
                              ),
                              endAdornment: (
                                <IconButton
                                  onClick={() => handleIncrement(index)}>
                                  <IoIosAdd
                                    size={16}
                                    style={{
                                      color: "rgba(193, 32, 37, 1)",
                                    }}
                                  />
                                </IconButton>
                              ),
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                textAlign: "center",
                                width: "20px",
                                fontSize: "12px",
                                fontWeight: 700,
                                color: "rgba(193, 32, 37, 1)",
                              },
                              "& fieldset": { border: "none" },
                              padding: 0,
                            }}
                          />
                        </Stack>
                      </Stack>
                    </>
                  </Stack>
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10%",
                    }}>
                    <Box>
                      <MdDelete
                        onClick={() => handleDeleteRow(item.productid)}
                        size={20}
                        style={{
                          color: "rgba(193, 32, 37, 1)",
                        }}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </>
            ))}
          </Box>
        </Container>
        <footer style={footerStyle}>
          <Container
            maxWidth="sm"
            sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Stack
              sx={{
                justifyContent: "space-around",
                flexDirection: "row",

                height: "120px",
                width: "100%",
              }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{
                  width: "100%",

                  border: "2px solid rgba(255, 255, 255, 1)",
                  padding: "10px",

                  alignItems: "center",
                  borderRadius: "10px",

                  boxShadow:
                    "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                }}>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  sx={{ width: "60%" }}>
                  <Stack gap={"10px"}>
                    <Stack justifyContent={"space-around"} gap={"2%"}>
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        {totalProducts} nos.
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}>
                        {" "}
                        Products
                      </Label>
                    </Stack>

                    <Stack justifyContent={"space-around"} gap={"2%"}>
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹<NumberFormat number={calculateTotalCGST()}/> 
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}>
                        {" "}
                        Cgst
                      </Label>
                    </Stack>
                  </Stack>
                  <Stack gap={"10px"}>
                    {" "}
                    <Stack justifyContent={"space-around"} gap={"2%"}>
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹<NumberFormat number={calculateSubtotal()}/>  
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}>
                        {" "}
                        Sub Total
                      </Label>
                    </Stack>
                    <Stack justifyContent={"space-around"} gap={"2%"}>
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹<NumberFormat number={calculateTotalSGST()}/>  
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}>
                        {" "}
                        Sgst
                      </Label>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: "30%",
                    alignItems: "end",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}>
                  <Stack justifyContent={"space-around"} gap={"2%"}>
                    <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                      ₹<NumberFormat number={calculateGrandTotal()}/>   
                    </Label>
                    <Label
                      variant="body2"
                      sx={{ color: "rgba(146, 146, 146, 1)" }}>
                      {" "}
                      Grand Total
                    </Label>
                  </Stack>
                  <Stack flexDirection="row" gap="10px">
                    <Button variant="contained" onClick={handleSubmit}>
                      SUBMIT
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </footer>
        <Dialog open={openSubmission} onClose={handleCloseSubmission}>
          <Stack
            justifyContent={"space-evenly"}
            alignItems={"center"}
            sx={{ height: "200px" }}>
            <DialogTitle
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                color: "rgba(193, 32, 37, 1)",
              }}>
              New order placed!
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <DialogContentText sx={{ textAlign: "center", color: "black" }}>
                <Typography variant="caption">
                  You can edit the order until the order is approved
                  <br />
                  by your alloted Medpick manager
                </Typography>
              </DialogContentText>
            </DialogContent>
          </Stack>
        </Dialog>
      </div>
    </>
  );
};

export default EditNewOrder;
