import * as React from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, styled } from "@mui/material/styles";
import { IoArrowBackCircle } from "react-icons/io5";
import { useState } from "react";
import { FormHelperText, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaInbox } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../redux/auth/action";
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const InnerContainer = styled(Container)(({ theme }) => ({
  bgcolor: "white",
  //   padding: "40px",
  height: "463px",
  borderRadius: "10px",
  display: "flex",
  width: "521px",
  alignItems: "center",
  border: "2px solid rgba(255, 255, 255, 1)",
  justifyContent: "center",

  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  paddingBottom: "10px",
  marginTop: "10%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    minHeight: "303px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TitleBox = styled(Box)(({ theme }) => ({
  width: "384px",

  flexDirection: "row",
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  marginTop: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "30px",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const InsideBox = styled(Box)(({ theme }) => ({
  width: "384px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",

    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Fields = styled(TextField)(({ theme }) => ({
  width: "384px",
  borderRadius: "5px",
  "& fieldset": { border: "none" },
  background: "rgba(240, 240, 240, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Buttons = styled(Button)(({ theme }) => ({
  width: "182px",
  height: "45px",
  fontWeight: 700,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ButtonBox = styled(Box)(({ theme }) => ({
  width: "384px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "10%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    flexDirection: "column",
    gap: "20px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const defaultTheme = createTheme();

export default function ForgotPassword() {
  const response = useSelector((store) => store.data.resetPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email) {
      setEmailError("Please fill the email field");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    const data = {
      email: email,
    };
    dispatch(resetPassword(data));
    setMessage(
      "We have sent you an email, click on the link to reset the password"
    );
    setShowMessage(true);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  return (
    <Wrapper>
      <InnerContainer>
        <CssBaseline />
        <InsideBox>
          <TitleBox>
            {!isMobileScreen && (
              <Box
                sx={{ width: "30%", display: "flex", justifyContent: "start" }}>
                <IoArrowBackCircle
                  onClick={() => navigate(-1)}
                  size={40}
                  color="rgba(193, 32, 37, 1)"
                />
              </Box>
            )}
            {!showMessage ? (
              <Box sx={{ textAlign: !isMobileScreen ? "start" : "center" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "25px" }}>
                  Forgot Password?
                </Typography>
              </Box>
            ) : (
              <Box sx={{ textAlign: !isMobileScreen ? "start" : "center" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "25px" }}>
                  Successfull
                </Typography>
              </Box>
            )}
          </TitleBox>

          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",

              width: "100%",
            }}>
            {showMessage ? (
              <>
                <Typography
                  sx={{ width: "80%", marginBottom: "50px" }}
                  variant="body1">
                  {message}
                </Typography>

                <FaInbox size={55} />
              </>
            ) : (
              <>
                <Fields
                  required
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="off"
                  InputProps={{ style: { height: "45px" } }}
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <FormHelperText
                    sx={{
                      color: "red",
                      mt: 1,
                      textAlign: "start",
                      width: "100%",
                    }}>
                    {emailError}
                  </FormHelperText>
                )}

                <FormHelperText
                  sx={{
                    color: "black",
                    mt: 1,
                    textAlign: "start",
                    width: "100%",
                  }}>
                  You will receive a link to reset the password.
                </FormHelperText>
              </>
            )}
          </Box>
          <ButtonBox sx={{ marginTop: "25%" }}>
            {showMessage ? (
              <>
                {!isMobileScreen ? (
                  <>
                    {" "}
                    <Buttons
                      onClick={handleHomeClick}
                      sx={{
                        border: "2px solid rgba(193, 32, 37, 1)",
                        color: "rgba(193, 32, 37, 1)",
                      }}>
                      Home
                    </Buttons>
                    <Buttons
                      onClick={handleSignIn}
                      sx={{
                        bgcolor: "rgba(193, 32, 37, 1)",
                        border: "1px solid #000000",
                        "&:hover": {
                          bgcolor: "rgba(193, 32, 37, 1)",
                          color: "white",
                        },
                        color: "white",
                      }}>
                      Sign In
                    </Buttons>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Buttons
                  onClick={handleSubmit}
                  sx={{
                    bgcolor: "rgba(193, 32, 37, 1)",
                    width: "100%",
                    "&:hover": {
                      bgcolor: "rgba(193, 32, 37, 1)",
                      color: "white",
                    },
                    color: "white",
                  }}>
                  Reset Password
                </Buttons>
              </>
            )}
          </ButtonBox>
        </InsideBox>
      </InnerContainer>
      {isMobileScreen && showMessage && (
        <ButtonBox>
          <Buttons
            onClick={handleSignIn}
            sx={{
              bgcolor: "rgba(193, 32, 37, 1)",
              border: "1px solid #000000",
              "&:hover": {
                bgcolor: "rgba(193, 32, 37, 1)",
                color: "white",
              },
              color: "white",
            }}>
            Sign In
          </Buttons>
        </ButtonBox>
      )}
    </Wrapper>
  );
}
