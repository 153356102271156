import { Box, Typography, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logos from "../images/asthra logo-white.png";
import medpicks from "../images/medpickwhite.png";
import { HiDotsHorizontal } from "react-icons/hi";
const Box1 = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  display: "flex",

  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Box2 = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
function LoadingPage() {
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [location.pathname]);

  if (loading) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          border: "1px solid red",
          width: "100%",
          background: "rgba(193, 32, 37, 1)",
        }}>
        <Box1
          sx={{
            width: "30%",
            height: "80%",
            gap: "0px",
            justifyContent: "space-evenly",

            flexDirection: "column",
          }}>
          <Box2>
            <Box
              as="img"
              src={logos}
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
            <Typography variant="caption" sx={{ color: "white" }}>
              ASTRA
            </Typography>
          </Box2>

          <HiDotsHorizontal size={100} color="yellow" />

          <Box2>
            <Typography
              variant="caption"
              sx={{
                color: "white",
                width: "97px",
                height: "14px",
              }}>
              From
            </Typography>
            <Box as="img" src={medpicks} sx={{ mt: 2 }} />
          </Box2>
        </Box1>
      </Box>
    );
  }

  
  return null;
}

export default LoadingPage;


