import React from "react";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Box, Stack, Typography } from "@mui/material";

function Submission({
  openSubmission,
  handleCloseSubmission,
  handleSubmission,
}) {
  return (
    <Dialog open={openSubmission} onClose={handleCloseSubmission}>
      <Stack
        justifyContent={"space-evenly"}
        alignItems={"center"}
        sx={{  height: "200px", }}>
        <DialogTitle
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            color: "rgba(193, 32, 37, 1)",
          }}>
         
          Changes submitted successfully!
        </DialogTitle>
        <DialogContent sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <DialogContentText sx={{ textAlign: "center", color: "black" ,}}>
            <Typography variant="body2">
              Your changes have been successfully submitted.
              <br />
              You can now view the updated order status.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}

export default Submission;
