import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";

import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import { IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import logos from "../images/asthra logo-03 2.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PaymentSharpIcon from "@mui/icons-material/PaymentSharp";
import PrivateRoute from "../routes/PrivateRoute";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MdLogout } from "react-icons/md";
import LogoutWarning from "./LogoutWarning";
const ImageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "50px",
    height: "54px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
}));

const drawerWidth = {
  xs: 140,
  sm: 140,
  md: 140,
  lg: 125,
  xl: 140,
};
export default function Dashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));

  const [showSuccess1, setShowSuccess1] = useState(false);
  const [showSuccess2, setShowSuccess2] = useState(false);
  const [showSuccess3, setShowSuccess3] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [logoutOpen, setLogoutOpen] = React.useState(false);
  const handleLogout = () => {
    // window.location.reload();
    localStorage.clear();

    navigate("/signin");
    window.location.reload();
  };

  const handleCloseLogout = () => {
    setLogoutOpen(false);
  };

  const handleOpenLogout = () => {
    setLogoutOpen(true);
  };

  return (
    <Box>
      {!isMobileScreen ? (
        <>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  bgcolor: theme.palette.primary.main,
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <StyledBox
                style={{
                  justifyContent: "start",

                  height: "100%",
                  marginTop: "70px",
                }}
              >
                <StyledBox>
                  <List>
                    <ImageBox
                      component="img"
                      src={logos}
                      onClick={() => navigate("/dashboard")}
                      sx={{ cursor: "pointer", height: "60px", width: "60px" }}
                    />
                  </List>
                </StyledBox>

                <StyledBox
                  style={{
                    height: "100%",
                    gap: "30px",
                  }}
                >
                  {userData && (
                    <>
                      <Box
                        position="relative"
                        onClick={() => navigate("/dashboard")}
                      >
                        <List
                          style={{ color: theme.palette.secondary.main }}
                          onMouseEnter={() => setShowSuccess1(true)}
                          onMouseLeave={() => setShowSuccess1(false)}
                          sx={{
                            display: "inline-block",
                            padding: "5px",
                            borderRadius: "10px",
                            cursor: "pointer",
                            border: "1px solid transparent",
                            width: "100%",
                            // height: "100%",

                            "&:hover": {
                              borderColor: "white",
                            },
                          }}
                        >
                          <ListItem key={""} disablePadding>
                            <ListItemButton>
                              <Box>
                                <DashboardIcon />
                              </Box>
                            </ListItemButton>
                          </ListItem>
                        </List>

                        <Typography
                          component="div"
                          variant="caption"
                          sx={{
                            display: showSuccess1 ? "block" : "none",
                            position: "absolute",
                            bottom: "-30px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "white",
                          }}
                        ></Typography>
                        <Box
                          sx={{
                            fontSize: "12px",
                            color: "white",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          Dashboard
                        </Box>
                      </Box>
                      <Box
                        position="relative"
                        onClick={() => navigate("/new-order")}
                      >
                        <List
                          style={{ color: theme.palette.secondary.main }}
                          onMouseEnter={() => setShowSuccess2(true)}
                          onMouseLeave={() => setShowSuccess2(false)}
                          sx={{
                            display: "inline-block",
                            padding: "5px",
                            borderRadius: "10px",
                            cursor: "pointer",
                            border: "1px solid transparent",
                            width: "100%",
                            // height: "100%",

                            "&:hover": {
                              borderColor: "white",
                            },
                          }}
                        >
                          <ListItem key={""} disablePadding>
                            <ListItemButton>
                              <Box>
                                <AddBoxSharpIcon />
                              </Box>
                            </ListItemButton>
                          </ListItem>
                        </List>

                        <Typography
                          component="div"
                          variant="caption"
                          sx={{
                            display: showSuccess2 ? "block" : "none",
                            position: "absolute",
                            bottom: "-30px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "white",
                          }}
                        ></Typography>
                        <Box
                          sx={{
                            fontSize: "12px",
                            color: "white",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          New Order
                        </Box>
                      </Box>
                      <Box
                        position="relative"
                        onClick={() => navigate("/my-order")}
                      >
                        <List
                          style={{ color: theme.palette.secondary.main }}
                          onMouseEnter={() => setShowSuccess2(true)}
                          onMouseLeave={() => setShowSuccess2(false)}
                          sx={{
                            display: "inline-block",
                            padding: "5px",
                            borderRadius: "10px",
                            cursor: "pointer",
                            border: "1px solid transparent",
                            width: "100%",
                            // height: "100%",

                            "&:hover": {
                              borderColor: "white",
                            },
                          }}
                        >
                          <ListItem key={""} disablePadding>
                            <ListItemButton>
                              <Box>
                                <AssignmentTurnedInIcon />
                              </Box>
                            </ListItemButton>
                          </ListItem>
                        </List>

                        <Typography
                          component="div"
                          variant="caption"
                          sx={{
                            display: showSuccess2 ? "block" : "none",
                            position: "absolute",
                            bottom: "-30px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "white",
                          }}
                        ></Typography>
                        <Box
                          sx={{
                            fontSize: "12px",
                            color: "white",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          Orders
                        </Box>
                      </Box>
                      <Box
                        position="relative"
                        onClick={() => navigate("/transaction")}
                      >
                        <List
                          style={{ color: theme.palette.secondary.main }}
                          onMouseEnter={() => setShowSuccess3(true)}
                          onMouseLeave={() => setShowSuccess3(false)}
                          sx={{
                            display: "inline-block",
                            padding: "5px",
                            borderRadius: "10px",
                            cursor: "pointer",
                            border: "1px solid transparent",
                            width: "100%",
                            // height: "100%",

                            "&:hover": {
                              borderColor: "white",
                            },
                          }}
                        >
                          <ListItem key={""} disablePadding>
                            <ListItemButton>
                              <Box>
                                <PaymentSharpIcon />
                              </Box>
                            </ListItemButton>
                          </ListItem>
                        </List>

                        <Typography
                          component="div"
                          variant="caption"
                          sx={{
                            display: showSuccess3 ? "block" : "none",
                            position: "absolute",
                            bottom: "-30px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "white",
                          }}
                        ></Typography>
                        <Box
                          sx={{
                            fontSize: "12px",
                            color: "white",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          Transactions
                        </Box>
                      </Box>
                    </>
                  )}
                </StyledBox>

                <StyledBox sx={{ paddingBottom: "25px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <IconButton>
                      <MdLogout
                        onClick={handleOpenLogout}
                        size={25}
                        style={{ color: "white" }}
                      />
                    </IconButton>
                    Log Out
                  </Box>
                </StyledBox>
              </StyledBox>
            </Drawer>
            <StyledBox
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: "#F7F7F7",
              }}
            >
              <PrivateRoute />
            </StyledBox>
          </Box>
        </>
      ) : (
        <>
          {/* <PrivateRoute /> */}

          <PrivateRoute />
        </>
      )}

      <LogoutWarning
        handleClickOpen={handleLogout}
        handleClose={handleCloseLogout}
        open={logoutOpen}
      />
    </Box>
  );
}
