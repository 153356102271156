import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React from "react";

import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import { BiCommentDetail } from "react-icons/bi";

import { IoIosAdd } from "react-icons/io";
import { IoIosRemove } from "react-icons/io";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import { PiMinusSquareFill } from "react-icons/pi";
import { newOrder, placeOrder } from "../../redux/data/action";
import { useNavigate } from "react-router-dom";
import NumberFormat from "../../components/NumberFormat";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label3 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,

  cursor: "pointer",
  fontSize: "small",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SeeOrder = () => {
  const dispatch = useDispatch();
  let branchData = JSON.parse(localStorage.getItem("branchData"));

  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  const [open, setOpen] = React.useState(false);
  /////console.log("opennnnn", open);
  const navigate = useNavigate();
  const datas = JSON.parse(localStorage.getItem("savedData")) || [];
  // Function to monitor and handle branchName changes
  function monitorBranchNameChange() {
    let previousBranchName = null;

    setInterval(() => {
      const branchData = JSON.parse(localStorage.getItem("branchData"));

      if (branchData) {
        const currentBranchName = branchData.branchName;

        if (previousBranchName && previousBranchName !== currentBranchName) {
          // Clear savedData if branchName changes
          localStorage.removeItem("savedData");
          console.log("Branch name changed. savedData cleared.");
        }

        // Update the previousBranchName
        previousBranchName = currentBranchName;
      }
    }, 1000); // Check every second
  }

  // Call the function to start monitoring
  monitorBranchNameChange();

  ///////console.log("datas", datas);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const retrievedDatas = JSON.parse(localStorage.getItem("savedData"));
  const [openSubmission, setOpenSubmission] = useState(false);
  const [data, setData] = useState([]);
  ///////console.log("retrievedDatas", retrievedDatas);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [commentValue, setCommentValue] = useState("");
  /////console.log("commentValue", commentValue);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [quantities, setQuantities] = useState([]);

  const [comments, setComment] = useState([]);
  /////console.log("quantities", quantities);

  const handlePopoverOpen = (event, productId, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
    setCommentValue(comment);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedProductId(null);
  };

  // const handleSaveComment = () => {
  //   const index = data.findIndex(
  //     (item) => item.productid === selectedProductId
  //   );
  //   if (index !== -1) {
  //     const updatedComments = [...comments];
  //     updatedComments[index] = commentValue;
  //     setComment(updatedComments);
  //     handlePopoverClose();
  //   }
  // };

  const handleSaveComment = () => {
    const index = data.findIndex(
      (item) => item.productid === selectedProductId
    );
    if (index !== -1) {
      const updatedData = [...data];
      updatedData[index].comment = commentValue;
      setData(updatedData);
      localStorage.setItem("savedData", JSON.stringify(updatedData));
      handlePopoverClose();
    }
  };

  useEffect(() => {
    setQuantities(data.map((item) => parseInt(item.quantity)));
    setComment(data.map((item) => item.comment));
  }, [data]);

  /////console.log("comments", comments);

  // const handleIncrement = (index) => {
  //   const updatedQuantities = [...quantities];
  //   updatedQuantities[index] += 1;
  //   setQuantities(updatedQuantities);
  // };

  // const handleDecrement = (index) => {
  //   if (quantities[index] > 0) {
  //     const updatedQuantities = [...quantities];
  //     updatedQuantities[index] -= 1;
  //     setQuantities(updatedQuantities);
  //   }
  // };
  // const handleQuantityChange = (event, index) => {
  //   const { value } = event.target;
  //   const updatedQuantities = [...quantities];
  //   updatedQuantities[index] = parseInt(value) || 0;
  //   setQuantities(updatedQuantities);
  // };

  const handleIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] += 1;
    setQuantities(updatedQuantities);
  };

  const handleDecrement = (index) => {
    if (quantities[index] > 1) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] -= 1;
      setQuantities(updatedQuantities);
    }
  };

  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    const updatedQuantities = [...quantities];
    const newValue = parseInt(value, 10);

    if (!isNaN(newValue) && newValue >= 1) {
      updatedQuantities[index] = newValue;
    } else {
      updatedQuantities[index] = 1; // Reset to 1 if the input is invalid or less than 1
    }

    setQuantities(updatedQuantities);
  };

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
    // navigate(-1);
    localStorage.removeItem("savedData");
    setOpen(false);
    dispatch(newOrder());
  };
  let totalProducts = 0;
  let subtotal = 0;
  let totalCGST = 0;
  let totalSGST = 0;

  retrievedDatas &&
    retrievedDatas?.forEach((product) => {
      totalProducts = retrievedDatas?.length;
      subtotal += parseFloat(product.curr_price) * product.quantity;
      totalCGST +=
        (parseFloat(product.cgst) / 100) *
        (parseFloat(product.curr_price) * product.quantity);
      totalSGST +=
        (parseFloat(product.sgst) / 100) *
        (parseFloat(product.curr_price) * product.quantity);
    });

  const grandTotal = subtotal + totalCGST + totalSGST;

  useEffect(() => {
    const datas = JSON.parse(localStorage.getItem("savedData")) || [];
    if (Array.isArray(datas) && datas.length !== 0) {
      const initialQuantities = datas.map((product) => product.quantity);
      setQuantities(initialQuantities);
      setData(datas);
    }
  }, [open]);

  const calculateSubtotal = () => {
    let subtotal = 0;
    datas.forEach((product, index) => {
      subtotal += parseFloat(product.curr_price) * quantities[index];
    });
    return subtotal;
  };

  const calculateTotalCGST = () => {
    let totalCGST = 0;
    datas.forEach((product, index) => {
      totalCGST +=
        (parseFloat(product.cgst) / 100) *
        (parseFloat(product.curr_price) * quantities[index]);
    });
    return parseFloat(totalCGST.toFixed(2)); // Rounding off to 2 decimal places
  };

  const calculateTotalSGST = () => {
    let totalSGST = 0;
    datas.forEach((product, index) => {
      totalSGST +=
        (parseFloat(product.sgst) / 100) *
        (parseFloat(product.curr_price) * quantities[index]);
    });
    return parseFloat(totalSGST.toFixed(2)); // Rounding off to 2 decimal places
  };

  const calculateGrandTotal = () => {
    return calculateSubtotal() + calculateTotalCGST() + calculateTotalSGST();
  };

  const totalQuantities = quantities.reduce((acc, val) => acc + val, 0);
  const totalPrevPrice = data.reduce(
    (total, product, index) => total + product.prev_price * quantities[index],
    0
  );
  const totalPrice = calculateSubtotal();
  const totalSavingPrice = totalPrevPrice - totalPrice;
  const totalSavingPercentage = Math.round(
    (totalSavingPrice / totalPrevPrice) * 100
  );

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalCurrentPrice, setTotalCurrentPrice] = useState(0);

  useEffect(() => {
    let quantitySum = 0;
    let priceSum = 0;

    datas?.forEach((item) => {
      quantitySum += item?.quantity;
      priceSum += parseInt(item?.curr_price) * item?.quantity;
    });

    setTotalQuantity(quantitySum);
    setTotalCurrentPrice(priceSum);
  }, []);

  useEffect(() => {
    if (datas.length === 0) {
      setOpen(false);
    }
  }, [datas]);

  /////console.log("datas", datas);

  const handleDeleteRow = (productId) => {
    const index = datas.findIndex((item) => item.productid === productId);
    if (index !== -1) {
      const updatedDatas = [...datas];

      updatedDatas.splice(index, 1);

      setData(updatedDatas);

      localStorage.setItem("savedData", JSON.stringify(updatedDatas));
      dispatch(newOrder());
    }
  };

  const handleSubmit = () => {
    let productslist = datas.map((item, index) => ({
      productid: item.productid,
      quantity: String(quantities[index]),
      savings: item.savings,
      comment: comments[index],

      product: item.product,
      hsn: item.hsn,
      brand: item.brand,
      category: item.category,
      packsize: item.packsize,

      prev_price: item.prev_price,
      curr_price: item.curr_price,
      cgst: item.cgst,
      sgst: item.sgst,
    }));
    const requestData = {
      userid: userData.userid,
      username: userData.name,
      userrole: userData.role,
      hospid: userData.hospid,
      hospname: userData.hospname,
      branch: branchData.branchName,
      branchid: branchData.branchId,
      totproduct: totalProducts,
      totquantity: totalQuantities,
      totprevprice: totalPrevPrice,

      totgst: totalCGST,
      subtot: subtotal,
      totprice: calculateGrandTotal(),
      totsavings: totalSavingPrice,
      totsavings_percent: totalSavingPercentage,
      productslist: productslist,
    };

    dispatch(placeOrder(requestData));
    setOpen(false);
    setOpenSubmission(true);
    setTimeout(() => {
      handleCloseSubmission();
      navigate("/my-order");
    }, 2000);
  };
  const [isPaperVisible, setIsPaperVisible] = useState(true);

  const handleClosePaper = () => {
    setIsPaperVisible(false);
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: "2.07px solid rgba(193, 32, 37, 1)",
        width: "100%",
        height: "84px",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Stack flexDirection="column" gap="10px">
        <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
          {totalProducts} nos.{" "}
        </Label>
        <Label>Total Products</Label>
      </Stack>

      <Stack flexDirection="column" gap="10px">
        <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
          {/* ₹<NumberFormat number={subtotal} /> */}₹
          {new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(subtotal)}
        </Label>
        <Label>Sub Total</Label>
      </Stack>

      <Stack flexDirection="column" gap="10px">
        <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
          {/* ₹<NumberFormat number={totalCGST} /> */}₹
          {new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalCGST)}
        </Label>
        <Label>CGST Total</Label>
      </Stack>

      <Stack flexDirection="column" gap="10px">
        <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
          ₹{/* ₹<NumberFormat number={totalSGST} /> */}
          {new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalSGST)}
        </Label>
        <Label>SGST Total</Label>
      </Stack>

      <Stack flexDirection="column" gap="10px">
        <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
          ₹
          {new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(grandTotal)}
        </Label>
        <Label>Grand Total</Label>
      </Stack>

      <Stack flexDirection="row" gap="10px">
        <Button
          disabled={!datas || datas?.length === 0}
          variant="contained"
          onClick={handleClickOpen("body")}
        >
          See all products
        </Button>
      </Stack>
      {datas && datas?.length > 0 && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          sx={{}}
        >
          <Box sx={{ width: "1092px", height: "100%" }}>
            <DialogTitle id="scroll-dialog-title"></DialogTitle>
            <DialogContent sx={{}}>
              <>
                <Stack
                  justifyContent="space-between"
                  sx={{ width: "100%", height: "100%" }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          boxShadow:
                            "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                        }}
                      >
                        <CloseIcon
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            cursor: "pointer",
                            color: "rgba(193, 32, 37, 1)",
                          }}
                          onClick={handleClose}
                        />

                        <React.Fragment>
                          {datas?.length > 0 ? (
                            <>
                              {" "}
                              <Table size="small" style={{}}>
                                <TableHead>
                                  <TableRow>
                                    <TableHeading>
                                      <Label variant="caption">
                                        {" "}
                                        PROD NAME
                                      </Label>
                                    </TableHeading>
                                    <TableHeading>
                                      <Label variant="caption"> BRAND</Label>
                                    </TableHeading>
                                    <TableHeading>
                                      <Label variant="caption">HSN</Label>
                                    </TableHeading>
                                    {/* <TableHeading>
                                      <Label variant="caption"> CATEGORY</Label>
                                    </TableHeading> */}
                                    <TableHeading>
                                      <Label variant="caption"> /PKG</Label>
                                    </TableHeading>
                                    <TableHeading>
                                      <Label variant="caption"> PRICE</Label>
                                    </TableHeading>
                                    <TableHeading>
                                      <Label variant="caption"> CGST</Label>
                                    </TableHeading>
                                    <TableHeading>
                                      <Label variant="caption"> SGST</Label>
                                    </TableHeading>
                                    <TableHeading
                                      sx={{
                                        color: "rgba(193, 32, 37, 1)",
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      <Label variant="caption"> QTY</Label>
                                    </TableHeading>
                                    <TableHeading
                                      sx={{
                                        color: "rgba(193, 32, 37, 1)",
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      <Label variant="caption"> CMT</Label>
                                    </TableHeading>
                                    {/* <TableHeading
                                      sx={{
                                        textAlign: "center",
                                        color: "rgba(193, 32, 37, 1)",
                                        display: "flex",
                                        // justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <BiCommentDetail size={20} />
                                    </TableHeading> */}
                                    <TableHeading>
                                      <Label variant="caption"> </Label>
                                    </TableHeading>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {datas
                                    ?.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => (
                                      <TableRow
                                        key={row.productid}
                                        sx={{
                                          "&:hover": {
                                            bgcolor: "rgba(255, 237, 237, 1)",
                                          },
                                        }}
                                        style={{
                                          boxShadow:
                                            "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                        }}
                                      >
                                        <TableRows>
                                          {row.productid?.length > 11 ? (
                                            <Tooltip title={row.productid}>
                                              <Label3
                                                variant="caption"
                                                style={{
                                                  maxWidth: "11ch",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {/* {row.productid.slice(0, 9) +
                                                  "..."} */}
                                                {row.product}
                                              </Label3>
                                            </Tooltip>
                                          ) : (
                                            <Label3
                                              variant="caption"
                                              style={{
                                                maxWidth: "11ch",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {row.product}
                                            </Label3>
                                          )}
                                        </TableRows>
                                        <TableRows>
                                          <Label3 variant="caption">
                                            {row.brand}
                                          </Label3>
                                        </TableRows>
                                        <TableRows>
                                          <Label3 variant="caption">
                                            {row.hsn}
                                          </Label3>
                                        </TableRows>
                                        {/* <TableRows>
                                          <Label3 variant="caption">
                                            {" "}
                                            {row.category} nos
                                          </Label3>
                                        </TableRows> */}
                                        <TableRows>
                                          <Label3 variant="caption">
                                            {row.packsize}
                                          </Label3>
                                        </TableRows>
                                        <TableRows>
                                          <Label3 variant="caption">
                                            {row.curr_price}
                                          </Label3>
                                        </TableRows>

                                        <TableRows>
                                          <TableText variant="caption">
                                            {row.cgst}%
                                          </TableText>
                                        </TableRows>
                                        <TableRows>
                                          <TableText variant="caption">
                                            {row.sgst}%
                                          </TableText>
                                        </TableRows>
                                        <TableRows
                                          style={{
                                            background:
                                              "rgba(255, 246, 246, 1)",
                                            border: "1px solid white",
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            textAlign: "center",
                                          }}
                                        >
                                          <TableText variant="caption">
                                            <TextField
                                              value={quantities[index]}
                                              onChange={(event) =>
                                                handleQuantityChange(
                                                  event,
                                                  index
                                                )
                                              }
                                              InputProps={{
                                                startAdornment: (
                                                  <IconButton
                                                    onClick={() =>
                                                      handleDecrement(index)
                                                    }
                                                  >
                                                    <IoIosRemove size={16} />
                                                  </IconButton>
                                                ),
                                                endAdornment: (
                                                  <IconButton
                                                    onClick={() =>
                                                      handleIncrement(index)
                                                    }
                                                  >
                                                    <IoIosAdd size={16} />
                                                  </IconButton>
                                                ),
                                              }}
                                              sx={{
                                                "& .MuiInputBase-input": {
                                                  textAlign: "center",
                                                  width: "20px",
                                                  fontSize: "12px",
                                                },
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                                padding: 0,
                                              }}
                                            />
                                          </TableText>
                                        </TableRows>
                                        <TableRows
                                          style={{
                                            background:
                                              "rgba(255, 246, 246, 1)",
                                          }}
                                        >
                                          <TableText variant="caption">
                                            {row.comment !== "" ? (
                                              <>
                                                {" "}
                                                <BiCommentDetail
                                                  size={20}
                                                  onClick={(event) =>
                                                    handlePopoverOpen(
                                                      event,
                                                      row.productid,
                                                      row.comment
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <Typography
                                                  variant="caption"
                                                  onClick={(event) =>
                                                    handlePopoverOpen(
                                                      event,
                                                      row.productid,
                                                      row.comment
                                                    )
                                                  }
                                                >
                                                  comments
                                                </Typography>
                                              </>
                                            )}

                                            <>
                                              <Popover
                                                open={
                                                  isPopoverOpen &&
                                                  selectedProductId ===
                                                    row.productid
                                                }
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left",
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-around",
                                                    flexDirection: "column",
                                                    width: "200px",
                                                    minHeight: "90px",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <>
                                                    <TextField
                                                      autoFocus
                                                      multiline
                                                      value={commentValue}
                                                      onChange={(e) =>
                                                        setCommentValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    />

                                                    <Stack
                                                      sx={{ marginTop: 2 }}
                                                      flexDirection="row"
                                                      justifyContent="space-around"
                                                    >
                                                      <Button
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={
                                                          handlePopoverClose
                                                        }
                                                        sx={{ width: "54px" }}
                                                      >
                                                        Cancel
                                                      </Button>

                                                      <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={
                                                          handleSaveComment
                                                        }
                                                        sx={{ width: "54px" }}
                                                      >
                                                        Save
                                                      </Button>
                                                    </Stack>
                                                  </>
                                                </Box>
                                              </Popover>
                                            </>
                                          </TableText>
                                        </TableRows>

                                        <TableRows
                                          sx={{
                                            background:
                                              "rgba(255, 246, 246, 1)",
                                            border: "1px solid white",
                                          }}
                                        >
                                          <TableText variant="caption">
                                            <DeleteIcon
                                              onClick={() =>
                                                handleDeleteRow(row.productid)
                                              }
                                              size={20}
                                              style={{
                                                color: "rgba(193, 32, 37, 1)",
                                              }}
                                            />
                                          </TableText>
                                        </TableRows>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  flexDirection: "column",
                                  gap: "20px",
                                }}
                              >
                                <Typography>
                                  No orders have been placed yet.
                                </Typography>
                                <Typography>Add to your cart now</Typography>
                                <Button variant="contained">
                                  PLACE NEW ORDER
                                </Button>
                              </Box>
                            </>
                          )}
                        </React.Fragment>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      border: "2.07px solid rgba(193, 32, 37, 1)",
                      width: "100%",
                      height: "84px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        {totalProducts} nos.{" "}
                      </Label>
                      <Label>Total Products</Label>
                    </Stack>

                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹{/* <NumberFormat number={calculateSubtotal()} /> */}
                        {new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(calculateSubtotal())}
                      </Label>
                      <Label>Sub Total</Label>
                    </Stack>

                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹{/* <NumberFormat number={calculateTotalCGST()} /> */}
                        {new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(calculateTotalCGST())}
                      </Label>
                      <Label>CGST Total</Label>
                    </Stack>

                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹{/* <NumberFormat number={calculateTotalSGST()} /> */}
                        {new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(calculateTotalSGST())}
                      </Label>
                      <Label>SGST Total</Label>
                    </Stack>

                    {/* <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹<NumberFormat number={calculateGrandTotal()} />
                      </Label>
                      <Label>Grand Total</Label>
                    </Stack> */}
                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹
                        {new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(calculateGrandTotal())}
                      </Label>
                      <Label>Grand Total</Label>
                    </Stack>

                    <Stack flexDirection="row" gap="10px">
                      <Button variant="contained" onClick={handleSubmit}>
                        SUBMIT
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            </DialogContent>
          </Box>
        </Dialog>
      )}

      <Dialog open={openSubmission} onClose={handleCloseSubmission}>
        <Stack
          justifyContent={"space-evenly"}
          alignItems={"center"}
          sx={{ width: "420px", height: "200px" }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              color: "rgba(193, 32, 37, 1)",
            }}
          >
            New order placed!
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogContentText sx={{ textAlign: "center", color: "black" }}>
              <Typography variant="body2">
                You can edit the order until the order is approved
                <br />
                by your alloted Medpick manager
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default SeeOrder;
