import * as React from "react";

import Box from "@mui/material/Box";

import { useTheme } from "@mui/material/styles";
import Navbar from "./Navbar";
import Footer from "./Footer";

import PublicRoute from "../routes/PublicRoute";
import { Divider, useMediaQuery } from "@mui/material";

import Footer2 from "./Footer2";
import { useLocation } from "react-router-dom";
import LoadingPage from "./LoadingPage";
export default function HomePage() {
  const location = useLocation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          height: "auto",

          justifyContent: "space-between",
        }}>
        <Navbar />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",

            flexDirection: "column",
            paddingTop: "40px",
            paddingBottom: "40px",
          }}>
          <PublicRoute />
        </Box>
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            marginTop: "2%",
          }}
          component="footer">
          {location.pathname === "/signup" ||
          location.pathname === "/signin" ||
          location.pathname === "/forgot-password" ? (
            <>{!isMobileScreen && <Footer />}</>
          ) : (
            <>
              {/* {!isMobileScreen && (
                <Divider
                  sx={{
                    width: "88%",
                    bgcolor: "rgba(193, 32, 37, 1)",
                    height: 2,
                  }}
                />
              )} */}
              <Footer />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
