// import React from "react";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import HomeIcon from "@mui/icons-material/Home";
// import AddIcon from "@mui/icons-material/Add";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
// import PaymentSharpIcon from "@mui/icons-material/PaymentSharp";
// const Footer = () => {
//   const [value, setValue] = React.useState(0);

//   return (
//     <BottomNavigation
//       value={value}
//       onChange={(event, newValue) => {
//         setValue(newValue);
//       }}
//       showLabels
//       style={{ position: "fixed", bottom: 0, width: "100%" }}>
//       <BottomNavigationAction label="Home" icon={<HomeIcon />} />
//       <BottomNavigationAction label="Add" icon={<AddBoxSharpIcon />} />
//       <BottomNavigationAction label="Add" icon={<AddIcon />} />
//       <BottomNavigationAction label="Add" icon={<PaymentSharpIcon />} />
//       <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} />
//     </BottomNavigation>
//   );
// };

// export default Footer;
import React from "react";
import { Link } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import PaymentSharpIcon from "@mui/icons-material/PaymentSharp";
import DashboardIcon from "@mui/icons-material/Dashboard";
const Footer = () => {
  const [value, setValue] = React.useState(0);

  return (
    // <BottomNavigation
    //   value={value}
    //   onChange={(event, newValue) => {
    //     setValue(newValue);
    //   }}
    //   showLabels
    //   style={{ position: "fixed", bottom: 0, width: "100%" }}
    // >
    //   <BottomNavigationAction
    //     label="Home"
    //     icon={<HomeIcon />}
    //     component={Link}
    //     to="/home"
    //   />
    //   <BottomNavigationAction
    //     label="Dashboard"
    //     icon={<DashboardIcon />}
    //     component={Link}
    //     to="/dashboard"
    //   />
    //   <BottomNavigationAction
    //     label="Orders"
    //     icon={<AddBoxSharpIcon />}
    //     component={Link}
    //     to="/my-order"
    //   />
    //   <BottomNavigationAction
    //     label="Transactions"
    //     icon={<PaymentSharpIcon />}
    //     component={Link}
    //     to="/transaction"
    //   />
    //   <BottomNavigationAction
    //     label="Profile"
    //     icon={<AccountCircleIcon />}
    //     component={Link}
    //     to="/profile"
    //   />
    // </BottomNavigation>

    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        boxShadow: " 0px -3px 15px 2px rgba(229, 205, 204, 0.6)",
      }}>
      <BottomNavigationAction
        // label="Home"
        icon={<HomeIcon />}
        component={Link}
        to="/home"
        style={{ fontSize: "2px" }} // Customize label size here
      />
      <BottomNavigationAction
        // label="Dashboard"
        icon={<DashboardIcon />}
        component={Link}
        to="/dashboard"
        style={{ fontSize: "2px" }} // Customize label size here
      />
      <BottomNavigationAction
        // label="Orders"
        icon={<AddBoxSharpIcon />}
        component={Link}
        to="/my-order"
        style={{ fontSize: "2px" }} // Customize label size here
      />
      <BottomNavigationAction
        // label="Transactions"
        icon={<PaymentSharpIcon />}
        component={Link}
        to="/transaction"
        style={{ fontSize: "2px" }} // Customize label size here
      />
      <BottomNavigationAction
        // label="Profile"
        icon={<AccountCircleIcon />}
        component={Link}
        to="/profile"
        style={{ fontSize: "2px" }} // Customize label size here
      />
    </BottomNavigation>
  );
};

export default Footer;
