import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MdSavings } from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NumberFormat from "../../components/NumberFormat";
const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "59px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ topProdsSection, topBrandsSection }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {!isMobileScreen ? (
        <>
          <Box
            sx={{
              width: "100%",
              bgcolor: "white",
              height: "230px",
              borderRadius: "10px",
            }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Tabs
                variant="fullWidth"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  height: "50px",
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab
                  label="TOP PRODUCTS"
                  {...a11yProps(0)}
                  sx={{
                    color: value === 0 ? "black" : "rgba(204, 204, 204, 1)", // Change text color when selected
                    fontWeight: 700,
                    fontSize: "12px",
                  }}
                />
                <Tab
                  label="TOP BRANDS"
                  {...a11yProps(1)}
                  sx={{
                    color: value === 1 ? "black" : "rgba(204, 204, 204, 1)",
                    fontWeight: 700,
                    fontSize: "12px",
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} sx={{ padding: "0px" }}>
              <Stack justifyContent={"center"} alignItems={"center"}>
                <Table size="small">
                  <TableBody>
                    {topProdsSection.map((row, index) => (
                      <TableRow
                        key={row.product}
                        sx={{
                          "&:hover": {
                            bgcolor: "rgba(255, 237, 237, 1)",
                          },
                        }}
                        style={{
                          boxShadow:
                            "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                        }}>
                        <TableRows style={{}}>
                          <TableText
                            variant="subtitle"
                            style={{
                              overflow: "hidden",
                              cursor:"default",
                              textDecoration: "none",
                              color: "black",
                            }}>
                            {row.product}
                          </TableText>
                        </TableRows>

                        <TableRows>
                          <TableText
                            variant="subtitle"
                            style={{ color: "rgba(255, 153, 0, 1)" ,cursor:"default"}}>
                            <MdSavings />₹<NumberFormat number={row.prod_savings}/>
                          </TableText>
                        </TableRows>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>{" "}
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Stack justifyContent={"center"} alignItems={"center"}>
                <Table size="small">
                  <TableBody>
                    {topBrandsSection.map((el, index) => (
                      <TableRow
                        key={el.brandid}
                        sx={{
                          "&:hover": {
                            bgcolor: "rgba(255, 237, 237, 1)",
                          },
                        }}
                        style={{
                          boxShadow:
                            "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                        }}>
                        <TableRows>
                          <TableText variant="subtitle" sx={{cursor:"default"}}>{el.brand}</TableText>
                        </TableRows>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>{" "}
              </Stack>
            </CustomTabPanel>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",

              // height: "230px",
              borderRadius: "10px",

              bgcolor: "#f0f0f0",
            }}>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}>
              <Tabs
                variant="fullWidth"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  width: "100%",
                  padding: "0px",

                  "& .MuiTab-root": {
                    padding: "0 !important",
                    minHeight: "59px",
                  },
                }}
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: "none" } }}
                aria-label="basic tabs example">
                <Tab
                  icon={
                    <Stack
                      style={{
                        border:
                          value === 0
                            ? "2px solid rgba(255, 255, 255, 1)"
                            : "none",
                        backgroundColor: value === 0 ? "white" : "transparent",
                        padding: "8px",
                        borderRadius: "10px",
                        color: value === 0 ? "black" : "grey",
                        width: "200px",
                        height: "59px",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow:
                          value === 0
                            ? "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset"
                            : "none ",
                      }}>
                      TOP PRODUCTS
                    </Stack>
                  }
                  {...a11yProps(0)}
                  selected={value === 0}
                />
                <Tab
                  icon={
                    <Stack
                      style={{
                        border:
                          value === 1
                            ? "2px solid rgba(255, 255, 255, 1)"
                            : "none",
                        backgroundColor: value === 1 ? "white" : "transparent",
                        padding: "8px",
                        borderRadius: "10px",
                        color: value === 1 ? "black" : "grey",
                        width: "200px",
                        height: "59px",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow:
                          value === 1
                            ? "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset"
                            : "none ",
                      }}>
                      TOP BRANDS
                    </Stack>
                  }
                  {...a11yProps(1)}
                  selected={value === 1}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} sx={{ padding: "0px" }}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  marginTop: "20px",
                  background: "white",
                  padding: "10px",
                  border: "2px solid rgba(255, 255, 255, 1)",
                  boxShadow:
                    " 0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  borderRadius: "10px",
                }}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"start"}
                  sx={{ height: "60px", width: "90%" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Save more with your top picks
                  </Typography>
                </Stack>
                <Divider
                  style={{
                    // background: "rgba(217, 217, 217, 1)",

                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Table size="small">
                  <TableBody>
                    {topProdsSection.map((row, index) => (
                      <Box marginTop="15px" sx={{ width: "100%" }}>
                        <TableRow
                          key={row.product}
                          sx={{
                            "&:hover": {
                              bgcolor: "rgba(255, 237, 237, 1)",
                            },
                          }}
                          style={{
                            boxShadow:
                              " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                            width: "100%",
                          }}>
                          <TableRows style={{ width: "100%" }}>
                            <TableText
                              component={Link}
                              to={`/order-summary/${row.product}`}
                              variant="subtitle"
                              style={{
                                overflow: "hidden",

                                textDecoration: "none",
                                color: "black",
                              }}>
                              {row.product}
                            </TableText>
                          </TableRows>

                          <TableRows>
                            <TableText
                              variant="subtitle"
                              style={{ color: "rgba(255, 153, 0, 1)" }}>
                              <MdSavings />₹<NumberFormat number={row.prod_savings}/>
                            </TableText>
                          </TableRows>

                          <TableRows>
                            <HiOutlineArrowNarrowRight size={20} />
                          </TableRows>
                        </TableRow>
                      </Box>
                    ))}
                  </TableBody>
                </Table>{" "}
                <Stack
                  justifyContent={"center"}
                  alignItems={"start"}
                  sx={{ height: "60px", width: "100%", marginTop: "20px" }}>
                  <Button
                    onClick={() => navigate("/my-order")}
                    size="large"
                    variant="outlined"
                    color="info"
                    sx={{
                      width: "100%",
                      color: "black",
                      height: "45px",
                      fontWeight: 700,
                      border: "1px solid rgba(0, 0, 0, 1)",
                    }}>
                    All Orders
                  </Button>
                </Stack>
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  marginTop: "20px",
                  background: "white",
                  padding: "10px",
                  borderRadius: "10px",
                }}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"start"}
                  sx={{ height: "60px", width: "90%" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Save more with your top picks
                  </Typography>
                </Stack>
                <Divider
                  style={{
                    // background: "rgba(217, 217, 217, 1)",

                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Table size="small">
                  <TableBody>
                    {topBrandsSection.map((row, index) => (
                      <Box marginTop="15px" sx={{ width: "100%" }}>
                        <TableRow
                          key={row.product}
                          sx={{
                            "&:hover": {
                              bgcolor: "rgba(255, 237, 237, 1)",
                            },
                          }}
                          style={{
                            boxShadow:
                              " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                            width: "100%",
                          }}>
                          <TableRows style={{ width: "100%" }}>
                            <TableText
                              component={Link}
                              to={`/order-summary/${row.product}`}
                              variant="h6"
                              style={{
                                overflow: "hidden",

                                textDecoration: "none",
                                color: "black",
                                fontWeight: 700,
                              }}>
                              {row.brand}
                            </TableText>
                          </TableRows>

                          <TableRows>
                            <HiOutlineArrowNarrowRight size={20} />
                          </TableRows>
                        </TableRow>
                      </Box>
                    ))}
                  </TableBody>
                </Table>{" "}
                <Stack
                  justifyContent={"center"}
                  alignItems={"start"}
                  sx={{ height: "60px", width: "100%", marginTop: "20px" }}>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{
                      width: "100%",

                      height: "45px",
                      fontWeight: 700,
                    }}>
                    New Order
                  </Button>
                </Stack>
              </Stack>
            </CustomTabPanel>
          </Box>
        </>
      )}
    </>
  );
}
