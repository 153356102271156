import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { RiEqualizerFill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";

import {
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { GrFilter } from "react-icons/gr";
import { LuSettings2 } from "react-icons/lu";
import InputBase from "@mui/material/InputBase";
import { RiSearchLine } from "react-icons/ri";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import RightMenu from "../OnBoarding/RightMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",

  //   border: "1px  solid black",
}));

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const TopBar = ({ data, setFilteredData, heading }) => {
  ///////console.log("data*******", data);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const openSort = Boolean(anchorElSort);

  const [searchQuery, setSearchQuery] = useState("");

  const [filterChanged, setFilterChanged] = useState(false);

  const [page, setPage] = useState(0);

  const [nameSort, setNameSort] = useState(null);

  const [priceSort, setPriceSort] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const handleBrandCheckboxChange = (event) => {
    const brand = event.target.name;
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brand)
        ? prevSelected.filter((selected) => selected !== brand)
        : [...prevSelected, brand]
    );
  };
  const handleCategoryCheckboxChange = (event) => {
    const category = event.target.name;
    setSelectedCategory((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((selected) => selected !== category)
        : [...prevSelected, category]
    );
  };

  const brand = [...new Set(data?.map((item) => item.brand))].sort();
  const category = [...new Set(data?.map((item) => item.category))];

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNameSortChange = (event) => {
    setNameSort(event.target.checked ? event.target.value : null);
  };

  const handlePriceSortChange = (event) => {
    setPriceSort(event.target.checked ? event.target.value : null);
  };

  useEffect(() => {
    const filterData = (
      data,
      selectedCategory,
      selectedBrands,
      nameSort,
      priceSort
    ) => {
      let filteredData = data.filter(
        (item) =>
          (selectedCategory?.length === 0 ||
            selectedCategory.includes(item.category)) &&
          (selectedBrands?.length === 0 || selectedBrands.includes(item.brand))
      );

      filteredData = filteredData.filter((product) =>
        product.product.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (nameSort === "a-z") {
        filteredData.sort((a, b) => a.product.localeCompare(b.product));
      } else if (nameSort === "z-a") {
        filteredData.sort((a, b) => b.product.localeCompare(a.product));
      }
      if (priceSort === "low-high") {
        filteredData.sort(
          (a, b) => parseFloat(a.curr_price) - parseFloat(b.curr_price)
        );
      } else if (priceSort === "high-low") {
        filteredData.sort(
          (a, b) => parseFloat(b.curr_price) - parseFloat(a.curr_price)
        );
      }

      return filteredData;
    };

    const newFilteredData = filterData(
      data,
      selectedCategory,
      selectedBrands,
      nameSort,
      priceSort
    );
    setFilteredData(newFilteredData);
  }, [
    data,
    selectedCategory,
    selectedBrands,
    nameSort,
    priceSort,
    searchQuery,
  ]);
  return (
    <>
      {!isMobileScreen ? (
        <>
          <StyledBox
            sx={{
              height: "130px",
              borderRadius: "0px 0px 10px 10px",
              alignItems: "start",
              padding: "2%",
              boxShadow: "0px 4px 15px 0px rgba(230, 210, 209, 1)",
              background: "white",
            }}>
            <Stack
              flexDirection={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "6%",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "20%",
                 
                }}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>

                <Label2 variant="caption" color="info">
                  Dashboard
                </Label2>

                <Typography color="info">|</Typography>

                <Label2
                  variant="caption"
                  color="info"
                  onClick={() => navigate("/my-order")}>
                  My Orders
                </Label2>
                <Typography color="info">|</Typography>
                <Label2 variant="caption" color="info">
                  {heading}{" "}
                </Label2>
              </Box>

              <Stack flexDirection={"row"} gap={"20px"}>
                <RightMenu />
              </Stack>
            </Stack>

            <Box1 sx={{ width: "100%", marginTop: "2%" }}>
              <Label color="primary" variant="button" size="large">
                {heading}
              </Label>
              {/*  */}

              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ color: "black" }}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    fontWeight="700">
                    <GrFilter />
                    <Label variant="subtitle2"> Filters</Label>
                  </Stack>
                </Button>
                <Menu
                  sx={{}}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}>
                  <MenuItem
                    sx={{
                      paddingLeft: "20px",
                    }}>
                    <GrFilter />
                    <Box sx={{ marginLeft: "10px", fontWeight: "700" }}>
                      {" "}
                      Filter
                    </Box>
                  </MenuItem>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Divider sx={{ backgroundColor: "black", width: "90%" }} />
                  </Stack>

                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                      alignItems: "start",

                      gap: "20px",
                    }}>
                    <Stack>
                      <Label variant="body1">Category</Label>
                      {category?.map((category) => (
                        <FormControlLabel
                          key={category}
                          control={
                            <Checkbox
                              color="info"
                              checked={selectedCategory.includes(category)}
                              onChange={handleCategoryCheckboxChange}
                              name={category}
                            />
                          }
                          label={<Label2 variant="body2">{category}</Label2>}
                        />
                      ))}
                    </Stack>

                    <Stack>
                      <Label variant="body1">Brand</Label>
                      {brand?.map((brand) => (
                        <FormControlLabel
                          color="info"
                          key={brand}
                          control={
                            <Checkbox
                              checked={selectedBrands.includes(brand)}
                              onChange={handleBrandCheckboxChange}
                              name={brand}
                            />
                          }
                          label={<Label2 variant="body2">{brand}</Label2>}
                        />
                      ))}
                    </Stack>
                  </MenuItem>
                </Menu>
              </div>

              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={openSort ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSort ? "true" : undefined}
                  onClick={handleClickSort}
                  sx={{ color: "black" }}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    fontWeight="700">
                    <LuSettings2 />
                    <Label variant="subtitle2">Sort</Label>
                  </Stack>
                </Button>
                <Menu
                  sx={{}}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorElSort}
                  open={openSort}
                  onClose={handleCloseSort}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}>
                  <MenuItem>
                    <IconButton>
                      <LuSettings2 />
                    </IconButton>
                    <Label variant="subtitle2">Sort</Label>
                  </MenuItem>

                  <MenuItem>
                    <Checkbox
                      color="info"
                      value="a-z"
                      onChange={handleNameSortChange}
                      checked={nameSort === "a-z"}
                    />
                    A-Z
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      color="info"
                      value="z-a"
                      onChange={handleNameSortChange}
                      checked={nameSort === "z-a"}
                    />
                    Z-A
                  </MenuItem>

                  {/* <MenuItem>
                <Checkbox
                  value="oldest-newest"
                  onChange={handleDateSortChange}
                  checked={dateSort === "oldest-newest"}
                />
                Oldest-Newest
              </MenuItem>
              <MenuItem>
                <Checkbox
                  value="newest-oldest"
                  onChange={handleDateSortChange}
                  checked={dateSort === "newest-oldest"}
                />
                Newest-Oldest
              </MenuItem> */}
                  <MenuItem>
                    <Checkbox
                      value="low-high"
                      onChange={handlePriceSortChange}
                      checked={priceSort === "low-high"}
                    />
                    <ListItemText primary="Lowest Price" />
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      value="high-low"
                      onChange={handlePriceSortChange}
                      checked={priceSort === "high-low"}
                    />
                    <ListItemText primary="Highest Price" />
                  </MenuItem>
                </Menu>
              </div>

              <Search>
                <SearchIconWrapper>
                  <RiSearchLine size={15} style={{ color: "" }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Search>
            </Box1>
          </StyledBox>
        </>
      ) : (
        <>
          <IconButton onClick={handleDrawerOpen}>
            <RiEqualizerFill
              size={20}
              style={{ color: "rgba(135, 135, 135, 1)" }}
            />
          </IconButton>

          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            sx={{
              "& .MuiDrawer-paper": {
                marginTop: "20px",
              },
            }}>
            <Stack
              sx={{
                width: 222,
                gap: "10px",
              }}
              role="presentation"
              alignItems={"center"}
              justifyContent={"center"}>
              <Stack
                sx={{
                  width: "172px",
                  alignItems: "end",
                }}>
                <IconButton onClick={handleDrawerClose}>
                  <IoCloseSharp style={{ color: "black" }} />
                </IconButton>
              </Stack>

              <Stack
                style={{
                  width: "172px",
                  height: "49px",
                  background: "rgba(242, 242, 242, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ color: "black", textAlign: "start" }}
                  endIcon={<KeyboardArrowDownIcon />}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    fontWeight="700"
                    sx={{ width: "100px" }}>
                    <GrFilter />
                    <Label variant="subtitle2"> Filters</Label>
                  </Stack>
                </Button>
                <Menu
                  sx={{}}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}>
                  <MenuItem
                    sx={{
                      paddingLeft: "20px",
                    }}>
                    <GrFilter />
                    <Box sx={{ marginLeft: "10px", fontWeight: "700" }}>
                      {" "}
                      Filter
                    </Box>
                  </MenuItem>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Divider sx={{ backgroundColor: "black", width: "90%" }} />
                  </Stack>

                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                      alignItems: "start",

                      gap: "20px",
                    }}>
                    <Stack>
                      <Label variant="body1">Category</Label>
                      {category?.map((category) => (
                        <FormControlLabel
                          key={category}
                          control={
                            <Checkbox
                              color="info"
                              checked={selectedCategory.includes(category)}
                              onChange={handleCategoryCheckboxChange}
                              name={category}
                            />
                          }
                          label={<Label2 variant="body2">{category}</Label2>}
                        />
                      ))}
                    </Stack>

                    <Stack>
                      <Label variant="body1">Brand</Label>
                      {brand?.map((brand) => (
                        <FormControlLabel
                          color="info"
                          key={brand}
                          control={
                            <Checkbox
                              checked={selectedBrands.includes(brand)}
                              onChange={handleBrandCheckboxChange}
                              name={brand}
                            />
                          }
                          label={<Label2 variant="body2">{brand}</Label2>}
                        />
                      ))}
                    </Stack>
                  </MenuItem>
                </Menu>
              </Stack>

              <Stack
                style={{
                  width: "172px",
                  height: "49px",
                  background: "rgba(242, 242, 242, 1)",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Button
                  id="demo-positioned-button"
                  aria-controls={openSort ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSort ? "true" : undefined}
                  onClick={handleClickSort}
                  sx={{ color: "black" }}
                  endIcon={<KeyboardArrowDownIcon />}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    fontWeight="700"
                    sx={{ width: "100px" }}>
                    <LuSettings2 />
                    <Label variant="subtitle2">Sort</Label>
                  </Stack>
                </Button>
                <Menu
                  sx={{}}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorElSort}
                  open={openSort}
                  onClose={handleCloseSort}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}>
                  <MenuItem>
                    <IconButton>
                      <LuSettings2 />
                    </IconButton>
                    <Label variant="subtitle2">Sort</Label>
                  </MenuItem>

                  <MenuItem>
                    <Checkbox
                      color="info"
                      value="a-z"
                      onChange={handleNameSortChange}
                      checked={nameSort === "a-z"}
                    />
                    A-Z
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      color="info"
                      value="z-a"
                      onChange={handleNameSortChange}
                      checked={nameSort === "z-a"}
                    />
                    Z-A
                  </MenuItem>

                  <MenuItem>
                    <Checkbox
                      value="low-high"
                      onChange={handlePriceSortChange}
                      checked={priceSort === "low-high"}
                    />
                    <ListItemText primary="Lowest Price" />
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      value="high-low"
                      onChange={handlePriceSortChange}
                      checked={priceSort === "high-low"}
                    />
                    <ListItemText primary="Highest Price" />
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </Drawer>
        </>
      )}
    </>
  );
};

export default TopBar;
