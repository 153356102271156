
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
 
} from "@mui/material";

import { useNavigate } from "react-router-dom"; // Import useNavigate
import { DialogActions } from "@mui/material";
import { PiClockCounterClockwise } from "react-icons/pi";
const ReloginModal = ({ status }) => {
  const [open, setOpen] = React.useState(false);


  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload()
  };

  return (
    <Dialog
      open={
        status === "INVALID_TOKEN"
        // false
      }
      onClose={handleClose}
      sx={{  }}>
      <DialogTitle
        sx={{
         
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            color: "rgba(193, 32, 37, 1)",
          }}>
          <PiClockCounterClockwise size={30} /> Session Expired
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
         
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", fontWeight: "500" }}>
          Login again to continue.
          <br />
          You will be redirected to the Login Page.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
         
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Button
          onClick={handleClick}
          variant="contained"
          color="primary"
          sx={{ width: "100%" }}>
          Login Again
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReloginModal;
