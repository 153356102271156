import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel"; // Import the CancelIcon
import { useRef } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Loading_page from "../../components/Loading_Page";

import Grid from "@mui/material/Grid";
import { FiPlusSquare } from "react-icons/fi";
import { MdInsertComment } from "react-icons/md";
import { BsCheckSquare } from "react-icons/bs";
import { GrFilter } from "react-icons/gr";
import { RiCloseLine } from "react-icons/ri";

import {
  AppBar,
  Button,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemText,
  Paper,
  Popover,
  Stack,
  TablePagination,
  TextField,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { LuSettings2 } from "react-icons/lu";
import InputBase from "@mui/material/InputBase";
import { RiSearchLine } from "react-icons/ri";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newOrder } from "../../redux/data/action";
import { useNavigate } from "react-router-dom";
import { BiCommentDetail } from "react-icons/bi";
import { RiAddBoxFill } from "react-icons/ri";

import { IoIosAdd } from "react-icons/io";
import { IoIosRemove } from "react-icons/io";
import RequestProduct from "./RequestProduct";
import SeeOrder from "./SeeOrder";

import { useMemo } from "react";
import { useTheme } from "@emotion/react";
import Navbar from "../../MobileView/Navbar";
import DrawerBox from "../CommonComponent/Drawer";
import RightMenu from "../OnBoarding/RightMenu";
import NumberFormat from "../../components/NumberFormat";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",
}));

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
}));
const Label3 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,

  cursor: "pointer",
  fontSize: "small",
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",

  minHeight: "621px",
  justifyContent: "space-between",
  gap: "20px",
}));

const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  textAlign: "start",
  width: "200px",
  overflow: "overlay",
  height: "543px",
  minHeight: "543px",
  borderRadius: "10px",
  paddingTop: "10px",
  paddingBottom: "10px",
  justifyContent: "space-between",
  gap: "20px",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));
const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "start",

  flexDirection: "column",

  width: "70%",
  gap: "13px",

  textAlign: "start",
  borderRadius: "2px ",
  justifyContent: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const Search3 = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper2 = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase2 = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const appStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  background: "white",
};

const contentStyle = {
  flexGrow: 1,
  overflowY: "auto",
  paddingTop: 16, // Adjust as needed
  paddingBottom: 16, // Adjust as needed
};

const footerStyle = {
  backgroundColor: "#fff", // Adjust as needed
  boxShadow:
    "0px 10px 20px 0px rgb(145 136 136 / 60%), 5px 5px 15px 2px rgba(230, 210, 209, 0.3)",
  position: "sticky",
  bottom: 0,

  height: "156px",
};

const NewOrder = () => {
  const newOrderList = useSelector((store) => store.data.newOrders);

  const [data, setData] = useState([]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [errorMessages, setErrorMessages] = useState([]);

  const navigate = useNavigate();

  const datas = useMemo(() => {
    return newOrderList.map((item) => ({
      ...item,
      quantity: 0,
      comment: "",
    }));
  }, [newOrderList]);

  const dispatch = useDispatch();

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const openSort = Boolean(anchorElSort);

  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [nameSort, setNameSort] = useState(null);

  const [priceSort, setPriceSort] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalCurrentPrice, setTotalCurrentPrice] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);

  const [filterDataa, setFilteredData] = useState([]);

  const retrievedDatas = JSON.parse(localStorage.getItem("savedData"));

  /////console.log("retrievedDatas", retrievedDatas);
  const open = Boolean(anchorElFilter);
  let totalProducts = 0;
  let subtotal = 0;
  let totalCGST = 0;
  let totalSGST = 0;

  retrievedDatas &&
    retrievedDatas?.forEach((product) => {
      totalProducts = retrievedDatas?.length;
      subtotal += parseFloat(product.curr_price) * product.quantity;
      totalCGST +=
        (parseFloat(product.cgst) / 100) *
        (parseFloat(product.curr_price) * product.quantity);
      totalSGST +=
        (parseFloat(product.sgst) / 100) *
        (parseFloat(product.curr_price) * product.quantity);
    });

  const grandTotal = subtotal + totalCGST + totalSGST;
  const handleToggle = (index) => {
    setClickedIndex(index === clickedIndex ? null : index);
  };
  const handleClick = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handlePopoverOpen = (event, productId, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
    setCommentValue(comment);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedProductId("");
    setCommentValue("");
    setIsPopoverOpen(false);
  };
  const handleClose = () => {
    setAnchorElFilter(null);
  };
  const brand = [...new Set(data?.map((item) => item.brand))];
  const category = [...new Set(data?.map((item) => item.category))];

  const handleSaveComment = () => {
    const updatedData = datas.map((item) => {
      if (item.productid === selectedProductId) {
        return {
          ...item,
          comment: commentValue,
        };
      }
      return item;
    });

    setData(updatedData);

    handlePopoverClose();
  };
  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const handleBrandCheckboxChange = (event) => {
    const brand = event.target.name;
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brand)
        ? prevSelected.filter((selected) => selected !== brand)
        : [...prevSelected, brand]
    );
  };

  const filterData = (
    data,
    selectedCategory,
    selectedBrands,
    nameSort,
    priceSort
  ) => {
    let filteredData = data.filter(
      (data) =>
        (!selectedCategory || data.category === selectedCategory) &&
        (selectedBrands?.length === 0 || selectedBrands.includes(data.brand))
    );

    filteredData = filteredData.filter((product) =>
      product.product.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Sort data based on name
    if (nameSort === "a-z") {
      filteredData.sort((a, b) => a.product.localeCompare(b.product));
    } else if (nameSort === "z-a") {
      filteredData.sort((a, b) => b.product.localeCompare(a.product));
    }

    if (priceSort === "low-high") {
      filteredData.sort(
        (a, b) => parseFloat(a.curr_price) - parseFloat(b.curr_price)
      );
    } else if (priceSort === "high-low") {
      filteredData.sort(
        (a, b) => parseFloat(b.curr_price) - parseFloat(a.curr_price)
      );
    }

    return filteredData;
  };
  const filteredData = filterData(
    data,
    selectedCategory,
    selectedBrands,
    nameSort,
    priceSort
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
  };
  const handleNameSortChange = (event) => {
    setNameSort(event.target.checked ? event.target.value : null);
  };

  const handlePriceSortChange = (event) => {
    setPriceSort(event.target.checked ? event.target.value : null);
  };

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  useEffect(() => {
    if (datas && datas?.length > 0) {
      setData(datas);
    }
  }, [datas]);

  useEffect(() => {
    dispatch(newOrder());
  }, []);

  // const handleIncrement = (index) => {
  //   const updatedQuantities = [...data];
  //   updatedQuantities[index].quantity =
  //     (updatedQuantities[index].quantity || 0) + 1;
  //   setData(updatedQuantities);
  // };

  // const handleDecrement = (index) => {
  //   if (data[index].quantity > 0) {
  //     const updatedQuantities = [...data];
  //     updatedQuantities[index].quantity -= 1;
  //     setData(updatedQuantities);
  //   }
  // };

  const handleAddToSavedData = (row, index) => {
    if (row.quantity === 0) {
      setErrorMessages((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "Enter quantity";
        return newErrors;
      });
      return;
    }

    setErrorMessages((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = "";
      return newErrors;
    });

    const prevPrice = parseFloat(row.prev_price);
    const currPrice = parseFloat(row.curr_price);
    const savings = prevPrice - currPrice;

    const updatedRowData = { ...row, savings: savings };

    const savedDataString = localStorage.getItem("savedData");
    let savedData = savedDataString ? JSON.parse(savedDataString) : [];

    savedData.push(updatedRowData);

    localStorage.setItem("savedData", JSON.stringify(savedData));

    let totalQuantity = 0;
    let totalCurrentPrice = 0;

    savedData.forEach((item) => {
      totalQuantity += parseInt(item.quantity);

      totalCurrentPrice += parseInt(item.curr_price);
    });

    setTotalQuantity(totalQuantity);
    setTotalCurrentPrice(totalCurrentPrice);
    // dispatch(newOrder());
  };

  const isRowSaved = (row) => {
    const savedDataString = localStorage.getItem("savedData");
    const savedData = savedDataString ? JSON.parse(savedDataString) : [];
    return savedData.some((item) => item.productid === row.productid);
  };

  const handleQuantityChange = (e, productId) => {
    // Set quantity to 0 if the input is empty; otherwise, parse the input value
    const newQuantity =
      e.target.value === "" ? 0 : parseInt(e.target.value, 10);

    const updatedData = data.map((item) =>
      item.productid === productId ? { ...item, quantity: newQuantity } : item
    );
    setData(updatedData);

    // Clear the error message for the specific item
    setErrorMessages((prevErrors) => {
      const newErrors = { ...prevErrors, [productId]: "" };
      return newErrors;
    });
  };

  const handleIncrement = (productId) => {
    const updatedData = data.map((item) =>
      item.productid === productId
        ? { ...item, quantity: (item.quantity || 0) + 1 }
        : item
    );
    setData(updatedData);
  };

  const handleDecrement = (productId) => {
    const updatedData = data.map((item) =>
      item.productid === productId && item.quantity > 0
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setData(updatedData);
  };

  useEffect(() => {
    setErrorMessages(Array(data?.length).fill(""));
  }, [data]);
  /////console.log("search", searchQuery);
  /////console.log("filter", filterDataa);

  const searchRef = useRef(null);
  const handleClearSearch = () => {
    setSearchQuery("");
  };

  // Clear search when clicking outside the search component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      {!isMobileScreen ? (
        <>
          <StyledBox
            sx={{
              height: "130px",
              borderRadius: "0px 0px 10px 10px",
              alignItems: "start",
              padding: "2%",
              boxShadow: "0px 4px 15px 0px rgba(230, 210, 209, 1)",
              background: "white",
            }}
          >
            <Stack
              flexDirection={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              sx={{}}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "6%",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "20%",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>

                <Label2 variant="caption" color="info">
                  Dashboard
                </Label2>

                <Typography color="info">|</Typography>

                <Label2
                  variant="caption"
                  color="info"
                  onClick={() => navigate("/my-order")}
                >
                  My Orders
                </Label2>
                <Typography color="info">|</Typography>
                <Label2 variant="caption" color="info">
                  New order
                </Label2>
              </Box>

              <Stack flexDirection={"row"} gap={"20px"}>
                <RightMenu />
              </Stack>
            </Stack>
            <Box1 sx={{ width: "100%", marginTop: "2%" }}>
              <Label color="primary" variant="subtitle1" size="large">
                NEW ORDER
              </Label>

              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ color: "black" }}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    fontWeight="700"
                  >
                    <GrFilter />
                    <Label variant="subtitle2"> Filters</Label>
                  </Stack>
                </Button>
                <Menu
                  sx={{}}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorElFilter}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    sx={{
                      paddingLeft: "20px",
                    }}
                  >
                    <GrFilter />
                    <Box sx={{ marginLeft: "10px", fontWeight: "700" }}>
                      {" "}
                      Filter
                    </Box>
                  </MenuItem>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Divider sx={{ backgroundColor: "black", width: "90%" }} />
                  </Stack>

                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                      alignItems: "start",

                      gap: "20px",
                    }}
                  >
                    <Stack>
                      <Stack
                        sx={{ color: "rgba(193, 32, 37, 1)" }}
                        variant="body1"
                      >
                        Brand
                      </Stack>

                      {brand?.map((brand) => (
                        <FormControlLabel
                          color="info"
                          key={brand}
                          control={
                            <Checkbox
                              checked={selectedBrands.includes(brand)}
                              onChange={handleBrandCheckboxChange}
                              name={brand}
                            />
                          }
                          label={<Label2 variant="body2">{brand}</Label2>}
                        />
                      ))}
                    </Stack>
                  </MenuItem>
                </Menu>
              </div>

              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={openSort ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSort ? "true" : undefined}
                  onClick={handleClickSort}
                  sx={{ color: "black" }}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    fontWeight="700"
                  >
                    <LuSettings2 />
                    <Label variant="subtitle2">Sort</Label>
                  </Stack>
                </Button>
                <Menu
                  sx={{}}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorElSort}
                  open={openSort}
                  onClose={handleCloseSort}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem>
                    <IconButton>
                      <LuSettings2 />
                    </IconButton>
                    <Label variant="subtitle2">Sort</Label>
                  </MenuItem>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Divider sx={{ backgroundColor: "black", width: "90%" }} />
                  </Stack>
                  <MenuItem sx={{ color: "rgba(193, 32, 37, 1)" }}>
                    Name sort
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      value="a-z"
                      onChange={handleNameSortChange}
                      checked={nameSort === "a-z"}
                    />
                    A-Z
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      value="z-a"
                      onChange={handleNameSortChange}
                      checked={nameSort === "z-a"}
                    />
                    Z-A
                  </MenuItem>

                  <MenuItem sx={{ color: "rgba(193, 32, 37, 1)" }}>
                    Price sort
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      value="low-high"
                      onChange={handlePriceSortChange}
                      checked={priceSort === "low-high"}
                    />
                    <ListItemText primary="Low-High" />
                  </MenuItem>
                  <MenuItem>
                    <Checkbox
                      value="high-low"
                      onChange={handlePriceSortChange}
                      checked={priceSort === "high-low"}
                    />
                    <ListItemText primary="High-Low" />
                  </MenuItem>
                </Menu>
              </div>

              {/* <Search>
                <SearchIconWrapper>
                  <RiSearchLine size={15} style={{ color: "" }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Search> */}
              <Search ref={searchRef} sx={{ display: "flex" }}>
                <SearchIconWrapper>
                  <RiSearchLine size={15} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {searchQuery && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={handleClearSearch} size="small">
                      <RiCloseLine size={15} />
                    </IconButton>
                  </Box>
                )}
              </Search>
            </Box1>
          </StyledBox>
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              alignItems: "start",
            }}
          >
            <LeftWrapper>
              {/* <LeftBox1>
    <LeftBox sx={{ gap: "20px" }}>
      <Label>Category</Label>
      {category.map((category) => (
        <Label3
          key={category}
          style={{
            marginRight: "10px",
            cursor: "pointer",
            textDecoration:
              category === selectedCategory ? "underline" : "none",
          }}
          onClick={() => handleCategoryClick(category)}
        >
          {category}
        </Label3>
      ))}

      {selectedCategory && (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <span>{selectedCategory}</span>
          <CancelIcon
            onClick={() => {
              setSelectedCategory(null); 
            }}
            style={{
              cursor: "pointer",
              color: "red", 
            }}
          />
        </div>
      )}
    </LeftBox>
  </LeftBox1> */}
              <LeftWrapper>
                <LeftBox1>
                  <LeftBox sx={{ gap: "20px" }}>
                    <Label>Category</Label>
                    {category.map((category) => (
                      <Label3
                        key={category}
                        style={{
                          cursor: "pointer",
                          textDecoration:
                            category === selectedCategory
                              ? "underline"
                              : "none",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        onClick={() => handleCategoryClick(category)}
                      >
                        <span>{category}</span>

                        {category === selectedCategory && (
                          <CancelIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedCategory(null);
                            }}
                            style={{
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        )}
                      </Label3>
                    ))}
                  </LeftBox>
                </LeftBox1>

                <RequestProduct />
              </LeftWrapper>
              {/* <RequestProduct /> */}
            </LeftWrapper>

            <Stack
              justifyContent="space-between"
              sx={{ width: "100%", height: "100%" }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      height:
                        datas?.length > 0 && filteredData?.length > 0
                          ? "543px"
                          : "543px",
                      maxHeight: "543px",
                      display: "flex",
                      flexDirection: "column",

                      width: "100%",
                      overflowY: "auto",

                      boxShadow:
                        "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                    }}
                  >
                    <React.Fragment>
                      {datas?.length > 0 && filteredData?.length > 0 ? (
                        <>
                          {" "}
                          <Table size="small" stickyHeader style={{}}>
                            <TableHead>
                              <TableRow>
                                <TableHeading>
                                  <Label variant="caption"> PROD NAME</Label>
                                </TableHeading>
                                <TableHeading>
                                  <Label variant="caption"> BRAND</Label>
                                </TableHeading>
                                <TableHeading>
                                  <Label variant="caption">HSN</Label>
                                </TableHeading>
                                {/* <TableHeading>
                                  <Label variant="caption"> CATEGORY</Label>
                                </TableHeading> */}
                                <TableHeading>
                                  <Label variant="caption"> /PKG</Label>
                                </TableHeading>
                                <TableHeading>
                                  <Label variant="caption"> PRICE</Label>
                                </TableHeading>
                                <TableHeading>
                                  <Label variant="caption"> CGST</Label>
                                </TableHeading>
                                <TableHeading>
                                  <Label variant="caption"> SGST</Label>
                                </TableHeading>
                                <TableHeading
                                  sx={{
                                    color: "rgba(193, 32, 37, 1)",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <Label variant="caption"> QTY</Label>
                                </TableHeading>
                                <TableHeading
                                  sx={{
                                    color: "rgba(193, 32, 37, 1)",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <Label variant="caption"> CMT</Label>
                                </TableHeading>
                                {/* <TableHeading
                                  sx={{
                                    textAlign: "center",
                                    color: "rgba(193, 32, 37, 1)",
                                    display: "flex",
                                    // justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <BiCommentDetail size={20} />
                                </TableHeading> */}
                                <TableHeading>
                                  <Label variant="caption"> </Label>
                                </TableHeading>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => (
                                  <TableRow
                                    key={row.productid}
                                    sx={{
                                      "&:hover": {
                                        bgcolor: "rgba(255, 237, 237, 1)",
                                      },
                                    }}
                                    style={{
                                      boxShadow:
                                        "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                    }}
                                  >
                                    <TableRows>
                                      {row.product?.length > 11 ? (
                                        <Tooltip title={row.product}>
                                          <Label3
                                            variant="caption"
                                            style={{
                                              maxWidth: "11ch",

                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "normal",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {row.product}
                                          </Label3>
                                        </Tooltip>
                                      ) : (
                                        <Label3
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {row.product}
                                        </Label3>
                                      )}
                                    </TableRows>
                                    <TableRows>
                                      <Tooltip title={row.brand}>
                                        <Label3 variant="caption">
                                          {row.brand}
                                        </Label3>
                                      </Tooltip>
                                    </TableRows>
                                    <TableRows>
                                      <Label3 variant="caption">
                                        {row.hsn}
                                      </Label3>
                                    </TableRows>

                                    {/* <TableRows>
                                      {row.category?.length > 11 ? (
                                        <Tooltip title={row.category}>
                                          <Label3
                                            variant="caption"
                                            style={{
                                              maxWidth: "11ch",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {row.category.slice(0, 9) + "..."}
                                          </Label3>
                                        </Tooltip>
                                      ) : (
                                        <Label3
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {row.category}
                                        </Label3>
                                      )}
                                    </TableRows> */}

                                    <TableRows>
                                      <Label3 variant="caption">
                                        {row.packsize}
                                      </Label3>
                                    </TableRows>
                                    <TableRows>
                                      <Label3 variant="caption">
                                        {row.curr_price}
                                      </Label3>
                                    </TableRows>

                                    <TableRows>
                                      <TableText variant="caption">
                                        {row.cgst}%
                                      </TableText>
                                    </TableRows>
                                    <TableRows>
                                      <TableText variant="caption">
                                        {row.sgst}%
                                      </TableText>
                                    </TableRows>
                                    <TableRows
                                      style={{
                                        background: "rgba(255, 246, 246, 1)",
                                        border: "1px solid white",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      <TextField
                                        value={row.quantity ?? 0}
                                        onChange={(e) =>
                                          handleQuantityChange(e, row.productid)
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <IconButton
                                              onClick={() =>
                                                handleDecrement(row.productid)
                                              }
                                            >
                                              <IoIosRemove size={16} />
                                            </IconButton>
                                          ),
                                          endAdornment: (
                                            <IconButton
                                              onClick={() =>
                                                handleIncrement(row.productid)
                                              }
                                            >
                                              <IoIosAdd size={16} />
                                            </IconButton>
                                          ),
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input": {
                                            textAlign: "center",
                                            width: "20px",
                                            fontSize: "12px",
                                          },
                                          "& fieldset": { border: "none" },
                                          padding: 0,
                                        }}
                                      />

                                      {errorMessages[index] && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          {errorMessages[index]}
                                        </div>
                                      )}
                                    </TableRows>
                                    <TableRows
                                      sx={{
                                        background: "rgba(255, 246, 246, 1)",
                                        border: "1px solid white",
                                      }}
                                    >
                                      <TableText variant="caption">
                                        {!isRowSaved(row) && (
                                          <Stack
                                            sx={{
                                              height: "100%",
                                              width: "40px",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                            onClick={(event) =>
                                              handlePopoverOpen(
                                                event,
                                                row.productid,
                                                row.comment
                                              )
                                            }
                                          >
                                            {row.comment === "" ? (
                                              "comments"
                                            ) : (
                                              <BiCommentDetail size={20} />
                                            )}
                                          </Stack>
                                        )}

                                        <Popover
                                          open={
                                            isPopoverOpen &&
                                            selectedProductId === row.productid
                                          }
                                          anchorEl={anchorEl}
                                          onClose={handlePopoverClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-around",
                                              flexDirection: "column",
                                              width: "200px",
                                              minHeight: "190px",
                                              padding: "10px",
                                            }}
                                          >
                                            <TextField
                                              autoFocus
                                              multiline
                                              value={commentValue}
                                              onChange={(e) =>
                                                setCommentValue(e.target.value)
                                              }
                                            />

                                            <Stack
                                              sx={{ marginTop: 2 }}
                                              flexDirection="row"
                                              justifyContent="space-around"
                                            >
                                              <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={handlePopoverClose}
                                                sx={{ width: "54px" }}
                                              >
                                                Cancel
                                              </Button>

                                              <Button
                                                size="small"
                                                variant="contained"
                                                onClick={handleSaveComment}
                                                sx={{ width: "54px" }}
                                              >
                                                Save
                                              </Button>
                                            </Stack>
                                          </Box>
                                        </Popover>
                                      </TableText>
                                    </TableRows>

                                    <TableRows
                                      style={{
                                        background: "rgba(255, 246, 246, 1)",
                                        border: "1px solid white",
                                        // display: "flex",
                                        // justifyContent: "center",
                                        // alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      {!isRowSaved(row) ? (
                                        // <RiAddBoxFill
                                        //   onClick={() =>
                                        //     handleAddToSavedData(row, index)
                                        //   }
                                        //   size={20}
                                        //   style={{
                                        //     color: "rgba(193, 32, 37, 1)",
                                        //   }}
                                        // />

                                        <Button
                                          size="small"
                                          sx={{
                                            height: "25px",
                                            width: "45px",
                                            minWidth: 0,
                                          }}
                                          variant="contained"
                                          onClick={() =>
                                            handleAddToSavedData(row, index)
                                          }
                                        >
                                          <Typography variant="caption">
                                            {" "}
                                            Add
                                          </Typography>
                                        </Button>
                                      ) : (
                                        <BsCheckSquare
                                          size={20}
                                          style={{
                                            color: "rgba(193, 32, 37, 1)",
                                          }}
                                        />
                                      )}
                                    </TableRows>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                          {filteredData?.length > rowsPerPage && (
                            <TablePagination
                              sx={{ width: "95%" }}
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={filteredData?.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Box1
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              flexDirection: "column",
                              gap: "20px",
                            }}
                          >
                            <Typography
                              sx={{ color: "rgba(183, 183, 183, 1)" }}
                            >
                              Can’t find products?
                            </Typography>
                            <Label2 sx={{ color: "rgba(183, 183, 183, 1)" }}>
                              Place a request and our team will get back to you
                              soon
                            </Label2>
                            <RequestProduct />
                          </Box1>
                        </>
                      )}
                    </React.Fragment>
                  </Paper>
                </Grid>
              </Grid>

              <SeeOrder
                totalQuantity={totalQuantity}
                totalCurrentPrice={totalCurrentPrice}
                data={data}
              />
            </Stack>
          </StyledBox>
        </>
      ) : (
        <>
          <div style={appStyle}>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"New Order"} />
            </AppBar>
            <Toolbar />

            <Box
              sx={{
                height: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
                marginBottom: "auto",

                mt: 4,
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "90%",

                  borderRadius: "10px",
                  height: "40px",

                  boxShadow:
                    "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                }}
              >
                <Search3>
                  <SearchIconWrapper2>
                    <RiSearchLine size={15} style={{ color: "" }} />
                  </SearchIconWrapper2>
                  <StyledInputBase2
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search3>

                <DrawerBox
                  data={data}
                  setData={setData}
                  filteredData={filterDataa}
                  setFilteredData={setFilteredData}
                  heading={"Edit Order"}
                  Filter1="category"
                  Filter2="brand"
                  sortPrice="curr_price"
                  sortDate="date"
                  name="product"
                  show="show"
                  // searchQuery={searchQuery}
                  // setSearchQuery={setSearchQuery}
                />
              </Stack>
            </Box>
            <Container component="main" style={contentStyle}>
              <Box mt={4}>
                {filterDataa.map((item, index) => (
                  <>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        // padding: "10px",
                        marginBottom: "10px",
                        bgcolor: "white",
                        minHeight: "110px",
                        marginTop: "4%",
                        width: "98%",
                        gap: "10px",
                      }}
                    >
                      <Stack
                        sx={{
                          width: "90%",
                          padding: "10px",
                          gap: "10px",
                          borderRadius: "10px",
                          boxShadow:
                            " 0px 0px 20px 0px rgba(230, 210, 209, 0.6)",
                          bgcolor:
                            clickedIndex === index
                              ? "rgba(255, 246, 246, 1)"
                              : "white",
                          justifyContent: "space-around",
                        }}
                      >
                        <Stack
                          onClick={() =>
                            navigate(`/order-summary/${item.product}`)
                          }
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Label
                            variant="subtitle1"
                            style={{
                              maxWidth: "11ch",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              textDecoration: "none",
                            }}
                          >
                            {item.product}
                          </Label>
                          <Label variant="subtitle2">{item.brand}</Label>
                        </Stack>

                        {clickedIndex === index ? (
                          <Stack
                            sx={{
                              bgcolor: "rgba(255, 246, 246, 1)",
                              justifyContent: "space-between",
                            }}
                            flexDirection={"row"}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                color: "rgba(255, 246, 246, 1)",
                                height: "100%",
                                width: "109px",
                                border: "1px solid rgba(193, 32, 37, 1)",
                              }}
                              onClick={(event) =>
                                handlePopoverOpen(
                                  event,
                                  item.productid,
                                  item.comment
                                )
                              }
                            >
                              <MdInsertComment
                                style={{ color: "rgba(193, 32, 37, 1)" }}
                              />
                            </Button>

                            <Popover
                              open={
                                isPopoverOpen &&
                                selectedProductId === item.productid
                              }
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  flexDirection: "column",
                                  width: "200px",
                                  minHeight: "100px",
                                  padding: "10px",
                                }}
                              >
                                {item.comment === "" ? (
                                  <>
                                    <TextField
                                      autoFocus
                                      multiline
                                      value={commentValue}
                                      onChange={(e) =>
                                        setCommentValue(e.target.value)
                                      }
                                    />

                                    <Stack
                                      sx={{ marginTop: 2 }}
                                      flexDirection="row"
                                      justifyContent="space-around"
                                    >
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={handlePopoverClose}
                                        sx={{ width: "54px" }}
                                      >
                                        Cancel
                                      </Button>

                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={handleSaveComment}
                                        sx={{ width: "54px" }}
                                      >
                                        Save
                                      </Button>
                                    </Stack>
                                  </>
                                ) : (
                                  <Typography
                                    sx={{
                                      width: "90%",
                                      height: "90%",
                                      display: "flex",
                                      wordBreak: "break-word",
                                    }}
                                    alignItems={"center"}
                                    justifyContent={"start"}
                                  >
                                    {item.comment}
                                  </Typography>
                                )}
                              </Box>
                            </Popover>

                            <Stack
                              style={{
                                border: "1px solid rgba(193, 32, 37, 1)",
                                height: "30px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "109px",
                                color: "rgba(193, 32, 37, 1)",
                              }}
                            >
                              <TextField
                                style={{ color: "rgba(193, 32, 37, 1)" }}
                                value={
                                  item.quantity === undefined
                                    ? 0
                                    : item.quantity
                                }
                                onChange={(e) => handleQuantityChange(e, index)}
                                InputProps={{
                                  startAdornment: (
                                    <IconButton
                                      onClick={() => handleDecrement(index)}
                                    >
                                      <IoIosRemove
                                        size={16}
                                        style={{
                                          color: "rgba(193, 32, 37, 1)",
                                        }}
                                      />
                                    </IconButton>
                                  ),
                                  endAdornment: (
                                    <IconButton
                                      onClick={() => handleIncrement(index)}
                                    >
                                      <IoIosAdd
                                        size={16}
                                        style={{
                                          color: "rgba(193, 32, 37, 1)",
                                        }}
                                      />
                                    </IconButton>
                                  ),
                                }}
                                sx={{
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                    width: "20px",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    color: "rgba(193, 32, 37, 1)",
                                  },
                                  "& fieldset": { border: "none" },
                                  padding: 0,
                                }}
                              />
                            </Stack>
                          </Stack>
                        ) : (
                          <>
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label3 variant="caption">
                                ₹<NumberFormat number={item.curr_price} />
                              </Label3>
                              <Label3 variant="caption">
                                {item.packsize}/pkg
                              </Label3>
                              <Label3 variant="caption">{item.hsn}</Label3>
                            </Stack>
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              sx={{}}
                            >
                              <Label3 variant="caption">
                                {item.cgst}% cgst
                              </Label3>
                              <Label3 variant="caption">
                                {item.sgst}% sgst
                              </Label3>
                            </Stack>
                          </>
                        )}
                      </Stack>
                      <Stack
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          width: "10%",
                        }}
                      >
                        <Box onClick={() => handleToggle(index)}>
                          {!isRowSaved(item) && (
                            <>
                              {clickedIndex === index ? (
                                <BsCheckSquare
                                  onClick={() =>
                                    handleAddToSavedData(item, index)
                                  }
                                  size={20}
                                  style={{
                                    color: "rgba(193, 32, 37, 1)",
                                  }}
                                />
                              ) : (
                                <FiPlusSquare
                                  size={20}
                                  style={{
                                    color: "rgba(193, 32, 37, 1)",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </>
                ))}
              </Box>
            </Container>
            <footer style={footerStyle}>
              <Container maxWidth="sm" sx={{ height: "100%" }}>
                <Stack
                  sx={{
                    height: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <Stack
                    style={{
                      flexDirection: "row",

                      // border: "2px solid rgba(255, 255, 255, 1)",
                      justifyContent: "space-around",
                      height: "56px",
                      alignItems: "center",
                      borderRadius: "10px",

                      boxShadow:
                        "0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                      border: "1px solid red",
                      padding: 5,
                    }}
                  >
                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        {totalProducts} nos.{" "}
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}
                      >
                        {" "}
                        Products
                      </Label>
                    </Stack>

                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹<NumberFormat number={subtotal} />
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}
                      >
                        Sub Total
                      </Label>
                    </Stack>

                    <Stack flexDirection="column" gap="10px">
                      <Label sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹<NumberFormat number={grandTotal} />
                      </Label>
                      <Label
                        variant="body2"
                        sx={{ color: "rgba(146, 146, 146, 1)" }}
                      >
                        Grand Total
                      </Label>
                    </Stack>
                  </Stack>

                  <Stack
                    flexDirection="row"
                    justifyContent={"space-between"}
                    gap="10px"
                    sx={{}}
                  >
                    <RequestProduct />

                    <Button
                      onClick={() => navigate("/edit-order")}
                      sx={{ width: "148px", height: "45px" }}
                      disabled={!datas || datas?.length === 0}
                      variant="contained"
                    >
                      <Label variant="caption"> Edit order</Label>
                    </Button>
                  </Stack>
                </Stack>
              </Container>
            </footer>
          </div>
        </>
      )}
      <Loading_page />
    </>
  );
};

export default NewOrder;
