// import { createTheme } from "@mui/material";

// const Theme = createTheme({
//   palette: {
//     primary: {
//       main: "#C12025",
//     },
//     secondary: {
//       main: "#FFFFFF",
//     },
//     error:{
//       main:"#C12025"
//     },
//     info:{
//       main:"#000000"
//     }
//   },
//   typography: {
//     fontFamily: "Inter",
//     color: "rgba(193, 32, 37, 1)",
//     fontWeight: 100,
//   },
//   shape: {
//     borderRadius: 10,
//   },
//   overrides: {
//     MuiButton: {
//       root: {
//         borderRadius: 30,
//         textTransform: "none",
//         minWidth: "200px",
//       },
//     },
//     MuiCard: {
//       root: {
//         padding: 30,
//       },
//     },
//   },
//   props: {
//     MuiButton: {
//       variant: "contained",
//       color: "primary",
//     },
//     MuiTextField: {
//       variant: "filled",
//       InputLabelProps: {
//         shrink: true,
//       },
//     },
//     MuiPaper: {
//       elevation: 5,
//     },
//     MuiCheckbox: {
//       disableRipple: true,
//     },
//   },
// });
// export default Theme;

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    fontSize: 14,
    // Add more typography styles as needed
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: "none", 
       
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          flexDirection:"column"
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#C12025",
    },
    secondary: {
      main: "#FFFFFF",
    },
    error: {
      main: "#C12025",
    },
    info: {
      main: "#000000",
    },
  },
  sizing: {
    width: {
      default: 600, // Default width for elements
      // Add more width options as needed
    },
    height: {
      default: 400, // Default height for elements
      // Add more height options as needed
    },
  },
});

export default theme;
