import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { GoSquareFill } from "react-icons/go";

import {
  Button,
  Popover,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { dashboardAnalytics } from "../../redux/data/action";
import { MdSavings } from "react-icons/md";
import { MdArrowOutward } from "react-icons/md";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import BasicTabs from "./Tab";
import Graph from "./Graph";
import { MdDateRange } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";

import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { SemiCircleProgress } from "react-semicircle-progressbar";
import NumberFormat from "../../components/NumberFormat";

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  textDecoration: "none",
  color: "black",
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",
}));

const GraphMainBox = styled(Stack)(({ theme }) => ({
  display: "flex",

  alignItems: "start",
}));

const GraphBox = styled(Stack)(({ theme }) => ({
  display: "flex",

  alignItems: "start",
}));
const Boxes = styled(Stack)(({ theme }) => ({
  border: "2px solid rgba(255, 255, 255, 1)",

  height: "25%",
  justifyContent: "space-around",
  alignItems: "start",
  padding: "15px",
  backgroundColor: "white",
  borderRadius: "10px",

  boxShadow:
    "0px 10px 20px 0px rgba(230, 210, 209, 0.6) ,5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));

const Boxes2 = styled(Stack)(({ theme }) => ({
  border: "2px solid rgba(255, 255, 255, 1)",

  height: "25%",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: "white",
  borderRadius: "10px",

  boxShadow:
    "0px 10px 20px 0px rgba(230, 210, 209, 0.6) ,5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));
export default function OrderTabs({
  topBrandsSection,
  topProdsSection,
  analyticsGraphSection,
}) {
  /////console.log("analyticsGraphSection", analyticsGraphSection);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const analyticData = useSelector((store) => store.data.dashboardAnalytics);
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  let branch = JSON.parse(localStorage.getItem("branchData"));
  const [orders, setOrders] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [monthData, setMonthData] = useState({});
  /////console.log("monthData", monthData);
  const [lastDateOrders, setLastDateOrders] = useState([]);
  const dispatch = useDispatch();
  /////console.log("lastDateOrders", lastDateOrders);

  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentMonthName, setCurrentMonthName] = useState(
    moment().format("MMM")
  );
  /////console.log("cu", currentYear, currentMonthName);

  /////console.log("cu", currentMonth, currentYear);

  const [dateState, setDateState] = useState(new Date());
  /////console.log("dateState", dateState);
  const [selectedOrders, setSelectedOrders] = useState([]);
  /////console.log("selectedOrders", selectedOrders);
  const [lastMonth, setLastMonth] = useState("");
  const [selectData, setSelectDate] = useState("");
  /////console.log("selectedDate", selectData, "lastMonth", lastMonth);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    setCurrentMonth(moment(dateState).format("MM"));
    setCurrentYear(moment(dateState).format("YYYY"));
    const selectedDate = moment(dateState).format("DD");
    /////console.log("selectedDate", selectedDate);
    setSelectDate(selectedDate);
    const selectedOrders = orders.filter(
      (order) => order.orderdate === selectedDate
    );
    setSelectedOrders(selectedOrders);
  }, [dateState]);

  const highlightDates = ({ date }) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    const isWeekend = date.getDay() === 0 || date.getDay() === 6; // Sunday: 0, Saturday: 6
    const orderDateMatch = orders.some(
      (order) =>
        moment(date)
          .set({
            year: parseInt(currentYear),
            month: parseInt(currentMonth) - 1,
            date: parseInt(order.orderdate),
          })
          .format("YYYY-MM-DD") === formatDate
    );
    return orderDateMatch && !isWeekend ? "highlight" : null;
  };

  const changeDate = (e) => {
    setDateState(e);
  };

  const handleMonthChange = (activeStartDate) => {
    setCurrentMonth(moment(activeStartDate).format("MM"));
    setCurrentYear(moment(activeStartDate).format("YYYY"));
    setCurrentMonthName(moment(activeStartDate).format("MMM"));
    setSelectedOrders([]);
    setLastDateOrders([]);
  };

  useEffect(() => {
    let requestData = {
      userid: userData.userid,
      branchid: branch.branchId,
      hospid: userData.hospid,
      month: currentMonth,
      year: currentYear,
    };

    dispatch(dashboardAnalytics(requestData));
  }, [currentMonth, currentYear]);
  useEffect(() => {
    if (analyticData) {
      setOrders(analyticData.analyticsdata_section || []);
      setGraphData(analyticData.analyticsdata_section_graph);
      setMonthData(analyticData.analyticsgraph_section);

      if (
        analyticData.analyticsdata_section &&
        analyticData.analyticsdata_section?.length > 0
      ) {
        // Extract all the order dates from the data
        const orderDates = analyticData.analyticsdata_section.map((order) =>
          parseInt(order.orderdate)
        );

        // Find the maximum order date, which will be the last date of the month
        const lastDateOfMonth = moment
          .max(orderDates.map((date) => moment(date, "DD")))
          .format("DD");
        /////console.log("lastDateOfMonth", lastDateOfMonth);
        setLastMonth(lastDateOfMonth);

        // Filter orders for the last date of the month
        const lastDateOrders = analyticData.analyticsdata_section.filter(
          (order) => order.orderdate === lastDateOfMonth
        );

        setLastDateOrders(lastDateOrders);
      }
    }
  }, [analyticData]);

  /////console.log("ssssssssssssssss", selectedOrders);

  const calculateTotalSavings = (data) => {
    const savingsMap = {};
    data.forEach((item) => {
      const orderDate = item.orderdate;
      const savings = parseFloat(item.totsavings);
      if (savingsMap[orderDate]) {
        savingsMap[orderDate] += savings;
      } else {
        savingsMap[orderDate] = savings;
      }
    });
    return savingsMap;
  };

  // Calculate total savings
  const totalSavingsMap = calculateTotalSavings(orders);

  // Convert total savings map back to array format
  const newData = Object.keys(totalSavingsMap).map((orderdate) => ({
    orderdate,
    totsavings: totalSavingsMap[orderdate].toString(),
  }));

  /////console.log(newData);
  const date = [
    ...new Set(
      (selectedOrders?.length > 0 ? selectedOrders : lastDateOrders)?.map(
        (item) => item.orderdate
      )
    ),
  ];
  /////console.log("date", date);
  return (
    <>
      <style>{`
    .highlight {
      color: rgba(193, 32, 37, 1);
      font-weight: bold;
    }
    .react-calendar {
      width: 100%;
      height: 250px;
      font-family: Inter;
      font-size: 14px;
      border: 2px solid rgba(255, 255, 255, 1);
      box-shadow: 
        10px 10px 20px 0px rgba(230, 210, 209, 0.6), 
        -10px -10px 15px 0px rgba(255, 255, 255, 1), 
        5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset;
      border-radius: 10px;
    }
    .react-calendar__tile--active {
      background-color:rgba(193, 32, 37, 1);
      color: white;
      font-weight: bold;
    }
    .react-calendar__tile--highlight {
      background-color:rgba(193, 32, 37, 1);
      color: white;
    }
    .react-calendar__navigation__label {
      font-weight: bold;
      font-size: 12px;
    }
    .react-calendar__month-view__weekdays__weekday abbr {
      font-size: 10px;
      text-decoration: none;
    }
    .react-calendar__tile {
      padding: 5px;
      
    }


    // .react-calendar {
    //   width: 350px;
    //   max-width: 100%;
    //   background: white;
    //   border: 1px solid #a0a096;
    //   font-family: Arial, Helvetica, sans-serif;
    //   line-height: 1.125em;
    // }
    // .react-calendar--doubleView {
    //   width: 700px;
    // }
    // .react-calendar--doubleView .react-calendar__viewContainer {
    //   display: flex;
    //   margin: -0.5em;
    // }
    // .react-calendar--doubleView .react-calendar__viewContainer > * {
    //   width: 50%;
    //   margin: 0.5em;
    // }
    // .react-calendar,
    // .react-calendar *,
    // .react-calendar *:before,
    // .react-calendar *:after {
    //   -moz-box-sizing: border-box;
    //   -webkit-box-sizing: border-box;
    //   box-sizing: border-box;
    // }
    // .react-calendar button {
    //   margin: 0;
    //   border: 0;
    //   outline: none;
    // }
    // .react-calendar button:enabled:hover {
    //   cursor: pointer;
    // }
    // .react-calendar__navigation {
    //   height: 44px;
    //   margin-bottom: 1em;
    // }
    // .react-calendar__navigation button {
    //   min-width: 44px;
    //   background: none;
    // }
    // .react-calendar__navigation button:enabled:hover,
    // .react-calendar__navigation button:enabled:focus {
    //   background-color: #e6e6e6;
    // }
    // .react-calendar__navigation button[disabled] {
    //   background-color: #f0f0f0;
    // }
    // .react-calendar__month-view__weekdays {
    //   text-align: center;
    //   text-transform: uppercase;
    //   font-weight: bold;
    //   font-size: 0.75em;
    // }
    // .react-calendar__month-view__weekdays__weekday {
    //   padding: 0.5em;
    // }
    // .react-calendar__month-view__weekNumbers {
    //   font-weight: bold;
    // }
    // .react-calendar__month-view__weekNumbers .react-calendar__tile {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   font-size: 0.75em;
    //   padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    // }
    // .react-calendar__month-view__days__day--weekend {
    //   color: black;
    // }
    // .react-calendar__month-view__days__day--neighboringMonth {
    //   color: #af7171;
    // }
    // .react-calendar__year-view .react-calendar__tile,
    // .react-calendar__decade-view .react-calendar__tile,
    // .react-calendar__century-view .react-calendar__tile {
    //   padding: 2em 0.5em;
    // }
    // .react-calendar__tile {
    //   max-width: 100%;
    //   text-align: center;
    //   padding: 0.75em 0.5em;
    //   background: none;
    // }
    // .react-calendar__tile:disabled {
    //   background-color: #f0f0f0;
    // }
    // .react-calendar__tile:enabled:hover,
    // .react-calendar__tile:enabled:focus {
    //   background-color: #be7f7f;
    // }
    // .react-calendar__tile--now {
    //   background: white;
    // }
    // .react-calendar__tile--now:enabled:hover,
    // .react-calendar__tile--now:enabled:focus {
    //   background: white;
    // }
    // .react-calendar__tile--hasActive {
    //   background: #b46b6b;
    // }
    // .react-calendar__tile--hasActive:enabled:hover,
    // .react-calendar__tile--hasActive:enabled:focus {
    //   background: #ad6a6a;
    // }
    // .react-calendar__tile--active {
    //   background: red;
    //   color: white;
    // }
    // .react-calendar__tile--active:enabled:hover,
    // .react-calendar__tile--active:enabled:focus {
    //   background: red;
    // }
    // .react-calendar--selectRange .react-calendar__tile--hover {
    //   background-color: #9c6b6b;
    // }
  `}</style>

      <>
        {!isMobileScreen ? (
          <>
            <GraphBox
              sx={{
                borderRadius: "10px",
                width: "100%",
                height: "354px",
                bgcolor: "white",
                padding: "20px",

                boxShadow:
                  "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
              }}>
              {orders.length > 0 ? (
                <GraphMainBox
                  sx={{
                    height: "100%",
                    flexDirection: "row",

                    width: "100%",
                  }}>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      height: "100%",
                      gap: "20px",
                      alignItems: "center",
                    }}>
                    <Stack
                      sx={{
                        width: "92%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}>
                      <Label variant="h5">ANALYTICS</Label>
                      {monthData?.map((item) => (
                        <Stack gap={"15px"} flexDirection={"row"}>
                          <Label variant="body2">
                            {item.order_count} order
                          </Label>
                          <Label variant="body2">
                            ₹<NumberFormat number={Math.round(item.total_amt)}/>
                          </Label>
                          <Label
                            variant="body2"
                            sx={{ color: "rgba(255, 153, 0, 1)" }}>
                            <MdSavings /> {Math.round(item.totsavings)}% savings{" "}
                            <MdArrowOutward />
                          </Label>
                        </Stack>
                      ))}
                    </Stack>
                    <Stack
                      sx={{
                        width: "100%",
                        height: "100%",
                        flexDirection: "row",
                      }}>
                      <Graph
                        data={graphData}
                        analyticsGraphSection={analyticsGraphSection}
                      />
                      <Stack
                        sx={{
                          justifyContent: "end",
                          alignItems: "end",
                          paddingBottom: "",
                        }}>
                        <MdDateRange size={20} />
                        <Label variant="caption">{currentMonthName}</Label>
                      </Stack>
                    </Stack>
                  </Stack>
                </GraphMainBox>
              ) : (
                <>
                  <GraphMainBox
                    sx={{
                      height: "100%",
                      flexDirection: "row",

                      width: "100%",
                    }}>
                    <Stack
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        height: "100%",
                        gap: "20px",
                        alignItems: "center",
                      }}>
                      <Stack
                        sx={{
                          width: "95%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          //
                        }}>
                        <Label variant="subtitle1">ANALYTICS</Label>

                        <Stack gap={"15px"} flexDirection={"row"}>
                          <Label variant="body2">0 order</Label>
                          <Label variant="body2">₹0</Label>
                          <Label
                            variant="body2"
                            sx={{ color: "rgba(255, 153, 0, 1)" }}>
                            <MdSavings /> 0% savings <MdArrowOutward />
                          </Label>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}>
                        No data available for this month
                        <Button
                          variant="contained"
                          onClick={() => navigate("/new-order")}>
                          Place order now
                        </Button>
                      </Stack>
                    </Stack>
                  </GraphMainBox>
                </>
              )}
            </GraphBox>

            <Stack flexDirection={"row"} sx={{ gap: "20px", width: "100%" }}>
              <Stack sx={{ gap: "20px", width: "45%" }}>
                <Calendar
                  value={dateState}
                  onChange={changeDate}
                  tileClassName={highlightDates}
                  onActiveStartDateChange={({ activeStartDate }) =>
                    handleMonthChange(activeStartDate)
                  }
                />

                <BasicTabs
                  topBrandsSection={topBrandsSection}
                  topProdsSection={topProdsSection}
                />
              </Stack>

              <Grid
                container
                sx={{ width: "55%", height: "100%", borderRadius: "10px" }}>
                <Grid item xs={12} sx={{ borderRadius: "10px" }}>
                  <Paper
                    sx={{
                      height: "100%",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRadius: "10px",
                      boxShadow:
                        "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                    }}>
                    <Stack sx={{ width: "100%", height: "461px", gap: "10px" }}>
                      <Stack
                        sx={{
                          height: "100px",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          padding: "20px",
                          boxShadow:
                            "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                        }}>
                        <Label>
                          {date?.map((row, index) => (
                            <>{row}</>
                          ))}
                          &nbsp; {currentMonthName} {currentYear}
                        </Label>

                        <Stack
                          flexDirection={"row"}
                          gap="20px"
                          sx={{
                            alignItems: "center",

                            justifyContent: "center",
                          }}>
                          <Label variant="button" color="primary">
                            {selectedOrders?.length > 0
                              ? selectedOrders?.length
                              : lastDateOrders?.length}{" "}
                            Order
                          </Label>
                        </Stack>
                      </Stack>
                      <Stack
                        sx={{
                          height: "61%",
                          maxHeight: "61%",
                          overflow: "auto",
                        }}>
                        {selectedOrders?.length > 0 ||
                        lastDateOrders?.length > 0 ? (
                          <>
                            <Table size="small" style={{}}>
                              <TableHead>
                                <TableRow>
                                  <TableHeading>
                                    <Label variant="caption"> ORDER ID</Label>
                                  </TableHeading>
                                  <TableHeading>
                                    <Label variant="caption"> Value</Label>
                                  </TableHeading>
                                  <TableHeading>
                                    <Label
                                      variant="caption"
                                      style={{ color: "rgba(255, 153, 0, 1)" }}>
                                      Saved
                                    </Label>
                                  </TableHeading>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(selectedOrders?.length > 0
                                  ? selectedOrders
                                  : lastDateOrders
                                )?.map((row, index) => (
                                  <TableRow
                                    key={row.orderid}
                                    sx={{
                                      "&:hover": {
                                        bgcolor: "rgba(255, 237, 237, 1)",
                                      },
                                    }}
                                    style={{
                                      boxShadow:
                                        "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                    }}>
                                    <TableRows>
                                      {row.orderid?.length > 11 ? (
                                        <Tooltip title={row.orderid}>
                                          <TableText
                                            component={Link}
                                            to={`/order-summary/${row.orderid}`}
                                            variant="caption"
                                            style={{
                                              maxWidth: "11ch",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              textDecoration: "none",
                                            }}>
                                            {/* {row.orderid.slice(0, 9) + "..."} */}
                                            {row.orderid}
                                          </TableText>
                                        </Tooltip>
                                      ) : (
                                        <TableText
                                          component={Link}
                                          to={`/order-summary/${row.orderid}`}
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textDecoration: "none",
                                          }}>
                                          {row.orderid}
                                        </TableText>
                                      )}
                                    </TableRows>

                                    <TableRows>
                                      <TableText variant="caption">
                                      ₹<NumberFormat number={row.total_value}/>
                                      </TableText>
                                    </TableRows>
                                    <TableRows>
                                      <TableText
                                        variant="caption"
                                        style={{
                                          color: "rgba(255, 153, 0, 1)",
                                        }}>
                                        {parseFloat(
                                        row.totsavings
                                      ).toFixed(2)}
                                      %
                                      </TableText>
                                    </TableRows>
                                    <TableRows>
                                      <TableText variant="caption">
                                        <FaArrowRightLong
                                          onClick={() =>
                                            navigate(
                                              `/order-summary/${row.orderid}`
                                            )
                                          }
                                          size={15}
                                        />
                                      </TableText>
                                    </TableRows>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        ) : (
                          <>
                            <Box1
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                flexDirection: "column",
                                gap: "20px",
                              }}>
                              <Typography>
                                No orders have been placed yet.
                              </Typography>
                            </Box1>
                          </>
                        )}
                      </Stack>
                      <Stack
                        sx={{
                          width: "90%",

                          justifyContent: "end",
                          alignItems: "end",
                        }}>
                        <Button
                          onClick={() => navigate("/my-order")}
                          variant="outlined"
                          sx={{
                            border: "1px solid black",
                            width: "106px",
                            whiteSpace: "nowrap",
                            color: "black",
                            height: "45px",
                          }}>
                          <Typography variant="caption" fontWeight={700}>
                            All order
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              sx={{
                backgroundColor: "rgba(241, 241, 241, 1)",
              }}>
              <Stack
                sx={{
                  //   width: "100%",
                  gap: "20px",

                  zIndex: 1,
                }}>
                <Stack
                  sx={{ gap: "20px" }}
                  direction="row"
                  justifyContent={"space-around"}>
                  <Boxes
                    sx={{
                      height: "107px",
                      width: "45%",
                    }}>
                    <Label variant="subtitle1">March</Label>

                    <Stack>
                      <Label variant="subtitle1">12 orders </Label>
                      <Label
                        variant="subtitle1"
                        sx={{ color: "rgba(183, 183, 183, 1)" }}>
                        {" "}
                        In this month
                      </Label>
                    </Stack>
                    <Label></Label>
                  </Boxes>
                  {/* <Boxes>
                    <Label>Payment due</Label>
                    <Stack>
                      <Label variant="subtitle1">Total </Label>
                      <Label
                        variant="subtitle1"
                        sx={{ color: "rgba(193, 32, 37, 1)" }}>
                        ₹40,000
                      </Label>

                     
                    </Stack>
                    
                  </Boxes> */}
                  <Boxes2 style={{ height: "125px", padding: "5px" }}>
                    <Stack
                      style={{
                        width: 130,
                        height: 100,

                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <SemiCircleProgress
                        percentage={26}
                        size={{
                          width: 160,
                          height: 130,
                        }}
                        strokeWidth={3}
                        strokeColor="#FF9900"
                        hasBackground="#EEEEEE"
                      />
                    </Stack>

                    <Stack flexDirection={"row"} gap={"20px"}>
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <GoSquareFill style={{ color: "#FF9900" }} />
                        <Typography variant="caption"> Asthra₹</Typography>
                      </Stack>
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <GoSquareFill style={{ color: "#EEEEEE" }} />
                        <Typography variant="caption">Market₹</Typography>
                      </Stack>
                    </Stack>
                  </Boxes2>
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: "white",

                    borderRadius: "10px",
                    height: "450px",
                    justifyContent: "space-between",
                    boxShadow:
                      " 0px 10px 20px 0px rgba(230, 210, 209, 0.6),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  }}>
                  <GraphBox sx={{ height: "100%" }}>
                    <GraphMainBox
                      sx={{
                        height: "100%",
                        flexDirection: "column",

                        width: "100%",
                        //
                      }}>
                      <Stack
                        sx={{
                          width: "100%",
                          justifyContent: "space-between",
                          height: "100%",
                          gap: "10px",
                          alignItems: "center",
                          //border: "1px solid black",
                        }}>
                        <Stack
                          sx={{
                            width: "90%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "20px",
                            //
                          }}>
                          <Label variant="subtitle1">
                            {currentMonthName} {currentYear}
                          </Label>

                          <Box onClick={handleClick}>
                            {open ? (
                              <>
                                <RiArrowDropUpLine size={40} />{" "}
                              </>
                            ) : (
                              <>
                                <RiArrowDropDownLine size={40} />
                              </>
                            )}
                          </Box>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}>
                            <Calendar
                              value={dateState}
                              onChange={changeDate}
                              tileClassName={highlightDates}
                              onActiveStartDateChange={({ activeStartDate }) =>
                                handleMonthChange(activeStartDate)
                              }
                            />
                          </Popover>
                          {/*  */}
                        </Stack>
                      </Stack>
                      {orders.length > 0 ? (
                        <>
                          <Stack
                            sx={{
                              width: "100%",
                              height: "100%",
                              flexDirection: "row",
                            }}>
                            <Graph
                              data={graphData}
                              analyticsGraphSection={analyticsGraphSection}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              justifyContent: "space-between",
                              flexDirection: "column",
                              alignItems: "start",
                              width: "90%",
                              padding: "20px",
                            }}>
                            <Label>
                              {date?.map((row, index) => (
                                <>
                                  {row} {currentYear}
                                </>
                              ))}
                            </Label>

                            <Stack>
                              {selectedOrders?.length > 0 ||
                              lastDateOrders?.length > 0 ? (
                                <Label>
                                  {selectedOrders?.length > 0
                                    ? selectedOrders?.length
                                    : lastDateOrders?.length}{" "}
                                  order
                                </Label>
                              ) : (
                                <>
                                  <Label>No order</Label>
                                </>
                              )}
                            </Stack>
                            <Stack sx={{ width: "100%", marginTop: "10px" }}>
                              <Button
                                onClick={() => navigate("/my-order")}
                                variant="outlined"
                                color="info"
                                sx={{ height: "45px" }}>
                                All orders
                              </Button>
                            </Stack>
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Stack
                            sx={{
                              width: "100%",
                              height: "100%",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: "20px",
                            }}>
                            No data available for this month
                            <Button
                              variant="contained"
                              onClick={() => navigate("/new-order")}>
                              Place order now
                            </Button>
                          </Stack>
                        </>
                      )}
                    </GraphMainBox>
                  </GraphBox>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </>
    </>
  );
}
