import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import {
  LinearProgress,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FaClipboardCheck, FaTruck, FaCheck } from "react-icons/fa";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledTabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export default function CurrentOrder({ trackerSection }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeOrderId, setActiveOrderId] = useState(null);
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});

  useEffect(() => {
    if (trackerSection?.length > 0) {
      setActiveOrderId(trackerSection[0].orderid);
      setGetData(trackerSection[0]);
    }
  }, [trackerSection]);

  const handleTabChange = (event, newValue) => {
    setActiveOrderId(trackerSection[newValue].orderid);
    setGetData(trackerSection[newValue]);
  };

  const progressStatus = (order) => {
    switch (order.orderstatus) {
      case "In Progress":
        return 18.18;
      case "Shipped":
        return 50.5;
      case "Delivered":
        return 100;
      default:
        return 0;
    }
  };

  const selectedOrder =
    trackerSection.find((order) => order.orderid === activeOrderId) ||
    trackerSection[trackerSection.length - 1];
  /////console.log("selectedOrder", selectedOrder);
  return (
    <>
      {!isMobileScreen ? (
        <>
          <Stack sx={{ width: "100%", height: "100%", gap: "20px" }}>
            {trackerSection?.length > 0 ? (
              <>
                <Tabs
                  value={trackerSection.findIndex(
                    (order) => order.orderid === activeOrderId
                  )}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {trackerSection.map((order) => (
                    <Tab key={order.orderid} label={order.orderid} />
                  ))}
                </Tabs>
                {selectedOrder && (
                  <StyledTabPanel>
                    <Stack
                      style={{ justifyContent: "space-between", gap: "20px" }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "10px",
                          width: "100%",
                          alignItems: "center",
                          padding: "0px",
                        }}
                      >
                        <Box
                          sx={{
                            flexDirection: "column",
                            display: "flex",
                            gap: "5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            alignItems: "center",
                          }}
                        >
                          <FaClipboardCheck size={20} />
                          Approved
                        </Box>
                        <Box
                          sx={{
                            flexDirection: "column",
                            display: "flex",
                            gap: "5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            alignItems: "center",
                          }}
                        >
                          <FaTruck size={20} />
                          Shipped
                        </Box>
                        <Box
                          sx={{
                            flexDirection: "column",
                            display: "flex",
                            gap: "5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            alignItems: "center",
                          }}
                        >
                          <FaCheck size={20} />
                          Delivered
                        </Box>
                      </Box>

                      <LinearProgress
                        variant="determinate"
                        value={progressStatus(selectedOrder)}
                        sx={{
                          width: "100%",
                          marginTop: "2px",
                          backgroundColor: "rgba(231, 231, 231, 1)",
                        }}
                        color="info"
                      />

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="40px"
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          style={{ height: "45px", width: "100px" }}
                          onClick={() =>
                            navigate(`/order-summary/${getData.orderid}`)
                          }
                        >
                          <Typography variant="caption" fontWeight={700}>
                            Summary
                          </Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{ height: "45px", width: "100px" }}
                          onClick={() =>
                            navigate("/order-tracking", { state: getData })
                          }
                        >
                          <Typography variant="caption" fontWeight={700}>
                            Track order
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          style={{ height: "45px", width: "100px" }}
                          onClick={() => navigate("/new-order")}
                        >
                          <Typography
                            variant="caption"
                            fontWeight={700}
                            color="white"
                          >
                            New order
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </StyledTabPanel>
                )}
              </>
            ) : (
              <>
                <Stack
                  sx={{
                    padding: "20px",
                    height: "100%",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  <Typography sx={{ color: "rgba(183, 183, 183, 1)" }}>
                    Your cart is empty!
                  </Typography>

                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Enjoy exclusive discounts <br /> & maximise your savings
                  </Typography>

                  <Button variant="contained"onClick={() => navigate("/new-order")}>New order</Button>
                </Stack>
              </>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Stack sx={{ width: "100%", height: "100%", gap: "20px" }}>
            {trackerSection?.length > 0 ? (
              <>
                <Tabs
                  value={trackerSection.findIndex(
                    (order) => order.orderid === activeOrderId
                  )}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {trackerSection.map((order) => (
                    <Tab key={order.orderid} label={order.orderid} />
                  ))}
                </Tabs>
                {selectedOrder && (
                  <StyledTabPanel>
                    <Stack
                      style={{ justifyContent: "space-between", gap: "20px" }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "10px",
                          width: "100%",
                          alignItems: "center",
                          padding: "0px",
                        }}
                      >
                        <FaClipboardCheck size={20} />
                        <FaTruck size={20} />
                        <FaCheck size={20} />
                      </Box>

                      <LinearProgress
                        variant="determinate"
                        value={progressStatus(selectedOrder)}
                        sx={{
                          width: "100%",
                          marginTop: "10px",
                          backgroundColor: "rgba(231, 231, 231, 1)",
                        }}
                        color="info"
                      />

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="30px"
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          style={{ height: "45px" }}
                          onClick={() =>
                            navigate(`/order-summary/${getData.orderid}`)
                          }
                        >
                          <Typography variant="caption" fontWeight={700}>
                            Summary
                          </Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{ height: "45px" }}
                          onClick={() =>
                            navigate("/order-tracking", { state: getData })
                          }
                        >
                          <Typography variant="caption" fontWeight={700}>
                            Track order
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          style={{ height: "45px" }}
                          onClick={() => navigate("/new-order")}
                        >
                          <Typography
                            variant="caption"
                            fontWeight={700}
                            color="white"
                          >
                            New order
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </StyledTabPanel>
                )}
              </>
            ) : (
              <>
                <Stack
                  sx={{
                    padding: "20px",
                    height: "100%",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  <Typography sx={{ color: "rgba(183, 183, 183, 1)" }}>
                    Your cart is empty!
                  </Typography>

                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Enjoy exclusive discounts <br /> & maximise your savings
                  </Typography>

                  <Button variant="contained">New order</Button>
                </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
}
