import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const Label2 = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));
const EmptyPage = () => {
  const navigate=useNavigate()
  return (
    <Box1
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        gap: "20px",
      }}>
      <Typography>No orders have been placed yet.</Typography>
      <Label2>Add to your cart now</Label2>
      <Button variant="contained" onClick={()=>navigate("/new-order")}>PLACE NEW ORDER</Button>
    </Box1>
  );
};

export default EmptyPage;
