import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export default function LoadingPage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call with a timeout
    const fetchData = async () => {
      try {
        // Make your API call here
        // await fetch("your_api_endpoint");
        setTimeout(() => {
          setLoading(false); // Set loading to false when the API is done
        }, 2000); // Replace with actual API logic
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div> 
      <Backdrop
        sx={{
          color: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      </Backdrop>
    </div>
  );
}
