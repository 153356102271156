import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
import PublicRoute from "../routes/PublicRoute";
import Footer2 from "./Footer2";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  padding: "40px",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const InnerContainer = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  padding: "20px",
  borderRadius: "10px",
  display: "flex",
  width: "90%",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  border: "2px solid rgba(255, 255, 255, 1)",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  paddingBottom: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Disclaimer = () => {
  const navigate = useNavigate();
  return (
    <Wrapper sx={{}}>
      <InnerContainer>
        <Typography variant="h4" sx={{ margin: "30px",fontWeight:600 }}>
          Disclaimer
        </Typography>
        <Box
          sx={{
            width: " 90%",
            marginBottom: "30px",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color:"rgba(94, 94, 94, 1)"
          }}>
          <p>
            The information contained on https://vajra.software website (the
            “Service”) is for general information purposes only.
          </p>
          <p>
            vajra assumes no responsibility for errors or omissions in the
            content on the Service. In no event shall vajra be liable for any
            special, direct, indirect, consequential, or incidental damages or
            any damages whatsoever, whether in an action of contract, negligence
            or other tort, arising out of or in connection with the use of the
            Service or the contents of the Service.
          </p>
          <p>
            vajra reserves the right to make additions, deletions, or
            modifications to the contents on the Service at any time without
            prior notice. This Disclaimer for vajra has been created with the
            help of an online generator.
          </p>
          <p>
            vajra does not warrant that the website is free of viruses or other
            harmful components.
          </p>
          <h2>External links disclaimer</h2>
          <p>
            https://vajra.software website may contain links to external
            websites that are not provided or maintained by or in any way
            affiliated with vajra Please note that the vajra does not guarantee
            the accuracy, relevance, timeliness, or completeness of any
            information on these external websites.
          </p>
       
        </Box>
      </InnerContainer>
    </Wrapper>
  );
};

export default Disclaimer;
