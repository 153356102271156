import axios from "axios";
const token = localStorage.getItem("AstraToken");
let userData = JSON.parse(localStorage.getItem("AstraUserData"));
export const POST_SIGNIN_REQUEST = "POST_SIGNIN_REQUEST";
export const POST_SIGNIN_SUCCESS = "POST_SIGNIN_SUCCESS";
export const POST_SIGNIN_FAILURE = "POST_SIGNIN_FAILURE";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
const signInRequest = () => {
  return { type: POST_SIGNIN_REQUEST };
};

const signInSuccess = (data) => {
  return { type: POST_SIGNIN_SUCCESS, payload: data };
};

const signInFailure = (error) => {
  return { type: POST_SIGNIN_FAILURE, payload: error };
};

const registerRequest = () => {
  return { type: REGISTER_REQUEST };
};

const registerSuccess = (data) => {
  return { type: REGISTER_SUCCESS, payload: data };
};

const registerFailure = (error) => {
  return { type: REGISTER_FAILURE, payload: error };
};
const resetPasswordRequest = () => {
  return { type: RESET_PASSWORD_REQUEST };
};

const resetPasswordSuccess = (data) => {
  return { type: RESET_PASSWORD_SUCCESS, payload: data };
};

const resetPasswordFailure = (error) => {
  return { type: RESET_PASSWORD_FAILURE, payload: error };
};

const refreshTokenRequest = () => {
  return {
    type: REFRESH_TOKEN_REQUEST,
  };
};

const refreshTokenSuccess = (auth) => {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: auth,
  };
};

const refreshTokenFailure = () => {
  return {
    type: REFRESH_TOKEN_FAILURE,
  };
};

export const signIn = (data) => (dispatch) => {
  dispatch(signInRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_SIGN_IN);


  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_SIGN_IN,
    data,
  })
    .then((res) => {
      dispatch(signInSuccess(res.data));
      if (res.data?.fbtoken) {
        localStorage.setItem("userToken", res.data?.fbtoken);
      }
      if (res.data.token !== undefined && res.data.token) {
        localStorage.setItem(
          "AstraUserData",
          JSON.stringify(res.data?.userdata)
        );

        localStorage.setItem("AstraToken", res.data.token);
      }
    })
    .catch((error) => {
      dispatch(signInFailure(error));
    });
};
export const refreshToken = () => (dispatch) => {
  dispatch(refreshTokenRequest());

  const data = {
    userid: userData.userid,
    branchid: userData.branchid,
    hospid: userData.hospid,
    wtoken: token,
  };

  // console.log("requestData API", process.env.REACT_APP_GET_FB_TOKEN);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_FB_TOKEN,
    data,
  })
    .then((res) => {
      /////console.log("res", res.data);
      if (res.data?.success) {
        localStorage.setItem("AstraToken", res.data?.token);
        window.location.reload();
      }

      dispatch(refreshTokenSuccess(res.data));
    })
    .catch((error) => {
      dispatch(refreshTokenFailure());
    });
};

export const register = (data) => (dispatch) => {
  dispatch(registerRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_REGISTER);

  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_REGISTER,
    data,
  })
    .then((res) => {
      dispatch(registerSuccess(res.data));
    })
    .catch((error) => {
      dispatch(registerFailure(error));
    });
};

export const resetPassword = (data) => (dispatch) => {
  dispatch(resetPasswordRequest());

  // console.log("requestData API", process.env.REACT_APP_GET_PASSWORD_RESET);


  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_PASSWORD_RESET,
    data,
  })
    .then((res) => {
      dispatch(resetPasswordSuccess(res.data));
    })
    .catch((error) => {
      dispatch(resetPasswordFailure(error));
    });
};
