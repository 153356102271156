import React from "react";

import Box from "@mui/material/Box";

import { Button, IconButton, Stack, Typography, styled } from "@mui/material";
import { RiWhatsappFill } from "react-icons/ri";
import CloseIcon from "@mui/icons-material/Close";
const BubbleContainer = styled(Box)(({ theme }) => ({
  margin: "20px",

  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "10px",
}));

const SpeechBubble = styled(Box)(({ theme }) => ({
  textAlign: "start",
  width: "231px",
  //   margin: "50px auto",
  borderRadius: "15px",
  background: "rgba(244, 244, 244, 1)",
  color: "black",
  padding: "20px",

  fontWeight: "900",
  fontFamily: "arial",
  position: "relative",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SpeechBubbleRight = styled(SpeechBubble)(({ theme }) => ({
  textAlign: "start",
  "&::before": {
    content: '""',
    width: "0px",
    height: "0px",
    position: "absolute",
    borderLeft: "15px solid rgba(244, 244, 244, 1)",
    borderRight: "15px solid transparent",
    borderTop: "15px solid rgba(244, 244, 244, 1)",
    borderBottom: "15px solid transparent",
    right: "-16px",
    top: "0px",
  },
}));

const SpeechBubbleLeft = styled(SpeechBubble)(({ theme }) => ({
  textAlign: "start",
  "&::before": {
    content: '""',
    width: "0px",
    height: "0px",
    position: "absolute",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid rgba(244, 244, 244, 1)",
    borderTop: "15px solid rgba(244, 244, 244, 1)",
    borderBottom: "15px solid transparent",
    left: "-16px",
    top: "0px",
  },
}));

const ChatBox1 = ({ isOpen, toggleChat, setIsOpen }) => {
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  let managerMobile = userData?.mngrmobile

  return (
    <>
      <div
        className="overlay"
        style={{ display: isOpen ? "block" : "none" }}
        onClick={toggleChat}
      ></div>

      <div
        className="chat-box1"
        style={{
          display: isOpen ? "flex" : "none",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            bgcolor: "rgba(193, 32, 37, 1)",
            height: "50px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            color: "white",
            padding: "4px",
          }}
        >
          <Typography>Chat through WhatsApp</Typography>
          <CloseIcon onClick={toggleChat} />
        </Box>
        <BubbleContainer>
          <SpeechBubbleLeft>
            <Typography color="rgba(193, 32, 37, 1)" variant="subtitle2">
              Asthra Team
            </Typography>
            <Typography variant="subtitle2">
              {" "}
              Hi, Start a conversation to know more
            </Typography>
          </SpeechBubbleLeft>

          <SpeechBubble>
            <Typography variant="subtitle2" color="rgba(135, 135, 135, 1)">
              The team typically replies in a few minutes.
            </Typography>
          </SpeechBubble>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
              marginTop: "15px",
            }}
          >
            <SpeechBubbleRight>
              <Typography variant="subtitle2">
                I would like to start a conversation
              </Typography>
              <Typography variant="caption" color="rgba(135, 135, 135, 1)">
                ⓘ Takes you to an outside link
              </Typography>
            </SpeechBubbleRight>
          </Box>
          <Stack
            justifyContent={"flex-end"}
            flexDirection="row"
            sx={{ width: "100%" }}
            gap="20px"
          >
            <IconButton sx={{ color: "rgba(37, 211, 102, 1)" }}>
              {" "}
              <RiWhatsappFill size={30} />
            </IconButton>

            <a
              href={`https://api.whatsapp.com/send?phone=91${managerMobile}&text=Hi,%20I%20have%20a%20query%20in%20Asthra%20App%20.%20Can%20you%20help%20?`}
              target="_blank"
              style={{
                height: "100%",
              }}
              rel="noreferrer"
            >
              {" "}
              <Button
                variant="contained"
                size="small"
                sx={{
                  bgcolor: "rgba(37, 211, 102, 1)",
                  width: "100px",
                  height: "45px",
                  "&:hover": {
                    bgcolor: "rgba(37, 211, 102, 1)",
                    color: "white",
                  },
                }}
              >
                Start{" "}
              </Button>
            </a>

            <Button
              onClick={toggleChat}
              variant="outlined"
              color="primary"
              size="small"
              sx={{ width: "100px", height: "45px" }}
            >
              {" "}
              Cancel
            </Button>
          </Stack>
        </BubbleContainer>
      </div>
    </>
  );
};

export default ChatBox1;
