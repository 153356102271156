import * as React from "react";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import { FormHelperText, useMediaQuery } from "@mui/material";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaInbox } from "react-icons/fa6";
import { useTheme } from "@emotion/react";

const InnerContainer = styled(Box)(({ theme }) => ({
  bgcolor: "white",

  borderRadius: "10px",
  display: "flex",
  width: "792px",
  alignItems: "center",
  border: "2px solid rgba(255, 255, 255, 1)",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  paddingBottom: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Fields = styled(TextField)(({ theme }) => ({
  width: "314px",
  borderRadius: "5px",
  "& fieldset": { border: "none" },
  background: "rgba(240, 240, 240, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Fields2 = styled(TextField)(({ theme }) => ({
  width: "90%",
  borderRadius: "5px",
  "& fieldset": { border: "none" },
  background: "rgba(240, 240, 240, 1)",
  [theme.breakpoints.down("xl")]: {
    width: "92%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "86%",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Box)(({ theme }) => ({
  width: "90%",

  flexDirection: "row",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const MainBox = styled(Box)(({ theme }) => ({
  width: "95%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "90%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ButtonBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",
  flexDirection: "row",
  marginTop: "10%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    flexDirection: "column",
    gap: "20px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Buttons = styled(Button)(({ theme }) => ({
  width: "314px",
  height: "45px",
  fontWeight: 700,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "45%",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const GridBox = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "start",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const HelperText2 = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  width: "90%",

  [theme.breakpoints.down("xl")]: {
    width: "92%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "86%",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  width: "314px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const InfiormationBox = styled(Typography)(({ theme }) => ({
  borderRadius: "30px",
  paddingLeft: "13px",
  paddingRight: "13px",
  whiteSpace: "nowrap",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
export default function SignUp() {
  let branch = JSON.parse(localStorage.getItem("branchData"));
  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  
  const navigate = useNavigate();
  const theme = useTheme();
  const [userName, setUserName] = useState("");
  ///////console.log("userName", userName);
  const [hospitalName, setHospitalName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [text, setText] = useState("");

  const [showNext, setShowNext] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const validateField = (fieldName, value) => {
    let error = "";

    switch (fieldName) {
      case "userName":
        if (!value.trim().match(/^[a-zA-Z.\s]+$/)) {
          error = "Invalid username";
        }
        break;
      case "hospitalName":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid hospital name ";
        }
        break;

      case "city":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid city name ";
        }
        break;
      case "email":
        if (!value.trim().match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[\w-]{2,4}$/)) {
          error = "Invalid email address.";
        }
        break;
      case "phoneNumber":
        if (!value.trim().match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "text":
        if (!value.trim().match(/^[#.0-9a-zA-Z\s,-]+$/)) {
          error = "Invalid description";
        }
        break;

      case "pincode":
        if (!value.trim().match(/^\d{6}$/)) {
          error = "Invalid pincode";
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleInputChange = (fieldName, value) => {
    const error = validateField(fieldName, value);
    let fieldError = "";

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error || fieldError,
    }));

    switch (fieldName) {
      case "userName":
        setUserName(value);
        break;
      case "hospitalName":
        setHospitalName(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "text":
        setText(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "pincode":
        setPincode(value);
        break;
      case "city":
        setCity(value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    Object.keys(errors).forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospitalName"
          ? hospitalName
          : key === "phoneNumber"
          ? phoneNumber
          : key === "city"
          ? city
          : key === "email"
          ? email
          : key === "pincode"
          ? pincode
          : key === "text"
          ? text
          : "";

      const error = validateField(key, value);

      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!hospitalName.trim()) {
      formErrors["hospitalName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phoneNumber.trim()) {
      formErrors["phoneNumber"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!city.trim()) {
      formErrors["city"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!pincode.trim()) {
      formErrors["pincode"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!text.trim()) {
      formErrors["text"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      let data = {
        username: userName,
        email: email,
        phoneNumber: phoneNumber,
      };

      setMessage(
        "Your message has been received.We will respond to you as soon as possible."
      );
      setShowMessage(true);
    } else {
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };
  const handleNext = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    Object.keys(errors).forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospitalName"
          ? hospitalName
          : key === "phoneNumber"
          ? phoneNumber
          : key === "city"
          ? city
          : key === "email"
          ? email
          : key === "pincode"
          ? pincode
          : key === "text"
          ? text
          : "";

      const error = validateField(key, value);

      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phoneNumber.trim()) {
      formErrors["phoneNumber"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      let data = {
        username: userName,
        email: email,
        phoneNumber: phoneNumber,
      };

      setShowNext(true);
    } else {
    }
  };
  return (
    <Wrapper>
      {isMobileScreen && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",

              width: "90%",
              marginTop: 5,
              gap: "2%",
            }}>
            {!showMessage && (
              <>
                <InfiormationBox
                  variant="body2"
                  sx={{
                    border: showNext
                      ? "1px solid rgba(119, 119, 119, 1)"
                      : "1px solid rgba(193, 32, 37, 1)",
                    color: showNext
                      ? " rgba(119, 119, 119, 1)"
                      : " rgba(193, 32, 37, 1)",
                  }}>
                  Personal information
                </InfiormationBox>
                <InfiormationBox
                  variant="body2"
                  sx={{
                    border: showNext
                      ? "1px solid rgba(193, 32, 37, 1)"
                      : "1px solid rgba(119, 119, 119, 1)",
                    color: showNext
                      ? " rgba(193, 32, 37, 1)"
                      : " rgba(119, 119, 119, 1)",
                  }}>
                  Hospital information
                </InfiormationBox>
              </>
            )}
          </Box>
        </>
      )}

      <InnerContainer
        component="main"
        maxWidth="xs"
        sx={{
          marginTop: isMobileScreen && showMessage ? 8 : 2,
          display: "flex",
          flexDirection: "column",
        }}>
        <MainBox>
          {!isMobileScreen ? (
            <>
              {!showMessage ? (
                <>
                  <TitleBox sx={{ height: "150px" }}>
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{ fontWeight: 600 }}>
                        Contact
                      </Typography>
                    </Box>
                  </TitleBox>
                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Grid
                      container
                      sx={{
                        padding: "0px",
                        width: "100%",
                        rowGap: "20px",
                      }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ paddingLeft: "0px", width: "95%" }}>
                        <GridBox>
                          <Fields
                            placeholder="User Name"
                            InputProps={{ style: { height: "45px" } }}
                            value={userName}
                            onChange={(e) =>
                              handleInputChange("userName", e.target.value)
                            }
                          />

                          {errors.userName && (
                            <HelperText>{errors.userName}</HelperText>
                          )}
                        </GridBox>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ paddingLeft: "0px" }}
                        sx={{ justifyContent: "start", alignItems: "start" }}>
                        <GridBox>
                          <Fields
                            placeholder="Hospital Name"
                            InputProps={{ style: { height: "45px" } }}
                            value={hospitalName}
                            onChange={(e) =>
                              handleInputChange("hospitalName", e.target.value)
                            }
                          />
                          {errors.hospitalName && (
                            <HelperText>{errors.hospitalName}</HelperText>
                          )}
                        </GridBox>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{}}>
                        <GridBox>
                          <Fields
                            placeholder="Phone Number"
                            InputProps={{ style: { height: "45px" } }}
                            value={phoneNumber}
                            onChange={(e) =>
                              handleInputChange("phoneNumber", e.target.value)
                            }
                          />
                          {errors.phoneNumber && (
                            <HelperText>{errors.phoneNumber}</HelperText>
                          )}
                        </GridBox>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingLeft: "0px" }}>
                        <GridBox>
                          <Fields
                            placeholder="City"
                            InputProps={{ style: { height: "45px" } }}
                            value={city}
                            onChange={(e) =>
                              handleInputChange("city", e.target.value)
                            }
                          />
                          {errors.city && (
                            <HelperText>{errors.city}</HelperText>
                          )}
                        </GridBox>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingLeft: "0px" }}>
                        <GridBox>
                          <Fields
                            placeholder="Email ID"
                            InputProps={{ style: { height: "45px" } }}
                            value={email}
                            onChange={(e) =>
                              handleInputChange("email", e.target.value)
                            }
                          />
                          {errors.email && (
                            <HelperText style={{ color: "red" }}>
                              {errors.email}
                            </HelperText>
                          )}
                        </GridBox>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingLeft: "0px" }}>
                        <GridBox>
                          <Fields
                            placeholder="Pincode"
                            InputProps={{ style: { height: "45px" } }}
                            value={pincode}
                            onChange={(e) =>
                              handleInputChange("pincode", e.target.value)
                            }
                          />

                          {errors.pincode && (
                            <HelperText>{errors.pincode}</HelperText>
                          )}
                        </GridBox>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      sx={{
                        padding: "0px",
                        width: "100%",

                        marginTop: "20px",
                      }}>
                      <Grid item xs={12} sm={12} style={{ paddingLeft: "0px" }}>
                        <GridBox>
                          <Fields2
                            placeholder="Enter your message"
                            InputProps={{ style: { height: "89px" } }}
                            value={text}
                            onChange={(e) =>
                              handleInputChange("text", e.target.value)
                            }
                          />

                          {errors.text && (
                            <HelperText2>{errors.text}</HelperText2>
                          )}
                        </GridBox>
                      </Grid>
                    </Grid>
                  </Box>
                  <ButtonBox sx={{ width: "90%" }}>
                    <Buttons
                      sx={{
                        border: "2px solid rgba(193, 32, 37, 1)",
                        color: "rgba(193, 32, 37, 1)",
                      }}>
                      <Typography
                        onClick={() => navigate('/')}
                        sx={{ fontWeight: "700" }}>
                        Cancel
                      </Typography>
                    </Buttons>
                    <Buttons
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{
                        bgcolor: "rgba(193, 32, 37, 1)",
                        border: "1px solid #000000",
                        color: "white",
                        "&:hover": {
                          bgcolor: "rgba(193, 32, 37, 1)",
                          color: "white",
                        },
                      }}>
                      <Typography sx={{ fontWeight: "700" }}>Submit</Typography>
                    </Buttons>
                  </ButtonBox>
                </>
              ) : (
                <>
                  <TitleBox sx={{ height: "150px" }}>
                    <Box sx={{ width: "80%", textAlign: "center" }}>
                      <Typography
                        component="h1"
                        variant="h5"
                        sx={{ fontWeight: 600 }}>
                        Thank you for contacting us!
                      </Typography>
                    </Box>
                  </TitleBox>

                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "3%",
                    }}>
                    <Typography
                      sx={{ width: "80%", color: "rgba(94, 94, 94, 1)" }}
                      variant="body1">
                      {message}
                    </Typography>
                  </Box>

                  <FaInbox size={55} />
                  <ButtonBox sx={{ width: "90%", justifyContent: "center" }}>
                    <Buttons
                      onClick={handleHomeClick}
                      variant="contained"
                      sx={{
                        bgcolor: "rgba(193, 32, 37, 1)",

                        color: "white",
                        width: "264px",
                        "&:hover": {
                          bgcolor: "rgba(193, 32, 37, 1)",
                          color: "white",
                        },
                      }}>
                      <Typography sx={{ fontWeight: "700" }}>Home</Typography>
                    </Buttons>
                  </ButtonBox>
                </>
              )}
            </>
          ) : (
            <>
              {!showMessage ? (
                <>
                  <TitleBox sx={{ height: "150px" }}>
                    <Typography
                      component="h1"
                      variant="h4"
                      sx={{ fontWeight: 600 }}>
                      Contact
                    </Typography>
                  </TitleBox>
                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Grid
                      container
                      sx={{
                        padding: "0px",
                        width: "100%",
                        rowGap: "20px",
                      }}>
                      {!showNext ? (
                        <>
                          {" "}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ paddingLeft: "0px", width: "95%" }}>
                            <GridBox>
                              <Fields
                                placeholder="User Name"
                                InputProps={{ style: { height: "45px" } }}
                                value={userName}
                                onChange={(e) =>
                                  handleInputChange("userName", e.target.value)
                                }
                              />

                              {errors.userName && (
                                <HelperText>{errors.userName}</HelperText>
                              )}
                            </GridBox>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{}}>
                            <GridBox>
                              <Fields
                                placeholder="Phone Number"
                                InputProps={{ style: { height: "45px" } }}
                                value={phoneNumber}
                                onChange={(e) =>
                                  handleInputChange(
                                    "phoneNumber",
                                    e.target.value
                                  )
                                }
                              />
                              {errors.phoneNumber && (
                                <HelperText>{errors.phoneNumber}</HelperText>
                              )}
                            </GridBox>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ paddingLeft: "0px" }}>
                            <GridBox>
                              <Fields
                                placeholder="Email ID"
                                InputProps={{ style: { height: "45px" } }}
                                value={email}
                                onChange={(e) =>
                                  handleInputChange("email", e.target.value)
                                }
                              />
                              {errors.email && (
                                <HelperText style={{ color: "red" }}>
                                  {errors.email}
                                </HelperText>
                              )}
                            </GridBox>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ paddingLeft: "0px" }}
                            sx={{
                              justifyContent: "start",
                              alignItems: "start",
                            }}>
                            <GridBox>
                              <Fields
                                type="text"
                                placeholder="Hospital Name"
                                InputProps={{ style: { height: "45px" } }}
                                value={hospitalName}
                                onChange={(e) =>
                                  handleInputChange(
                                    "hospitalName",
                                    e.target.value
                                  )
                                }
                              />
                              {errors.hospitalName && (
                                <HelperText>{errors.hospitalName}</HelperText>
                              )}
                            </GridBox>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ paddingLeft: "0px" }}>
                            <GridBox>
                              <Fields
                                placeholder="Pincode"
                                InputProps={{ style: { height: "45px" } }}
                                value={pincode}
                                onChange={(e) =>
                                  handleInputChange("pincode", e.target.value)
                                }
                              />
                              {errors.pincode && (
                                <HelperText>{errors.pincode}</HelperText>
                              )}
                            </GridBox>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ paddingLeft: "0px" }}>
                            <GridBox>
                              <Fields
                                placeholder="City"
                                InputProps={{ style: { height: "45px" } }}
                                value={city}
                                onChange={(e) =>
                                  handleInputChange("city", e.target.value)
                                }
                              />
                              {errors.city && (
                                <HelperText>{errors.city}</HelperText>
                              )}
                            </GridBox>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ paddingLeft: "0px" }}>
                            <GridBox>
                              <Fields
                                placeholder="Enter message"
                                InputProps={{ style: { height: "70px" } }}
                                value={text}
                                onChange={(e) =>
                                  handleInputChange("text", e.target.value)
                                }
                              />
                              {errors.text && (
                                <HelperText>{errors.text}</HelperText>
                              )}
                            </GridBox>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                  {!showNext ? (
                    <>
                      {" "}
                      <ButtonBox sx={{ width: "90%" }}>
                        <Button
                          onClick={handleNext}
                          variant="contained"
                          sx={{
                            bgcolor: "rgba(193, 32, 37, 1)",

                            color: "white",
                            "&:hover": {
                              bgcolor: "rgba(193, 32, 37, 1)",
                              color: "white",
                            },
                            width: "100%",
                          }}>
                          <Typography sx={{ fontWeight: "700" }}>
                            Next
                          </Typography>
                        </Button>
                      </ButtonBox>
                    </>
                  ) : (
                    <>
                      {" "}
                      <ButtonBox sx={{ width: "90%" }}>
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          sx={{
                            bgcolor: "rgba(193, 32, 37, 1)",

                            color: "white",
                            "&:hover": {
                              bgcolor: "rgba(193, 32, 37, 1)",
                              color: "white",
                            },
                            width: "100%",
                          }}>
                          <Typography sx={{ fontWeight: "700" }}>
                            Submit
                          </Typography>
                        </Button>
                      </ButtonBox>
                    </>
                  )}
                </>
              ) : (
                <>
                  <TitleBox sx={{ height: "150px", marginTop: "20px" }}>
                    <Box sx={{ width: "80%", textAlign: "center" }}>
                      <Typography
                        component="h1"
                        variant="h5"
                        sx={{ fontWeight: 600 }}>
                        Thank you for contacting us!
                      </Typography>
                    </Box>
                  </TitleBox>

                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}>
                    <Typography
                      sx={{ width: "80%", color: "rgba(94, 94, 94, 1)" }}
                      variant="body1">
                      {message}
                    </Typography>
                  </Box>
                  <FaInbox size={55} />
                  <ButtonBox sx={{ width: "90%", justifyContent: "center" }}>
                    <Buttons
                      onClick={handleHomeClick}
                      variant="contained"
                      sx={{
                        bgcolor: "rgba(193, 32, 37, 1)",

                        color: "white",
                        width: "264px",
                        "&:hover": {
                          bgcolor: "rgba(193, 32, 37, 1)",
                          color: "white",
                        },
                      }}>
                      <Typography sx={{ fontWeight: "700" }}>Home</Typography>
                    </Buttons>
                  </ButtonBox>
                </>
              )}
            </>
          )}
        </MainBox>
      </InnerContainer>
    </Wrapper>
  );
}
