import {
  AppBar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  colors,
  styled,
} from "@mui/material";
import React from "react";
import Navbar from "../../MobileView/Navbar";
import { BsGraphUpArrow } from "react-icons/bs";
import { FiPlusSquare } from "react-icons/fi";
import { LuStar } from "react-icons/lu";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";
import ChatBot from "../../components/ChatBot";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { dashboardAnalytics } from "../../redux/data/action";
import NumberFormat from "../../components/NumberFormat";
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const Boxes = styled(Stack)(({ theme }) => ({
  border: "2px solid rgba(255, 255, 255, 1)",
  width: "45%",
  height: "187px",
  justifyContent: "space-around",
  alignItems: "start",
  padding: "15px",
  borderRadius: "10px",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6),-10px -10px 15px 0px rgba(255, 255, 255, 1),5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));
const Home = () => {
  const navigate = useNavigate();
  const analyticData = useSelector((store) => store.data.dashboardAnalytics);

  let userData = JSON.parse(localStorage.getItem("AstraUserData"));
  let branch = JSON.parse(localStorage.getItem("branchData"));
  const [orders, setOrders] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  /////console.log("monthData", monthData);
  const [lastDateOrders, setLastDateOrders] = useState([]);
  const dispatch = useDispatch();
  /////console.log("lastDateOrders", lastDateOrders);

  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentMonthName, setCurrentMonthName] = useState(
    moment().format("MMM")
  );
  /////console.log("cu", currentYear, currentMonthName);

  /////console.log("cu", currentMonth, currentYear);

  const [dateState, setDateState] = useState(new Date());
  /////console.log("dateState", dateState);
  const [selectedOrders, setSelectedOrders] = useState([]);
  /////console.log("selectedOrders", selectedOrders);
  const [lastMonth, setLastMonth] = useState("");
  const [selectData, setSelectDate] = useState("");
  /////console.log("selectedDate", selectData, "lastMonth", lastMonth);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    setCurrentMonth(moment(dateState).format("MM"));
    setCurrentYear(moment(dateState).format("YYYY"));
    const selectedDate = moment(dateState).format("DD");
    /////console.log("selectedDate", selectedDate);
    setSelectDate(selectedDate);
    const selectedOrders = orders.filter(
      (order) => order.orderdate === selectedDate
    );
    setSelectedOrders(selectedOrders);
  }, [dateState]);

  useEffect(() => {
    let requestData = {
      userid: userData.userid,
      branchid: branch.branchId,
      hospid: userData.hospid,
      month: currentMonth,
      year: currentYear,
    };

    dispatch(dashboardAnalytics(requestData));
  }, [currentMonth, currentYear]);
  useEffect(() => {
    if (analyticData) {
      setOrders(analyticData.analyticsdata_section || []);
      setGraphData(analyticData.analyticsdata_section_graph);
      setMonthData(analyticData.analyticsgraph_section);

      if (
        analyticData.analyticsdata_section &&
        analyticData.analyticsdata_section?.length > 0
      ) {
        // Extract all the order dates from the data
        const orderDates = analyticData.analyticsdata_section.map((order) =>
          parseInt(order.orderdate)
        );

        // Find the maximum order date, which will be the last date of the month
        const lastDateOfMonth = moment
          .max(orderDates.map((date) => moment(date, "DD")))
          .format("DD");
        /////console.log("lastDateOfMonth", lastDateOfMonth);
        setLastMonth(lastDateOfMonth);

        // Filter orders for the last date of the month
        const lastDateOrders = analyticData.analyticsdata_section.filter(
          (order) => order.orderdate === lastDateOfMonth
        );

        setLastDateOrders(lastDateOrders);
      }
    }
  }, [analyticData]);

  /////console.log("ssssssssssssssss", selectedOrders);

  const calculateTotalSavings = (data) => {
    const savingsMap = {};
    data.forEach((item) => {
      const orderDate = item.orderdate;
      const savings = parseFloat(item.totsavings);
      if (savingsMap[orderDate]) {
        savingsMap[orderDate] += savings;
      } else {
        savingsMap[orderDate] = savings;
      }
    });
    return savingsMap;
  };

  // Calculate total savings
  const totalSavingsMap = calculateTotalSavings(orders);

  // Convert total savings map back to array format
  const newData = Object.keys(totalSavingsMap).map((orderdate) => ({
    orderdate,
    totsavings: totalSavingsMap[orderdate].toString(),
  }));

  /////console.log(newData);
  const date = [
    ...new Set(
      (selectedOrders?.length > 0 ? selectedOrders : lastDateOrders)?.map(
        (item) => item.orderdate
      )
    ),
  ];
  /////console.log("monthData", monthData);
  const lastOrder = orders[orders?.length - 1];

  /////console.log(lastOrder);
  return (
    <Stack
      sx={{
        backgroundColor: "white",

        height: "calc(100vh - 4px)",
      }}
    >
      <AppBar position="fixed" id="navbar">
        <Navbar heading={"Home"} />
      </AppBar>
      <Stack
        sx={{
          //   width: "100%",
          gap: "20px",
          backgroundColor: "white",
          zIndex: 1,

          marginTop: "95px",
          padding: "20px",
        }}
      >
        <Label variant="h5">Welcome back!</Label>
        <Stack
          sx={{ marginTop: "30px", gap: "20px" }}
          direction="row"
          justifyContent={"space-around"}
        >
          <Boxes onClick={() => navigate("/dashboard")}>
            <IconButton>
              <BsGraphUpArrow size={20} style={{ color: "black" }} />
            </IconButton>
            <Label>
              {currentMonthName} {currentYear}
            </Label>
            <Stack>
              {monthData?.map((item) => (
                <>
                  <Label
                    variant="subtitle1"
                    sx={{ color: "rgba(183, 183, 183, 1)" }}
                  >
                    {item.order_count} Orders
                  </Label>
                  <Label
                    variant="subtitle1"
                    sx={{ color: "rgba(255, 153, 0, 1)" }}
                  >
                    {Math.round(item.totsavings)}% savings
                    <MdArrowOutward
                      size={20}
                      style={{ color: "rgba(255, 153, 0, 1)" }}
                    />
                  </Label>
                </>
              ))}
            </Stack>
          </Boxes>

          <Boxes onClick={() => navigate("/dashboard/3")}>
            <IconButton>
              <FiPlusSquare size={20} style={{ color: "black" }} />
            </IconButton>
            <Label>Shipped</Label>
            <Stack sx={{ color: "rgba(183, 183, 183, 1)" }}>
              <Label variant="subtitle1">Order ID</Label>

              {lastOrder?.orderid?.length > 11 ? (
                <Tooltip title={lastOrder?.orderid}>
                  <Label
                    variant="caption"
                    style={{
                      maxWidth: "11ch",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {lastOrder?.orderid.slice(0, 9) + "..."}
                  </Label>
                </Tooltip>
              ) : (
                <Label
                  variant="caption"
                  style={{
                    maxWidth: "11ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {lastOrder?.orderid}
                </Label>
              )}
            </Stack>
          </Boxes>
        </Stack>

        <Stack
          sx={{ gap: "20px" }}
          direction="row"
          justifyContent={"space-around"}
        >
          <Boxes onClick={() => navigate("/dashboard/2")}>
            <IconButton>
              <LuStar size={20} style={{ color: "black" }} />
            </IconButton>
            <Stack>
              <Label variant="subtitle1">Top products </Label>
              <Label variant="subtitle1">& brands </Label>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(183, 183, 183, 1)" }}
              >
                {" "}
                for you
              </Label>
            </Stack>
            <Label></Label>
          </Boxes>
          {monthData?.map((item) => (
            <Boxes onClick={() => navigate("/dashboard/1")}>
              <IconButton>
                <FaIndianRupeeSign size={20} style={{ color: "black" }} />
              </IconButton>
              <Label>Payment</Label>
              <Stack>
                <Label variant="subtitle1">Total </Label>
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(193, 32, 37, 1)" }}
                >
                  ₹{/* <NumberFormat number={item.total_amt} /> */}
                  {parseFloat(item.total_amt).toFixed(2)}
                </Label>
              </Stack>
            </Boxes>
          ))}
        </Stack>
        <ChatBot />
        <Stack sx={{ marginTop: "30px" }}>
          <Button variant="contained" onClick={() => navigate("/new-order")}>
            Place order
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
