import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import { ThemeProvider } from "@mui/material";
import Theme from "./Theme";
import DocumentMeta from "react-document-meta";
import ScrollToTop from "./components/ScrollToTop";
const root = ReactDOM.createRoot(document.getElementById("root"));
const meta = {
  title: "Asthra - Hospital Procurement management software",
  description:
    "Asthra Software streamlines and optimizes the procurement process for healthcare facilities, enhancing efficiency in purchasing medical supplies, equipment, and services while ensuring compliance with regulations and budgetary constraints. Asthra helps by centralizing inventory management and supplier interactions and it facilitates seamless operations and cost-effective resource allocation within hospitals.",
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "Asset management App,Incident management App,Asset Maintenance App,Preventive Maintenance App",
    },
  },
};
root.render(
  <DocumentMeta {...meta}>
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Provider store={store}>
          <ScrollToTop />
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </DocumentMeta>
);
