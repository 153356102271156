import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { FaTruck } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { TbClipboardCheck } from "react-icons/tb";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  AppBar,
  Button,
  Popover,
  Select,
  Stack,
  TableContainer,
  TablePagination,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import InputBase from "@mui/material/InputBase";

import MenuItem from "@mui/material/MenuItem";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepLabel } from "@mui/material";
import { RxDotFilled } from "react-icons/rx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { TiTick } from "react-icons/ti";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { contact, orderTracking } from "../../redux/data/action";
import Navbar from "../../MobileView/Navbar";
import Footer from "../../MobileView/Footer";
import DrawerBox from "../CommonComponent/Drawer";
import NumberFormat from "../../components/NumberFormat";
import LoadingPage from "../../components/Loading_Page";

//////////////////////////////////////////////////////////////////////////////////////////////
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  InputAdornment,
  FormHelperText,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import profile from "../../images/doctor.png";

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

//////////////////////////////////////////////////////////////////////////////////////////////

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  alignItems: "center",
  flexDirection: "column",
}));

const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  alignItems: "center",

  flexDirection: "row",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));
const Buttons = styled(Button)(({ theme }) => ({
  background: "white",
  border: "1px solid black",
  color: "black",
  width: "130px",
  marginTop: "20px",
}));

const TableText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  cursor: "pointer",
  textDecoration: "none",
  color: "black",
}));

const TableRows = styled(TableCell)(({ theme }) => ({
  fontSize: "small",

  height: "50px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  borderBottom: "none",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  fontSize: "small",
  borderBottom: "none",
  height: "50px",
  fontWeight: "700",
  whiteSpace: "nowrap",
}));
const LeftWrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",

  height: "487px",

  width: "645px",
  justifyContent: "space-between",
  gap: "20px",
}));

const LeftBox1 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",
  width: "100%",
  height: "176px",
  borderRadius: "10px",
  justifyContent: "center",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));

const LeftBox2 = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  width: "645px",
  height: "291px",
  borderRadius: "10px",
  justifyContent: "space-around",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
}));

const InnerBox = styled(Stack)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  background: "white",

  border: "2px solid rgba(255, 255, 255, 1)",
  boxShadow:
    "0px 10px 20px 0px rgba(230, 210, 209, 0.6), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  borderRadius: "10px",
}));

const VerticalLinearProgress = styled(LinearProgress)(() => ({
  width: "6px",
  height: "100%",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#F5F6FA",
  },
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: "black",
  },
}));

const steps = ["Approved", "Shipped", "Delivered"];

const OrderTracking = () => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const [userName, setUserName] = useState("");
  const [hospital, setHospital] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});
  const maxLength = 300;
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [openSubmission, setOpenSubmission] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const orderTrackingData = useSelector((store) => store.data.orderTracking);
  /////console.log("orderTrackingData", orderTrackingData);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState([]);
  const location = useLocation();
  const getData = location.state;
  /////console.log("getData************", getData);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6); // Adjust the number of rows per page as needed

  const [selectedOrder, setSelectedOrder] = useState(null);
  /////console.log("selectedOrder", selectedOrder);
  const [lastObject, setLastObject] = useState("");
  /////console.log("lastObject", lastObject);

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setPopoverAnchorEl(popoverAnchorEl ? null : event.currentTarget);
  };

  const isPopoverOpen = Boolean(popoverAnchorEl);
  const getStatusIndex = (status) => {
    /////console.log("status", status);
    switch (status) {
      case "Processing":
        return 0;
      case "In Progress":
        return 1;
      case "Shipped":
        return 2;
      case "Delivered":
        return 3;
      default:
        return 0;
    }
  };
  /////console.log("get", getData);
  const [activeStep, setActiveStep] = useState("");
  useEffect(() => {
    setActiveStep(
      getStatusIndex(
        selectedOrder
          ? selectedOrder.orderstatus
          : getData && getData.orderstatus
      )
    );
  }, [selectedOrder, getData]);
  const getStepColor = (stepIndex) => {
    return stepIndex <= activeStep ? "black" : "rgba(231, 231, 231, 1)";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const orderstatus = [
    ...new Set(data?.map((item) => item.orderstatus)),
  ].sort();

  useEffect(() => {
    if (orderTrackingData && orderTrackingData?.length > 0) {
      setData(orderTrackingData);

      setLastObject(orderTrackingData[orderTrackingData?.length - 1]);
    }
  }, [orderTrackingData]);

  useEffect(() => {
    dispatch(orderTracking());
  }, []);

  useEffect(() => {
    const matchedOrder = data?.find(
      (order) => order.orderid === getData?.orderid
    );
    if (matchedOrder) {
      setSelectedOrder(matchedOrder);
    }
  }, [data, getData]);

  const handleOrderChange = (event) => {
    setSelectedOrder(
      data?.find((order) => order.orderid === event.target.value)
    );
  };

  const handleData = (row) => {
    setSelectedOrder(row);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCloseSubmission = () => {
    setOpenSubmission(false);
  };

  const toggleChat = () => {
    /////console.log("toggleChat called");
    setIsOpen(!isOpen);
  };
  const handleInputChange = (field, value) => {
    // Update the respective input value state
    switch (field) {
      case "userName":
        setUserName(value);
        break;
      case "hospital":
        setHospital(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
        break;
    }

    // Clear the error for the field if the user is typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Reset the error for the changed field
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    let hasEmptyField = false;

    ["userName", "hospital", "phone", "comment", "email"].forEach((key) => {
      const value =
        key === "userName"
          ? userName
          : key === "hospital"
          ? hospital
          : key === "phone"
          ? phone
          : key === "comment"
          ? comment
          : key === "email"
          ? email
          : "";
      const error = validateField(key, value);
      if (error) {
        formErrors[key] = error;
      }
    });

    if (!userName.trim()) {
      formErrors["userName"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!hospital.trim()) {
      formErrors["hospital"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!phone.trim()) {
      formErrors["phone"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!comment.trim()) {
      formErrors["comment"] = "This field is required.";
      hasEmptyField = true;
    }
    if (!email.trim()) {
      formErrors["email"] = "This field is required.";
      hasEmptyField = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors)?.length === 0 && !hasEmptyField) {
      dispatch(contact(userName, email, phone, comment, hospital));
      setOpen(false);
      setOpenSubmission(true);
      setTimeout(() => {
        handleCloseSubmission();
      }, 2000);
      setUserName("");
      setHospital("");
      setPhone("");
      setEmail("");
      setComment("");
      setErrors("");
      setIsOpen(!isOpen);
      //
    } else {
      // /////console.log("Form has errors. Please correct them.");
    }
  };

  const handleIconClick = () => {
    setUserName("");
    setHospital("");
    setPhone("");
    setEmail("");
    setComment("");
    setErrors("");
    setIsOpen(!isOpen);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "userName":
        if (!value.trim().match(/^[a-zA-Z.\s]+$/)) {
          error = "Invalid username";
        }
        break;
      case "hospital":
        if (!value.trim().match(/^[a-zA-Z\s]+$/)) {
          error = "Invalid hospital name";
        }
        break;
      case "email":
        // Enhanced email validation to ensure proper format
        if (
          !value
            .trim()
            .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
          error = "Please enter a valid email address.";
        }
        break;
      case "phone":
        if (!value.trim().match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "comment":
        if (!value.trim().match(/^[#.0-9a-zA-Z\s,-]+$/)) {
          error = "Invalid address format.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue?.length <= maxLength) {
      setComment(inputValue);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {!isMobileScreen ? (
        <>
          <DrawerBox
            data={data}
            setData={setData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            heading={"Order Tracking"}
            Filter1="orderstatus"
            Filter2=""
            sortPrice=""
            sortDate="date"
            name="orderid"
          />
          <StyledBox
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "20px",
              paddingBottom: "20px",
              alignItems: "start",
            }}
          >
            <LeftWrapper sx={{}}>
              <LeftBox1 sx={{}}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "10px",
                    width: "80%",
                    alignItems: "center",
                  }}
                >
                  <StepLabel>
                    <TbClipboardCheck
                      size={20}
                      style={{ color: getStepColor(1) }}
                    />
                  </StepLabel>
                  <StepLabel>
                    <FaTruck size={20} style={{ color: getStepColor(2) }} />
                  </StepLabel>
                  <StepLabel>
                    <TiTick size={20} style={{ color: getStepColor(3) }} />
                  </StepLabel>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={(activeStep / 3) * 100}
                  sx={{
                    width: "80%",
                    marginTop: "10px",
                    backgroundColor:
                      orderstatus === "shipped"
                        ? "primary"
                        : "rgba(231, 231, 231, 1)",
                  }}
                  color="primary"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "25px",
                    width: "80%",
                    alignItems: "center",
                  }}
                >
                  {steps.map((label, index) => (
                    <div key={label} style={{}}>
                      <StepLabel>
                        <Typography
                          variant="caption"
                          style={{
                            color: getStepColor(index + 1),
                            fontWeight: 600,
                          }}
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </div>
                  ))}
                </div>
              </LeftBox1>
              <LeftBox2>
                <Stack
                  sx={{
                    width: "90%",
                    justifyContent: "space-around",
                    alignItems: "center",

                    height: "90%",
                  }}
                >
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    <Label variant="button" color="primary">
                      Oder ID:
                      {(selectedOrder && selectedOrder.orderid) ||
                        (getData && getData.orderid)}
                    </Label>
                    <Label
                      variant="subtitle2"
                      style={{ color: "rgba(255, 153, 0, 1)" }}
                    >
                      {(selectedOrder && selectedOrder.totsavings_percent) ||
                        (getData && getData.totsavings_percent)}
                      % Saved
                    </Label>
                  </Stack>

                  <Stack flexDirection={"row"} sx={{ width: "100%" }}>
                    <Stack gap={"10px"} sx={{ width: "100%" }}>
                      <Label variant="subtitle2">Total Value</Label>
                      <Label2 variant="subtitle2">
                        ₹
                        <NumberFormat
                          number={
                            (selectedOrder && selectedOrder.totprice) ||
                            (getData && getData.totprice)
                          }
                        />
                      </Label2>
                    </Stack>
                    <Stack gap={"10px"} sx={{ width: "100%" }}>
                      <Label variant="subtitle2">Date</Label>
                      <Label2 variant="subtitle2">
                        {(selectedOrder && selectedOrder.date) ||
                          (getData && getData.date)}
                      </Label2>
                    </Stack>
                    <Stack gap={"10px"} sx={{ width: "100%" }}>
                      <Label variant="subtitle2">Quantity</Label>
                      <Label2 variant="subtitle2">
                        {(selectedOrder && selectedOrder.totquantity) ||
                          (getData && getData.totquantity)}
                        nos.
                      </Label2>
                    </Stack>
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    <Link
                      to={`/order-summary/${
                        (selectedOrder && selectedOrder.orderid) ||
                        (getData && getData.orderid)
                      }`}
                    >
                      <Button variant="outlined">Summary</Button>
                    </Link>

                    <Button variant="outlined" onClick={toggleChat}>
                      Pay Now
                    </Button>
                  </Stack>
                </Stack>
              </LeftBox2>
            </LeftWrapper>
            <div
              className="chat-box"
              style={{
                display: isOpen ? "flex" : "none",
                justifyContent: "space-evenly",
                flexDirection: "column",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Adding box-shadow for border effect
                borderRadius: "8px", // Optional: to round the corners
              }}
            >
              <div
                className="chat-box"
                style={{
                  display: isOpen ? "flex" : "none",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <Box
            component="img"
            src={profile}
            sx={{ width: "100%", height: "100px" }}
          /> */}
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100px",
                    }}
                  >
                    <Box
                      component="img"
                      src={profile}
                      alt="Profile"
                      sx={{ width: "100%", height: "100%" }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "rgba(193, 32, 37, 1)",
                        width: "20px",
                        height: "20px",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={toggleChat}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
                    How can we help you?
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="form" noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="Mr. Samuel Arora"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={userName}
                          onChange={(e) =>
                            handleInputChange("userName", e.target.value)
                          }
                        />
                        {errors.userName && (
                          <HelperText>{errors.userName}</HelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="Apollo Hospitals, Delhi"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },

                            startAdornment: (
                              <InputAdornment position="start">
                                <ApartmentIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={hospital}
                          onChange={(e) =>
                            handleInputChange("hospital", e.target.value)
                          }
                        />
                        {errors.hospital && (
                          <HelperText>{errors.hospital}</HelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="samuel008@gmail.com"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={email}
                          onChange={(e) =>
                            handleInputChange("email", e.target.value)
                          }
                        />
                        {errors.email && (
                          <HelperText>{errors.email}</HelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="011 7179 1090"
                          variant="filled"
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{
                            style: { height: "50px", fontSize: "15px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalPhoneIcon />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          value={phone}
                          onChange={(e) =>
                            handleInputChange("phone", e.target.value)
                          }
                        />
                        {errors.phone && (
                          <HelperText>{errors.phone}</HelperText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="filled"
                          name="text"
                          placeholder="Add comments"
                          multiline
                          maxRows={10}
                          InputProps={{
                            style: { height: "100px", fontSize: "15px" },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            maxLength: maxLength,
                          }}
                          helperText={`${comment?.length}/${maxLength} characters`}
                          value={comment}
                          onChange={(e) => {
                            handleInputChange("comment", e.target.value);
                            handleChange(e);
                          }}
                        />
                        {errors.comment && (
                          <HelperText>{errors.comment}</HelperText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={handleIconClick}
                          fullWidth
                          variant="outlined"
                          sx={{
                            mt: 3,
                            mb: 2,
                            border: "1px solid rgba(193, 32, 37, 1)",
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={handleSubmit}
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
              <Dialog open={openSubmission} onClose={handleCloseSubmission}>
                <Stack
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  sx={{ width: "420px", height: "200px" }}
                >
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      color: "rgba(193, 32, 37, 1)",
                    }}
                  >
                    Request submitted!
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DialogContentText
                      sx={{ textAlign: "center", color: "black" }}
                    >
                      <Typography variant="body2">
                        Your request has been successfully submitted.
                        <br />
                         Our team will get in touch with you soon.
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                </Stack>
              </Dialog>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    height:
                      orderTrackingData?.length > 0 && filteredData?.length > 0
                        ? "487px"
                        : "487px",
                    maxHeight: "487px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",

                    boxShadow:
                      "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
                  }}
                >
                  <Stack
                    sx={{
                      height: "100px",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      padding: "20px",
                      boxShadow:
                        "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                    }}
                  >
                    <Label
                      variant="button"
                      color="primary"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      aria-describedby={
                        isPopoverOpen ? "simple-popover" : undefined
                      }
                      onClick={handlePopoverClick}
                    >
                      {" "}
                      Live order
                      {isPopoverOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Label>
                    <Popover
                      id="simple-popover"
                      open={isPopoverOpen}
                      anchorEl={popoverAnchorEl}
                      onClose={handlePopoverClick}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography
                        sx={{ p: 2, cursor: "pointer" }}
                        onClick={() => navigate("/my-order")}
                      >
                        All Orders
                      </Typography>
                    </Popover>

                    {/* <Stack
                      flexDirection={"row"}
                      gap="20px"
                      sx={{
                        alignItems: "center",

                        justifyContent: "center",
                      }}>
                      <Label variant="button" color="primary">
                        07
                      </Label>
                      <Label
                        variant="caption"
                        style={{
                          color: "rgba(255, 153, 0, 1)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <RxDotFilled size={20} /> active
                      </Label>
                    </Stack> */}
                  </Stack>
                  <TableContainer sx={{ height: "100%", overflow: "auto" }}>
                    {orderTrackingData?.length > 0 &&
                    filteredData?.length > 0 ? (
                      <>
                        {" "}
                        <Table size="small" stickyHeader style={{}}>
                          <TableHead>
                            <TableRow>
                              <TableHeading sx={{ textAlign: "center" }}>
                                <Label variant="caption"> SL.NO</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> ORDER ID</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption"> DATE</Label>
                              </TableHeading>
                              <TableHeading>
                                <Label variant="caption">STATUS</Label>
                              </TableHeading>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData
                              // ?.slice(
                              //   page * rowsPerPage,
                              //   page * rowsPerPage + rowsPerPage
                              // )
                              ?.map((row, index) => (
                                <TableRow
                                  key={row.orderid}
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "rgba(255, 237, 237, 1)",
                                    },
                                  }}
                                  style={{
                                    boxShadow:
                                      "5px 4px 15px 0px rgba(230, 210, 209, 0.25) inset",
                                  }}
                                >
                                  <TableRows sx={{ textAlign: "center" }}>
                                    {page * rowsPerPage + index + 1}
                                  </TableRows>
                                  <TableRows>
                                    {row.orderid?.length > 11 ? (
                                      <Tooltip title={row.orderid}>
                                        <TableText
                                          onClick={() => handleData(row)}
                                          // component={Link}
                                          // to={`/order-summary/${row.orderid}`}
                                          variant="caption"
                                          style={{
                                            maxWidth: "11ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {/* {row.orderid.slice(0, 9) + "..."} */}
                                          {row.orderid}
                                        </TableText>
                                      </Tooltip>
                                    ) : (
                                      <TableText
                                        onClick={() => handleData(row)}
                                        // component={Link}
                                        // to={`/order-summary/${row.orderid}`}
                                        variant="caption"
                                        style={{
                                          maxWidth: "11ch",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {row.orderid}
                                      </TableText>
                                    )}
                                  </TableRows>

                                  <TableRows>
                                    <TableText variant="caption">
                                      {row.date}
                                    </TableText>
                                  </TableRows>
                                  <TableRows>
                                    {row.orderstatus === "Delivered" ? (
                                      <>
                                        <TableText
                                          variant="caption"
                                          style={{
                                            color: "rgba(54, 201, 2, 1)",
                                          }}
                                        >
                                          {row.orderstatus}
                                        </TableText>
                                      </>
                                    ) : (
                                      <>
                                        <TableText variant="caption">
                                          {row.orderstatus}
                                        </TableText>
                                      </>
                                    )}
                                  </TableRows>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {/* {filteredData?.length > rowsPerPage && (
                          <TablePagination
                            sx={{ width: "95%", overflow: "hidden" }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )} */}
                      </>
                    ) : (
                      <>
                        <Box1
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <Typography variant="subtitle2">
                            No orders have been placed yet.
                          </Typography>
                          <Label2 variant="subtitle2">
                            Add to your cart now
                          </Label2>
                          <Button variant="contained" size="small">
                            PLACE NEW ORDER
                          </Button>
                        </Box1>
                      </>
                    )}
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </StyledBox>
          <LoadingPage />
        </>
      ) : (
        <>
          <div>
            <AppBar position="fixed" id="navbar">
              <Navbar heading={"My order"} />
            </AppBar>
            <Toolbar />
            <Box
              sx={{
                height: "100%",

                overflow: "auto",
              }}
            >
              <Stack
                sx={{
                  backgroundColor: "#f0f0f0",

                  position: "fixed",

                  zIndex: 1,

                  bgcolor: "rgba(241, 241, 241, 1)",
                  // justifyContent: "space-around",
                  alignItems: "start",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100%",
                  height: "100%",

                  // overflow: "auto",
                }}
                id="search-bar"
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",

                    padding: "20px",
                    bgcolor: "rgba(241, 241, 241, 1)",
                    gap: "20px",

                    width: "100%",
                    height: "100%",

                    //justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      gap: "10px",

                      width: "100%",
                    }}
                  >
                    <InnerBox
                      sx={{
                        width: "70%",
                        height: "142px",
                      }}
                    >
                      <Stack
                        sx={{
                          padding: "10px",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <Label sx={{ paddingLeft: "10px" }}>
                          Active orders
                        </Label>

                        <Select
                          value={
                            (selectedOrder && selectedOrder.orderid) ||
                            (getData && getData.orderid) ||
                            (lastObject && lastObject.orderid)
                          }
                          onChange={handleOrderChange}
                          sx={{
                            width: 200,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            "& .MuiOutlinedInput-root": {
                              border: "none",
                            },
                            "& .MuiSelect-root": {
                              borderBottom: "none",
                            },
                            borderRadius: "30px",
                            "& fieldset": { border: "none" },

                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {data.map((order, index) => (
                            <MenuItem key={index} value={order.orderid}>
                              <Label variant="body2" noWrap>
                                {order.orderid}
                              </Label>
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </InnerBox>
                    <InnerBox
                      sx={{
                        width: "30%",
                        height: "142px",
                      }}
                    >
                      <Stack
                        sx={{
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          alignItems: "center",

                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <Label
                          variant="body2"
                          sx={{ color: "rgba(255, 153, 0, 1)" }}
                        >
                          Active{" "}
                        </Label>
                        <Stack sx={{ alignItems: "center" }}>
                          <Label variant="body2">04</Label>
                          <Label variant="body2">Orders</Label>
                        </Stack>
                      </Stack>
                    </InnerBox>
                  </Stack>
                  <InnerBox
                    sx={{
                      width: "100%",
                      height: "40%",
                      justifyContent: "space-around",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",

                        height: "100%",
                      }}
                    >
                      <Stack
                        style={{
                          display: "flex",
                          justifyContent: "space-around",

                          alignItems: "center",
                          padding: "0px",

                          height: "90%",
                        }}
                      >
                        <StepLabel>
                          <TbClipboardCheck
                            size={20}
                            style={{ color: getStepColor(1) }}
                          />
                        </StepLabel>
                        <StepLabel>
                          <FaTruck
                            size={20}
                            style={{ color: getStepColor(2) }}
                          />
                        </StepLabel>
                        <StepLabel>
                          <TiTick
                            size={20}
                            style={{ color: getStepColor(3) }}
                          />
                        </StepLabel>
                      </Stack>

                      <Box
                        sx={{
                          display: "flex",
                          height: "80%",
                          p: 2,
                        }}
                      >
                        <VerticalLinearProgress
                          variant="determinate"
                          color="secondary"
                          value={(activeStep / 3) * 100}
                          sx={{
                            [`& .${linearProgressClasses.bar}`]: {
                              transform: `translateY(${
                                (activeStep / 3) * 100
                              }%)!important`,
                            },
                          }}
                        />
                      </Box>
                      <Stack
                        style={{
                          display: "flex",
                          justifyContent: "space-around",

                          alignItems: "center",
                          height: "90%",
                        }}
                      >
                        {steps.map((label, index) => (
                          <div key={label} style={{}}>
                            <StepLabel>
                              <Typography
                                variant="caption"
                                style={{
                                  color: getStepColor(index + 1),
                                  fontWeight: 600,
                                }}
                              >
                                {label}
                              </Typography>
                            </StepLabel>
                          </div>
                        ))}
                      </Stack>
                    </Stack>
                  </InnerBox>
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Buttons>Summary</Buttons>
                    <Buttons>Edit Order</Buttons>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
              id="footer"
            >
              <Footer />
            </AppBar>
          </div>
        </>
      )}
    </>
  );
};

export default OrderTracking;
