import * as React from "react";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading_page from "./Loading_Page";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";

import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../redux/auth/action";
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    gap: "80px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const InnerContainer = styled(Container)(({ theme }) => ({
  bgcolor: "white",

  height: "463px",
  borderRadius: "10px",
  display: "flex",
  width: "521px",
  alignItems: "center",
  justifyContent: "center",

  border: "2px solid rgba(255, 255, 255, 1)",
  boxShadow:
    "10px 10px 20px 0px rgba(230, 210, 209, 0.6), -10px -10px 15px 0px rgba(255, 255, 255, 1), 5px 5px 15px 2px rgba(230, 210, 209, 0.3) inset",
  paddingBottom: "10px",
  marginTop: "4%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TitleBox = styled(Box)(({ theme }) => ({
  width: "384px",

  flexDirection: "row",
  display: "flex",
  justifyContent: "start",
  alignItems: "start",

  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "30px",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Fields = styled(TextField)(({ theme }) => ({
  width: "384px",
  borderRadius: "5px",
  "& fieldset": { border: "none" },
  background: "rgba(240, 240, 240, 1)",

  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Buttons = styled(Button)(({ theme }) => ({
  width: "182px",
  height: "45px",
  fontWeight: 700,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ButtonBox = styled(Box)(({ theme }) => ({
  width: "384px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "10%",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
    gap: "20px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  textAlign: "start",

  width: "100%",
}));

export default function SignIn() {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [showPassword, setShowPassword] = React.useState(false);
  const [savedTime, setSavedTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  let token = localStorage.getItem("userToken");

  const navigate = useNavigate();

  const signin = useSelector((store) => store.auth.signIn);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameError, setIsUsernameError] = useState("");
  const [isPasswordError, setIsPasswordError] = useState("");
  const dispatch = useDispatch();

  const handleUserName = (e) => {
    const value = e.target.value;
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (value === "" || regex.test(value.trim())) {
      setUserName(value.trim());
      setIsUsernameError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setIsUsernameError("Enter valid email");
    }
    if (value === "") {
      setIsUsernameError("Please fill the field");
    }
  };

  const handlePassword = (e) => {
    const value = e.target.value;

    if (value?.length >= 6) {
      setPassword(value.trim());
      setIsPasswordError("");
    }
    if (value.trim().length < 6) {
      setIsPasswordError("Password must have 6 characters");
    }
    if (value === "") {
      setIsPasswordError("Please fill the field");
    }
  };

  const handleClick = () => {
    if (userName !== "" && password !== "") {
      if (
        password?.length >= 6 &&
        isUsernameError === "" &&
        isPasswordError === ""
      ) {
        let data = {
          email: userName.trim(),
          password: password.trim(),
        };

        dispatch(signIn(data));
        localStorage.setItem("loginEvent", Date.now().toString());
      }
    }
    userName === "" && setIsUsernameError("Please fill the field");
    password === "" && setIsPasswordError("Please fill the field");
  };

  useEffect(() => {
    if (signin?.success === true) {
      const firstBranchId = signin.userdata.branchid[0];
      const firstBranchName = signin.userdata.branchname[0];

      const branchData = {
        branchId: firstBranchId,
        branchName: firstBranchName,
      };

      localStorage.setItem("branchData", JSON.stringify(branchData));
      window.location.reload();

      navigate("/dashboard");
    }
  }, [token, navigate, signin, token]);

  useEffect(() => {
    const savedTime = localStorage.getItem("savedTime");
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - savedTime;

    if (savedTime && timeDifference < 60000) {
      setIsButtonDisabled(true);
      setRemainingTime(Math.ceil((60000 - timeDifference) / 1000));

      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - savedTime;

        const remainingSeconds = Math.ceil((60000 - timeDifference) / 1000);

        if (remainingSeconds > 0) {
          setRemainingTime(remainingSeconds);
        } else {
          localStorage.removeItem("savedTime");
          setSavedTime(null);
          setIsButtonDisabled(false);
          setRemainingTime(0);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isButtonDisabled]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  return (
    <Wrapper>
      {/* <Box as="img" src={logo} /> */}
      <InnerContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TitleBox sx={{ height: "50px" }}>
            {/* {!isMobileScreen && (
              <Box
                onClick={() => navigate(-1)}
                sx={{ width: "40%", display: "flex", justifyContent: "start" }}>
                <IoArrowBackCircle size={40} color="rgba(193, 32, 37, 1)" />
              </Box>
            )} */}

            <Box
              sx={{
                width: "100%",
                textAlign: !isMobileScreen ? "start" : "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "25px" }}>
                Sign In
              </Typography>
            </Box>
          </TitleBox>
          <Box
            component="form"
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              alignItems: "center",
            }}
          >
            <Fields
              size="small"
              margin="normal"
              placeholder="Email Address"
              type="email"
              onChange={handleUserName}
              onKeyPress={handleKeyPress}
              InputProps={{ style: { height: "45px" } }}
              autoComplete="off"
            />
            <HelperText>{isUsernameError}</HelperText>

            <Fields
              size="small"
              margin="normal"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                style: { height: "45px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handlePassword}
              onKeyPress={handleKeyPress}
              autoComplete="new-email"
            />

            <HelperText>{isPasswordError}</HelperText>

            {signin?.success === false && (
              <Typography
                variant="caption"
                sx={{ color: "rgba(193, 32, 37, 1)" }}
              >
                INVALID_LOGIN_CREDENTIALS
              </Typography>
            )}
            <ButtonBox sx={{ marginTop: "18%" }}>
              {!isMobileScreen && (
                <Buttons
                  onClick={() => navigate("/forgot-password")}
                  sx={{
                    border: "2px solid rgba(193, 32, 37, 1)",
                    color: "rgba(193, 32, 37, 1)",
                  }}
                >
                  Forgot Password
                </Buttons>
              )}

              <Buttons
                onClick={handleClick}
                sx={{
                  bgcolor: "rgba(193, 32, 37, 1)",
                  border: "1px solid #000000",
                  "&:hover": {
                    bgcolor: "rgba(193, 32, 37, 1)",
                    color: "white",
                  },
                  color: "white",
                }}
              >
                Sign In{" "}
              </Buttons>
            </ButtonBox>
          </Box>
        </Box>
        <Loading_page />
      </InnerContainer>

      {isMobileScreen && (
        <>
          <Box
            onClick={() => navigate("/forgot-password")}
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid rgba(193, 32, 37, 1)",
              borderRadius: "5px",
            }}
          >
            <Button
              onClick={() => navigate("/forgot-password")}
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
                borderRadius: "1px",
                textTransform: "none",
                width: "271.38px",
              }}
            >
              Forgot Password?
            </Button>
          </Box>
        </>
      )}
    </Wrapper>
  );
}
